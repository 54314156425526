import { get, sumBy } from 'lodash';

export default function calculateLimit(data) {
  const totalMonthlyIncomes = sumBy(get(data, 'monthlyIncomes', []), (item) => get(item, 'amount', 0));
  const totalMonthlyExpenses = sumBy(get(data, 'monthlyExpenses', []), (item) => get(item, 'costs', 0));
  const totalAssets = sumBy(get(data, 'assets', []), (item) => get(item, 'value', 0));

  const calculated = (totalMonthlyIncomes + totalAssets) - totalMonthlyExpenses;
  const calculatedGlobalLimit = calculated > 0
    ? Math.floor(calculated / 500) * 500
    : 0;
  const calculatedDesiredGlobalLimit = calculatedGlobalLimit * 1;

  return {
    actualCalculatedGlobalLimit: calculated,
    calculatedGlobalLimit,
    calculatedDesiredGlobalLimit,
    totalAssets,
    totalMonthlyExpenses,
    totalMonthlyIncomes
  };
}
