import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isNil, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col } from 'reactstrap';
import { Checkbox } from '@evoja-web/react-form';
import { withAcl } from '@evoja-web/client-acl';

import NoCustomerChange from '../../../../components/Workguide/NoCustomerChangeError';
import setAdvisorActions from '../../../../actions/Actions';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../../Workguide/hocs';

class SetAdvisorWorkguideEbankingContractKubeEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasCustomerChanged: false
    };

    this.onCustomerChange = this.onCustomerChange(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Handle customer change in workguide instance data
   * Set hasCustomerChanged to true and display a no customer change possible error
   *
   * @return  void
   */
  onCustomerChange() {
    this.setState({ hasCustomerChanged: true });
  }

  /**
   * Handle submit click
   *
   * @return  void
   */
  onSubmit() {
    const {
      acl,
      setAdvisorActions,
      task,
      toggleRedirectModal
    } = this.props;

    const skipUserCheck = acl.isAllowed('Bpf.SkipUserCheck');
    const taskId = get(task, 'id');

    setAdvisorActions.ebankingKubeEditFormSaveRequest({
      skipUserCheck,
      taskId
    });

    toggleRedirectModal();
  }

  /**
   * Render the missing poas
   *
   * @return {ReactElement} markup
   */
  renderMissingPoas() {
    const { process } = this.props;

    return get(process, 'missingPoas', []).map((poa) => {
      const {
        clientDesignation = '',
        clientKey,
        editClientNumber = ''
      } = poa;

      return (
        <Row className="pb-2 ps-4">
          <Col lg={12} md={12}>
            <Checkbox key={clientKey} disabled>
              {`${clientDesignation} (${editClientNumber})`}
            </Checkbox>
          </Col>
        </Row>
      );
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { hasCustomerChanged } = this.state;
    const {
      customer,
      onFormCancel,
      process,
    } = this.props;
    const processSubStatusKey = get(process, 'subStatusKey');

    if (hasCustomerChanged) {
      return (
        <NoCustomerChange />
      );
    }

    const hasMissingBasisContract = get(process, 'basisContract', false);
    const hasMissingPoas = get(process, 'missingPoas.length', 0) > 0;

    return (
      <div className="setadvisor-workguide-ebanking-contract-kube-edit">
        <WorkguideCustomerAware
          onChange={this.onCustomerChange}
          onInit={this.onCustomerChange}
        >
          <h5>
            <FormattedMessage id="Freemium.Workguide.EBankingContractKubeEdit.Title" />
          </h5>

          <Row style={{ paddingBottom: '2rem' }} />

          {!hasMissingBasisContract || processSubStatusKey === 'product-addDeleteAccountsEBankingContract-postprocess' && (
            <Row className="pb-4">
              <Col lg={4} md={4}>
                <FormattedMessage id="Freemium.Workguide.EBankingContractKubeEdit.BaseContractMissing" />
              </Col>

              <Col lg={8} md={8}>
                <FormattedMessage id="General.Yes" />
              </Col>
            </Row>
          )}

          {hasMissingPoas && (
            <>
              <Row className="pb-4 pt-2">
                <Col lg={12} md={12}>
                  <FormattedMessage id="Freemium.Workguide.EBankingContractKubeEdit.MissingPoaDescription" />
                </Col>
              </Row>

              <Row className="pb-4">
                <Col lg={12} md={12}>
                  <FormattedMessage
                    id="Freemium.Workguide.EBankingContractKubeEdit.MissingPoaTitle"
                    tagName="h6"
                  />
                </Col>
              </Row>

              {this.renderMissingPoas()}
            </>
          )}

          <Row className="pb-4 pt-4">
            <Col lg={12} md={12}>
              <FormattedMessage id="Freemium.Workguide.EBankingContractKubeEdit.ExecuteProcessDescription" />
            </Col>
          </Row>

          <Row className="pt-5">
            <Col lg={12} md={12} className="text-end">
              <Button
                color="primary"
                outline
                onClick={onFormCancel}
              >
                <FormattedMessage id="Freemium.Workguide.EBankingContractKubeEdit.Cancel" />
              </Button>

              <span className="pe-2" />

              <Button
                color="primary"
                disabled={isNil(customer)}
                onClick={this.onSubmit}
              >
                <FormattedMessage id="Freemium.Workguide.EBankingContractKubeEdit.Submit" />
              </Button>
            </Col>
          </Row>
        </WorkguideCustomerAware>
      </div>
    );
  }
}

SetAdvisorWorkguideEbankingContractKubeEdit.propTypes = {
  acl: PropTypes.object.isRequired,
  customer: PropTypes.object,
  onFormCancel: PropTypes.func,
  process: PropTypes.object.isRequired,
  setAdvisorActions: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func
};

SetAdvisorWorkguideEbankingContractKubeEdit.defaultProps = {
  customer: undefined,
  onFormCancel: noop,
  toggleRedirectModal: noop
};

function mapStateToProps(state, ownProps) {
  return {
    process: get(ownProps, 'bpf.process'),
    task: get(ownProps, 'bpf.task')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAdvisorActions: bindActionCreators(setAdvisorActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    withAcl()(SetAdvisorWorkguideEbankingContractKubeEdit)
  )
);
