import { call, put, } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import actions from '../../actions/Form/OptionComfort';
import modalActions from '../../../Modal/actions/Actions';

import {
  toIntIfSet
} from '../../../../lib/Utils/index';

const {
  OPTION_COMFORT_FORM_SAVE_PENDING,
  OPTION_COMFORT_FORM_SAVE_FULFILLED,
  OPTION_COMFORT_FORM_SAVE_REJECTED,
} = actions;
const { LOADING_MODAL_REQUEST, LOADING_MODAL_FULFILLED, LOADING_MODAL_REJECTED } = modalActions;

export default function* saveOptionComfortForm(request) {
  yield put({ type: OPTION_COMFORT_FORM_SAVE_PENDING });
  yield put({ type: LOADING_MODAL_REQUEST, dataKey: 'workguideAsync' });
  try {
    const {
      customerId,
      tenant = 6300,
      accountId,
      consultantId
    } = request;

    const data = {
      variables: {
        clientKey: toIntIfSet(customerId),
        responsibleUser: toIntIfSet(consultantId),
        processCallOrigin: 'MAP',
        debitAccountForFee: toIntIfSet(accountId),
      },
      businessKey: 'Product ModuleComfort',
      resultVariableNames: [
        'callingProcessKeyDE',
        'callingProcessKeyFR',
        'endStatus'
      ],
      waitTimeoutInMs: 20000,
    };

    const result = yield call(axios, {
      url: `/sif/bpf/public/api/v1/process-definition/key/valiant-product-moduleComfortV1/tenant-id/${tenant}/start`,
      method: 'post',
      data
    });

    const payload = get(result, 'data', {});

    yield put({ type: OPTION_COMFORT_FORM_SAVE_FULFILLED, payload });
    yield put({ type: LOADING_MODAL_FULFILLED, dataKey: 'workguideAsync' });
    return payload;
  } catch (error) {
    yield put({ type: OPTION_COMFORT_FORM_SAVE_REJECTED, error });
    yield put({ type: LOADING_MODAL_REJECTED, dataKey: 'workguideAsync', error });

    return error;
  }
}
