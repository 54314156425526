import { get } from 'lodash';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';

import toIntIfSet from '../../../../../../lib/Utils/toIntIfSet';

export default function OrderCctToolPayload({
  customer,
  form,
  session
}) {
  return removeUnwantedValues({
    responsibleUser: toIntIfSet(get(session, 'id')),
    processCallOrigin: 'MAP',
    creditCardAccount: { clientKey: toIntIfSet(get(customer, 'id')) },
    debitAccountForFee: get(form, 'data.debitAccount.accountKey')
  }, [undefined]);
}
