import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import userInfoAction from '../actions/UserInfo';

const {
  USER_INFO_PENDING,
  USER_INFO_FULFILLED,
  USER_INFO_REJECTED
} = userInfoAction;

export default function* getUserInfo() {
  yield put({ type: USER_INFO_PENDING });

  try {
    const url = '/_auth/userinfo';
    const { data: payload } = yield call(axios, {
      url,
      method: 'get'
    });

    yield put({ type: USER_INFO_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: USER_INFO_REJECTED, error });

    return error;
  }
}
