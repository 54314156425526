import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, isUndefined, findKey, has } from 'lodash';
import { Row } from 'reactstrap';
import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../../../General/components/SubSection';

import './EmbossedLines.scss';

const SummaryCards = React.memo(({ data }) => {
  const getIdForCard = (card) => {
    const categoryType = get(card, 'categoryType');

    const cardTypeCategorieMessages = {
      'Freemium.Form.CreditCard.CardCategory.Main': categoryType === 'HK',
      'Freemium.Form.CreditCard.CardCategory.Additional': categoryType === 'ZK',
      'Freemium.Form.CreditCard.CardCategory.Combo': categoryType === 'DC'
    };

    const message = findKey(cardTypeCategorieMessages, (value) => value === true);
    const id = !isUndefined(message)
      ? message
      : 'Freemium.Form.Summary.Card.CategoryTypeUndefined';

    return id;
  };

  return (
    data.map((card, index) => {
      return (
        <SubSection>
          <SubSectionTitle>
            <FormattedMessage id={getIdForCard(card)} />
            {` ${index + 1}`}
          </SubSectionTitle>
          <SubSectionContent>
            <Row className="embossed-lines-section-content--row">
              <strong style={{ width: 'auto', paddingRight: '5px' }}>
                <FormattedMessage id="Freemium.Form.CreditCard.CardHolder.EmbossedLineCardHolder" />
                :
              </strong>
              {' '}
              {get(card, 'embossedLineOne')}
            </Row>
            {has(card, 'embossedLineTwo') && (
            <Row className="embossed-lines-section-content--row">
              <strong style={{ width: 'auto', paddingRight: '5px' }}>
                <FormattedMessage id="Freemium.Form.CreditCard.CardHolder.EmbossedLineAccountHolder" />
                :
              </strong>
              {' '}
              {get(card, 'embossedLineTwo')}
            </Row>
            )}
          </SubSectionContent>
        </SubSection>
      );
    })
  );
});

SummaryCards.propTypes = {
  data: PropTypes.object,
};

SummaryCards.defaultProps = {
  data: {},
};

export default SummaryCards;
