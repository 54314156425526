import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import actions from '../../actions/Form/OpenNewLilaSet';
import modalActions from '../../../Modal/actions/Actions';

import { toStringIfSet, toIntIfSet } from '../../../../lib/Utils';

const { OPEN_LILA_SET_SAVE_PENDING, OPEN_LILA_SET_SAVE_FULFILLED, OPEN_LILA_SET_SAVE_REJECTED } = actions;
const { LOADING_MODAL_REQUEST, LOADING_MODAL_FULFILLED, LOADING_MODAL_REJECTED } = modalActions;

export default function* openNewLilaSet(request) {
  yield put({ type: OPEN_LILA_SET_SAVE_PENDING });

  try {
    const {
      payload, tenant = 6300, customerId, consultantId
    } = request;
    yield put({ type: LOADING_MODAL_REQUEST, dataKey: 'workguideAsync' });
    const result = yield call(axios, {
      url: '/sif/bpf/public/api/v1/message',
      method: 'post',
      data: {
        messageName: 'valiant-product-lilaSetV2',
        businessKey: toStringIfSet(customerId),
        processVariables: {
          ...payload,
          processCallOrigin: 'MAP',
          responsibleUser: toIntIfSet(consultantId),
        },
        resultEnabled: true,
        waitTimeoutInMs: 5000,
      },
    });

    yield put({ type: OPEN_LILA_SET_SAVE_FULFILLED, result });
    yield put({ type: LOADING_MODAL_FULFILLED, dataKey: 'workguideAsync' });
    return payload;
  } catch (error) {
    yield put({ type: OPEN_LILA_SET_SAVE_REJECTED, error });
    yield put({ type: LOADING_MODAL_REJECTED, dataKey: 'workguideAsync', error });

    return error;
  }
}
