import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, noop, chain } from 'lodash';

import { CodeSelect } from '../../../../../General/index';

import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../General/components/SubSection/index';

const relationParts = [
  '302',
  '303',
  '309',
  '10',
  '88',
  '95',
  '19',
  '89',
  '90',
  '92',
  '94',
  '29',
  '75',
  '310',
  '9996',
  '9995',
  '9994',
  '311',
  '93',
  '9',
  '80',
  '83'
];

function getRelationPartCodes(codes) {
  return get(codes, 'groups.relationPart', [])
    .filter((code) => relationParts.includes(get(code, 'number')));
}

const RelationshipToMainCardHolder = React.memo(({
  codes,
  id,
  language,
  onChange,
  validations,
  value
}) => {
  return (
    <SubSection>
      <p className="mb-2">
        <FormattedMessage id="Freemium.Form.CreditCard.RelationshipToMainCardHolder.Title" />
      </p>

      <SubSectionContent>
        <CodeSelect
          codes={getRelationPartCodes(codes)}
          id={id}
          language={language}
          onChange={onChange}
          showValidations
          validations={validations}
          value={value}
        />
      </SubSectionContent>
    </SubSection>
  );
});

RelationshipToMainCardHolder.propTypes = {
  codes: PropTypes.object,
  id: PropTypes.string,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.object
};

RelationshipToMainCardHolder.defaultProps = {
  codes: { groups: {} },
  id: 'relationshipToMainCardHolder',
  language: 'de',
  onChange: noop,
  validations: {},
  value: undefined
};

export default RelationshipToMainCardHolder;
