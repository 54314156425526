import { DefaultReducer } from '@evoja-web/redaction';
import update from 'immutability-helper';
import { get } from 'lodash';
import actions, { markNotificationAsReadAction } from '../actions/MarkNotificationRead';

const { MARK_NOTIFICATION_AS_READ_FULFILLED } = actions;

export const reducer = DefaultReducer('readNotifications', markNotificationAsReadAction);

function updateNotifications(state, action) {
  const { notifications } = state;
  const response = action.payload;

  const updatedNotifications = get(notifications, 'data', []).map((notification) => {
    if (response.includes(get(notification, '_id', get(notification, 'id')))) {
      return {
        ...notification,
        read: true
      };
    }
    return notification;
  });

  return update(state, {
    notifications: {
      data: { $set: updatedNotifications }
    }
  });
}

reducer.after(MARK_NOTIFICATION_AS_READ_FULFILLED, updateNotifications);
export default reducer.get();
