import React from 'react';
import { configuration as formConfiguration } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import { configuration } from '@evoja-web/entris-portal-shared';
import { registerFunction } from '@metrichor/jmespath-plus';
import { ComponentConfiguration } from '@evoja-web/component-configuration';

import { getValidator, getCodeMapper, getModalHandler } from './globals';
import { shouldAddPledgeDocumentCheck, maritalStatusIsTogetherCheck } from './lib/Entris/Utils/index';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { extendJmesPath, commonJmesFunction } from './packages/JmesPath/index';
import { init as initBpfSecuritiesAccountPackage } from './packages/BpfSecuritiesAccount/index';
import { init as initBpfDocumentPackage } from './packages/BpfDocument/index';
import { init as initBpfCmsPackage } from './packages/BpfCms/index';
import { init as initAirlockPackage } from './packages/Airlock/index';
import { init as initLilaSetPackage } from './packages/Freemium/index';
import { init as initEnforcementsPackage } from './packages/Enforcement/index';
import { init as initBpfOnboarding } from './packages/BpfOnboarding/index';
import {
  init as initEdossierPackage,
  jmesFunctions as eDossierJmesFunctions
} from './packages/EDossier/index';
import { init as initActivityPackage } from './packages/Activity/index';
// See comment in ./packages/Lead/index.js
import initLeadPackage from './packages/Lead/init';
import initCardPackage from './packages/Card/init';
import initProvisionPackage from './packages/Provision/init';
import initInterestPackage from './packages/Interest/init';
import initLoaderPackage from './packages/Loader/init';
import initRelationPackage from './packages/Relation/init';
import initSpsPackage from './packages/Sps/init';

/**
 * Init packages (from /src/packages as well as external @evoja-web packages).
 * Atm. this is done here. If asnyc is needed, we have to move this to a boostrap step...
 *
 * @return  void
 */
function initPackages() {
  const codeMapper = getCodeMapper();
  const modalHandler = getModalHandler();
  const validator = getValidator();

  const params = { codeMapper, modalHandler, validator };
  initActivityPackage(params);
  initAirlockPackage(params);
  initBpfCmsPackage(params);
  initBpfDocumentPackage(params);
  initBpfOnboarding(params);
  initBpfSecuritiesAccountPackage(params);
  initCardPackage(params);
  initEdossierPackage(params);
  initEnforcementsPackage(params);
  initInterestPackage(params);
  initLeadPackage(params);
  initLoaderPackage(params);
  initLilaSetPackage(params);
  initProvisionPackage(params);
  initRelationPackage(params);
  initSpsPackage(params);
}

/**
 * Init necessary stuff
 *
 * @return  void
 */
export default function init() {
  // Disable service worker
  unregisterServiceWorker();
  // Extend jmes path with custom funcitons
  extendJmesPath({
    functions: { ...commonJmesFunction, ...eDossierJmesFunctions },
    registerFunction
  });

  // Per default, entris-portal-shared uses the tenant of the given customer.
  // As this project is not a multi tenant environment, we need to overwrite this behaviour and use
  // 6300 as fixed tenant.
  const tenantConfiguration = {
    isMultiTenant: false,
    tenant: 6300,
    fallbackTenant: 6300,
  };

  // For some reason the customerSex codes in MAP / Portal are different.
  // customerSex-1 in portal is male, in MAP female
  const codeConfiguration = {
    customerSex: {
      male: 'customerSex-2',
      female: 'customerSex-1'
    }
  };

  ComponentConfiguration.setConfigValue('@evoja-web/entris-portal-shared', 'Tenant', tenantConfiguration);
  ComponentConfiguration.setConfigValue('@evoja-web/entris-portal-shared', 'Code', codeConfiguration);
  ComponentConfiguration.setConfigValue('@evoja-web/entris-portal-shared', 'PrintQrCode', true);

  configuration.configure({
    isMultiTenant: false,
    tenant: 6300,
    fallbackTeneant: 6300,
    mailTemplateId: 'mailtemplate-entb',
    customerIdProp: 'id',
    beatLoaderColor: '#794cb2',
    shouldAddPledgeDocumentCheck,
    maritalStatusIsTogetherCheck,
    togetherMaritalStatus: ['customerMaritalStatus-10', 'customerMaritalStatus-2']
  });

  formConfiguration.configure({
    validator: getValidator(),
    translationFunction: (message, values) => <FormattedMessage id={message} values={values} />
  });

  initPackages();
}
