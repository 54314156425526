import { call, put } from 'redux-saga/effects';
import axios from 'axios';

// ToDo: Move functions like removeUnwantedProperties/removeUnwantedValues to own repo
import removeUnwantedProperties from '../../../../lib/Utils/removeUnwantedProperties';
import removeUnwantedValues from '../../../../lib/Utils/removeUnwantedValues';
import connectAccountsToContractAction from '../../actions/Form/ConnectAccountsToContract';

const {
  CONNNECT_ACCOUNTS_TO_CONTRACT_SAVE_PENDING,
  CONNNECT_ACCOUNTS_TO_CONTRACT_SAVE_FULFILLED,
  CONNNECT_ACCOUNTS_TO_CONTRACT_SAVE_REJECTED
} = connectAccountsToContractAction;

export default function* saveConnectAccountsToContract(request) {
  const { data, tenant = 6300 } = request;

  yield put({ type: CONNNECT_ACCOUNTS_TO_CONTRACT_SAVE_PENDING });

  try {
    let payload = {
      variables: {
        ...data
      },
      businessKey: 'valiant-product-addDeleteAccountsEBankingContract',
      resultVariableNames: [
        'callingProcessKeyDE',
        'callingProcessKeyFR',
        'endStatus'
      ],
      waitTimeoutInMs: 40000
    };

    payload = removeUnwantedValues(payload, [null, undefined]);
    payload = removeUnwantedProperties(
      payload,
      [
        '_id',
        'recordOrigin',
        '_loadModifiedData',
        '_loadCreatedAt',
        'lastModified',
        'recordOrigin',
        'acl',
        'rank',
        'employeeId'
      ]
    );

    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-product-addDeleteAccountsEBankingContract/tenant-id/${tenant}/start`;
    yield call(axios, {
      url,
      method: 'post',
      data: payload
    });

    yield put({ type: CONNNECT_ACCOUNTS_TO_CONTRACT_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONNNECT_ACCOUNTS_TO_CONTRACT_SAVE_REJECTED, error });

    return error;
  }
}
