import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isEmpty, flatten, isUndefined, compact, isNil, head } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import update from 'immutability-helper';
import axios from 'axios';
import { withCodeMapper } from '@evoja-web/entity-code';
import { withAcl } from '@evoja-web/client-acl';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import NoPoasError from '../../../../../components/Workguide/NoPoasError';
import freemiumActions from '../../../../../actions/Actions';
import { getValidator } from '../../../../../../../globals';
import { isCompany, isDoublePartner } from '../../../../../../../lib/Customer/Utils';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../../../Workguide/hocs';
import EBankingContractForm, {
  AssignmentAccounts,
  getOldEbankingWorkguideLink,
  hasExistingEbankingContract
} from '../../../../../components/Form/EBankingContract/EBankingContractForm';
import ReactivateInformation from '../../../../../components/Form/EBankingContract/DirectActivation/ReactivateInformation';

const validationDefinition = {
  validations: {
    poa: {
      type: 'object',
      required: true
    },
    selectedPoaProfileCompletion: {
      type: 'boolean',
      required: ({ parent }) => !isEmpty(get(parent, 'customerAccounts', [])),
      validations: {
        isTrue: []
      }
    },
    shippingAddress: {
      type: 'object',
      required: true
    },
    customerAccounts: {
      type: 'array',
      required: ({ parent }) => get(parent, 'poaAccounts.length', 0) === 0
    },
    poaAccounts: {
      type: 'array',
      required: ({ parent }) => get(parent, 'customerAccounts.length', 0) === 0
    }
  }
};

/**
 * Check if the selected customer has the necessary poas
 *
 * @param   {Object}  poas  Result from /valiant-product-loadPoas
 *
 * @return  {Boolean}
 */
function hasNecessaryPoas(givenPoas, poas) {
  const eBankingPoas = givenPoas
    .filter((poa) => get(poa, 'poaType') === 6026 && get(poa, 'signingAuthority') === 'INDIVIDUALLY');

  return get(poas, 'fulfilled', false) && get(eBankingPoas, 'length', 0) > 0;
}

class SetAdvisorWorkguideEbankingContractOrderViaMobileApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gravitonInformation: undefined,
      grvLoading: false
    };
    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPoaAccountsChange = this.onPoaAccountsChange.bind(this);
    this.fetchGravitonInformation = this.fetchGravitonInformation.bind(this);
  }

  /**
   * Set defaults as soon everything is loaded
   *
   * @param   {Object}  prevProps Prev props
   *
   * @return  void
   */
  componentDidUpdate(prevProps) {
    const {
      poas,
      requesting,
      freemiumActions
    } = this.props;
    const { requesting: prevRequesting } = prevProps;

    const eBankingPoas = get(poas, 'data.resultVariables.givenPoas', [])
      .filter((poa) => get(poa, 'poaType') === 6026 && get(poa, 'signingAuthority') === 'INDIVIDUALLY');

    if (!requesting && prevRequesting) {
      freemiumActions.ebankingOrderViaMobileFormSetValue('customerAccounts', AssignmentAccounts(get(eBankingPoas, 0)));
      freemiumActions.ebankingOrderViaMobileFormSetValue('addFutureOpenedAccounts', true);
      freemiumActions.ebankingOrderViaMobileFormSetValue('edocuments', true);
    }
  }

  /**
   * Reset form on unmount
   *
   * @return  void
   */
  componentWillUnmount() {
    const { freemiumActions } = this.props;

    freemiumActions.ebankingOrderViaMobileFormReset();
  }

  /**
   * Handle customer change in workguide instance data
   *
   * @param   {Object}  customer  Selected customer
   *
   * @return  void
   */
  onCustomerChange(customer) {
    const { freemiumActions, bpfTask } = this.props;

    if (!this.isBusinessOrPartnerCustomer()) {
      const process = get(bpfTask, 'data.process');
      this.fetchGravitonInformation(get(process, 'clientKey'), get(process, 'contractId'));
      const customerId = get(customer, 'id');
      freemiumActions.customerPoasRequest({ dataKey: customerId, loadAccounts: true });
      freemiumActions.customerAccountsRequest({ dataKey: customerId });
      freemiumActions.loadLilaSetRequest({ dataKey: customerId });
      freemiumActions.orderCheckRequest({ customerId });
    }
  }

  /**
   * Submit the form and toggle the redirect modal
   *
   * @return  {[type]}  [return description]
   */
  onSubmit() {
    const {
      customer,
      form,
      session,
      freemiumActions,
      toggleRedirectModal,
      bpfTask,
      acl
    } = this.props;
    const poaAccounts = flatten(get(form, 'data.poaAccounts', []));
    const taskId = get(bpfTask, 'data.task.id');
    freemiumActions.ebankingOrderViaMobileFormSaveRequest({
      customerId: get(customer, 'id'),
      data: { ...get(form, 'data', {}), poaAccounts },
      responsibleUser: get(session, 'id'),
      taskId,
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
    });

    toggleRedirectModal();
  }

  onPoaAccountsChange(key, account, index) {
    const { freemiumActions, form } = this.props;
    const accounts = get(form, `data.${key}`, []);

    const updated = update(accounts, {
      [index]: { $set: isUndefined(account) ? undefined : account }
    });

    freemiumActions.ebankingOrderViaMobileFormSetValue(key, updated);
  }

  /**
   * fetch grv customerNumberAlternate from customer
   * @param {*} clinetKey bpf customer id
   */
  async fetchGravitonInformation(clientKey, contractId) {
    this.setState({ grvLoading: true });
    let customer;
    let contract;
    if (!isNil(clientKey)) {
      const { data } = await axios.get(`/person/customer/${clientKey}?select(customerNumberAlternate)`);
      customer = data;
    }
    if (!isNil(contractId)) {
      const { data } = await axios.get(`/entity/contract/?eq(number,string:${contractId})&limit(1)`);
      contract = head(data);
    }

    try {
      this.setState({ gravitonInformation: { customer, contract }, grvLoading: false });
    } catch (error) {
      this.setState({ grvLoading: false });
      console.error('error fetching customer and contract information');
    }
  }

  /**
   * Validate current form data
   *
   * @return  {Object} validations Validation result
   */
  validate() {
    const { form } = this.props;

    const validator = getValidator();
    const poaAccounts = compact(flatten(get(form, 'data.poaAccounts')), isEmpty);
    // @Mänu maybe you can help me make this code cleaner reguarding the poaAccounts, valiadation does no work when poaAccounts
    // is a empty array
    const formData = { ...get(form, 'data', {}), poaAccounts: isEmpty(poaAccounts) ? undefined : poaAccounts };
    return validator.validate(validationDefinition, formData);
  }

  isBusinessOrPartnerCustomer() {
    const { customer } = this.props;
    return isDoublePartner(customer) || isCompany(customer);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customer,
      givenPoas,
      poas,
      requesting,
      hasExistingOrder,
      receivedPoas,
      language,
      form,
      lilaSet,
      onFormCancel,
      freemiumActions,
      bpfTask
    } = this.props;
    const hasExistingContract = hasExistingEbankingContract(get(form, 'data.poa'), lilaSet);
    const { gravitonInformation, grvLoading } = this.state;
    const process = get(bpfTask, 'data.process');
    const validations = this.validate();
    const isBusinessOrPartnerCustomer = this.isBusinessOrPartnerCustomer();
    const hasPoas = hasNecessaryPoas(givenPoas, poas);
    return (
      <div className="setadvisor-workguide-ebanking-contract-order">
        <h5>
          <FormattedMessage id="Freemium.Workguide.EBankingContract.Title" />
        </h5>

        <p className="pb-4">
          <FormattedMessage
            id="Freemium.Workguide.EBankingContract.Hint"
            values={{
              workguideLink: (chunks) => getOldEbankingWorkguideLink(chunks, customer)
            }}
          />
        </p>

        <WorkguideCustomerAware
          onChange={this.onCustomerChange}
          onInit={this.onCustomerChange}
          requesting={requesting || grvLoading}
          showLoadIndicator
        >
          {hasExistingOrder && (
          <Alert type="error">
            <AlertContent>
              <FormattedMessage id="Freemium.Workguide.EBankingContract.ExistingOrderWarning" />
            </AlertContent>
          </Alert>
          )}

          {isBusinessOrPartnerCustomer && (
            <Alert type="error">
              <AlertContent>
                <FormattedMessage id="Freemium.Workguide.EBankingContract.NoBusinessCustomer" />
              </AlertContent>
            </Alert>
          )}
          {(!hasPoas && !isBusinessOrPartnerCustomer) && (
            <NoPoasError />
          )}

          {!isBusinessOrPartnerCustomer && hasPoas && !hasExistingOrder
          && (
            <React.Fragment>
              <ReactivateInformation process={process} gravitonInformation={gravitonInformation} />
              <EBankingContractForm
                form={form}
                language={language}
                receivedPoas={receivedPoas}
                customer={customer}
                poas={poas}
                validations={validations}
                onChange={freemiumActions.ebankingOrderViaMobileFormSetValue}
                hasExistingContract={hasExistingContract}
              />
            </React.Fragment>
          )}

          <Row className="pt-5">
            <Col lg={12} md={12} className="text-start">
              <Button
                color="primary"
                outline
                onClick={onFormCancel}
              >
                <FormattedMessage id="Freemium.Workguide.EBankingContract.Cancel" />
              </Button>

              <span className="pe-2" />

              <Button
                color="primary"
                disabled={isNil(customer) || !isEmpty(validations) || hasExistingContract}
                onClick={this.onSubmit}
              >
                <FormattedMessage id="Freemium.Workguide.EBankingContract.Submit" />
              </Button>
            </Col>
          </Row>
        </WorkguideCustomerAware>
      </div>
    );
  }
}

SetAdvisorWorkguideEbankingContractOrderViaMobileApp.propTypes = {
  customer: PropTypes.object,
  form: PropTypes.object,
  language: PropTypes.string,
  lilaSet: PropTypes.object,
  onFormCancel: PropTypes.func,
  poas: PropTypes.object,
  requesting: PropTypes.bool,
  session: PropTypes.object.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  acl: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func,
  hasExistingOrder: PropTypes.bool,
  receivedPoas: PropTypes.array,
  givenPoas: PropTypes.array,
  // eslint-disable-next-line react/no-unused-prop-types
  mapper: PropTypes.object.isRequired,
  bpfTask: PropTypes.object
};

SetAdvisorWorkguideEbankingContractOrderViaMobileApp.defaultProps = {
  customer: undefined,
  form: {},
  language: 'de',
  lilaSet: {},
  onFormCancel: noop,
  poas: {},
  requesting: false,
  toggleRedirectModal: noop,
  hasExistingOrder: false,
  receivedPoas: [],
  givenPoas: [],
  bpfTask: {}
};

function isRequesting(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return (
    get(state, `freemium.customerAccounts.${customerId}.requesting`, false)
    || get(state, `freemium.customerPoas.${customerId}.requesting`, false)
    || get(state, `freemium.lilaSet.${customerId}.requesting`, false)
  );
}

function removeMortgageAccountFromPoas(poas, mapper) {
  const mortgageTypes = mapper.get('setAdvisorAccountTypes', 'mortgage');
  return poas.map((poa) => {
    const filteredAccount = get(poa, 'assignmentAccounts', [])
      .filter((account) => !mortgageTypes.includes(`accountType-${get(account, 'accountType')}`));
    return { ...poa, assignmentAccounts: filteredAccount };
  });
}

function mapStateToProps(state, ownProps) {
  const { mapper } = ownProps;
  const customerId = get(ownProps, 'customer.id');
  const customerAccounts = get(state, `freemium.customerAccounts.${customerId}`);
  const poas = get(state, `freemium.customerPoas.${customerId}`);
  const lilaSet = get(state, `freemium.lilaSet.${customerId}`);
  return {
    customerAccounts,
    form: state.freemium.eBankingOrderViaMobileAppForm,
    language: state.login.language,
    lilaSet,
    poas,
    givenPoas: removeMortgageAccountFromPoas(get(poas, 'data.resultVariables.givenPoas', []), mapper),
    receivedPoas: removeMortgageAccountFromPoas(get(poas, 'data.resultVariables.receivedPoas', []), mapper),
    session: state.login.session,
    requesting: isRequesting(state, ownProps),
    hasExistingOrder: get(state, 'freemium.hasExistingOrder.data.count', 0) > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeMapper()(
    withAcl()(connect(mapStateToProps, mapDispatchToProps)(SetAdvisorWorkguideEbankingContractOrderViaMobileApp))
  )
);
