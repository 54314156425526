import React from 'react';
import PropTypes from 'prop-types';
import { get, upperFirst } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Row from './Row';

const Address = React.memo(({ address, type }) => {
  return (
    <Row label={<FormattedMessage id={`Freemium.Form.Summary.CardHolder.${upperFirst(type)}Address`} />}>
      {`${get(address, 'street')} ${get(address, 'houseNumber')}, ${get(address, 'postcode')} ${get(address, 'place')}`}
    </Row>
  );
});

Address.propTypes = {
  address: PropTypes.object,
  type: PropTypes.string
};

Address.defaultProps = {
  address: {},
  type: 'domicile'
};

export default Address;
