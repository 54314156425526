import { registerCustomWorkguide } from '../Workguide';
import WorkguideSetAdvisorConnectAccountsToContract from './containers/Form/ConnectAccountsToContract/Form';
import WorkguideSetAdvisorWorkguideDissolveLilaSet from './containers/Form/DissolveLilaSet/DissolveLilaSet';
import WorkguideSetAdvisorWorkguideNewLilaSet from './containers/Form/NewLilaSet/NewLilaSet';
import WorkguideSetAdvisorWorkguideOpenAccount from './containers/Form/OpenAccount/OpenAccount';
import WorkguideSetAdvisorWorkguideOpenOptionComfort from './containers/Form/OpenOptionComfort/OpenOptionComfort';
import WorkguideSetAdvisorWorkguideOpenOptionPaper from './containers/Form/OpenOptionPaper/OpenOptionPaper';

import WorkguideSetAdvisorWorkguidePrivateCreditCardApproval from './containers/Workguide/Private/CreditCard/Approval';
import WorkguideSetAdvisorWorkguidePrivateCreditCardCanceled from './containers/Workguide/Private/CreditCard/Canceled';
import WorkguideSetAdvisorWorkguidePrivateCreditCardCctTool from './containers/Workguide/Private/CreditCard/CctTool';
import WorkguideSetAdvisorWorkguidePrivateCreditCardKubeEdit from './containers/Workguide/Private/CreditCard/KubeEdit';
import WorkguideSetAdvisorWorkguidePrivateCreditCardOrder from './containers/Workguide/Private/CreditCard/Order';
import WorkguideSetAdvisorWorkguidePrivateCreditCardOrderWithoutModule from './containers/Workguide/Private/CreditCard/OrderWithoutModule';
import WorkguideSetAdvisorWorkguidePrivateEbankingContractOrder from './containers/Workguide/Private/EBankingContract/Order';
import WorkguideSetAdvisorWorkguidePrivateEbankingContractOrderViaMobileApp from './containers/Workguide/Private/EBankingContract/DirectActivation/OrderViaMobileApp';
import WorkguideSetAdvisorWorkguidePrivateEbankingContractReactivate from './containers/Workguide/Private/EBankingContract/DirectActivation/Reactivate';
import WorkguideSetAdvisorWorkguidePrivateEbankingContractKubeEdit from './containers/Workguide/Private/EBankingContract/KubeEdit';
import WorkguideSetAdvisorContractDispatchRules from './containers/Workguide/Private/DispatchRule/ContractDispatchRules';
import WorkguideSetAdvisorContractDispatchRulesPostProcessing from './containers/Workguide/Private/DispatchRule/ContractDispatchRulesPostProcessing';

export default function initSetAdvisorPackage() {
  registerCustomWorkguide({ key: 'SetAdvisorWorkguideOpenOptionComfort', component: WorkguideSetAdvisorWorkguideOpenOptionComfort });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguideNewLilaSet', component: WorkguideSetAdvisorWorkguideNewLilaSet });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguideOpenAccount', component: WorkguideSetAdvisorWorkguideOpenAccount });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguideOpenModulePaper', component: WorkguideSetAdvisorWorkguideOpenOptionPaper });

  registerCustomWorkguide({ key: 'SetAdvisorConnectAccountsToContract', component: WorkguideSetAdvisorConnectAccountsToContract });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguideDissolveLilaSet', component: WorkguideSetAdvisorWorkguideDissolveLilaSet });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguidePrivateCreditCardApproval', component: WorkguideSetAdvisorWorkguidePrivateCreditCardApproval });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguidePrivateCreditCardCanceled', component: WorkguideSetAdvisorWorkguidePrivateCreditCardCanceled });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguidePrivateCreditCardCctTool', component: WorkguideSetAdvisorWorkguidePrivateCreditCardCctTool });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguidePrivateCreditCardKubeEdit', component: WorkguideSetAdvisorWorkguidePrivateCreditCardKubeEdit });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguidePrivateCreditCardOrder', component: WorkguideSetAdvisorWorkguidePrivateCreditCardOrder });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguidePrivateDispatchRules', component: WorkguideSetAdvisorContractDispatchRules });
  registerCustomWorkguide({ key: 'SetAdvisorWorkguideBackofficeDispatchRules', component: WorkguideSetAdvisorContractDispatchRulesPostProcessing });
  registerCustomWorkguide({
    key: 'SetAdvisorWorkguidePrivateCreditCardOrderWithoutModule',
    component: WorkguideSetAdvisorWorkguidePrivateCreditCardOrderWithoutModule
  });
  registerCustomWorkguide({
    key: 'SetAdvisorWorkguidePrivateEbankingContractOrder',
    component: WorkguideSetAdvisorWorkguidePrivateEbankingContractOrder
  });
  registerCustomWorkguide({
    key: 'SetAdvisorWorkguidePrivateEbankingContractViaMobileApp',
    component: WorkguideSetAdvisorWorkguidePrivateEbankingContractOrderViaMobileApp
  });
  registerCustomWorkguide({
    key: 'SetAdvisorWorkguideSetAdvisorWorkguidePrivateEbankingContractReactivate',
    component: WorkguideSetAdvisorWorkguidePrivateEbankingContractReactivate
  });
  registerCustomWorkguide({
    key: 'SetAdvisorWorkguidePrivateEbankingContractKubeEdit',
    component: WorkguideSetAdvisorWorkguidePrivateEbankingContractKubeEdit
  });
}
