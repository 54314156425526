import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { get } from 'lodash';

import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../General/components/SubSection';

function ExistingAccounts({ accounts, language }) {
  return (
    <SubSection className="existing-accounts--container">
      <SubSectionTitle>
        <FormattedMessage id="Freemium.Form.NewLilaSet.ExistingAccounts" values={{ amount: accounts.length }} />
      </SubSectionTitle>
      <SubSectionContent>
        {accounts.map((account) => {
          return (
            <Row style={{ width: '90%', paddingTop: 10 }}>
              <Col md={3} lg={3}>{get(account, `accountTypeText.${language}`)}</Col>
              <Col md={3} lg={3}>{account.editAccountNumber}</Col>
              <Col md={6} lg={6}>{account.clientDesignation}</Col>
            </Row>
          );
        })}
      </SubSectionContent>
    </SubSection>
  );
}

ExistingAccounts.propTypes = {
  accounts: PropTypes.array.isRequired,
  language: PropTypes.string
};

ExistingAccounts.defaultProps = {
  language: 'de'
};

export default ExistingAccounts;
