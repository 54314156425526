import { get } from 'lodash';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';

import MainCardHolder from './MainCardHolder';
import AdditionalCardHolder from './AdditionalCardHolder';
import ResponsibleApprover from './ResponsibleApprover';
import CalculatedGlobalLimit from './CalculatedGlobalLimit';
import FinancialSituation from './FinancialSituation';
import toIntIfSet from '../../../../../../lib/Utils/toIntIfSet';

export default function OrderMainAndAdditionalCardPayload({
  calculation,
  form,
  session
}) {
  return removeUnwantedValues({
    responsibleApprover: ResponsibleApprover({ form }),
    responsibleUser: toIntIfSet(get(session, 'id')),
    processCallOrigin: 'MAP',
    creditCardAccount: {
      accountKey: get(form, 'data.connectionAccount.accountKey'),
      clientKey: get(form, 'data.connectionAccount.clientKey')
    },
    beneficialOwner: get(form, 'data.beneficialOwner'),
    debitAccountForFee: get(form, 'data.debitAccount.accountKey'),
    paymentType: get(form, 'data.paymentType'),
    mainCardHolder: MainCardHolder({ form }),
    additionalCardHolder: AdditionalCardHolder({ form }),
    calculatedGlobalLimit: CalculatedGlobalLimit({ calculation, form }),
    desiredGlobalLimit: get(form, 'data.desiredGlobalLimit'),
    financialSituation: FinancialSituation({ form })
  }, [undefined]);
}
