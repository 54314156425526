import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEmpty, isNil } from 'lodash';
import { Button, CloseButton, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertContent, AlertTitle } from '@evoja-web/react-layout-components';

import modalActions from '../../actions/Actions';
import { BeatLoader } from '../../../General';
import SuccessMessage from '../../components/LoadingModal/SuccessMessage';
import workguideActions from '../../../Workguide/actions/Actions';
import './LoadingModal.css';

class LoadingModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
    this.renderLoadingContent = this.renderLoadingContent.bind(this);
    this.onWorkguideFulfilled = this.onWorkguideFulfilled.bind(this);
  }

  onWorkguideFulfilled() {
    const { workguideActions, modalActions, dataKey } = this.props;
    modalActions.loadingModalFulfilled({ dataKey, payload: { isOpen: false } });
    workguideActions.workguideFormReset();
    workguideActions.workguideModalReset();
  }

  closeModal() {
    const { modalActions, dataKey } = this.props;
    modalActions.loadingModalFulfilled({ dataKey, payload: { isOpen: false } });
  }

  renderErrors() {
    const { modal } = this.props;
    const errorMessage = get(modal, 'error') instanceof Error
      ? get(modal, 'error.message')
      : get(modal, 'error');

    return (
      <Alert type="error" style={{ margin: '20px 0', width: '100%' }}>
        <AlertTitle>
          <FormattedMessage id="Workguide.LoadingModal.ErrorTitle" />
        </AlertTitle>
        <AlertContent>
          <p>
            {errorMessage}
          </p>
          <br />
          <Button outline color="primary" onClick={this.closeModal}>
            <FormattedMessage id="Workguide.LoadingModal.TryAgain" />
          </Button>
        </AlertContent>
      </Alert>
    );
  }

  renderLoadingContent() {
    const { modal } = this.props;
    return (
      <>
        <div className="loading-modal--paragraph">
          <FormattedMessage id="Workguide.RedirectModal.Line1" />
        </div>

        <div className="loading-modal--paragraph">
          {get(modal, 'data.loading', false) && (
          <div className="loading-modal--paragraph">
            <BeatLoader />
          </div>
          )}
        </div>

        <div className="loading-modal--paragraph">
          <FormattedMessage id="Workguide.RedirectModal.Line2" />
        </div>
      </>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      children,
      className,
      modal,
    } = this.props;
    if (isEmpty(modal)) return null;

    const classNames = cl(
      'loading-modal',
      className,
      get(modal, 'data.props.className')
    );

    return (
      <Modal
        {...get(modal, 'data.props', {})}
        backdrop="static"
        backdropClassName="loading-modal-backdrop"
        modalClassName={classNames}
        isOpen={get(modal, 'data.isOpen', false)}
        size="lg"
        unmountOnClose
        toggle={this.closeModal}
      >
        <ModalHeader
          close={
            (get(modal, 'fulfilled', false) || get(modal, 'error'))
              ? <CloseButton onClick={this.closeModal} />
              : <div />
            }
          toggle={this.closeModal}
        >
          <FormattedMessage id="Workguide.LoadingModal.Title" />

        </ModalHeader>

        <ModalBody>
          <ModalBody className="loading-modal">
            {get(modal, 'error', false) && this.renderErrors()}
            {get(modal, 'requesting', false) && this.renderLoadingContent()}
            {(get(modal, 'fulfilled', false) && !get(modal, 'error', false)) && (
            <SuccessMessage
              redirectTo={this.onWorkguideFulfilled}
              title={<FormattedMessage id="Card.Workguide.SuccessMessage" />}
              countdownTime={3}
            />
            )}

            {!isNil(children) && (
            <div className="loading-modal--paragraph">
              {children}
            </div>
            )}

          </ModalBody>
        </ModalBody>
      </Modal>
    );
  }
}

LoadingModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  dataKey: PropTypes.string.isRequired,
  modal: PropTypes.object,
  workguideActions: PropTypes.object.isRequired,
  modalActions: PropTypes.object.isRequired
};

LoadingModal.defaultProps = {
  children: undefined,
  className: undefined,
  modal: {}
};

function mapStateToProps(state, ownProps) {
  const { dataKey } = ownProps;

  return {
    modal: get(state, `modal.loadingModal.${dataKey}`, {})
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    workguideActions: bindActionCreators(workguideActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingModal);
