import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, head, isNil } from 'lodash';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { withAcl } from '@evoja-web/client-acl';

import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ReactivateInformation from '../../../../../components/Form/EBankingContract/DirectActivation/ReactivateInformation';
import { WorkguideCustomerAware, withWorkguideInstanceData } from '../../../../../../Workguide/hocs';
import freemiumActions from '../../../../../actions/Actions';
import CommentInput from '../../../../../components/Form/Common/Comments/Comments';

class Reactivate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gravitonInformation: undefined,
      requesting: false
    };
    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.fetchGravitonInformation = this.fetchGravitonInformation.bind(this);
    this.submit = this.submit.bind(this);
  }

  onCustomerChange() {
    const { bpfTask } = this.props;
    const process = get(bpfTask, 'data.process');
    this.fetchGravitonInformation(get(process, 'clientKey'), get(process, 'contractId'));
  }

  /**
   * fetch graviton information
   * @param {*} clientKey customerId
   * @param {*} contractId contractId
   */
  async fetchGravitonInformation(clientKey, contractId) {
    this.setState({ requesting: true });
    let customer;
    let contract;
    if (!isNil(clientKey)) {
      const { data } = await axios.get(`/person/customer/${clientKey}?select(customerNumberAlternate)`);
      customer = data;
    }
    if (!isNil(contractId)) {
      const { data } = await axios.get(`/entity/contract/?eq(number,string:${contractId})&limit(1)`);
      contract = head(data);
    }

    try {
      this.setState({ gravitonInformation: { customer, contract }, requesting: false });
    } catch (error) {
      this.setState({ requesting: false });
      console.error('error fetching customer and contract information');
    }
  }

  /**
   * submit form
   * @param {*} kdcCheckOk bool if check was approved or not
   */
  submit(kdcCheckOk) {
    const {
      freemiumActions, form, bpfTask, toggleRedirectModal, acl
    } = this.props;
    freemiumActions.reactivateSaveRequest({
      comments: get(form, 'data.comments', []),
      kdcCheckOk,
      taskId: get(bpfTask, 'data.task.id'),
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
    });
    toggleRedirectModal();
  }

  render() {
    const { bpfTask, session, freemiumActions } = this.props;
    const { gravitonInformation, requesting } = this.state;
    const process = get(bpfTask, 'data.process');
    const taskId = get(bpfTask, 'data.task.id');

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        requesting={requesting}
        showLoadIndicator
      >
        {!isNil(taskId) && (
          <>
            <ReactivateInformation
              process={process}
              gravitonInformation={gravitonInformation}
            />

            <CommentInput
              session={session}
              onChange={freemiumActions.reactivateSetValue}
              timeFormat="lll"
            />

            <Row style={{ textAlign: 'left' }}>
              <Col lg="auto">
                <Button
                  color="primary"
                  onClick={() => this.submit(true)}
                >
                  <FormattedMessage id="Freemium.Workguide.Reactivate.SubmitButton" />
                </Button>
              </Col>

              <Col lg="auto">
                <Button
                  outline
                  color="primary"
                  onClick={() => this.submit(false)}
                >
                  <FormattedMessage id="Freemium.Workguide.Reactivate.RejectButton" />
                </Button>
              </Col>
            </Row>
          </>
        )}
      </WorkguideCustomerAware>
    );
  }
}

Reactivate.propTypes = {
  bpfTask: PropTypes.object,
  freemiumActions: PropTypes.object.isRequired,
  acl: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func.isRequired,
  form: PropTypes.object,
};

Reactivate.defaultProps = {
  bpfTask: {},
  form: {}
};

function isRequesting(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return (
    get(state, `freemium.customerAccounts.${customerId}.requesting`, false)
      || get(state, `freemium.customerPoas.${customerId}.requesting`, false)
      || get(state, `freemium.lilaSet.${customerId}.requesting`, false)
  );
}

function mapStateToProps(state, ownProps) {
  return {
    form: state.freemium.eBankingReactivationForm,
    language: state.login.language,
    requesting: isRequesting(state, ownProps),
    session: state.login.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    withAcl()(Reactivate)
  )
);
