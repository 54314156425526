import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Row from './Row';

const PoaStatus90 = React.memo(({ status }) => {
  const value = status
    ? <FormattedMessage id="General.Yes" />
    : <FormattedMessage id="General.No" />;

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.PoaStatus90" />}>
      {value}
    </Row>
  );
});

PoaStatus90.propTypes = {
  status: PropTypes.bool
};

PoaStatus90.defaultProps = {
  status: false
};

export default PoaStatus90;
