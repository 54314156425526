import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';

import { InputField } from '../../../../../General/index';

import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../General/components/SubSection/index';
import normalizeEmbossedLine from '../../../../lib/CreditCard/normalizeEmbossedLine';

class EmbossedLineOne extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  /**
   * Handle onChange of the embossed line input
   *
   * @param  {String} id     Form element id
   * @param  {String} value  Input value
   *
   * @return void
   */
  onChange(key, value) {
    const {
      cards,
      categoryType,
      onChange
    } = this.props;

    const updated = cards.map((card) => {
      return get(card, 'categoryType') === categoryType
        ? update(card, {
          embossedLineOne: { $set: value }
        })
        : card;
    });

    onChange('cards', updated);
  }

  /**
   * Normalize the input value when user leaves the input field
   *
   * @param  {String} id    Form element id
   * @param  {String} value Input value
   *
   * @return void
   */
  onBlur(id, value) {
    const normalized = normalizeEmbossedLine(value);

    this.onChange(id, normalized);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      cards,
      categoryType,
      id,
      validations
    } = this.props;

    const card = cards.find((card) => get(card, 'categoryType') === categoryType);

    return (
      <SubSection>
        <p className="mb-2">
          <FormattedMessage id="Freemium.Form.CreditCard.CardHolder.EmbossedLineOne" />
        </p>
        <SubSectionContent>
          <InputField
            id={id}
            onBlur={this.onBlur}
            onChange={this.onChange}
            showValidations
            validations={validations}
            value={get(card, 'embossedLineOne')}
          />
        </SubSectionContent>
      </SubSection>
    );
  }
}

EmbossedLineOne.propTypes = {
  cards: PropTypes.array.isRequired,
  categoryType: PropTypes.string.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object
};

EmbossedLineOne.defaultProps = {
  id: 'embossedLineOne',
  onChange: noop,
  validations: {}
};

export default EmbossedLineOne;
