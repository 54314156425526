import { DefaultReducer } from '@evoja-web/redaction';
import update from 'immutability-helper';
import { get, isNil, toNumber } from 'lodash';
import {
  notificationBadgeCountAction,
  DECREASE_NOTIFICATION_BADGE_COUNT,
  INCREASE_NOTIFICATION_BADGE_COUNT,
  SET_NOTIFICATION_BADGE_COUNT
} from '../actions/BadgeCount';

const getCurrentBadgeCount = () => {
  const badgeCount = localStorage.getItem('badgeCount');
  return isNil(badgeCount) ? 0 : toNumber(badgeCount);
};

const defaultState = {
  count: getCurrentBadgeCount()
};

const updateBadgeCount = (state, action, increment) => {
  const currentCount = state.badgeCount.count || 0;
  const countChange = toNumber(get(action, 'payload.count', 1)); // Default to 1 if no count is provided
  const adjustment = increment ? countChange : -countChange; // Decide increase or decrease
  const newCount = Math.max(0, currentCount + adjustment);

  localStorage.setItem('badgeCount', newCount);

  return update(state, {
    badgeCount: {
      count: { $set: newCount }
    }
  });
};

const setBadgeCount = (state, action) => {
  const { count } = action.payload;
  if (!isNil(count)) {
    localStorage.setItem('badgeCount', count);

    return update(state, {
      badgeCount: {
        count: { $set: toNumber(count) }
      }
    });
  }
  return state;
};

export const reducer = DefaultReducer('badgeCount', notificationBadgeCountAction, defaultState);

reducer.addMethod(SET_NOTIFICATION_BADGE_COUNT, setBadgeCount);
reducer.addMethod(INCREASE_NOTIFICATION_BADGE_COUNT, (state, action) => updateBadgeCount(state, action, true));
reducer.addMethod(DECREASE_NOTIFICATION_BADGE_COUNT, (state, action) => updateBadgeCount(state, action, false));

export default reducer.get();
