import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { noop } from 'lodash';
import cl from 'classnames';
import { actions as documentPreviewActions, getIdFromGravitonUrl } from '@evoja-web/react-document-preview';

import './DocumentPreview.css';

class LimitCalculationDocumentPreview extends React.Component {
  constructor(props) {
    super(props);

    this.onPreview = this.onPreview.bind(this);
  }

  onPreview() {
    const {
      documentPreviewActions,
      uri
    } = this.props;

    const dataKey = getIdFromGravitonUrl(uri);
    documentPreviewActions.gravitonFileRequest({ dataKey, showModal: true });
  }

  onRemove(...args) {
    const {
      documentPreviewActions,
      onFileRemove,
      uri
    } = this.props;

    const dataKey = getIdFromGravitonUrl(uri);
    documentPreviewActions.fileReset({ dataKey });

    onFileRemove(uri, ...args);
  }

  render() {
    const {
      label,
      onFileRemove,
      removeable
    } = this.props;

    return (
      <div className="setadvisor-form-limit-calculation--document-preview">
        <span
          className="mdi mdi-file-eye-outline setadvisor-form-limit-calculation--document-preview--icon"
          onClick={this.onPreview}
        />

        <div className="setadvisor-form-limit-calculation--document-preview--file">
          {label}
        </div>

        {removeable && (
          <span
            className={cl([
              'mdi',
              'mdi-minus-circle-outline',
              'setadvisor-form-limit-calculation--document-preview--remove'
            ])}
            onClick={onFileRemove}
          />
        )}
      </div>
    );
  }
}

LimitCalculationDocumentPreview.propTypes = {
  documentPreviewActions: PropTypes.object.isRequired,
  uri: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  onFileRemove: PropTypes.func,
  removeable: PropTypes.bool
};

LimitCalculationDocumentPreview.defaultProps = {
  uri: undefined,
  label: '',
  onFileRemove: noop,
  removeable: false
};

function mapDispatchToProps(dispatch) {
  return {
    documentPreviewActions: bindActionCreators(documentPreviewActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(LimitCalculationDocumentPreview);
