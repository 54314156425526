import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { has, get, every, compact, isEmpty, isUndefined, noop } from 'lodash';
import { actions as documentPreviewActions, getIdFromGravitonUrl } from '@evoja-web/react-document-preview';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withCodeGroups } from '@evoja-web/entity-code';
import { Alert, AlertTitle, AlertContent, StepContainer, Step, StepButtons } from '@evoja-web/react-layout-components';
import moment from 'moment';

import './Order.css';
import GeneralForm from '../../../Form/CreditCard/General';
import LimitCalculationForm from '../../../Form/CreditCard/LimitCalculation';
import LimitDeterminationForm from '../../../Form/CreditCard/LimitDetermination';
import LimitDeterminationSummary from '../../../../components/Form/CreditCard/Summary/LimitDetermination';
import ResponsibleUsers from '../../../../components/Form/CreditCard/Summary/ResponsibleUsers';
import { actions as bpfCmsActions } from '../../../../../BpfCms/index';
import freemiumActions from '../../../../actions/Actions';
import { withWorkguideInstanceData } from '../../../../../Workguide/hocs';

/**
   * Render isNotOldEnough Error
   *
   * @return {ReactElement} markup
   */
function renderIsNotOldEnoughError() {
  return (
    <Alert type="error">
      <AlertTitle>
        <FormattedMessage
          id="Freemium.Form.General.Messages.Errors.IsNotOldEnoughTitle"
        />
      </AlertTitle>
      <AlertContent>
        <FormattedMessage
          id="Freemium.Form.General.Messages.Errors.IsNotOldEnoughDescription"
        />
      </AlertContent>
    </Alert>
  );
}

const GENERAL_STEP_ID = 'general';
const LIMIT_CALCULATION_STEP_ID = 'limitCalculation';
const LIMIT_DETERMINATION_STEP_ID = 'limitDetermination';

class SetAdvisorWorkguidePrivateCreditCardOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: GENERAL_STEP_ID
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.shouldRenderLimitDetermination = this.shouldRenderLimitDetermination.bind(this);
    this.shouldRenderLimitCalculation = this.shouldRenderLimitCalculation.bind(this);
    this.onActiveStepChange = this.onActiveStepChange.bind(this);
  }

  componentWillUnmount() {
    const {
      documentPreviewActions,
      files,
      freemiumActions
    } = this.props;

    freemiumActions.creditCardOrderFormReset();
    files.forEach((file) => documentPreviewActions.fileReset({ dataKey: get(file, 'id') }));
  }

  /**
   * Submit the form and place order
   *
   * @return void
   */
  onSubmit() {
    const {
      calculation,
      customer,
      files,
      form,
      orderWithoutModule,
      session,
      freemiumActions
    } = this.props;

    freemiumActions.creditCardOrderFormSaveRequest({
      customer,
      calculation,
      files,
      form,
      orderWithoutModule,
      session
    });
  }

  onActiveStepChange(activeStep) {
    this.setState({ activeStep });
  }

  /**
   * Get form validations based on active steps
   *
   * @return {Object} validations Object containing active form validations
   */
  getValidations() {
    const { formValidations } = this.props;

    const active = {
      creditCardGeneral: get(formValidations, 'creditCardGeneral.data', {})
    };

    if (this.shouldRenderLimitCalculation()) {
      active.creditCardLimitCalculation = get(formValidations, 'creditCardLimitCalculation.data', {});
    }

    if (this.shouldRenderLimitDetermination()) {
      active.creditCardLimitDetermination = get(formValidations, 'creditCardLimitDetermination.data', {});
    }

    return active;
  }

  shouldRenderLimitDetermination() {
    const { form } = this.props;
    const { active } = get(form, 'data', {});

    return active;
  }

  shouldRenderLimitCalculation() {
    const { form } = this.props;
    const { cards, orderInCctTool } = get(form, 'data', {});

    return (!isUndefined(cards) && get(cards, 'length', 0) > 0) && !orderInCctTool;
  }

  /**
   * Render errors
   *
   * @return {ReactElement} markup
   */
  renderErrors() {
    const { errors } = this.props;

    return (
      <Alert type="error">
        <AlertTitle>
          <FormattedMessage
            id="Freemium.Form.General.Messages.Errors.Title"
          />
        </AlertTitle>
        <AlertContent>
          <FormattedMessage
            id="Freemium.Form.General.Messages.Errors.Description"
          />

          {errors.map((error, index) => (
          // eslint-disable-next-line
          <div key={`error-${index}`}>
            {get(error, 'message', '')}
          </div>
          ))}
        </AlertContent>
      </Alert>
    );
  }

  /**
   * Render submit / cancel buttons based on current active step
   *
   * @return {ReactElement} markup
   */
  renderButtons() {
    const { onFormCancel, formSavings } = this.props;
    const { activeStep } = this.state;

    const validations = this.getValidations();
    const isValid = every(validations, isEmpty);
    const onLastStep = this.shouldRenderLimitCalculation()
      ? (
        this.shouldRenderLimitDetermination()
          ? activeStep === LIMIT_DETERMINATION_STEP_ID
          : activeStep === LIMIT_CALCULATION_STEP_ID
      )
      : true;

    return (
      <React.Fragment>
        <Button
          color="primary"
          onClick={onFormCancel}
          outline
          disabled={false}
        >
          <FormattedMessage id="Activity.Form.Cancel" />
        </Button>

        <span style={{ paddingRight: '0.7rem' }} />

        <Button
          color="primary"
          onClick={this.onSubmit}
          disabled={!isValid || !onLastStep}
        >
          <FormattedMessage id="Activity.Form.Submit" />

        </Button>
      </React.Fragment>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      alwaysEnableMainCard,
      alwaysEnableMainAndAdditionalCard,
      alwaysEnableDcCard,
      calculation,
      customer,
      errors,
      form,
      partners,
      session,
      showCctToolOption,
      showDebitAccountSelection,
      additionalConnectionAccountTypes,
      lilaSet,
      onFormCancel
    } = this.props;

    const hasErrors = get(errors, 'length', 0) > 0;
    const isNotOldEnough = moment().diff(get(customer, 'birthDate', 0), 'years', false) < 18;

    const validations = this.getValidations();

    const limitCalculationDisabled = !isEmpty(get(validations, 'creditCardGeneral'));
    const limitDeterminationDisabled = !isEmpty(get(validations, 'creditCardLimitCalculation')) || limitCalculationDisabled;

    if (!get(lilaSet, 'data.set.active', false)) {
      return (
        <Alert type="warning">
          <AlertContent>
            <p>
              <FormattedMessage id="Freemium.General.NoActiveLilaSet" />
            </p>
            <Button
              color="primary"
              outline
              onClick={onFormCancel}
            >
              <FormattedMessage id="Workguide.Form.Button.Cancel" />
            </Button>
          </AlertContent>
        </Alert>
      );
    }

    if (get(lilaSet, 'data.modules', []).some((m) => m.contractType === 6421 && m.active)) {
      return (
        <Alert type="warning">
          <AlertContent>
            <p>
              <FormattedMessage id="Freemium.General.OptionExistings" />
            </p>
            <Button color="primary" outline onClick={onFormCancel}>
              <FormattedMessage id="Workguide.Form.Button.Cancel" />
            </Button>
          </AlertContent>
        </Alert>
      );
    }

    return (
      <div className="setadvisor-workguide-credit-card">
        {hasErrors && this.renderErrors()}
        {isNotOldEnough && renderIsNotOldEnoughError()}
        {
          !hasErrors && !isNotOldEnough && (
            <StepContainer initialStep={GENERAL_STEP_ID} onActiveStepChange={this.onActiveStepChange}>
              <Step
                id={GENERAL_STEP_ID}
                title={<FormattedMessage id="Freemium.Form.CreditCard.General.Title" />}
              >
                <GeneralForm
                  alwaysEnableMainCard={alwaysEnableMainCard}
                  alwaysEnableMainAndAdditionalCard={alwaysEnableMainAndAdditionalCard}
                  alwaysEnableDcCard={alwaysEnableDcCard}
                  showCctToolOption={showCctToolOption}
                  showCurrentCards
                  showDebitAccountSelection={showDebitAccountSelection}
                  additionalConnectionAccountTypes={additionalConnectionAccountTypes}
                />
              </Step>

                {
                  this.shouldRenderLimitCalculation() && (
                    <Step
                      id={LIMIT_CALCULATION_STEP_ID}
                      title={<FormattedMessage id="Freemium.Form.CreditCard.LimitCalculation.Title" />}
                      disabled={limitCalculationDisabled}
                    >
                      <LimitCalculationForm />
                    </Step>
                  )
                }

                {
                  this.shouldRenderLimitDetermination() && (
                    <Step
                      id={LIMIT_DETERMINATION_STEP_ID}
                      title={<FormattedMessage id="Freemium.Form.CreditCard.LimitDetermination.Title" />}
                      disabled={limitDeterminationDisabled}
                    >
                      <LimitDeterminationSummary
                        cards={get(form, 'data.cards', [])}
                        customer={customer}
                        data={{ ...get(form, 'data', {}), ...calculation }}
                        partners={partners}
                      />

                      <ResponsibleUsers
                        responsibleApprover={get(form, 'data.responsibleApprover')}
                        responsibleUser={session}
                      />

                      <LimitDeterminationForm />
                    </Step>
                  )
                }

              <StepButtons>
                {this.renderButtons()}
              </StepButtons>
            </StepContainer>
          )
        }
      </div>
    );
  }
}

SetAdvisorWorkguidePrivateCreditCardOrder.propTypes = {
  alwaysEnableMainCard: PropTypes.bool,
  alwaysEnableMainAndAdditionalCard: PropTypes.bool,
  alwaysEnableDcCard: PropTypes.bool,
  calculation: PropTypes.object,
  customer: PropTypes.object,
  documentPreviewActions: PropTypes.object.isRequired,
  errors: PropTypes.array,
  files: PropTypes.array,
  form: PropTypes.object,
  formValidations: PropTypes.object,
  onFormCancel: PropTypes.func,
  partners: PropTypes.object,
  freemiumActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  showCctToolOption: PropTypes.bool,
  showDebitAccountSelection: PropTypes.bool,
  orderWithoutModule: PropTypes.bool,
  additionalConnectionAccountTypes: PropTypes.array,
  lilaSet: PropTypes.object,
  formSavings: PropTypes.bool
};

SetAdvisorWorkguidePrivateCreditCardOrder.defaultProps = {
  alwaysEnableMainCard: false,
  alwaysEnableMainAndAdditionalCard: false,
  alwaysEnableDcCard: false,
  calculation: {},
  customer: undefined,
  errors: [],
  files: [],
  form: {},
  formValidations: {},
  onFormCancel: noop,
  partners: {},
  showCctToolOption: true,
  showDebitAccountSelection: true,
  orderWithoutModule: false,
  additionalConnectionAccountTypes: [],
  formSavings: false,
  lilaSet: {}
};

/**
 * Collect errors from state
 *
 * @param  {State} state Application state
 *
 * @return {Error} errors Array of errors
 */
function collectErrors(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return compact([
    get(state, `freemium.customerAccounts.${customerId}.error`),
    get(state, `freemium.customerCards.${customerId}.error`),
    get(state, `freemium.customerPoas.${customerId}.error`),
    get(state, `freemium.lilaSet.${customerId}.error`),
  ]);
}

/**
 * Collect files from state
 *
 * @param  {State} state Application state
 *
 * @return {Error} errors Array of errors
 */
function collectFiles(state) {
  const residentialPermit = getIdFromGravitonUrl(get(state, 'freemium.creditCardOrderForm.data.residencePermitFile'));

  const files = has(state, 'freemium.creditCardOrderForm.data.residencePermitFile')
    ? [{
      id: residentialPermit,
      file: get(state, `documentPreview.file.${residentialPermit}.data.blob`)
    }]
    : [];

  get(state, 'freemium.creditCardOrderForm.data.monthlyIncomes', []).reduce((result, item) => {
    const { fileLink } = item;

    if (isUndefined(fileLink)) return result;

    const id = getIdFromGravitonUrl(fileLink);
    const file = get(state, `documentPreview.file.${id}.data.blob`);

    result.push({ id, file });

    return result;
  }, files);

  return files;
}

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;

  return {
    calculation: state.freemium.creditCardLimitCalculation,
    errors: collectErrors(state, ownProps),
    files: collectFiles(state, ownProps),
    form: state.freemium.creditCardOrderForm,
    language: state.login.language,
    formValidations: state.freemium.formValidations,
    session: state.login.session,
    formSaveFulfilled: state.freemium.creditCardOrderForm.fulfilled,
    formSavings: state.freemium.creditCardOrderForm.saving,
    lilaSet: get(state, `freemium.lilaSet.${get(customer, 'id')}`)

  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch),
    documentPreviewActions: bindActionCreators(documentPreviewActions, dispatch),
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeGroups({ groups: ['accountType', 'addressType', 'cardCategory', 'cardType', 'relationPart', 'residencePermit'] })(
    connect(mapStateToProps, mapDispatchToProps)(SetAdvisorWorkguidePrivateCreditCardOrder)
  )
);
