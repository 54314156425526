import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { Row, Col } from 'reactstrap';
import { withCodeGroups } from '@evoja-web/entity-code';

import CardHolders from '../../../../components/Form/CreditCard/Summary/CardHolders';
import Cards from '../../../../components/Form/CreditCard/Summary/Cards';
import LimitDetermination from '../../../../components/Form/CreditCard/Summary/LimitDetermination';
import ResponsibleUsers from '../../../../components/Form/CreditCard/Summary/ResponsibleUsers';
import Comments from '../../../../components/Form/CreditCard/Summary/Comments';
import { actions as bpfCmsActions } from '../../../../../BpfCms/index';
import isDoublePartner from '../../../../../../lib/Customer/Utils/isDoublePartner';
import calculateLimit from '../../../../lib/CreditCard/calculateLimit';
import toStringIfSet from '../../../../../../lib/Utils/toStringIfSet';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../../Workguide/hocs';

class SetAdvisorWorkguideCreditCardSummary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
  }

  /**
   * Handle customer change
   *
   * @param  {Object} customer Selected customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const { bpfCmsActions } = this.props;
    const customerId = get(customer, 'id');

    if (isDoublePartner(customer)) {
      bpfCmsActions.partnersRequest({ dataKey: customerId });
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      codes,
      consultants,
      customer,
      language,
      onChange,
      partners,
      process,
      showProfileCompletion,
      withEmbossedLines
    } = this.props;

    const cards = [
      ...get(process, 'mainCardHolder.cards', []),
      ...get(process, 'additionalCardHolder.cards', [])
    ];

    const {
      totalAssets,
      totalMonthlyExpenses,
      totalMonthlyIncomes
    } = calculateLimit(get(process, 'financialSituation', {}));

    const responsibleApprover = consultants.find((c) => get(c, 'id') === toStringIfSet(get(process, 'responsibleApprover')));
    const responsibleUser = consultants.find((c) => get(c, 'id') === toStringIfSet(get(process, 'responsibleUser')));

    return (
      <div className="setadvisor-workguide-credit-card-summary">
        <WorkguideCustomerAware
          onInit={this.onCustomerChange}
          onChange={this.onCustomerChange}
        >
          <Row>
            <Col lg={12} md={12}>
              <Cards
                codes={codes}
                language={language}
                data={process}
                withEmbossedLines={withEmbossedLines}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12}>
              <CardHolders
                codes={codes}
                data={process}
                language={language}
                onChange={onChange}
                showProfileCompletionAdditional={showProfileCompletion}
                showProfileCompletionMain={showProfileCompletion}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12}>
              <LimitDetermination
                cards={cards}
                codes={codes}
                customer={customer}
                data={{
                  ...process,
                  ...get(process, 'financialSituation', {}),
                  totalAssets,
                  totalMonthlyIncomes,
                  totalMonthlyExpenses,
                  cards
                }}
                language={language}
                partners={partners}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12}>
              <ResponsibleUsers
                responsibleApprover={responsibleApprover}
                responsibleUser={responsibleUser}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12}>
              <Comments comments={get(process, 'comments.value', get(process, 'comments', []))} />
            </Col>
          </Row>
        </WorkguideCustomerAware>
      </div>
    );
  }
}

SetAdvisorWorkguideCreditCardSummary.propTypes = {
  bpfCmsActions: PropTypes.object.isRequired,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  customer: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  partners: PropTypes.object,
  process: PropTypes.object.isRequired,
  showProfileCompletion: PropTypes.bool,
  withEmbossedLines: PropTypes.bool
};

SetAdvisorWorkguideCreditCardSummary.defaultProps = {
  codes: { groups: {} },
  consultants: [],
  customer: undefined,
  language: 'de',
  onChange: noop,
  partners: {},
  showProfileCompletion: false,
  withEmbossedLines: false,
};

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;

  return {
    consultants: state.consultantSearch.allConsultants,
    customer,
    language: state.login.language,
    partners: get(state, `bpfCms.partners.${get(customer, 'id')}`),
    process: get(ownProps, 'bpf.process'),
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeGroups({ groups: ['addressType', 'customerMaritalStatus', 'cardType', 'residencePermit', 'relationPart'] })(
    connect(mapStateToProps, mapDispatchToProps)(SetAdvisorWorkguideCreditCardSummary)
  )
);
