import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Collapse } from 'reactstrap';
import { first, get, noop, size } from 'lodash';
import cl from 'classnames';
import NotificationHeader from './NotificationCard/NotificationHeader';
import NotificationChild from './NotificationCard/NotificationChild';
import './NotificationCardItem.scss';

function NotificationCardItem({
  notifications,
  language,
  onMarkNotificationAsRead
}) {
  const [expanded, setExpanded] = useState(false);

  // Derived state
  const latestMessage = useMemo(() => first(notifications), [notifications]);
  const contentKey = useMemo(
    () => `content${language.charAt(0).toUpperCase()}${language.slice(1)}`,
    [language]
  );
  const hasUnread = useMemo(
    () => notifications.some((n) => !n.read),
    [notifications]
  );
  const multipleNotifications = useMemo(
    () => size(notifications) > 1,
    [notifications]
  );

  // Event handlers
  const toggleExpand = useCallback(() => {
    if (multipleNotifications) {
      setExpanded((prev) => !prev);
    }
  }, [multipleNotifications]);

  const handleMarkAllAsRead = useCallback((e) => {
    if (e) e.stopPropagation();

    const idsToMark = notifications
      .filter((n) => !n.read)
      .map((n) => get(n, '_id', get(n, 'id')))
      .filter(Boolean);

    if (idsToMark.length) {
      onMarkNotificationAsRead(idsToMark);
    }
  }, [notifications, onMarkNotificationAsRead]);

  // Guard clause
  if (!notifications.length) return null;

  return (
    <Card
      className={cl('notification-card', {
        'notification-card--unread': hasUnread
      })}
      aria-expanded={expanded}
    >
      <CardBody className="notification-card__body">
        <NotificationHeader
          hasUnread={hasUnread}
          description={get(latestMessage, contentKey, '')}
          latestMessage={latestMessage}
          language={language}
          toggleExpand={toggleExpand}
          handleMarkAllAsRead={handleMarkAllAsRead}
          sortedCount={size(notifications)}
          expanded={expanded}
          onMarkAsRead={onMarkNotificationAsRead}

        />

        <Collapse isOpen={expanded} className="notification-card__collapse">
          <div className="notification-card__children-container">
            {notifications.slice(1).map((notification) => (
              <NotificationChild
                key={get(notification, '_id', get(notification, 'id'))}
                notification={notification}
                contentKey={contentKey}
                language={language}
                onMarkAsRead={onMarkNotificationAsRead}
              />
            ))}
          </div>
        </Collapse>
      </CardBody>
    </Card>
  );
}

NotificationCardItem.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Added fallback id
    contentDe: PropTypes.string,
    contentFr: PropTypes.string,
    timeIndex: PropTypes.number.isRequired,
    read: PropTypes.bool
  })).isRequired,
  language: PropTypes.oneOf(['fr', 'de']),
  onMarkNotificationAsRead: PropTypes.func
};

NotificationCardItem.defaultProps = {
  language: 'de',
  onMarkNotificationAsRead: noop
};

export default React.memo(NotificationCardItem);
