import { call, put, select } from 'redux-saga/effects';
import { get, isNil } from 'lodash';
import { setLocale } from '@evoja-web/react-form';
import { actions as loginActions } from '@evoja-web/react-login';

const { SET_LANGUAGE_REQUEST } = loginActions;
const availableLanguages = ['de', 'fr'];

export default function* setLanguageAndLocale({ payload }) {
  const preferredLanguage = get(payload, 'preferredLanguage', '').toLowerCase();
  const language = !isNil(preferredLanguage) && availableLanguages.includes(preferredLanguage)
    ? preferredLanguage
    : yield select((state) => state.login.language);

  const locale = `${language}-CH`;

  yield call(setLocale, locale);
  yield put({ type: SET_LANGUAGE_REQUEST, payload: language });
}
