import React from 'react';
import PropTypes from 'prop-types';
import { noop, isFunction } from 'lodash';
import update from 'immutability-helper';

import './List.css';
import ListItem from './ListItem';

class AmountList extends React.Component {
  constructor(props) {
    super(props);

    this.onRemove = this.onRemove.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  /**
   * Remove the given item from list
   *
   * @param  {Number} index Array index
   * @param  {Array}  rest  Rest props
   *
   * @return void
   */
  onRemove(index, ...rest) {
    const {
      items,
      onChange,
      type
    } = this.props;

    const updated = update(items, {
      $splice: [[index, 1]]
    });

    onChange(type, updated, ...rest);
  }

  /**
   * Change the given value for the given index / key combination
   *
   * @param  {Number} index Array index
   * @param  {String} key   Form element key (id)
   * @param  {Mixed}  value New value
   * @param  {Array}  rest  Rest args
   *
   * @return void
   */
  onValueChange(index, key, value, ...rest) {
    const {
      items,
      onChange,
      type
    } = this.props;

    const updated = update(items, {
      [index]: {
        [key]: { $set: value }
      }
    });

    onChange(type, updated, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      itemLabel,
      items,
      removeable
    } = this.props;

    const children = items.map((data, index) => {
      const label = isFunction(itemLabel)
        ? itemLabel({ index, data })
        : itemLabel;

      const isRemoveable = isFunction(removeable)
        ? removeable({ index, data })
        : removeable;

      return (
        <ListItem
          // eslint-disable-next-line
          key={index}
          data={data}
          index={index}
          label={label}
          onChange={this.onValueChange}
          onRemove={this.onRemove}
          removeable={isRemoveable}
        />
      );
    });

    return (
      <div className="setadvisor-limit-calculation--amount-list">
        {children}
      </div>
    );
  }
}

AmountList.propTypes = {
  itemLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func
  ]),
  items: PropTypes.array,
  onChange: PropTypes.func,
  removeable: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  type: PropTypes.string.isRequired
};

AmountList.defaultProps = {
  itemLabel: '',
  items: [],
  onChange: noop,
  removeable: false
};

export default AmountList;
