import React from 'react';
import PropTypes from 'prop-types';
import { noop, get, isUndefined, isNil } from 'lodash';
import { RadioButton, RadioButtonGroup, Select } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

import {
  SubSection,
  SubSectionTitle,
  SubSectionContent
} from '../../../../../../General/components/SubSection/index';
import isAdditionalCardCategorySelected from '../../../../../lib/CreditCard/isAdditionalCardCategorySelected';
import getCardTypeOptions from '../../../../../lib/CreditCard/getCardTypeOptions';
import getCardNumberShort from '../../../../../lib/CreditCard/getCardNumberShort';

const cardTypesGold = [404, 506];
const cardTypesSilver = [401, 517];

class CardVarietyAdditionalCard extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.onCardVarietyChange = this.onCardVarietyChange.bind(this);
  }

  /**
   * Handle onChange of the radio button
   *
   * @param  {String} key      Form element id
   * @param  {Array}  selected Selected values
   * @param  {Array}  rest     Rest args
    *
   * @return void
   */
  onRadioChange(key, selected, ...rest) {
    const { currentCards } = this.props;

    const mainCard = isUndefined(selected)
      ? undefined
      : currentCards.find((card) => ['HK', 'DC'].includes(get(card, 'basicData.categoryType')));

    this.onChange('cardVariety', mainCard, ...rest);
  }

  /**
   * Handle change of the additional card select.
   * Update cards in form data with a single card of category ZK
   *
   * @param  {String} key      Form element id
   * @param  {Array}  selected Selected card
   * @param  {Array}  rest     Rest args
    *
   * @return void
   */
  onChange(key, selected, ...rest) {
    const {
      onCardsChange,
      onChange
    } = this.props;

    const connectionAccount = {
      accountKey: get(selected, 'basicData.accountKey'),
      clientKey: get(selected, 'basicData.clientKey')
    };

    const cards = isUndefined(selected)
      ? undefined
      : [{
        categoryType: 'ZK',
        cardVariety: get(selected, 'basicData.cardVariety'),
        cardCurrency: get(selected, 'basicData.cardCurrency', 'CHF'),
        cardNumber: get(selected, 'basicData.cardNumber'),
        displayCardNumber: get(selected, 'basicData.displayCardNumber', get(selected, 'basicData.cardNumber'))
      }];

    onCardsChange(cards, ...rest);
    onChange(key, get(selected, 'basicData.cardVariety'));
    onChange('connectionAccount', connectionAccount);
  }

  /**
   * Update cardVariety on card
   *
   * @param  {String} key      Form element id
   * @param  {Array}  selected Selected values
   * @param  {Array}  rest     Rest args
    *
   * @return void
   */
  onCardVarietyChange(key, selected, ...rest) {
    const {
      cards,
      onCardsChange,
      onChange
    } = this.props;

    const updated = update(cards, {
      0: {
        cardVariety: { $set: selected }
      }
    });

    onCardsChange(updated, ...rest);
    onChange(key, selected, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      cards,
      codes,
      currentCards,
      id,
      language
    } = this.props;
    const hasMainCard = !isNil(currentCards.find((card) => get(card, 'basicData.categoryType') === 'HK'));
    const additionalCard = cards.find((card) => get(card, 'categoryType') === 'ZK');
    const mainCard = currentCards.find((card) => (
      get(card, 'basicData.categoryType') === 'HK'
      && get(additionalCard, 'cardNumber') === get(card, 'basicData.cardNumber')
    ));

    const active = isAdditionalCardCategorySelected(cards);
    const options = currentCards
      .filter((card) => ['HK', 'DC'].includes(get(card, 'basicData.categoryType')))
      .map((card) => ({
        card,
        label: `${getCardNumberShort(get(card, 'basicData.cardNumber'))}, ${get(card, 'clientDesignation', '')}`,
        value: get(card, 'basicData.displayCardNumber', get(card, 'basicData.cardNumber'))
      }));

    const cardTypes = cardTypesGold.includes(get(mainCard, 'cardVariety'))
      ? cardTypesGold
      : cardTypesSilver;

    const cardTypeOptions = getCardTypeOptions({ codes, types: cardTypes, language });

    return (
      <SubSection>
        <SubSectionTitle>
          <RadioButton
            disabled={!hasMainCard}
            id="cardVariety"
            value={active}
            onChange={this.onRadioChange}
          >
            <FormattedMessage id="Freemium.Form.CreditCard.CardCategory.Additional" />
          </RadioButton>
        </SubSectionTitle>

        {active && (
          <SubSectionContent>
            <Select
              id={id}
              isClearable={false}
              clearable={false}
              onChange={this.onChange}
              options={options}
              returnValue="card"
              value={get(additionalCard, 'displayCardNumber', get(additionalCard, 'cardNumber'))}
            />

            <div className="pb-4" />

            <RadioButtonGroup
              id="cardVariety"
              options={cardTypeOptions}
              value={get(additionalCard, 'cardVariety')}
              onChange={this.onCardVarietyChange}
            />
          </SubSectionContent>
        )}
      </SubSection>
    );
  }
}

CardVarietyAdditionalCard.propTypes = {
  cards: PropTypes.array,
  codes: PropTypes.object,
  currentCards: PropTypes.array,
  id: PropTypes.string,
  language: PropTypes.string,
  onCardsChange: PropTypes.func,
  onChange: PropTypes.func
};

CardVarietyAdditionalCard.defaultProps = {
  cards: [],
  codes: { groups: {} },
  currentCards: [],
  id: 'mainCardNumber',
  language: 'de',
  onCardsChange: noop,
  onChange: noop
};

export default CardVarietyAdditionalCard;
