import React from 'react';
import update from 'immutability-helper';
import { get, isEmpty, has, isUndefined, noop } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Toggle, RadioButtonGroup } from '@evoja-web/react-form';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import './CreateNewAccount.css';
import { RadioSelectWrapper } from '../../../../General/index';
import { SubSectionTitle } from '../../../../General/components/SubSection';
import QuantityInput from '../OpenAccount/QuantityInput';
import Poa from '../CreditCard/General/Poa';
import PrivateIndividualBasicContract from '../../../../BpfCms/components/ProfileCompletion/PrivateIndividual/BasicContract';
import { workguideMap } from '../../../../../staticMappings/workguides';

class EBankingCreateNewContract extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountQuantity: 1
    };

    this.onQuantityChanged = this.onQuantityChanged.bind(this);
    this.getFilteredPoas = this.getFilteredPoas.bind(this);
    this.onPoaChange = this.onPoaChange.bind(this);
    this.onContractCreatorChange = this.onContractCreatorChange.bind(this);
    this.onBaseContractCreate = this.onBaseContractCreate.bind(this);
  }

  onQuantityChanged(val) {
    const { freemiumActions, form } = this.props;
    const { accountQuantity } = this.state;
    const newContract = get(form, 'data.newContract', []);

    const updated = accountQuantity > val
      ? update(newContract, { $splice: [[val - 1, 1]] })
      : update(newContract, { $push: [{}] });

    freemiumActions.openLilaSetSetValue('newContract', updated);
    this.setState({ accountQuantity: val });
  }

  onContractCreatorChange(key, value, index) {
    const { form, onChange } = this.props;
    const newContract = get(form, `data.newContract.${index}`, {});
    onChange(
      index,
      update(newContract, {
        [key]: { $set: value },
      })
    );
  }

  onPoaChange(key, value, index) {
    const { cmsCustomers, onLoadBpfCustomerData } = this.props;
    this.onContractCreatorChange(key, value, index);
    // If poa changed, load bpf customer data if not already done
    if (key === 'poaAdditional') {
      const customerId = get(value, 'clientAssignedKey');

      if (!has(cmsCustomers, customerId)) {
        onLoadBpfCustomerData(customerId);
      }
    }
  }

  /**
   * Handle onClick of base contract create button
   *
   * @param  {String} customerId Customer id
   *
   * @return void
   */
  onBaseContractCreate(customerId) {
    const { history } = this.props;
    const url = `/activity/${workguideMap.get('create-basic-contract')}/${customerId}`;
    history.push(url);
  }

  getFilteredPoas(index = undefined) {
    const { powerOfAttorneys, form } = this.props;
    const alreadySelected = get(form, 'data.newContract', [])
      .filter((el, i) => i !== index)
      .map((contract) => contract.selectedContractPartner?.poaKey);

    return powerOfAttorneys.filter((poa) => [102, 6026].includes(poa.poaType)
      && poa.signingAuthority === 'INDIVIDUALLY'
      && (!isUndefined(index) ? !alreadySelected.includes(poa.poaKey) : true));
  }

  render() {
    const {
      freemiumActions,
      form,
      cards,
      cmsCustomers,
      validations,
      gravitonCustomers,
      language,
      hasExistingOrder
    } = this.props;

    return hasExistingOrder ? (
      <Alert type="error">
        <AlertContent>
          <FormattedMessage id="Freemium.Workguide.EBankingContract.ExistingOrderWarning" />
        </AlertContent>
      </Alert>
    ) : (
      <>
        <Alert type="warning">
          <AlertContent>
            <FormattedMessage id="Freemium.Form.NewLilaSet.NewEbankingContractInfo" />
          </AlertContent>
        </Alert>

        <QuantityInput
          min={0}
          max={get(this.getFilteredPoas(), 'length')}
          onChange={(val) => this.onQuantityChanged(val)}
        />

        {get(form, 'data.newContract', []).map((newContract, index) => {
          const selectedPoas = get(form, `data.newContract.${index}.selectedContractPartner`, {});
          return (
          // eslint-disable-next-line react/no-array-index-key
            <div key={index} style={{ paddingTop: 20 }}>
              <div className="eBanking-contract--new--contract">
                <RadioButtonGroup
                  disabled={false}
                  id="eBankingOption-2"
                  value={get(form, 'data.eBankingOption', false)}
                  onChange={freemiumActions.openLilaSetSetValue}
                >
                  <b><FormattedMessage id="Freemium.Form.NewLilaSet.NewEbankingContract" /></b>
                </RadioButtonGroup>
              </div>

              <div className="eBanking-contract--contract-partner">
                <b><FormattedMessage id="Freemium.Form.NewLilaSet.ContractPartner" /></b>
                <br />
                <br />
                <p><FormattedMessage id="Freemium.Form.NewLilaSet.ContractPermissionError" /></p>
                <Poa
                  cards={cards}
                  categoryType="ZK"
                  cmsCustomers={cmsCustomers}
                  id="selectedContractPartner"
                  onChange={(key, value) => this.onPoaChange(key, value, index)}
                  powerOfAttorneys={this.getFilteredPoas(index)}
                  value={get(form, `data.newContract.${index}.selectedContractPartner.poaKey`)}
                  validations={get(validations, `newContract.${index}.selectedContractPartner`)}
                />

                {!isEmpty(selectedPoas) && (
                <div>
                  <b><FormattedMessage id="Freemium.Form.NewLilaSet.DeliveryAddress" /></b>
                  <br />
                  <br />
                  <RadioSelectWrapper
                    onChange={(key, value) => this.onContractCreatorChange(key, value, index)}
                    value={get(form, `data.newContract.${index}.eBankingCustomerAddress`)}
                    id="eBankingCustomerAddress"
                    disabled={false}
                    options={get(selectedPoas, 'addresses', []).map((address) => ({
                      label: (
                        <Row style={{ width: '90%' }}>
                          <Col md={3} lg={3}>
                            {get(address, `addressType.text.${language}`)}
                          </Col>
                          <Col md={6} lg={6}>
                            {`${address.street} ${address.houseNumber}, ${address.postcode} ${address.place}`}
                          </Col>
                        </Row>
                      ),
                      value: address,
                    }))}
                    validations={get(validations, `newContract.${index}.eBankingCustomerAddress`)}
                    showValidations
                  />
                  {get(selectedPoas, 'poaType') === 102 && (
                  <div className="eBanking-contract--basic--contract-box">
                    <PrivateIndividualBasicContract
                      bpfCustomerData={get(cmsCustomers, get(selectedPoas, 'clientAssignedKey'))}
                      customer={get(gravitonCustomers, get(selectedPoas, 'clientAssignedKey'))}
                      onBaseContractCreate={this.onBaseContractCreate}
                    />
                  </div>
                  )}
                </div>
                )}

                <Row style={{ marginTop: 20 }}>
                  <Col md={1} lg={1}>
                    <Toggle
                      value={get(form, `data.newContract.${index}.eDocument`, false)}
                      onChange={(key, value) => this.onContractCreatorChange('eDocument', value, index)}
                    />
                  </Col>
                  <Col>
                    <SubSectionTitle><FormattedMessage id="Freemium.Form.NewLilaSet.ElectronicDocuments" /></SubSectionTitle>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

EBankingCreateNewContract.propTypes = {
  freemiumActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  form: PropTypes.object,
  powerOfAttorneys: PropTypes.array,
  validations: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired,
  cmsCustomers: PropTypes.object.isRequired,
  gravitonCustomers: PropTypes.object.isRequired,
  onLoadBpfCustomerData: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  hasExistingOrder: PropTypes.bool,
  history: PropTypes.object.isRequired
};

EBankingCreateNewContract.defaultProps = {
  language: 'de',
  form: {},
  powerOfAttorneys: [],
  onLoadBpfCustomerData: noop,
  hasExistingOrder: false
};

export default withRouter(EBankingCreateNewContract);
