import React from 'react';
import PropTypes from 'prop-types';
import { has, get, noop, isNil } from 'lodash';

import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../../General/components/SubSection/index';
import FormOfAddress from './FormOfAddress';
import Customer from './Customer';
import Address from './Address';
import MaritalStatus from './MaritalStatus';
import ClientLanguage from './ClientLanguage';
import DateOfBirth from './DateOfBirth';
import Nationality from './Nationality';
import PlaceOfOrigin from './PlaceOfOrigin';
import TelephoneNumber from './TelephoneNumber';
import Email from './Email';
import EmbossedLineOne from './EmbossedLineOne';
import ProfileCompletion from '../../../../../containers/Workguide/ProfileCompletion';
import RelationshipToMainCardHolder from './RelationshipToMainCardHolder';
import PoaStatus90 from './PoaStatus90';

const CardHolder = React.memo(({
  cardHolder,
  codes,
  countries,
  formOfAddresses,
  id,
  language,
  label,
  onChange,
  poaStatus90,
  showProfileCompletion
}) => {
  return (
    <div className="setadvisor-form-summary-main-card-holder">
      <SubSection>
        <SubSectionTitle>
          {label}
        </SubSectionTitle>

        <SubSectionContent>
          <FormOfAddress
            cardHolder={cardHolder}
            formOfAddresses={formOfAddresses}
            language={language}
          />

          <Customer cardHolder={cardHolder} />

          {showProfileCompletion && (
            <ProfileCompletion
              customerId={get(cardHolder, 'clientInformation.clientKey')}
              id={`${id}ProfileCompletion`}
              onChange={onChange}
            />
          )}

          {!isNil(poaStatus90) && (
            <PoaStatus90 status={poaStatus90} />
          )}

          {has(cardHolder, 'clientInformation.domicileAddress') && (
            <Address
              address={get(cardHolder, 'clientInformation.domicileAddress')}
              type="domicile"
            />
          )}

          {has(cardHolder, 'clientInformation.correspondenceAddress') && (
            <Address
              address={get(cardHolder, 'clientInformation.correspondenceAddress')}
              type="correspondence"
            />
          )}

          <MaritalStatus
            cardHolder={cardHolder}
            codes={codes}
            language={language}
          />

          <ClientLanguage cardHolder={cardHolder} />

          <DateOfBirth cardHolder={cardHolder} />

          <Nationality
            cardHolder={cardHolder}
            countries={countries}
          />

          <PlaceOfOrigin cardHolder={cardHolder} />

          <TelephoneNumber cardHolder={cardHolder} />

          <Email cardHolder={cardHolder} />

          <EmbossedLineOne cardHolder={cardHolder} />

          {!isNil(get(cardHolder, 'clientInformation.relationshipToMainCardHolder')) && (
            <RelationshipToMainCardHolder
              cardHolder={cardHolder}
              codes={codes}
              language={language}
            />
          )}
        </SubSectionContent>
      </SubSection>
    </div>
  );
});

CardHolder.propTypes = {
  cardHolder: PropTypes.object,
  codes: PropTypes.object,
  countries: PropTypes.object,
  formOfAddresses: PropTypes.object,
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  onChange: PropTypes.func,
  poaStatus90: PropTypes.bool,
  showProfileCompletion: PropTypes.bool
};

CardHolder.defaultProps = {
  cardHolder: {},
  codes: { groups: {} },
  countries: {},
  formOfAddresses: {},
  language: 'de',
  label: '',
  onChange: noop,
  poaStatus90: undefined,
  showProfileCompletion: false
};

export default CardHolder;
