import { DefaultReducer } from '@evoja-web/redaction';
import update from 'immutability-helper';
import { isEmpty, isNil, set } from 'lodash';
import { notificationAction, ADD_NEW_NOTIFICATION } from '../actions/Notification';

export const reducer = DefaultReducer('notifications', notificationAction);

const addNewNotification = (state, action) => {
  const { notification } = action.payload;

  if (!isNil(notification) && !isEmpty(notification)) {
    // when notification is added trought the subscribe method the read field is missing
    set(notification, 'read', false);
    // `_id` is required for filtering in `NotificationOverview`. Since this is a new notification from Ruedi, it doesn't have an `_id` by default.
    set(notification, '_id', notification.id);
    return update(state, {
      notifications: {
        data: { $push: [notification] }
      }
    });
  }
  return state;
};
reducer.addMethod(ADD_NEW_NOTIFICATION, addNewNotification);

export default reducer.get();
