import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { get, isNil } from 'lodash';

import Row from './Row';

const IndividualLimits = React.memo(({ cards }) => {
  const mainCard = cards.find((card) => get(card, 'categoryType') === 'HK');
  const additionalCard = cards.find((card) => get(card, 'categoryType') === 'ZK');

  const limitMain = get(mainCard, 'cardLimit');
  const limitAdditional = get(additionalCard, 'cardLimit');

  return (
    <div>
      {!isNil(limitMain) && (
        <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.IndividualLimitMain" />}>
          <FormattedNumber
            value={limitMain}
            // eslint-disable-next-line
            style="currency"
            currency="CHF"
          />
        </Row>
      )}

      {!isNil(limitAdditional) && (
        <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.IndividualLimitAdditional" />}>
          <FormattedNumber
            value={limitAdditional}
            // eslint-disable-next-line
            style="currency"
            currency="CHF"
          />
        </Row>
      )}
    </div>
  );
});

IndividualLimits.propTypes = {
  cards: PropTypes.array
};

IndividualLimits.defaultProps = {
  cards: []
};

export default IndividualLimits;
