import update from 'immutability-helper';
import { compact, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { withCodeGroups } from '@evoja-web/entity-code';

import './OpenAccount.css';
import { getValidator } from '../../../../../globals';
import { toIntIfSet } from '../../../../../lib/Utils';
import freemiumActions from '../../../actions/Actions';
import AccountCreator from '../../../components/Form/OpenAccount/AccountCreator';
import QuantityInput from '../../../components/Form/OpenAccount/QuantityInput';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../Workguide/hocs';

class OpenAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountQuantity: 1,
    };
    this.onChange = this.onChange.bind(this);
    this.onQuantityChanged = this.onQuantityChanged.bind(this);
    this.submit = this.submit.bind(this);
    this.onInit = this.onInit.bind(this);
    this.validate = this.validate.bind(this);
    this.onCustomerChange = this.onCustomerChange.bind(this);
  }

  componentWillUnmount() {
    const { freemiumActions } = this.props;
    freemiumActions.openAccountFormsReset();
  }

  /**
   * Handle customer change
   *
   * @param  {Object} customer Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const { freemiumActions, form } = this.props;
    const accounts = get(form, 'data.accounts', []);
    if (accounts.length === 0) {
      this.onInit();
    }

    // Inital load
    freemiumActions.accountProductGroupsRequest({ dataKey: get(customer, 'id') });
  }

  /**
   *  Create empty object in form
   */
  onInit() {
    const { freemiumActions, form } = this.props;
    const accounts = get(form, 'data.accounts', []);
    freemiumActions.openAccountFormsSetValue('accounts', update(accounts, { $push: [{}] }));
  }

  onChange(index, account) {
    const { freemiumActions, form } = this.props;
    const accounts = get(form, 'data.accounts', []);
    const updated = update(accounts, {
      [index]: { $set: account },
    });
    freemiumActions.openAccountFormsSetValue('accounts', updated);
  }

  /**
   * Create initial empty account object or delete account object
   * @param val number quantity
   */
  onQuantityChanged(val) {
    const { freemiumActions, form } = this.props;
    const { accountQuantity } = this.state;
    const accounts = get(form, 'data.accounts', []);
    let updated = accounts;
    if (accountQuantity > val) {
      updated = update(accounts, { $splice: [[val - 1, 1]] });
    } else {
      updated = update(accounts, { $push: [{}] });
    }
    freemiumActions.openAccountFormsSetValue('accounts', updated);
    this.setState({ accountQuantity: val });
  }

  submit() {
    const {
      freemiumActions, form, customer, consultantId
    } = this.props;
    const payload = get(form, 'data.accounts', []).map((account) => {
      return {
        accountType: get(account, 'accountType'),
        accountCurrency: 'CHF',
        ...(get(account, 'hasRubric', false) && {
          ...(get(account, 'manuelRubric', false)
            ? {
              accountCategory: get(account, 'customRubric'),
            }
            : {
              accountCategoryId: toIntIfSet(get(account, 'accountCategory.number'))
            }),
        }),
      };
    });
    freemiumActions.openAccountFormsSaveRequest({ accounts: payload, customerId: customer.id, consultantId });
  }

  /**
   * Validate current form data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const { form } = this.props;
    const validator = getValidator();
    const data = get(form, 'data', {});
    const definition = {
      validations: {
        accounts: {
          type: 'array',
          required: true,
          validations: {
            accountType: {
              type: 'number',
              required: true,
              validations: {},
            },
            accountCategory: {
              type: 'object',
              required: ({ parent }) => {
                return get(parent, 'hasRubric', false) && !get(parent, 'manuelRubric', false);
              },
              validations: {},
            },
            customRubric: {
              type: 'string',
              required: ({ parent }) => get(parent, 'manuelRubric', false),
              validations: {},
            },
          },
        },
      },
    };

    return validator.validate(definition, data);
  }

  render() {
    const {
      form, codes, productGroups, fulfilled, errors, onFormCancel, language
    } = this.props;
    const validations = this.validate();

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        showLoadIndicator
        requesting={(!fulfilled && get(errors, 'length', 0) === 0) || get(productGroups, 'requesting', false)}
      >
        <div>
          <div className="open-account--header">
            <h4 className="open-account--label"><FormattedMessage id="Freemium.Form.OpenAccount.Account" /></h4>
          </div>

          <div className="open-account--quantity-selector">
            <h4 className="open-account--label"><FormattedMessage id="Freemium.Form.OpenAccount.Amount" /></h4>
            <QuantityInput min={1} onChange={(val) => this.onQuantityChanged(val)} />
          </div>

          {get(form, 'data.accounts', []).map((account, index) => {
            return (
              <AccountCreator
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                label={<FormattedMessage id="Freemium.Form.OpenAccount.CreateAccountLabel" values={{ value: index + 1 }} />}
                onChange={this.onChange}
                productGroups={get(productGroups, 'data')}
                account={account}
                codes={codes}
                language={language}
                validations={get(validations, 'accounts', {})}
              />
            );
          })}
          <div className="open-account--action-buttons">
            <Button color="primary" onClick={this.submit} disabled={!isEmpty(validations)}>
              <FormattedMessage id="Freemium.Form.OpenAccount.OpenAccountTitle" />
            </Button>
            <span style={{ paddingRight: '20px' }} />
            <Button color="primary" outline onClick={onFormCancel}>
              <FormattedMessage id="Freemium.Form.General.Labels.Cancel" />
            </Button>
          </div>
        </div>
      </WorkguideCustomerAware>
    );
  }
}

OpenAccount.propTypes = {
  form: PropTypes.object,
  customer: PropTypes.object.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  errors: PropTypes.array,
  fulfilled: PropTypes.bool,
  productGroups: PropTypes.object,
  codes: PropTypes.object,
  onFormCancel: PropTypes.func.isRequired,
  consultantId: PropTypes.string.isRequired
};
OpenAccount.defaultProps = {
  form: {},
  productGroups: {},
  codes: {},
  language: 'de',
  errors: [],
  fulfilled: false,
};

/**
 * Check if all necessary data was loaded
 *
 * @param  {Object}  state Current state
 *
 * @return {Boolean}
 */
function isFulfilled(state) {
  return (
    !get(state, 'state.freemium.openAccountForm.requesting', false)
    && get(state, 'codes.groupsFulfilled', []).includes('accountRubric')
  );
}

function collectErrors(state) {
  return compact([get(state, 'state.freemium.openAccountForm.error')]);
}

function mapStateToProps(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return {
    fulfilled: isFulfilled(state, ownProps),
    errors: collectErrors(state),
    form: state.freemium.openAccountForm,
    productGroups: get(state, `freemium.accountProductGroups.${customerId}`),
    consultantId: get(state, 'login.session.id')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeGroups({ groups: ['accountRubric'] })(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(OpenAccount))
  )
);
