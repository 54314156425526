import { isNil, isString } from 'lodash';

/**
 * Take the last 4 characters of the card number and add ... in front of them
 *
 * @param   {String}  cardNumber  Card number (unformatted)
 *
 * @return  {String} short Short card number (e.g. "...1234")
 */
export default function getCardNumberShort(cardNumber) {
  if (isNil(cardNumber) || !isString(cardNumber)) return '';

  return `...${cardNumber.substring(cardNumber.length - 4)}`;
}
