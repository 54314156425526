import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Row from './Row';

const EmployeeConditions = React.memo(({ employeeConditions }) => {
  const message = employeeConditions
    ? 'General.Yes'
    : 'General.No';

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.EmployeeConditions" />}>
      <FormattedMessage id={message} />
    </Row>
  );
});

EmployeeConditions.propTypes = {
  employeeConditions: PropTypes.bool
};

EmployeeConditions.defaultProps = {
  employeeConditions: false
};

export default EmployeeConditions;
