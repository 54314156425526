import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { withAcl } from '@evoja-web/client-acl';

import './ContractDispatchRulesPostProcessing.css';
import Form from '../../../../components/Form/ContractDispatchRules/Form';
import { withWorkguideInstanceData } from '../../../../../Workguide/index';
import freemiumActions from '../../../../actions/Actions';

class ContractDispatchRulesPostProcessing extends React.Component {
  constructor(props) {
    super(props);

    this.onInitForm();

    this.onSubmit = this.onSubmit.bind(this);
  }

  onInitForm() {
    const {
      bpf,
      freemiumActions
    } = this.props;

    const contracts = get(bpf, 'process.contracts', []);

    freemiumActions.contractDispatchRulesPostProcessingFormSetValue('contracts', contracts);
  }

  onSubmit() {
    const {
      acl,
      bpf,
      form,
      freemiumActions,
      toggleRedirectModal
    } = this.props;

    freemiumActions.contractDispatchRulesPostProcessingFormSaveRequest({
      data: get(form, 'data', {}),
      taskId: get(bpf, 'task.id'),
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck')
    });

    toggleRedirectModal();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      form,
      language,
      onFormCancel,
      session,
      freemiumActions
    } = this.props;

    return (
      <div className="setadvisor-workguide-contract-dispatch-rules-post-processing">
        <h5>
          <FormattedMessage id="Freemium.Form.EDocuments.Title" />
        </h5>

        <div className="setadvisor-workguide-contract-dispatch-rules-post-processing--content">
          <p className="open-module-world--disclaimer">
            <FormattedMessage id="Freemium.Form.EDocuments.Description" />
          </p>

          <Form
            contracts={get(form, 'data.contracts')}
            disabled
            id="contracts"
            language={language}
            onChange={freemiumActions.contractDispatchRulesFormSetValue}
            session={session}
            showCommentInput={false}
          />

          <div className="setadvisor-workguide-contract-dispatch-rules-post-processing--buttons">
            <Button
              color="primary"
              onClick={this.onSubmit}
            >
              <FormattedMessage id="Freemium.Form.EDocuments.Submit" />
            </Button>

            <span style={{ paddingRight: '20px' }} />

            <Button color="primary" outline onClick={onFormCancel}>
              <FormattedMessage id="Freemium.Form.General.Labels.Cancel" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ContractDispatchRulesPostProcessing.propTypes = {
  acl: PropTypes.object.isRequired,
  bpf: PropTypes.object.isRequired,
  form: PropTypes.object,
  language: PropTypes.string,
  onFormCancel: PropTypes.func,
  session: PropTypes.object.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func
};

ContractDispatchRulesPostProcessing.defaultProps = {
  form: {},
  language: 'de',
  onFormCancel: noop,
  toggleRedirectModal: noop
};

function mapStateToProps(state) {
  return {
    contractDispatchRules: state.freemium.contractDispatchRules,
    form: state.freemium.contractDispatchRulesPostProcessingForm,
    language: state.login.language,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withWorkguideInstanceData()(
    withAcl()(ContractDispatchRulesPostProcessing)
  )
);
