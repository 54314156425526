import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import update from 'immutability-helper';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Toggle } from '@evoja-web/react-form';
import { Col, Row } from 'reactstrap';
import { Card, Checkbox, CodeSelect, InputField, Select } from '../../../../General/index';
import './AccountCreator.scss';

class AccountCreator extends Component {
  constructor(props) {
    super(props);

    this.onAccountCreatorChange = this.onAccountCreatorChange.bind(this);
  }

  onAccountCreatorChange(key, value) {
    const { account, onChange, index } = this.props;
    onChange(
      index,
      update(account, {
        [key]: { $set: value },
      })
    );
  }

  render() {
    const {
      label, language, productGroups, account, codes, validations, index
    } = this.props;

    return (
      <Card className="account-selector--container" style={{ paddingTop: 0 }}>
        <h6 className="account-selector--label">{label}</h6>
        <Row className="account-selector--row">
          <Col className="setadvisor-form-open-account--create-account--row--label" lg={4} sm={4}>
            <FormattedMessage id="Freemium.Form.AccountCreator.ChooseAccountType" tagName="p" />
          </Col>
          <Col className="setadvisor-form-open-account--create-account--row--value" lg={6} sm={6}>
            <Select
              id="accountType"
              value={get(account, 'accountType')}
              options={productGroups.map((product) => ({ label: get(product, `text.${language}`), value: product.productNumber }))}
              onChange={this.onAccountCreatorChange}
              validations={get(validations, `newAccounts.${index}.accountType`, {})}
              showValidations
            />
          </Col>
        </Row>
        <Row className="account-selector--row">
          <Col md={12} lg={12} className="account-selector--rubric-col">
            <Row>
              <Col md={4} lg={4} sm={4}>
                <FormattedMessage id="Freemium.Form.AccountCreator.Rubric" tagName="p" />
              </Col>
              <Col md={2} lg={2} sm={2}>
                <Toggle
                  value={get(account, 'hasRubric', false)}
                  onChange={(key, value) => this.onAccountCreatorChange('hasRubric', value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {get(account, 'hasRubric', false) && (
          <Row className="account-selector--row">
            <Col md={4} sm={4} lg={4} />
            <Col md={6} sm={6} lg={6}>
              <div>
                {!get(account, 'manuelRubric', false) ? (
                  <CodeSelect
                    id="accountCategory"
                    language={language}
                    codes={get(codes, 'groups.accountRubric', [])}
                    value={get(account, 'accountCategory')}
                    onChange={this.onAccountCreatorChange}
                    validations={get(validations, `newAccounts.${index}.accountCategory`, {})}
                    showValidations
                  />
                ) : (
                  <InputField
                    id="customRubric"
                    name="customRubric"
                    value={get(account, 'customRubric')}
                    onChange={this.onAccountCreatorChange}
                    placeholder="Rubrik manuell eingeben"
                    validations={get(validations, `newAccounts.${index}.customRubric`, {})}
                    showValidations
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
        {get(account, 'hasRubric', false) && (
          <Row className="account-selector--row">
            <Col md={4} sm={4} lg={4} />
            <Col md={6} sm={6} lg={6}>
              <Checkbox
                value={get(account, 'manuelRubric', false)}
                textStyle={{ fontSize: 8.5 }}
                onChange={() => this.onAccountCreatorChange('manuelRubric', !get(account, 'manuelRubric', false))}
              >
                <FormattedMessage id="Freemium.Form.AccountCreator.ManuelRubric" />
              </Checkbox>
            </Col>
          </Row>
        )}
      </Card>
    );
  }
}

AccountCreator.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  codes: PropTypes.object.isRequired,
  language: PropTypes.string,
  productGroups: PropTypes.array,
  account: PropTypes.object.isRequired,
  validations: PropTypes.object
};

AccountCreator.defaultProps = {
  language: 'de',
  validations: {},
  productGroups: [],
  label: ''
};

export default injectIntl(AccountCreator);
