import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Row, Col } from 'reactstrap';

import './AddressOptionLabel.css';

const AddressOptionLabel = React.memo(({ address, language }) => {
  const {
    street = '',
    houseNumber = '',
    place = '',
    postcode = ''
  } = address;

  return (
    <Row className="setadvisor-form-common--address-option-label">
      <Col
        className="setadvisor-form-common--address-option-label--type"
        lg={4}
        md={4}
      >
        {get(address, `addressType.text.${language}`, '')}
      </Col>

      <Col
        className="setadvisor-form-common--address-option-label--address"
        lg={4}
        md={4}
      >
        {`${street} ${houseNumber}, ${postcode} ${place}`}
      </Col>
    </Row>
  );
});

AddressOptionLabel.propTypes = {
  address: PropTypes.object,
  language: PropTypes.string
};

AddressOptionLabel.defaultProps = {
  address: {},
  language: 'de'
};

export default AddressOptionLabel;
