import { deburr, get } from 'lodash';

const replaceMap = {
  slash: { fr: 'ET', de: 'UND' },
  and: { fr: 'ET', de: 'UND' }
};

/**
 * Normalize the given name.
 * Set it to upper case and remove special chars
 *
 * @param  {String} name Name
 *
 * @return {String} name Normalized string
 */
export default function normalizeEmbossedLine(name = '', language = 'de') {
  const normalized = name.replace(/ä/ig, 'ae')
    .replace(/ö/ig, 'oe')
    .replace(/ü/ig, 'ue')
    .replace(/\//ig, get(replaceMap, `slash.${language}`, 'UND'))
    .replace(/&/ig, get(replaceMap, `and.${language}`, 'UND'));
  return deburr(
    normalized.toUpperCase()
  );
}
