import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { has, get, upperFirst, isNil } from 'lodash';

import './Files.css';
import DocumentPreview from '../../../Common/DocumentPreview';
import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../../General/components/SubSection/index';
import toIntIfSet from '../../../../../../../lib/Utils/toIntIfSet';

/**
 * There are three possible places where the residence permit file can be found.
 * In the order form, it is located in form data.
 * In all other workguides (data from bpf) is is located under main / additional card holder
 *
 * @param  {Object} codes    Codes object
 * @param  {Object} data     Form data / data from bpf (depending on workguide)
 * @param  {String} language User language
 *
 * @return {Array} file Array of files to render
 */
function getResidencePermitFiles({ codes, data, language }) {
  const files = [{
    codeNumber: get(data, 'residencePermit'),
    link: get(data, 'residencePermitFile')
  }, {
    codeNumber: get(data, 'mainCardHolder.clientInformation.residencePermitCode'),
    link: get(data, 'mainCardHolder.clientInformation.residencePermitFileLink')
  }, {
    codeNumber: get(data, 'additionalCardHolder.clientInformation.residencePermitCode'),
    link: get(data, 'additionalCardHolder.clientInformation.residencePermitFileLink')
  }];

  return files
    .filter((file) => !isNil(file.link))
    .map((file) => {
      const { codeNumber, link } = file;
      const code = get(codes, 'groups.residencePermit', []).find((code) => toIntIfSet(get(code, 'number')) === toIntIfSet(codeNumber));

      return (
        <Col
          // eslint-disable-next-line
          key="residencePermit"
          className="setadvisor-form-limit-determination-summary--files--document-preview"
          lg={4}
          sm={4}
        >
          <DocumentPreview
            uri={link}
            label={get(code, `text.${language}`)}
            removeable={false}
          />
        </Col>
      );
    });
}

const LimitDeterminationSummaryFiles = React.memo(({
  codes,
  data,
  language
}) => {
  const children = get(data, 'monthlyIncomes', [])
    .filter((item) => has(item, 'fileLink') && !isNil(get(item, 'fileLink')))
    .map((item, index) => {
      const {
        proofOfIncome,
        fileLink
      } = item;

      return (
        <Col
          // eslint-disable-next-line
          key={index}
          className="setadvisor-form-limit-determination-summary--files--document-preview"
          lg={4}
          sm={4}
        >
          <DocumentPreview
            uri={fileLink}
            label={<FormattedMessage id={`Freemium.Form.CreditCard.MonthlyIncome.ProofOfIncome.${upperFirst(proofOfIncome)}`} />}
            removeable={false}
          />
        </Col>
      );
    });

  const residencePermitFiles = getResidencePermitFiles({ codes, data, language });

  return (
    <SubSection>
      <SubSectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Files.Title" />
      </SubSectionTitle>

      <SubSectionContent>
        <Row>
          {children}
          {residencePermitFiles}
        </Row>
      </SubSectionContent>
    </SubSection>
  );
});

LimitDeterminationSummaryFiles.propTypes = {
  codes: PropTypes.object,
  data: PropTypes.object,
  language: PropTypes.string
};

LimitDeterminationSummaryFiles.defaultProps = {
  codes: { groups: {} },
  data: {},
  language: 'de'
};

export default LimitDeterminationSummaryFiles;
