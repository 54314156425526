import axios from 'axios';
import { get } from 'lodash';
import { call, put } from 'redux-saga/effects';

import {
  toIntIfSet,
  toStringIfSet
} from '../../../lib/Utils/index';
import { BpfSyncCallError } from '../../Error';
import loadLilaSetAction from '../actions/loadLilaSet';

const {
  LOAD_LILA_SET_PENDING,
  LOAD_LILA_SET_FULFILLED,
  LOAD_LILA_SET_REJECTED,
} = loadLilaSetAction;

export default function* loadSetProduct(request) {
  // Fallback to customer id if data key is missing
  const dataKey = get(request, 'dataKey', get(request, 'customerId'));
  yield put({ type: LOAD_LILA_SET_PENDING, dataKey });

  try {
    const { tenant = 6300, invalidate = false } = request;

    const data = {
      businessKey: toStringIfSet(dataKey),
      variables: {
        clientKey: toIntIfSet(dataKey),
        ignoreProductCache: invalidate
      },
      resultVariableNames: [
        'set',
        'modules',
        'other',
        'basisSetRunning',
        'clientType',
        'endStatus',
        'validationErrors',
        'processStatus',
        'productAccounts'
      ],

      waitTimeoutInMs: 40000

    };

    const result = yield call(axios, {
      url: `sif/bpf/public/api/v1/process-definition/key/valiant-product-getVariablesV2/tenant-id/${tenant}/execute`,
      method: 'post',
      data
    });
    const payload = get(result, 'data.resultVariables');

    if (get(result, 'data.resultVariables.processStatus') !== 'fetched' || get(result, 'status') !== 200) {
      const e = new BpfSyncCallError(`Unable to load customer & product data for customer with id ${dataKey} from bpf!`, { cause: result });
      e.setTargetActive('sentry', false);

      throw e;
    }
    yield put({ type: LOAD_LILA_SET_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: LOAD_LILA_SET_REJECTED, dataKey, error });

    return error;
  }
}
