import { unified } from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';

const sanitizationSchema = {
  ...defaultSchema,
  attributes: {
    ...defaultSchema.attributes,
    span: [...(defaultSchema.attributes.span || []), 'data*'],
  },
};

export default function convertHtmlToPlainTextSync(html) {
  const sanitizedHtml = unified()
    .use(rehypeParse, { fragment: true })
    .use(rehypeSanitize, sanitizationSchema)
    .use(rehypeStringify)
    .processSync(html)
    .toString();

  return sanitizedHtml.replace(/<[^>]+>/g, '').replace(/\s+/g, ' ').trim();
}
