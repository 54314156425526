import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import moment from 'moment';

import actions from '../../actions/Actions';
import { toIntIfSet, toStringIfSet } from '../../../../lib/Utils/index';

const {
  DISSOLVE_LILA_SET_FORM_SAVE_PENDING,
  DISSOLVE_LILA_SET_FORM_SAVE_FULFILLED,
  DISSOLVE_LILA_SET_FORM_SAVE_REJECTED
} = actions;

export default function* saveDissolveLilaSet(request) {
  yield put({ type: DISSOLVE_LILA_SET_FORM_SAVE_PENDING });

  try {
    const {
      customerId,
      consultantId,
      cancellationDate = moment().format(),
      contractType
    } = request;

    const result = yield call(axios, {
      url: '/sif/bpf/public/api/v1/message',
      method: 'post',
      data: {
        messageName: 'valiant-product-cancelModule',
        processVariables: {
          clientKey: toIntIfSet(customerId),
          responsibleUser: toIntIfSet(consultantId),
          processCallOrigin: 'MAP',
          contractType,
          validUntil: moment(cancellationDate).format('YYYY-MM-DD')
        },
        businessKey: toStringIfSet(customerId),
        resultVariableNames: [
          'callingProcessKeyDE',
          'callingProcessKeyFR',
          'endStatus'
        ],
        waitTimeoutInMs: 20000
      }
    });

    yield put({ type: DISSOLVE_LILA_SET_FORM_SAVE_FULFILLED, result });

    return result;
  } catch (error) {
    yield put({ type: DISSOLVE_LILA_SET_FORM_SAVE_REJECTED, error });

    return error;
  }
}
