import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import qiDeclarationAction from '../actions/QiDeclaration';

const {
  QI_DECLARATION_PENDING,
  QI_DECLARATION_FULFILLED,
  QI_DECLARATION_REJECTED
} = qiDeclarationAction;

export default function* getQiDeclaration(request) {
  const {
    dataKey,
    tenant = 6300
  } = request;

  yield put({ type: QI_DECLARATION_PENDING, dataKey });

  try {
    const url = `/sif/finnova/contractadministration/api/v5/banks/${tenant}/contracts?clientKey=${dataKey}&contractType=6023&contractStatus=90`
    const { data } = yield call(axios, {
      url,
      method: 'get',
    });

    const payload = { contract: get(data, 0) };
    yield put({ type: QI_DECLARATION_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: QI_DECLARATION_REJECTED, dataKey, error });

    return error;
  }
}
