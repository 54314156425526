import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';

const Customer = React.memo(({ cardHolder }) => {
  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.Customer" />}>
      {get(cardHolder, 'clientInformation.firstName', '')}
      {' '}
      {get(cardHolder, 'clientInformation.name', '')}
      {' '}
      {`(${get(cardHolder, 'clientInformation.editClientNumber', '')})`}
    </Row>
  );
});

Customer.propTypes = {
  cardHolder: PropTypes.object
};

Customer.defaultProps = {
  cardHolder: {}
};

export default Customer;
