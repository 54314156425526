import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { chain, get, isEmpty, isNil, isUndefined, noop } from 'lodash';
import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';
import { ValidationResult, Toggle } from '@evoja-web/react-form';
import { Link } from 'react-router-dom';
import { Alert, AlertContent, StepContainer, Step, StepButtons } from '@evoja-web/react-layout-components';

import moment from 'moment';
import { Button, Col, Row } from 'reactstrap';
import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../General/components/SubSection';
import Accounts from './Accounts';
import Poa from './Poa';
import { Section, SectionContent, SectionTitle } from '../../../../General/components/Section';
import { workguideMap } from '../../../../../staticMappings/workguides';
import ShippingAddress from './ShippingAddress';
import ProfileCompletion from '../../../containers/Workguide/ProfileCompletion';

const STEP_CONTRACT_PARTNER = 'stepContractPartner';
const STEP_CUSTOMER_ACCOUNTS = 'stepCustomerAccounts';
const STEP_POA_ACCOUNTS = 'stepPoaAccounts';

/**
 * Prepare assignment accounts.
 * Add missing clientKey to each account as this is required in bpf
 *
 * @param   {Object}    poa     Poa object form bpf
 * @param   {Function}  filter  Optional filter to filter prepared accounts
 *
 * @return  {Array} accounts  Prepared (and filtered) accounts
 */
export function AssignmentAccounts(poa, filter = () => true) {
  return get(poa, 'assignmentAccounts', [])
    .map((a) => ({ ...a, clientKey: get(poa, 'clientAssignedKey') }))
    .filter(filter);
}

/**
   * Check if the client of the given poa already has an ebanking contract
   *
   * @param   {Object}  poa      Selected poa from form
   * @param   {Object}  lilaSet  Result from /valiant-product-getVariables
   *
   * @return  {Boolean}
   */
export function hasExistingEbankingContract(poa, lilaSet) {
  return (
    !isNil(poa)
      && chain(lilaSet)
        .get('data.other.ebankingContracts', [])
        .some((c) => get(c, 'clientAssignedKey') === get(poa, 'clientAssignedKey'))
        .value()
  );
}

/**
 * Prepare the old ebanking workguide link for formatted message
 *
 * @param   {String}  chunks   Content
 * @param   {Object}  customer Selected customer from workguide instance data
 *
 * @return {ReactElement} markup
 */
export function getOldEbankingWorkguideLink(chunks, customer) {
  const oldEbankingWorkguideLink = `/activity/${workguideMap.get('open-ebanking-contract-old')}/${get(customer, 'id')}`;

  return (
    <Link to={oldEbankingWorkguideLink} className="react-anchor">{chunks}</Link>
  );
}

class EBankingContractForm extends PureComponent {
  constructor(props) {
    super(props);

    this.onPoaAccountsChange = this.onPoaAccountsChange.bind(this);
  }

  onPoaAccountsChange(key, account, index) {
    const { onChange, form } = this.props;
    const accounts = get(form, `data.${key}`, []);

    const updated = update(accounts, {
      [index]: { $set: isUndefined(account) ? undefined : account }
    });

    onChange(key, updated);
  }

  /**
   * Render account select for all poas given
   *
   * @return {ReactElement} markup
   */
  renderPoaAccounts() {
    const {
      form,
      language,
      receivedPoas
    } = this.props;

    const filtered = receivedPoas
      .filter((poa) => get(poa, 'poaType') === 102 && get(poa, 'signingAuthority') === 'INDIVIDUALLY')
      .filter((poa) => {
        const accounts = get(poa, 'assignmentAccounts', [])
          .filter((account) => !(get(account, 'accountType') === 1 && get(poa, 'clientType') === 'companiesAndOther'));

        return get(accounts, 'length', 0) > 0;
      });

    if (get(filtered, 'length', 0) === 0) {
      return (
        <Alert type="warning">
          <AlertContent>
            <FormattedMessage id="Freemium.Workguide.EBankingContract.NoPoaAccountsWarning" />
          </AlertContent>
        </Alert>
      );
    }

    return filtered.map((poa, index) => {
      // check if receivedPoas date of birth is < 18
      const dob = moment(poa.dateOfBirth);
      const eighteenYearsAgo = moment().subtract(18, 'years');
      const isCustomerUnderAge = !dob.isBefore(eighteenYearsAgo);

      return (
        <SubSection>
          <SubSectionTitle>
            {get(poa, 'clientDesignationLong')}
            {` (${get(poa, 'editClientNumber')})`}
          </SubSectionTitle>
          {isCustomerUnderAge && (
          <Alert type="warning">
            <AlertContent>
              <FormattedMessage id="Freemium.Workguide.EBankingContract.UnderEighteenMessage" />
            </AlertContent>
          </Alert>
          )}
          <SubSectionContent>
            <Accounts
              accounts={AssignmentAccounts(poa)}
              form={form}
              id="poaAccounts"
              language={language}
              onChange={(key, value) => this.onPoaAccountsChange(key, value, index)}
              // validations={get(validations, 'poaAccounts')}
              value={get(form, `data.poaAccounts.${index}`, [])}
            />
          </SubSectionContent>
        </SubSection>
      );
    });
  }

  /**
   * Render the form
   *
   * @return {ReactElement} markup
   */
  renderForm() {
    const {
      customer,
      form,
      language,
      hasExistingContract,
      poas,
      onChange,
      validations,
      onFormCancel,
      onSubmit
    } = this.props;

    const eBankingPoas = get(poas, 'data.resultVariables.givenPoas', [])
      .filter((poa) => get(poa, 'poaType') === 6026 && get(poa, 'signingAuthority') === 'INDIVIDUALLY');

    const selected = get(form, 'data.poa');
    const customerAccounts = get(form, 'data.customerAccounts', []);

    return (
      <div className="px-2">
        <StepContainer initialStep={STEP_CONTRACT_PARTNER} onActiveStepChange={noop}>
          <Step id={STEP_CONTRACT_PARTNER} title={<FormattedMessage id="Freemium.Workguide.EBankingContract.Contractor" />}>
            <Section>
              <SectionTitle>
                <FormattedMessage id="Freemium.Workguide.EBankingContract.Contractor" />
              </SectionTitle>

              <SectionContent>
                <Poa
                  form={form}
                  language={language}
                  onChange={onChange}
                  poas={poas}
                  validations={validations}
                />
              </SectionContent>
            </Section>

            {!isNil(get(form, 'data.poa')) && hasExistingContract && (
            <Alert type="warning">
              <AlertContent>
                <FormattedMessage
                  id="Freemium.Workguide.EBankingContract.ExistingContractWarning"
                  values={{
                    workguideLink: (chunks) => getOldEbankingWorkguideLink(chunks, customer)
                  }}
                />
              </AlertContent>
            </Alert>
            )}

            {!isNil(get(form, 'data.poa')) && !hasExistingContract && (
            <Section>
              <SectionTitle>
                <FormattedMessage id="Freemium.Workguide.EBankingContract.ShippingAddress" />
              </SectionTitle>

              <SectionContent>
                <ShippingAddress
                  form={form}
                  language={language}
                  onChange={onChange}
                  validations={validations}
                />
              </SectionContent>
            </Section>
            )}
          </Step>

          {!hasExistingContract && (

          <Step id={STEP_CUSTOMER_ACCOUNTS} title={<FormattedMessage id="Freemium.Workguide.EBankingContract.CustomerAccounts" />}>
            <Section>
              <SectionTitle>
                <FormattedMessage id="Freemium.Workguide.EBankingContract.CustomerAccounts" />
              </SectionTitle>

              <SectionContent>
                {!true && (
                <Alert type="warning">
                  <AlertContent>
                    <FormattedMessage id="Freemium.Workguide.EBankingContract.UnderEighteenMessage" />
                  </AlertContent>
                </Alert>
                )}

                {!isNil(selected) && !isEmpty(customerAccounts) && (
                <ProfileCompletion
                  checks={['basicContract']}
                  customerId={get(selected, 'clientKey')}
                  id="selectedPoaProfileCompletion"
                  onChange={onChange}
                />
                )}
                <Toggle
                  className="pb-4"
                  id="addFutureOpenedAccounts"
                  onChange={onChange}
                  value={get(form, 'data.addFutureOpenedAccounts', false)}
                >
                  <FormattedMessage id="Freemium.Workguide.EBankingContract.AddFutureOpenedAccounts" />
                </Toggle>

                <Accounts
                  accounts={AssignmentAccounts(get(eBankingPoas, 0))}
                  form={form}
                  id="customerAccounts"
                  language={language}
                  onChange={onChange}
                  showAddFutureOpenedAccountsToggle
                  value={get(form, 'data.customerAccounts', [])}
                  validations={get(validations, 'customerAccounts')}
                  showValidations
                />

                <Toggle
                  className="pt-4"
                  id="edocuments"
                  onChange={onChange}
                  value={get(form, 'data.edocuments', false)}
                >
                  <FormattedMessage id="Freemium.Workguide.EBankingContract.EDocuments" />
                </Toggle>
              </SectionContent>
            </Section>
          </Step>
          )}

          {!hasExistingContract && (
          <Step id={STEP_POA_ACCOUNTS} title={<FormattedMessage id="Freemium.Workguide.EBankingContract.PoaAccounts" />}>
            <Section>
              <SectionTitle>
                <FormattedMessage id="Freemium.Workguide.EBankingContract.PoaAccounts" />
              </SectionTitle>

              <SectionContent>
                {this.renderPoaAccounts()}
                <ValidationResult
                  show
                  validations={get(validations, 'poaAccounts')}
                />
              </SectionContent>
            </Section>

          </Step>
          )}
          <StepButtons>
            <Button
              color="primary"
              outline
              onClick={onFormCancel}
            >
              <FormattedMessage id="Freemium.Workguide.EBankingContract.Cancel" />
            </Button>

            <span style={{ paddingRight: '20px' }} />

            <Button
              color="primary"
              disabled={isNil(customer) || !isEmpty(validations) || hasExistingContract}
              onClick={onSubmit}
            >
              <FormattedMessage id="Freemium.Workguide.EBankingContract.Submit" />
            </Button>

          </StepButtons>
        </StepContainer>
      </div>
    );
  }

  render() {
    return this.renderForm();
  }
}

EBankingContractForm.propTypes = {
  validations: PropTypes.object,
  customer: PropTypes.object,
  form: PropTypes.object,
  language: PropTypes.string,
  hasExistingContract: PropTypes.bool,
  poas: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  receivedPoas: PropTypes.array,
};

EBankingContractForm.defaultProps = {
  validations: {},
  customer: undefined,
  form: {},
  language: 'de',
  poas: {},
  receivedPoas: [],
  hasExistingContract: false
};

export default EBankingContractForm;
