import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isNil } from 'lodash';
import { CustomerWorkguideSearch, actions as customerWorkguideSearchActions } from '@evoja-web/customer-workguide-search';
import { withAcl } from '@evoja-web/client-acl';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import './Powersearch.css';
import MenuFooter from './MenuFooter';
import MostImportantComponent from './MostImportantComponent';
import * as usertrackingActions from '../../../../../../actions/UserTracking';
import { actions as workguideActions, getCreatableWorkguides } from '../../../../../Workguide/index';
import { withWorkguideModalToggle } from '../../../../../Workguide/hocs';
import { mainTypeMap } from '../../../../../../staticMappings/mapCustomerCodes';
import { customerSegmentMap } from '../../../../../../staticMappings/mapCustomerSegments';

/**
 * Check if the given customer is a company
 *
 * @param  {Object} customer Customer object
 *
 * @return {Boolean}
 */
function isCompany(customer) {
  return mainTypeMap.get('company').includes(get(customer, 'mainType.id'));
}

class Powersearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partyhard: false
    };

    this.onRedirectToCustomer = this.onRedirectToCustomer.bind(this);
    this.onRedirectToWorkguide = this.onRedirectToWorkguide.bind(this);
    this.onWorkguideSelect = this.onWorkguideSelect.bind(this);
    this.onWorkguidesIconClick = this.onWorkguidesIconClick.bind(this);
  }

  onRedirectToCustomer({ customer, workguide }) {
    const {
      history,
      onToggleWorkguideModal
    } = this.props;
    const customerId = get(customer, 'id');
    const workguideId = get(workguide, 'id');

    // Only toggle workguide modal if a already workguide is selected
    if (!isNil(workguideId)) {
      onToggleWorkguideModal({ customerId, workguideId });
    }
    history.push(`/customer/${customerId}`);
  }

  onRedirectToWorkguide({ customer, workguide }) {
    const { onToggleWorkguideModal } = this.props;

    const customerId = get(customer, 'id');
    const workguideId = get(workguide, 'id');

    onToggleWorkguideModal({ customerId, workguideId });
  }

  /**
   * Write a user tracking event if the user selects a workguide
   *
   * @param  {Object} workguide Selected workguide
   * @param  {Object} params    Params from powersearch.search.data
   *
   * @return void
   */
  onWorkguideSelect({ workguide, params }) {
    const {
      session,
      usertrackingActions
    } = this.props;
    const workguideId = get(workguide, 'id');

    usertrackingActions.addUsertrackingEvent(
      'WORKGUIDE_SELECTED',
      get(session, 'id'),
      { searchTerm: get(params, 'term', ''), workguideId }
    );
  }

  /**
   * Redirect to workguies list
   *
   * @return void
   */
  onWorkguidesIconClick(ev) {
    const {
      customerWorkguideSearchActions,
      history
    } = this.props;

    ev.preventDefault();
    ev.stopPropagation();

    customerWorkguideSearchActions.setCustomerRequest();
    history.push('/workguides');
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { partyhard } = this.state;
    const {
      acl,
      allowedWorkguides,
      onFocus,
      onBlur
    } = this.props;

    const excludedSegments = acl.isAllowed('Powersearch.Customer.ShowPostfinance') ? [] : customerSegmentMap.get('postfinance');

    return (
      <div className="layout-header-powersearch">
        <CustomerWorkguideSearch
          openMenuOnFocus
          useHistory
          excludedSegments={excludedSegments}
          focusAfterSelect={false}
          blurAfterSelect
          isCompanyCheck={isCompany}
          onCustomerSelect={this.onCustomerSelect}
          onRedirectToCustomer={this.onRedirectToCustomer}
          onRedirectToWorkguide={this.onRedirectToWorkguide}
          onWorkguideSelect={this.onWorkguideSelect}
          onInputChange={(term) => {
            if (term === 'Dr. Dr. Prof. Party Hard') {
              this.setState({ partyhard: true });
            }
          }}
          selectProps={{
            onFocus,
            onBlur,
            renderFooter: (props = {}) => <MenuFooter {...props} />,
            // menuIsOpen: true
          }}
          showHelpIcon
          workguides={allowedWorkguides}
        />

        {partyhard && (
          <MostImportantComponent />
        )}
      </div>
    );
  }
}

Powersearch.propTypes = {
  acl: PropTypes.object.isRequired,
  allowedWorkguides: PropTypes.array,
  customer: PropTypes.object,
  customerWorkguideSearchActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onToggleWorkguideModal: PropTypes.func,
  session: PropTypes.object.isRequired,
  usertrackingActions: PropTypes.object.isRequired,
  workguide: PropTypes.object,
  workguideActions: PropTypes.object.isRequired
};

Powersearch.defaultProps = {
  allowedWorkguides: [],
  customer: undefined,
  onBlur: noop,
  onFocus: noop,
  onToggleWorkguideModal: noop,
  workguide: undefined
};

function mapStateToProps(state, ownProps) {
  const { acl } = ownProps;

  const allowedWorkguides = getCreatableWorkguides({
    acl,
    workguides: get(state, 'workguide.allWorkguides.data'),
    onlyActive: true
  });

  return {
    allowedWorkguides,
    customer: state.powersearch.customer,
    session: state.login.session,
    workguide: state.powersearch.workguide
  };
}

function mapDispatchToProps(dispatch) {
  return {
    customerWorkguideSearchActions: bindActionCreators(customerWorkguideSearchActions, dispatch),
    usertrackingActions: bindActionCreators(usertrackingActions, dispatch),
    workguideActions: bindActionCreators(workguideActions, dispatch)
  };
}

export default withAcl()(
  connect(mapStateToProps, mapDispatchToProps)(
    withWorkguideModalToggle()(
      withRouter(
        injectIntl(Powersearch)
      )
    )
  )
);
