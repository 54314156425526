import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, chain } from 'lodash';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';

import orderFormAction from '../../actions/EBankingContract/OrderForm';
import { toIntIfSet, toStringIfSet } from '../../../../lib/Utils';

const {
  EBANKING_ORDER_FORM_SAVE_PENDING,
  EBANKING_ORDER_FORM_SAVE_FULFILLED,
  EBANKING_ORDER_FORM_SAVE_REJECTED
} = orderFormAction;

export default function* saveOrderForm(request) {
  yield put({ type: EBANKING_ORDER_FORM_SAVE_PENDING });

  try {
    const {
      customerId,
      data,
      responsibleUser,
      tenant = 6300
    } = request;

    const assignmentAccounts = chain(data)
      .get('poaAccounts')
      .concat(get(data, 'customerAccounts', []))
      .compact()
      .map((account) => ({
        accountKey: get(account, 'accountKey'),
        accountHolderClientKey: get(account, 'clientKey')
      }))
      .value();

    const payload = {
      businessKey: toStringIfSet(customerId),
      variables: {
        clientKey: toIntIfSet(customerId),
        edocuments: get(data, 'edocuments', false),
        responsibleUser: toIntIfSet(responsibleUser),
        addFutureOpenedAccounts: get(data, 'addFutureOpenedAccounts', false),
        assignmentAccounts,
        addressingRules: [{
          addressType: toIntIfSet(get(data, 'shippingAddress.addressType.number')),
          addresseeKey: get(data, 'poa.clientKey'),
        }]
      }
    };

    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-product-orderEBankingContract/tenant-id/${tenant}/start`;
    yield call(axios, {
      url,
      method: 'post',
      data: removeUnwantedValues(payload, [null, undefined])
    });

    yield put({ type: EBANKING_ORDER_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: EBANKING_ORDER_FORM_SAVE_REJECTED, error });

    return error;
  }
}
