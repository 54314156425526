import update from 'immutability-helper';
import { get, has, isEmpty, isNil, isUndefined, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Toggle } from '@evoja-web/react-form';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import './DebitMasterCard.scss';
import { NumberField, RadioSelectWrapper } from '../../../../../General/index';
import { toIntIfSet } from '../../../../../../lib/Utils';
import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../../General/components/SubSection';
import Poa from '../../CreditCard/General/Poa';
import SetAdvisorProfileCompletion from '../../../../containers/Workguide/ProfileCompletion';
import EmbossedLines from '../../CreditCard/EmbossedLines/EmbossedLines';
import normalizeEmbossedLine from '../../../../lib/CreditCard/normalizeEmbossedLine';

class DebitMasterCardFrom extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onPoaChange = this.onPoaChange.bind(this);
    this.getEmbossedLinesDCObject = this.getEmbossedLinesDCObject.bind(this);
    this.getAccountHolder = this.getAccountHolder.bind(this);
    this.getCardHolderClientKey = this.getCardHolderClientKey.bind(this);
    this.getAccountHolderClientKey = this.getAccountHolderClientKey.bind(this);
    this.getAccountHolderClientDesignation = this.getAccountHolderClientDesignation.bind(this);
    this.onProfileCompletion = this.onProfileCompletion.bind(this);
  }

  onProfileCompletion(id, value) {
    const { form, index, onDebitCardChange } = this.props;

    const debitCards = get(form, `data.debitCards.${index}`, {});
    /**
     * This check is necessary because the profile completion component
     * is only shown when the poaType is 102, but any other poaType is also valid.
     */
    const hasSpecialPoaType = get(debitCards, 'cardHolder.poaType') !== 102;
    const isProfileCOmpleted = hasSpecialPoaType ? true : value;
    onDebitCardChange(
      index,
      update(debitCards, {
        [id]: { $set: isProfileCOmpleted }
      })
    );
  }

  onChange(key, value) {
    const { form, index, onDebitCardChange } = this.props;
    const debitCards = get(form, `data.debitCards.${index}`, {});
    onDebitCardChange(
      index,
      update(debitCards, {
        [key]: { $set: value }
      })
    );
  }

  onPoaChange(key, value) {
    const {
      cmsCustomers, onLoadBpfCustomerData, index, form, freemiumActions, onDebitCardChange
    } = this.props;

    // Update debitCards form
    this.onChange('cardHolder', value);
    const poaAdditional = get(form, 'data.poaAdditional', []);

    const updated = update(poaAdditional, {
      [index]: { $set: value }
    });
    freemiumActions.openLilaSetSetValue('poaAdditional', updated);
    // If poa changed, load bpf customer data if not already done
    // Also set Value for EmbossedLine
    if (key === 'poaAdditional') {
      const customerId = get(value, 'clientAssignedKey');
      const debitCards = get(form, `data.debitCards.${index}`, {});

      if (!has(cmsCustomers, customerId)) {
        onLoadBpfCustomerData(customerId);
      }

      // Gets new Card Values as update Object.
      const debitCardDcObject = this.getEmbossedLinesDCObject(value);

      onDebitCardChange(
        index,
        update(debitCards, {
          ...debitCardDcObject,
          cardHolder: { $set: value }
        })
      );
    }
  }

  getAccountHolder() {
    const { accounts, form, index } = this.props;
    return accounts.find((account) => toIntIfSet(account.accountKey) === get(form, `data.debitCards.${index}.cardAccount`));
  }

  getAccountHolderClientKey() {
    const { customer } = this.props;

    const accountHolder = this.getAccountHolder();

    if (isNil(accountHolder)) {
      return get(customer, 'id');
    }

    return get(accountHolder, 'clientKey');
  }

  getAccountHolderClientDesignation() {
    const { customer } = this.props;

    const accountHolder = this.getAccountHolder();

    if (isNil(accountHolder)) {
      return get(customer, 'designation');
    }

    return get(accountHolder, 'clientDesignation');
  }

  getCardHolderClientKey() {
    const { form, index } = this.props;

    return get(form, `data.debitCards.${index}.cardHolder.clientKey`);
  }

  getEmbossedLinesDCObject(value) {
    const { customer } = this.props;

    const clientDesignationNormalized = normalizeEmbossedLine(get(value, 'clientDesignation'), get(customer, 'languageIso', 'de'));
    const accountHolderDesignation = normalizeEmbossedLine(this.getAccountHolderClientDesignation(), get(customer, 'languageIso', 'de'));

    const cardHolderClientKey = get(value, 'clientKey');
    const accountHolderClientKey = this.getAccountHolderClientKey();

    let embossedLineTwoData;
    if (cardHolderClientKey !== accountHolderClientKey) {
      embossedLineTwoData = { embossedLineTwo: { $set: accountHolderDesignation } };
    } else {
      embossedLineTwoData = { $unset: ['embossedLineTwo'] };
    }

    return {
      embossedLineOne: { $set: clientDesignationNormalized },
      ...embossedLineTwoData
    };
  }

  render() {
    const {
      accounts, language, form, index, cmsCustomers, customer, powerOfAttorneys, validations, intl
    } = this.props;

    const cardAccountOptions = accounts.filter((account) => [90, 110].includes(account.accountType)).map((account) => ({
      label: (
        <Row style={{ width: '90%' }}>
          <Col md={3} lg={3}>
            {get(account, `accountTypeText.${language}`)}
          </Col>
          <Col md={3} lg={3}>
            {account.editAccountNumber}
          </Col>
          <Col md={6} lg={6}>
            {account.clientDesignation}
          </Col>
        </Row>
      ),
      value: toIntIfSet(account.accountKey)
    }));

    if (get(form, 'data.newAccounts.length', 0) > 0) {
      cardAccountOptions.push({
        value: 'new',
        label: (
          <Row style={{ width: '90%' }}>
            <Col md={3} lg={3}><FormattedMessage id="Freemium.Form.NewLilaSet.NewlyOpenedAccount" /></Col>
            <Col md={9} lg={9} style={{ color: 'gray' }}>
              <i className="mdi mdi-information-outline" />
              <FormattedMessage id="Freemium.Form.NewLilaSet.CardAccountDisclaimer" />
            </Col>
          </Row>
        )
      });
    }
    const profileCompletionCustomerId = get(form, `data.poaAdditional.${index}.clientKey`);
    const selectedPoas = get(form, `data.poaAdditional.${index}`, {});

    return (
      <div className="debit-master-card-form--container" style={{ marginBottom: 30, padding: 10 }}>
        <h6>{`Debit Mastercard Nummer ${index + 1}`}</h6>

        <SubSection>
          <SubSectionTitle><FormattedMessage id="Freemium.Form.NewLilaSet.CardAccount" /></SubSectionTitle>
          <SubSectionContent>
            <RadioSelectWrapper
              onChange={this.onChange}
              value={get(form, `data.debitCards.${index}.cardAccount`)}
              id="cardAccount"
              disabled={false}
              options={cardAccountOptions}
              showValidations
              validations={get(validations, `debitCards.${index}.cardAccount`)}
            />
          </SubSectionContent>
        </SubSection>

        <SubSection>
          <SubSectionTitle><FormattedMessage id="Freemium.Form.NewLilaSet.CardOwner" /></SubSectionTitle>
          <SubSectionContent>
            <div className="debit-master-card-form--card--holder">
              <Poa
                cmsCustomers={cmsCustomers}
                id="poaAdditional"
                onChange={this.onPoaChange}
                powerOfAttorneys={powerOfAttorneys}
                value={get(form, `data.poaAdditional.${index}.poaKey`)}
                validations={get(validations, `debitCards.${index}.cardHolder`)}
              />
              {!isUndefined(profileCompletionCustomerId) && get(selectedPoas, 'poaType') === 102 && (
              <div className="debit-master-card-form--profile--completion--container">
                <SetAdvisorProfileCompletion
                  id="profileCompletionDebitcard"
                  customerId={profileCompletionCustomerId}
                  onChange={(id, value) => this.onProfileCompletion(id, value)}
                  checks={['identificationDocument']}
                />
              </div>
              )}
              <EmbossedLines
                embossedLineOne={get(form, `data.debitCards.${index}.embossedLineOne`)}
                embossedLineTwo={get(form, `data.debitCards.${index}.embossedLineTwo`)}
                cardHolderClientKey={this.getCardHolderClientKey()}
                accountHolderClientKey={this.getAccountHolderClientKey()}
                value={get(form, `data.debitCards.${index}.embossedLineOne`)}
                onChange={(key, value) => this.onChange(key, value)}
                validations={get(validations, `debitCards.${index}`)}
                customerLanguage={get(customer, 'languageIso')}
              />
            </div>
          </SubSectionContent>
        </SubSection>

        <SubSection>
          <SubSectionTitle><FormattedMessage id="Freemium.Form.NewLilaSet.DeliveryAddress" /></SubSectionTitle>
          <SubSectionContent>
            <div className="debit-master-card-form--toggle--delivery">
              <Toggle
                value={get(form, `data.debitCards.${index}.deliveryToOffice`, false)}
                onChange={(key, value) => this.onChange('deliveryToOffice', value)}
              />
              <FormattedMessage id="Freemium.Form.NewLilaSet.DeliveryToBranch" />
            </div>
            <br />
            {!get(form, `data.debitCards.${index}.deliveryToOffice`, false) && !isEmpty(selectedPoas) && (
            <div className="debit-master-card-form--delivery--address">
              <p><FormattedMessage id="Freemium.Form.NewLilaSet.DeliveryToCustomer" /></p>
              <RadioSelectWrapper
                onChange={this.onChange}
                value={get(form, `data.debitCards.${index}.customerAddress`)}
                id="customerAddress"
                disabled={false}
                options={get(selectedPoas, 'addresses', []).map((address) => ({
                  label: (
                    <Row style={{ width: '90%' }}>
                      <Col md={3} lg={3}>
                        {get(address, `addressType.text.${language}`)}
                      </Col>
                      <Col md={6} lg={6}>
                        {`${address.street} ${address.houseNumber}, ${address.postcode} ${address.place}`}
                      </Col>
                    </Row>
                  ),
                  value: address
                }))}
                showValidations
                validations={get(validations, `debitCards.${index}.customerAddress`)}
              />
            </div>
            )}
          </SubSectionContent>
        </SubSection>

        <SubSection>
          <SubSectionTitle><FormattedMessage id="Freemium.Form.NewLilaSet.CardDetails" /></SubSectionTitle>
          <SubSectionContent>
            <FormGroup style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
              <Label>
                <p><FormattedMessage id="Freemium.Form.NewLilaSet.DailyLimit" /></p>
              </Label>
              <NumberField
                id="dailyLimit"
                name="dailyLimit"
                value={get(form, `data.debitCards.${index}.dailyLimit`)}
                onChange={(key, value) => this.onChange(key, value)}
                placeholder="3000"
                validations={get(validations, `debitCards.${index}.dailyLimit`)}
                showValidations
              />
            </FormGroup>
            <FormGroup style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
              <Label>
                <p><FormattedMessage id="Freemium.Form.NewLilaSet.MonthlyLimit" /></p>
              </Label>
              <NumberField
                id="monthlyLimit"
                name="monthlyLimit"
                value={get(form, `data.debitCards.${index}.monthlyLimit`)}
                onChange={(key, value) => this.onChange(key, value)}
                placeholder="5000"
                validations={get(validations, `debitCards.${index}.monthlyLimit`)}
                showValidations
              />
            </FormGroup>
            <FormGroup className="debit-master-card-form--multi-account">
              <Row>
                <Col md={1} lg={1}>
                  <Toggle
                    value={get(form, `data.debitCards.${index}.multiAccountsAssign`, false)}
                    onChange={(key, value) => this.onChange('multiAccountsAssign', value)}
                  />
                </Col>
                <Col>
                  <FormattedMessage id="Freemium.Form.NewLilaSet.MultiAccount" />
                </Col>
              </Row>
            </FormGroup>
          </SubSectionContent>
        </SubSection>
      </div>
    );
  }
}

DebitMasterCardFrom.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  freemiumActions: PropTypes.object.isRequired,
  customer: PropTypes.object,
  accounts: PropTypes.array,
  language: PropTypes.string,
  form: PropTypes.object,
  intl: PropTypes.object.isRequired,
  cmsCustomers: PropTypes.object,
  powerOfAttorneys: PropTypes.array,
  onLoadBpfCustomerData: PropTypes.func,
  onDebitCardChange: PropTypes.func,
  validations: PropTypes.object.isRequired
};

DebitMasterCardFrom.defaultProps = {
  form: {},
  language: 'de',
  accounts: [],
  customer: {},
  cmsCustomers: {},
  powerOfAttorneys: [],
  onLoadBpfCustomerData: noop,
  onDebitCardChange: noop
};

export default injectIntl(DebitMasterCardFrom);
