import { compact, get, isEmpty, map } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { bindActionCreators } from 'redux';

import './OpenOptionPaper.css';
import { Alert, AlertContent, StepContainer, Step, StepButtons } from '@evoja-web/react-layout-components';
import { getValidator } from '../../../../../globals';
import { actions as bpfCmsActions } from '../../../../BpfCms/index';
import freemiumActions from '../../../actions/Actions';
import { Section, SectionContent, SectionTitle } from '../../../../General/components/Section';
import { SubSection, SubSectionContent } from '../../../../General/components/SubSection';
import AccountSelector from '../../../components/Form/OpenOptionComfort/AccountSelector';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../Workguide/hocs';
import ContractDispatchRulesForm from '../../../components/Form/ContractDispatchRules/Form';
import getContractDispatchRuleChanges from '../../../lib/ContractDispatchRule/getChanges';

const STEP_FEE_ACCOUNT = 'feeAccount';
const STEP_EDOCUMENTS = 'eDocuments';

class OpenOptionPaper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: STEP_FEE_ACCOUNT
    };
    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.onActiveStepChange = this.onActiveStepChange.bind(this);
  }

  /**
   * Handle customer change
   *
   * @param  {Object} customer Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const { freemiumActions } = this.props;
    const customerId = get(customer, 'id');

    freemiumActions.optionPaperFormInitRequest({ customerId });
    freemiumActions.customerAccountsRequest({ dataKey: customerId, accountTypes: [90, 110] });
    freemiumActions.loadLilaSetRequest({ dataKey: customerId });
  }

  /**
 * update current activeStep
 * @param {*} activeStep current set
 */
  onActiveStepChange(activeStep) {
    this.setState({ activeStep });
  }

  onSubmit() {
    const {
      consultant,
      consultantId,
      contractDispatchRules,
      customer,
      form,
      freemiumActions
    } = this.props;

    const contracts = getContractDispatchRuleChanges({
      contracts: map(contractDispatchRules, (rule) => get(rule, 'data', {})),
      updated: get(form, 'data.contracts')
    });

    const prependData = {
      contracts,
      debitAccountForFee: get(form, 'data.debitAccountKeyForFee'),
      comments: {
        type: 'Json',
        value: [
          {
            value: get(form, 'data.comment'),
            editor: `${consultant.firstName} ${consultant.lastName}`,
            timestamp: moment().format()
          }
        ]
      }
    };

    freemiumActions.optionPaperFormSaveRequest({ customerId: get(customer, 'id'), payload: prependData, consultantId });
  }

  /**
   * Validate current form data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const { form } = this.props;

    const data = get(form, 'data', {});
    const validator = getValidator();

    const definition = {
      validations: {
        debitAccountKeyForFee: {
          type: 'number',
          required: true,
        },
        contracts: {
          type: 'array',
          required: false,
          validations: {
            eDocumentsAccountIndependent: {
              type: 'boolean',
              required: true,
              validations: {}
            },
            dispatchRules: {
              type: 'array',
              required: true,
              validations: {
                eDocument: {
                  type: 'boolean',
                  required: true,
                  validations: {}
                },
                comment: {
                  type: 'string',
                  required: false,
                  validations: {}
                }
              }
            }
          }
        }
      },
    };

    return validator.validate(definition, data);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      accounts,
      errors,
      form,
      fulfilled,
      language,
      onFormCancel,
      session,
      freemiumActions,
      lilaSet
    } = this.props;
    const validations = this.validate();

    if (get(lilaSet, 'data.modules', []).some((m) => m.contractType === 6423 && m.active)) {
      return (
        <Alert type="warning">
          <AlertContent>
            <p>
              <FormattedMessage id="Freemium.General.OptionExistings" />
            </p>
            <Button color="primary" outline onClick={onFormCancel}>
              <FormattedMessage id="Workguide.Form.Button.Cancel" />
            </Button>
          </AlertContent>
        </Alert>
      );
    }

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        showLoadIndicator
        requesting={!fulfilled && get(errors, 'length', 0) === 0}
      >
        {!get(lilaSet, 'data.set.active', false) ? (
          <Alert type="warning">
            <AlertContent>
              <p>
                <FormattedMessage id="Freemium.General.NoActiveLilaSet" />
              </p>
              <Button
                color="primary"
                outline
                onClick={onFormCancel}
              >
                <FormattedMessage id="Workguide.Form.Button.Cancel" />
              </Button>
            </AlertContent>
          </Alert>
        ) : (
          <StepContainer initialStep={STEP_FEE_ACCOUNT} onActiveStepChange={this.onActiveStepChange}>
            <Step id={STEP_FEE_ACCOUNT} title={<FormattedMessage id="Freemium.Form.OptionPaper.AccountsTitle" />}>
              <Section>
                <SectionTitle><FormattedMessage id="Freemium.Form.OptionPaper.AccountsTitle" /></SectionTitle>
                <SectionContent>

                  <SubSection>
                    <SubSectionContent>
                      <AccountSelector
                        id="debitAccountKeyForFee"
                        accounts={accounts}
                        onChange={freemiumActions.optionPaperFormSetValue}
                        language={language}
                        value={get(form, 'data.debitAccountKeyForFee')}
                        validations={get(validations, 'debitAccountKeyForFee', {})}
                      />
                    </SubSectionContent>
                  </SubSection>

                </SectionContent>
              </Section>
            </Step>
            {get(form, 'data.contracts.length', 0) > 0 && (
            <Step id={STEP_EDOCUMENTS} title={<FormattedMessage id="Freemium.Form.OptionPaper.EDocumentTitle" />}>
              <Section>
                <SectionContent>
                  <ContractDispatchRulesForm
                    contracts={get(form, 'data.contracts')}
                    id="contracts"
                    language={language}
                    onChange={freemiumActions.optionPaperFormSetValue}
                    session={session}
                  />
                </SectionContent>
              </Section>
            </Step>

            )}
            <StepButtons>
              <Button
                color="primary"
                onClick={this.onSubmit}
                disabled={!isEmpty(validations)}
              >
                <FormattedMessage id="Workguide.Form.Button.Execute" />

              </Button>

              <span style={{ paddingRight: '20px' }} />

              <Button
                color="primary"
                outline
                onClick={onFormCancel}
              >
                <FormattedMessage id="Workguide.Form.Button.Cancel" />
              </Button>
            </StepButtons>
          </StepContainer>
        )}
      </WorkguideCustomerAware>
    );
  }
}

OpenOptionPaper.propTypes = {
  accounts: PropTypes.array,
  consultant: PropTypes.object.isRequired,
  consultantId: PropTypes.string.isRequired,
  contractDispatchRules: PropTypes.object,
  customer: PropTypes.object,
  errors: PropTypes.array,
  form: PropTypes.object,
  fulfilled: PropTypes.bool,
  language: PropTypes.string,
  onFormCancel: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  lilaSet: PropTypes.object
};

OpenOptionPaper.defaultProps = {
  accounts: [],
  contractDispatchRules: {},
  customer: undefined,
  errors: [],
  form: {},
  fulfilled: false,
  language: 'de',
  lilaSet: {}
};

/**
 * Check if all necessary data was loaded
 *
 * @param  {Object}  state Current state
 *
 * @return {Boolean}
 */
function isFulfilled(state, ownProps) {
  const { customer } = ownProps;

  const customerId = get(customer, 'id');
  return (
    get(state, `freemium.lilaSet.${customerId}.fulfilled`, false)
    && get(state, `freemium.customerAccounts.${customerId}.fulfilled`, false)
    && get(state, 'freemium.optionPaperForm.initialized', false)
  );
}

function collectErrors(state, ownProps) {
  const { customer } = ownProps;

  const customerId = get(customer, 'id');
  return compact([
    get(state, 'freemium.accounts.error'),
    get(state, `freemium.lilaSet.${get(customer, 'id')}.error`),
    get(state, `freemium.customerAccounts.${customerId}.error`)
  ]);
}

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;

  return {
    accounts: get(state, `freemium.customerAccounts.${get(customer, 'id')}.data.resultVariables.updatedAccounts`),
    consultant: state.login.session,
    consultantId: get(state, 'login.session.id'),
    contractDispatchRules: state.freemium.contractDispatchRules,
    errors: collectErrors(state, ownProps),
    form: state.freemium.optionPaperForm,
    fulfilled: isFulfilled(state, ownProps),
    language: state.login.language,
    session: state.login.session,
    lilaSet: get(state, `freemium.lilaSet.${get(customer, 'id')}`)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch),
    freemiumActions: bindActionCreators(freemiumActions, dispatch),
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(OpenOptionPaper)
);
