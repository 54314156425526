import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import { NumberField } from '../../../../../../General/index';

const MonthlyIncomeAmount = React.memo(({
  id,
  onChange,
  validations,
  value
}) => {
  return (
    <Row className="setadvisor-form-limit-calculation--monthly-income-item--row">
      <Col
        className="setadvisor-form-limit-calculation--monthly-income-item--row--label"
        lg={4}
        md={4}
      >
        <FormattedMessage id="Freemium.Form.CreditCard.MonthlyIncome.Amount.Label" />
      </Col>

      <Col
        className="setadvisor-form-limit-calculation--monthly-income-item--row--input"
        lg={8}
        md={8}
      >
        <NumberField
          id={id}
          onChange={onChange}
          showValidations
          validations={validations}
          value={value}
        />
      </Col>
    </Row>
  );
});

MonthlyIncomeAmount.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.number
};

MonthlyIncomeAmount.defaultProps = {
  id: 'amount',
  onChange: noop,
  validations: {},
  value: undefined
};

export default MonthlyIncomeAmount;
