import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';

import { Select } from '../../../../../../General/index';
import toIntIfSet from '../../../../../../../lib/Utils/toIntIfSet';

const MonthlyIncomeCustomerSelect = React.memo(({
  customer,
  id,
  onChange,
  partners,
  validations,
  value
}) => {
  const options = get(partners, 'length', 0) > 0
    ? partners.map((partner) => ({ value: toIntIfSet(get(partner, 'id')), label: get(partner, 'designation') }))
    : [{ value: toIntIfSet(get(customer, 'id')), label: get(customer, 'designation') }];

  return (
    <Row className="setadvisor-form-limit-calculation--monthly-income-item--row">
      <Col
        className="setadvisor-form-limit-calculation--monthly-income-item--row--label"
        lg={4}
        md={4}
      >
        <FormattedMessage id="Freemium.Form.CreditCard.MonthlyIncome.Customer" />
      </Col>

      <Col
        className="setadvisor-form-limit-calculation--monthly-income-item--row--input"
        lg={8}
        md={8}
      >
        <Select
          id={id}
          onChange={onChange}
          options={options}
          showValidations
          validations={validations}
          value={value}
        />
      </Col>
    </Row>
  );
});

MonthlyIncomeCustomerSelect.propTypes = {
  customer: PropTypes.object,
  id: PropTypes.string,
  onChange: PropTypes.func,
  partners: PropTypes.array,
  validations: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

MonthlyIncomeCustomerSelect.defaultProps = {
  customer: undefined,
  id: 'clientKey',
  onChange: noop,
  partners: [],
  validations: {},
  value: undefined
};

export default MonthlyIncomeCustomerSelect;
