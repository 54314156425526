import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { has, get, noop } from 'lodash';
import update from 'immutability-helper';

import ProfileCompletion from '../../../../containers/Workguide/ProfileCompletion';
import isComboCardCategorySelected from '../../../../lib/CreditCard/isComboCardCategorySelected';
import normalizeEmbossedLine from '../../../../lib/CreditCard/normalizeEmbossedLine';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';

import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../General/components/SubSection/index';
import ResidencePermit from './ResidencePermit';
import AddressType from './AddressType';
import EmbossedLine from './EmbossedLine';
import Poa from './Poa';

class CardHolderMain extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(key, value, ...rest) {
    const { onChange, form } = this.props;

    const cards = get(form, 'data.cards', []);
    const categoryType = isComboCardCategorySelected(cards)
      ? 'DC'
      : 'HK';

    const embossedLineOne = normalizeEmbossedLine(get(value, 'clientDesignation'));
    const updated = cards.map((card) => {
      return get(card, 'categoryType') === categoryType
        ? update(card, {
          embossedLineOne: { $set: embossedLineOne }
        })
        : card;
    });

    // Change Cards
    onChange('cards', updated, ...rest);

    // Change Poa
    onChange(key, value, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      codes,
      form,
      language,
      onChange,
      powerOfAttorneys,
      validations
    } = this.props;
    const cards = get(form, 'data.cards', []);
    const categoryType = isComboCardCategorySelected(cards)
      ? 'DC'
      : 'HK';
    const index = cards.findIndex((card) => get(card, 'categoryType') === categoryType);
    const profileCompletionCustomerId = get(form, 'data.poaMain.clientKey');

    const allowedResidencePermits = ['residencePermit-2', 'residencePermit-3', 'residencePermit-5', 'residencePermit-6'];
    const powerOfAttorneysMainCard = powerOfAttorneys.filter((poa) => get(poa, 'legitimation') === 'HOLDER');
    return (
      <Section className="setadvisor-form-credit-card-general--card-holder-main">
        <SectionTitle>
          <FormattedMessage id="Freemium.Form.CreditCard.CardHolderMain.Title" />
        </SectionTitle>

        <SectionContent>
          <Poa
            cards={cards}
            categoryType={categoryType}
            id="poaMain"
            onChange={this.onChange}
            powerOfAttorneys={powerOfAttorneysMainCard}
            value={get(form, 'data.poaMain.poaKey')}
            validations={get(validations, 'poaMain')}
          />

          {get(form, 'data.poaMain.poaType') === 102 && (
            <SubSection>
              <SubSectionTitle>
                <FormattedMessage id="Freemium.Form.CreditCard.CardHolder.ProfileCompletion" />
              </SubSectionTitle>

              <SubSectionContent>
                <ProfileCompletion
                  customerId={profileCompletionCustomerId}
                  id="mainCardHolderProfileCompletion"
                  onChange={onChange}
                />
              </SubSectionContent>
            </SubSection>
          )}

          <EmbossedLine
            cards={cards}
            categoryType={categoryType}
            id="embossedLineOne"
            onChange={onChange}
            validations={get(validations, `cards.${index}.embossedLineOne`)}
          />

          {(has(form, 'data.poaMain.nationality') && get(form, 'data.poaMain.nationality') !== 'CH') && (
            <ResidencePermit
              codes={codes}
              form={form}
              language={language}
              onChange={onChange}
              validations={validations}
              allowedResidencePermits={allowedResidencePermits}
            />
          )}

          <AddressType
            addresses={get(form, 'data.poaMain.addresses', [])}
            id="addressTypeMain"
            language={language}
            onChange={onChange}
            value={get(form, 'data.addressTypeMain')}
            validations={get(validations, 'addressTypeMain')}
          />
        </SectionContent>
      </Section>
    );
  }
}

CardHolderMain.propTypes = {
  codes: PropTypes.object,
  form: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  powerOfAttorneys: PropTypes.array,
  validations: PropTypes.object
};

CardHolderMain.defaultProps = {
  codes: { groups: {} },
  form: {},
  language: 'de',
  onChange: noop,
  powerOfAttorneys: [],
  validations: {}
};

export default CardHolderMain;
