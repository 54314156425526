import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { has, get, set, reduce, isUndefined, noop, isEmpty, some, isEqual, isNil } from 'lodash';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import update from 'immutability-helper';
import { actions as codeActions, utils as codeUtils } from '@evoja-web/entity-code';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import {
  withWorkguideInstanceData,
  WorkguideCustomerAware
} from '../../../Workguide/hocs';
import {
  AuthorizedCustomer,
  ConnectionAccount,
  Correspondence,
  Eeg,
  ExistingOpeningError,
  Funds,
  Heritage,
  InvestmentAmount,
  InvestmentStrategy,
  MainTypeError,
  PaymentInfo,
  PaymentOrder,
  PaymentRules,
  PortfolioNumber,
  Portfolios,
  ProductSelect,
  QIDeclaration
} from './Opening/index';
import {
  actions as bpfCmsActions,
  Formalities,
  FormalitiesNotFulfilled
} from '../../../BpfCms/index';
import securitiesAccountActions from '../../actions/Actions';
import { getValidator } from '../../../../globals';
import isDoublePartner from '../../../../lib/Customer/Utils/isDoublePartner';
import {
  doublePartnerFormalitiesFulfilled,
  singlePartnerFormalitiesFulfilled
} from '../../lib/Utils/index';

const validator = getValidator();

// Map components shown based on selected product
const componentMap = {
  1: {
    authorizedCustomer: true,
    connectionAccount: false,
    paymentRules: true,
    eeg: true,
    funds: false,
    investmentStrategy: false,
    investmentAmount: false,
    correspondence: false,
    defaultPaymentRule: true,
    heritage: false,
    portfolioNumber: false,
    portfolios: false,
    paymentOrder: false,
    paymentInfo: false,
    assetManagementAttachmentWarning: false
  },
  2: {
    authorizedCustomer: true,
    connectionAccount: false,
    paymentRules: true,
    eeg: true,
    funds: false,
    investmentStrategy: true,
    investmentAmount: false,
    correspondence: false,
    defaultPaymentRule: true,
    heritage: false,
    portfolioNumber: false,
    portfolios: false,
    paymentOrder: false,
    paymentInfo: false,
    assetManagementAttachmentWarning: false
  },
  3: {
    authorizedCustomer: true,
    connectionAccount: true,
    paymentRules: false,
    eeg: true,
    funds: false,
    investmentStrategy: true,
    investmentAmount: false,
    correspondence: false,
    defaultPaymentRule: true,
    heritage: false,
    portfolioNumber: false,
    portfolios: false,
    paymentOrder: false,
    paymentInfo: false,
    assetManagementAttachmentWarning: false
  },
  4: {
    authorizedCustomer: true,
    connectionAccount: true,
    paymentRules: false,
    eeg: true,
    funds: false,
    investmentStrategy: true,
    investmentAmount: false,
    correspondence: false,
    defaultPaymentRule: true,
    heritage: false,
    portfolioNumber: false,
    portfolios: false,
    paymentOrder: false,
    paymentInfo: false,
    assetManagementAttachmentWarning: false
  },
  5: {
    authorizedCustomer: true,
    connectionAccount: false,
    paymentRules: true,
    eeg: false,
    funds: true,
    investmentStrategy: true,
    investmentAmount: false,
    correspondence: false,
    defaultPaymentRule: true,
    heritage: false,
    portfolioNumber: false,
    portfolios: false,
    paymentOrder: false,
    paymentInfo: false,
    assetManagementAttachmentWarning: false
  },
  8: {
    authorizedCustomer: true,
    connectionAccount: false,
    paymentRules: false,
    eeg: false,
    funds: false,
    investmentStrategy: true,
    investmentAmount: true,
    correspondence: true,
    defaultPaymentRule: false,
    heritage: true,
    portfolioNumber: true,
    portfolios: false,
    paymentOrder: true,
    paymentInfo: true,
    assetManagementAttachmentWarning: false
  },
  9: {
    authorizedCustomer: true,
    connectionAccount: false,
    paymentRules: false,
    eeg: false,
    funds: false,
    investmentStrategy: true,
    investmentAmount: true,
    correspondence: true,
    defaultPaymentRule: false,
    heritage: true,
    portfolioNumber: true,
    portfolios: false,
    paymentOrder: true,
    paymentInfo: true,
    assetManagementAttachmentWarning: false
  },
  10: {
    authorizedCustomer: true,
    connectionAccount: false,
    paymentRules: false,
    eeg: true,
    funds: false,
    investmentStrategy: true,
    investmentAmount: true,
    correspondence: true,
    defaultPaymentRule: false,
    heritage: true,
    portfolioNumber: true,
    portfolios: false,
    paymentOrder: true,
    paymentInfo: true,
    assetManagementAttachmentWarning: true
  },
  11: {
    authorizedCustomer: true,
    connectionAccount: false,
    paymentRules: false,
    eeg: false,
    funds: false,
    investmentStrategy: true,
    investmentAmount: true,
    correspondence: true,
    defaultPaymentRule: false,
    heritage: true,
    portfolioNumber: true,
    portfolios: false,
    paymentOrder: true,
    paymentInfo: true,
    assetManagementAttachmentWarning: false
  },

};

const validationDefinition = {
  authorizedCustomer: {
    type: 'object',
    required: true
  },
  investmentStrategy: {
    type: 'object',
    required: true
  },
  connectionAccount: {
    type: 'array',
    required: true
  },
  funds: {
    type: 'plainObject',
    required: true,
    validations: {
      objectValuesMax: [100],
      objectValuesMin: [100]
    }
  },
  eeg: {
    type: 'boolean',
    required: true
  },
  correspondence: {
    type: 'string',
    required: true
  },
  defaultPaymentRule: {
    type: 'boolean',
    required: true
  },
  heritage: {
    type: 'boolean',
    required: true
  },
  investmentAmount: {
    type: 'number',
    required: true
  },
  qiDeclaration: {
    type: 'object',
    required: true
  }
};

function QIValidation(customer, data) {
  if (isUndefined(customer)) return {};

  return {
    [get(customer, 'id')]: {
      type: 'object',
      required: true,
      validations: {
        dbaLandCh: {
          type: 'boolean',
          required: true
        },
        dbaLandOther: {
          type: 'object',
          required: !get(data, `qiDeclaration.${get(customer, 'id')}.dbaLandCh`, false)
        }
      }
    }
  };
}

function QIDefaultValues(customers = []) {
  return customers.reduce((result, partner) => {
    return {
      ...result,
      [get(partner, 'id')]: {
        dbaLandCh: true
      }
    };
  }, {});
}

/**
 * Check if the given component should be rendered for the given product.
 * This function also return false if product is undefined
 *
 * @param  {Number} product Product id
 * @param  {String} key     Component key
 *
 * @return {Boolean}
 */
function shouldDisplayComponent(product, key) {
  return get(componentMap, `${product}.${key}`, false);
}

/**
 * swap the last to elements of a given array
 * @param {*} array
 * @returns
 */
function swapLastTwoElements(array = []) {
  if (get(array, 'length', 0) <= 1) {
    return array;
  }

  const lastIndex = array.length - 1;
  const secondToLastIndex = lastIndex - 1;

  // Create a new array with the last two elements swapped
  const newArray = [...array];
  [newArray[lastIndex], newArray[secondToLastIndex]] = [newArray[secondToLastIndex], newArray[lastIndex]];

  return newArray;
}

class SecuritiesAccountOpening extends React.Component {
  constructor(props) {
    super(props);

    const {
      codeActions,
      securitiesAccountActions
    } = this.props;

    codeActions.groupsRequest({ groups: ['currency', 'investmentStrategy', 'country'] });
    securitiesAccountActions.fundsRequest();

    this.loadData = this.loadData.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.onQiDeclarationChange = this.onQiDeclarationChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const partners = get(this, 'props.partners.data', []);
    const prevPartners = get(prevProps, 'partners.data', []);

    if (!isEqual(partners.map((p) => get(p, 'id')), prevPartners.map((p) => get(p, 'id')))) {
      this.loadQiDeclarations(partners);
    }
  }

  /**
   * Reset data
   *
   * @return void
   */
  componentWillUnmount() {
    const { securitiesAccountActions } = this.props;

    securitiesAccountActions.openingFormReset();
    securitiesAccountActions.authorizationsReset();
    securitiesAccountActions.portfoliosReset();
    securitiesAccountActions.paymentAccountsReset();
    securitiesAccountActions.productsReset();
  }

  /**
   * Handle product change.
   * Reset form values and set new defaults
   *
   * @param  {String} id    Form field id
   * @param  {Number} value Selected product number
   *
   * @return void
   */
  onProductChange(id, value) {
    const {
      codes,
      customer,
      securitiesAccountActions
    } = this.props;

    const chf = get(codes, 'groups.currency', []).find((c) => get(c, 'id') === 'currency-1');

    securitiesAccountActions.openingFormReset();
    securitiesAccountActions.openingFormSetValue(id, value);
    securitiesAccountActions.checkExistingOpeningRequest({ customerId: get(customer, 'id'), product: value });

    const defaults = {
      1: {
        eeg: true,
        defaultPaymentRule: true
      },
      2: {
        eeg: true,
        defaultPaymentRule: true
      },
      3: {
        eeg: true,
        defaultPaymentRule: true,
        connectionAccount: [chf]
      },
      4: {
        eeg: true,
        defaultPaymentRule: true,
        connectionAccount: [chf]
      },
      5: {
        eeg: false,
        defaultPaymentRule: true
      },
      8: {
        heritage: false,
        paymentOrder: true,
        correspondence: 'periodic'
      },
      9: {
        heritage: false,
        paymentOrder: true,
        correspondence: 'periodic'
      },
      10: {
        eeg: true,
        heritage: false,
        paymentOrder: true,
        correspondence: 'periodic'
      },
      11: {
        heritage: false,
        paymentOrder: true,
        correspondence: 'periodic'
      },
    };

    const values = {
      ...get(defaults, value, {}),
      qiDeclaration: QIDefaultValues(this.getQiCustomers())
    };

    securitiesAccountActions.openingFormSetDefaults(values);
  }

  /**
   * Handle value change
   *
   * @param  {String} id    Form field id
   * @param  {Mixed}  value Selected value
   *
   * @return void
   */
  onValueChange(id, value) {
    const { securitiesAccountActions } = this.props;

    securitiesAccountActions.openingFormSetValue(id, value);
  }

  /**
   * Handle submit and redirect
   *
   * @return void
   */
  onSubmit() {
    const {
      customer,
      data,
      securitiesAccountActions,
      session,
      toggleRedirectModal
    } = this.props;

    securitiesAccountActions.openingFormSaveRequest({
      customer,
      data,
      session
    });

    toggleRedirectModal();
  }

  /**
   * Handle qi declaration change
   *
   * @param  {Object} customer   Customer object
   * @param  {String} key        Form element key
   * @param  {Mixed}  value      Selected value
   *
   * @return {[type]}          [description]
   */
  onQiDeclarationChange(customer, key, value) {
    const {
      data,
      securitiesAccountActions
    } = this.props;

    const customerId = get(customer, 'id');

    const current = get(data, `qiDeclaration.${customerId}`, {});
    const updated = update(current, {
      [key]: { $set: value }
    });

    const qiDeclaration = update(get(data, 'qiDeclaration', {}), {
      [customerId]: { $set: updated }
    });

    securitiesAccountActions.openingFormSetValue('qiDeclaration', qiDeclaration);
  }

  /**
   * Get an array of customer that have no qi declaration
   *
   * @return {Array} customers Array of customers
   */
  getQiCustomers() {
    const {
      customer,
      partners,
      qiDeclaration
    } = this.props;

    const customers = isDoublePartner(customer)
      ? get(partners, 'data', [])
      : [customer];

    return customers.filter((c) => isNil(get(qiDeclaration, `${get(c, 'id')}.data.contract`)));
  }

  /**
   * Load customer data
   *
   * @param  {Object} customer Selected customer
   *
   * @return void
   */
  loadData(customer) {
    const {
      bpfCmsActions,
      partners,
      securitiesAccountActions
    } = this.props;

    const customerId = get(customer, 'id');
    securitiesAccountActions.openingFormReset();
    securitiesAccountActions.authorizationsRequest({ customerId });
    securitiesAccountActions.paymentAccountsRequest({ customerId });
    securitiesAccountActions.productsRequest({ customerId });
    securitiesAccountActions.qiDeclarationRequest({ dataKey: customerId });
    // Currently not used as portfolio select is never shown
    // securitiesAccountActions.portfoliosRequest({ customerId });
    const defaults = {
      eeg: true,
      defaultPaymentRule: true,
      qiDeclaration: QIDefaultValues(this.getQiCustomers())
    };
    securitiesAccountActions.openingFormSetDefaults(defaults);

    if (isDoublePartner(customer) && isUndefined(partners)) {
      bpfCmsActions.partnersRequest({ dataKey: customerId });
    }

    if (get(partners, 'data.length', 0) > 0) {
      this.loadQiDeclarations(get(partners, 'data', []));
    }
  }

  /**
   * Load qi declarations for partners of the customer
   *
   * @param {Array} customers Array of customer to load qi declarations for
   *
   * @return void
   */
  loadQiDeclarations(customers = []) {
    const {
      qiDeclaration,
      securitiesAccountActions
    } = this.props;

    customers
      .filter((c) => !has(qiDeclaration, `${get(c, 'id')}`))
      .map((c) => securitiesAccountActions.qiDeclarationRequest({ dataKey: get(c, 'id') }));
  }

  /**
   * Validate form data
   *
   * @return {Object} result validation result
   */
  validate() {
    const {
      customer,
      data
    } = this.props;

    const components = get(componentMap, get(data, 'product'), {});
    const qiCustomers = this.getQiCustomers();

    const qiValidations = isUndefined(customer)
      ? {}
      : qiCustomers.reduce((result, partner) => {
        return {
          ...result,
          ...QIValidation(partner, data)
        };
      }, {});

    const baseValidations = {
      product: {
        type: 'number',
        required: true
      },
      portfolioNumber: {
        type: 'string',
        required: get(components, 'portfolios', false) && get(data, 'heritage', false),
        validations: {
          isValidPortfolioNumber: []
        }
      },
      portfolio: {
        type: 'object',
        required: get(components, 'portfolios', false) && get(data, 'heritage', false)
      },
      paymentOrder: {
        type: 'boolean',
        required: get(components, 'paymentOrder', false) && !get(data, 'heritage', false)
      },
      payments: {
        type: 'object',
        required: get(components, 'payments', false) && !get(data, 'heritage', false) && get(data, 'paymentOrder', false),
        validations: {
          debitAccount: {
            type: 'object',
            required: true
          },
          amount: {
            type: 'number',
            required: true
          },
          currency: {
            type: 'object',
            required: true
          },
          executionDate: {
            type: 'string',
            required: true,
            validations: {
              minDate: [moment().format()]
            }
          }
        }
      },
      qiDeclaration: {
        type: 'object',
        required: true,
        validations: qiValidations
      }
    };

    const validations = reduce(components, (result, active, key) => {
      const v = get(validationDefinition, key);

      if (active && !isUndefined(v)) {
        set(result, key, v);
      }

      return result;
    }, baseValidations);

    return validator.validate({ validations }, data);
  }

  /**
   * Check if the submit button is disabled
   *
   * @param  {Object}  validations Validation result
   *
   * @return {Boolean}
   */
  isSubmitDisabled(validations) {
    const {
      customer,
      existingOpening,
      formalitiesFulfilled,
      formalitiesRequesting
    } = this.props;

    return (
      !isEmpty(validations)
      || isUndefined(customer)
      || !get(existingOpening, 'fulfilled', false)
      || get(existingOpening, 'data.length', 0) > 0
      || !formalitiesFulfilled
      || formalitiesRequesting
    );
  }

  /**
   * Check if we have to render the qi declaration form for the given customer or its partners
   *
   * @param  {Object} validations Validation result
   *
   * @return {ReactElement} markup
   */
  renderQiDeclaration(validations) {
    const {
      codes,
      data,
      language
    } = this.props;

    const customers = this.getQiCustomers();

    if (get(customers, 'length', 0) === 0) return null;

    return (
      <React.Fragment>
        <Row style={{ paddingTop: '20px' }}>
          <Col lg={12} md={12} sm={12} style={{ paddingBottom: '10px' }}>
            <FormattedMessage
              id="SecuritiesAccount.Opening.Form.QIDeclaration"
              tagName="strong"
            />
          </Col>
        </Row>

        <Row>
          {customers.map((c) => (
            <Col lg={6} md={6} sm={6} key={get(c, 'id')}>
              <div style={{ fontWeight: 'bold', paddingBottom: '5px' }}>
                {get(c, 'designation', '')}
              </div>

              <QIDeclaration
                codes={codes}
                customer={c}
                data={data}
                language={language}
                onChange={this.onQiDeclarationChange}
                validations={validations}
              />
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }

  /**
   * Render form components depending on the selected product
   *
   * @param  {Object} validations Validation result
   *
   * @return {ReactElement} markup
   */
  renderComponents(validations) {
    const {
      authorizations,
      codes,
      customer,
      data,
      funds,
      language,
      links,
      paymentAccounts,
      portfolios
    } = this.props;

    const selectedProduct = get(data, 'product');
    const paymentRulesLink = links.find((link) => get(link, 'id') === 'depotOpeningPaymentRules');

    return (
      <React.Fragment>
        {shouldDisplayComponent(selectedProduct, 'investmentStrategy') && (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <InvestmentStrategy
                codes={codes}
                language={language}
                onChange={this.onValueChange}
                validations={validations.investmentStrategy}
                value={get(data, 'investmentStrategy')}
                selectedProduct={selectedProduct}
              />
            </Col>
          </Row>
        )}

        {shouldDisplayComponent(selectedProduct, 'connectionAccount') && (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <ConnectionAccount
                codes={codes}
                language={language}
                onChange={this.onValueChange}
                value={get(data, 'connectionAccount')}
                validations={validations.connectionAccount}
              />
            </Col>
          </Row>
        )}

        {shouldDisplayComponent(selectedProduct, 'paymentRules') && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={6} md={6} sm={6}>
              <PaymentRules
                language={language}
                onChange={this.onValueChange}
                value={get(data, 'defaultPaymentRule')}
                links={links}
                validations={validations.defaultPaymentRule}
              />
            </Col>

            <Col lg={6} md={6} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
              {get(data, 'defaultPaymentRule', false) && (
                <span
                  className="react-anchor"
                  onClick={() => window.open(get(paymentRulesLink, `translatedUrl.${language}`), '_blank')}
                >
                  {get(paymentRulesLink, `name.${language}`, '')}
                </span>
              )}
            </Col>
          </Row>
        )}

        {shouldDisplayComponent(selectedProduct, 'funds') && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12} md={12} sm={12}>
              <Funds
                funds={funds}
                language={language}
                onChange={this.onValueChange}
                value={get(data, 'funds', {})}
                validations={validations.funds}
              />
            </Col>
          </Row>
        )}

        {shouldDisplayComponent(selectedProduct, 'investmentAmount') && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12} md={12} sm={12}>
              <InvestmentAmount
                onChange={this.onValueChange}
                value={get(data, 'investmentAmount')}
                validations={validations.investmentAmount}
                selectedProduct={selectedProduct}
              />
            </Col>
          </Row>
        )}

        {shouldDisplayComponent(selectedProduct, 'eeg') && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={6} md={6} sm={6}>
              <Eeg
                disabled={selectedProduct === 5}
                onChange={this.onValueChange}
                value={get(data, 'eeg')}
                validations={validations.eeg}
              />
            </Col>

            <Col lg={6} md={6} sm={6} />
          </Row>
        )}

        {shouldDisplayComponent(selectedProduct, 'correspondence') && (
        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={6} md={6} sm={6}>
            <Correspondence
              onChange={this.onValueChange}
              value={get(data, 'correspondence')}
              validations={validations.correspondence}
            />
          </Col>

          <Col lg={6} md={6} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
            {get(data, 'correspondence') === 'all' && (
            <Alert type="info" style={{ marginBottom: '0rem' }}>
              <AlertContent>
                <FormattedMessage id="SecuritiesAccount.Opening.Form.CorrespondenceHint" />
              </AlertContent>
            </Alert>
            )}
          </Col>
        </Row>
        )}

        {shouldDisplayComponent(selectedProduct, 'heritage') && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={6} md={6} sm={6}>
              <Heritage
                onChange={this.onValueChange}
                value={get(data, 'heritage')}
                validations={validations.heritage}
              />
            </Col>

            <Col lg={6} md={6} sm={6} />
          </Row>
        )}

        {(
          shouldDisplayComponent(selectedProduct, 'portfolioNumber')
          && get(data, 'heritage', false)
        ) && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={6} md={6} sm={6}>
              <PortfolioNumber
                onChange={this.onValueChange}
                value={get(data, 'portfolioNumber')}
                validations={validations.portfolioNumber}
              />
            </Col>

            <Col lg={6} md={6} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
              <Alert type="info" style={{ marginBottom: '0rem' }}>
                <AlertContent>
                  <FormattedMessage id="SecuritiesAccount.Opening.Form.PortfolioNumberHint" />
                </AlertContent>
              </Alert>
            </Col>
          </Row>
        )}

        {(
          shouldDisplayComponent(selectedProduct, 'portfolios')
          && get(data, 'heritage', false)
        ) && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={6} md={6} sm={6}>
              <Portfolios
                onChange={this.onValueChange}
                value={get(data, 'portfolio')}
                validations={validations.portfolio}
                portfolios={portfolios}
                language={language}
              />
            </Col>

            <Col lg={6} md={6} sm={6} />
          </Row>
        )}

        {(
          shouldDisplayComponent(selectedProduct, 'paymentOrder')
          && !get(data, 'heritage', false)
        ) && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={6} md={6} sm={6}>
              <PaymentOrder
                onChange={this.onValueChange}
                value={get(data, 'paymentOrder')}
                validations={validations.paymentOrder}
              />
            </Col>

            <Col lg={6} md={6} sm={6} />
          </Row>
        )}

        {(
          shouldDisplayComponent(selectedProduct, 'paymentInfo')
          && !get(data, 'heritage', false)
          && get(data, 'paymentOrder', false)
        ) && (
          <Row style={{ paddingBottom: '20px' }}>
            <PaymentInfo
              onChange={this.onValueChange}
              value={get(data, 'payments')}
              validations={validations.payments}
              paymentAccounts={paymentAccounts}
              codes={codes}
            />
          </Row>
        )}

        <Row style={{ paddingTop: '20px' }}>
          <Col lg={12} md={12} sm={12}>
            {shouldDisplayComponent(selectedProduct, 'authorizedCustomer') && (
              <AuthorizedCustomer
                authorizations={authorizations}
                customer={customer}
                onChange={this.onValueChange}
                validations={validations.authorizedCustomer}
                value={get(data, 'authorizedCustomer')}
              />
            )}
          </Col>
        </Row>

        {shouldDisplayComponent(selectedProduct, 'assetManagementAttachmentWarning') && (
          <Row style={{ paddingTop: '20px' }}>
            <Col lg={12} md={12} sm={12}>
              <Alert type="warning">
                <AlertTitle>
                  <FormattedMessage id="SecuritiesAccount.Opening.Form.ImportantNotice" />
                </AlertTitle>
                <AlertContent>
                  <FormattedMessage id="SecuritiesAccount.Opening.Form.AssetManagementAttachmentWarning" />
                </AlertContent>
              </Alert>
            </Col>
          </Row>
        )}

        {!isUndefined(selectedProduct) && this.renderQiDeclaration(validations)}
      </React.Fragment>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customer,
      data,
      existingOpening,
      formalitiesFulfilled,
      formalitiesRequesting,
      language,
      links,
      products,
      redirect,
      requesting
    } = this.props;

    const validations = this.validate();

    if (get(customer, 'mainType.id') === 'mainType-3') return <MainTypeError language={language} links={links} />;

    return (
      <WorkguideCustomerAware
        onChange={this.loadData}
        onInit={this.loadData}
        requesting={requesting}
        showLoadIndicator
      >
        <Row>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="SecuritiesAccount.Opening.Form.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <Row style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Col lg={12} md={12} sm={12}>
            <Formalities customer={customer} />
          </Col>
        </Row>

        {!formalitiesRequesting && !formalitiesFulfilled && !isUndefined(customer) && (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <FormalitiesNotFulfilled />
            </Col>
          </Row>
        )}

        <Row>
          <Col lg={12} md={12} sm={12}>
            <ProductSelect
              language={language}
              onChange={this.onProductChange}
              products={swapLastTwoElements(get(products, 'data', []))}
              validations={validations.product}
              value={get(data, 'product')}
            />
          </Col>
        </Row>

        {get(existingOpening, 'fulfilled', false) && get(existingOpening, 'data.length', 0) > 0 && (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <ExistingOpeningError />
            </Col>
          </Row>
        )}

        {get(existingOpening, 'fulfilled', false) && get(existingOpening, 'data.length', 0) === 0 && this.renderComponents(validations)}

        <Row style={{ paddingTop: '40px' }}>
          <Col lg="auto" md="auto" sm="auto">
            <Button
              color="primary"
              disabled={this.isSubmitDisabled(validations)}
              onClick={this.onSubmit}
            >
              <FormattedMessage id="Activity.Form.Submit" />
            </Button>

            <span style={{ paddingRight: '40px' }} />

            <Button
              color="primary"
              onClick={() => redirect()}
              outline
            >
              <FormattedMessage id="General.Button.Cancel" />
            </Button>
          </Col>
        </Row>
      </WorkguideCustomerAware>
    );
  }
}

SecuritiesAccountOpening.propTypes = {
  authorizations: PropTypes.object,
  bpfCmsActions: PropTypes.object.isRequired,
  codeActions: PropTypes.object.isRequired,
  codes: PropTypes.object,
  customer: PropTypes.object,
  data: PropTypes.object,
  existingOpening: PropTypes.object,
  formalitiesFulfilled: PropTypes.bool,
  formalitiesRequesting: PropTypes.bool,
  funds: PropTypes.object,
  language: PropTypes.string,
  links: PropTypes.object,
  partners: PropTypes.array,
  paymentAccounts: PropTypes.object,
  portfolios: PropTypes.object,
  products: PropTypes.object,
  qiDeclaration: PropTypes.object,
  redirect: PropTypes.func,
  requesting: PropTypes.bool,
  securitiesAccountActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func
};

SecuritiesAccountOpening.defaultProps = {
  authorizations: {},
  codes: { groups: {} },
  customer: undefined,
  data: {},
  existingOpening: {},
  formalitiesFulfilled: false,
  formalitiesRequesting: false,
  funds: {},
  language: 'de',
  links: {},
  partners: undefined,
  paymentAccounts: {},
  portfolios: {},
  products: {},
  qiDeclaration: {},
  redirect: noop,
  requesting: false,
  toggleRedirectModal: noop
};

function isRequesting(state) {
  return (
    get(state, 'securitiesAccount.paymentAccounts.requesting', false)
    || get(state, 'securitiesAccount.products.requesting', false)
    || get(state, 'securitiesAccount.authorizations.requesting', false)
    || get(state, 'securitiesAccount.funds.requesting', false)
    || get(state, 'externalLinks.externalLinks.requesting', false)
    || get(state, 'securitiesAccount.portfolios.requesting', false)
    || get(state, 'securitiesAccount.paymentAccounts.requesting', false)
    || !codeUtils.allGroupsFulfilledOrRejected(state.codes)
  );
}

/**
 * Check if some formalities are requesting
 *
 * @param  {Object} state Application state
 *
 * @return {Boolean}
 */
function formalitiesRequesting(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return (
    get(state, `bpfCms.partners.${customerId}.requesting`, false)
    || some(get(state, 'bpfCms.partners'), (item) => get(item, 'requesting', false))
  );
}

/**
 * Check if formalities for the given customer (and its partners) are fulfilled
 *
 * @param  {Object} state Application state
 *
 * @return {Boolean}
 */
function formalitiesFulfilled(state, ownProps) {
  const { customer } = ownProps;
  const customerId = get(customer, 'id');

  const formalitiesCheck = isDoublePartner(customer)
    ? doublePartnerFormalitiesFulfilled
    : singlePartnerFormalitiesFulfilled;

  const formalities = get(state, `bpfCms.customer.${customerId}.data`, {});
  const partnerFormalities = get(state, `bpfCms.partners.${customerId}.data`, [])
    .map((partner) => get(state, `bpfCms.customer.${get(partner, 'id')}.data`, {}));

  return formalitiesCheck({ formalities, partnerFormalities });
}

function mapStateToProps(state, ownProps) {
  const { securitiesAccount } = state;

  const {
    authorizations,
    existingOpening,
    funds,
    openingForm,
    paymentAccounts,
    portfolios,
    products
  } = securitiesAccount;

  const customer = get(ownProps, 'customer');

  return {
    authorizations,
    codes: state.codes,
    cmsCustomerData: state.bpfCms.customer,
    customer,
    data: get(openingForm, 'data', {}),
    existingOpening,
    formalitiesFulfilled: formalitiesFulfilled(state, ownProps),
    formalitiesRequesting: formalitiesRequesting(state, ownProps),
    funds,
    language: state.login.language,
    links: get(state, 'externalLinks.categories.linkCategory-securitiesAccount'),
    partners: get(state, `bpfCms.partners.${get(customer, 'id')}`),
    paymentAccounts,
    portfolios,
    products,
    qiDeclaration: state.securitiesAccount.qiDeclaration,
    requesting: isRequesting(state),
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch),
    codeActions: bindActionCreators(codeActions, dispatch),
    securitiesAccountActions: bindActionCreators(securitiesAccountActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(SecuritiesAccountOpening)
);
