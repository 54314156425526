import { has, get, isUndefined } from 'lodash';

const maxCosts = {
  depositAssets: 10000,
  savingsAccounts: 10000
};

export default function calculateItem(item = {}) {
  const amount = get(item, 'amount', 0);
  const multiplier = get(item, 'multiplier', 1);
  const type = get(item, 'assetType');
  const max = get(maxCosts, type);

  const calculated = amount * multiplier;
  const costs = !isUndefined(max) && calculated > max
    ? max
    : calculated;

  const valueProp = has(item, 'expenseType')
    ? 'costs'
    : 'value';

  return {
    ...item,
    [valueProp]: costs
  };
}
