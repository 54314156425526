import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import getNotifications from './Notification';
import markNotificationAsRead from './markNotificationAsRead';
import intNotificationCenterSubscription from './intNotificationCenterSubscription';
import saveNotificationCenterSubscription from './saveNotificationCenterSubscription';

const {
  NOTIFICATION_REQUEST, MARK_NOTIFICATION_AS_READ_REQUEST, NOTIFICATION_CENTER_SUBSCRIPTION_INIT_REQUEST, NOTIFICATION_CENTER_SUBSCRIPTION_SAVE_REQUEST
} = actions;

export default function* modalRootSaga() {
  yield takeLatest(NOTIFICATION_REQUEST, getNotifications);
  yield takeLatest(MARK_NOTIFICATION_AS_READ_REQUEST, markNotificationAsRead);
  yield takeLatest(NOTIFICATION_CENTER_SUBSCRIPTION_INIT_REQUEST, intNotificationCenterSubscription);
  yield takeLatest(NOTIFICATION_CENTER_SUBSCRIPTION_SAVE_REQUEST, saveNotificationCenterSubscription);
}
