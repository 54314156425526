import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import reactivateFormAction from '../../../actions/EBankingContract/DirectActivation/ReactivateForm';

const {
  REACTIVATE_SAVE_PENDING,
  REACTIVATE_SAVE_FULFILLED,
  REACTIVATE_SAVE_REJECTED
} = reactivateFormAction;

export default function* reactivateFormSave(request) {
  const {
    comments, kdcCheckOk, taskId, skipUserCheck = false
  } = request;

  yield put({ type: REACTIVATE_SAVE_PENDING });

  try {
    const payload = {
      variables: {
        kdcCheckOk,
        comments
      }
    };

    const url = `/sif/bpf/public/api/v1/task/${taskId}/complete?skipUserCheck=${skipUserCheck}`;
    yield call(axios, {
      url,
      method: 'post',
      data: payload
    });

    yield put({ type: REACTIVATE_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: REACTIVATE_SAVE_REJECTED, error });

    return error;
  }
}
