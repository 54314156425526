import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import { SubSection, SubSectionContent } from '../../../../../General/components/SubSection';

class Poa extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle onChange of the poa redio group.
   * Update selected value and set the card holder name
   *
   * @param  {String} key     Form element id
   * @param  {Number} value   Selected option value
   * @param  {Object} option  Selected option
   *
   * @return void
   */
  onChange(key, value, option) {
    const { onChange } = this.props;

    onChange(key, get(option, 'poa'));
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      id,
      powerOfAttorneys,
      validations,
      value
    } = this.props;

    const options = powerOfAttorneys.map((poa) => ({
      value: get(poa, 'poaKey'),
      label: get(poa, 'clientDesignationLong'),
      poa
    }));

    return (
      <SubSection>
        <SubSectionContent>
          <RadioButtonGroup
            id={id}
            options={options}
            onChange={this.onChange}
            value={value}
          />

          {get(powerOfAttorneys, 'length', 0) === 0 && (
            <Alert type="error">
              <AlertTitle>
                <FormattedMessage
                  id="Freemium.Form.CreditCard.CardHolder.NoPoasTitle"
                />
              </AlertTitle>
              <AlertContent>
                <FormattedMessage
                  id="Freemium.Form.CreditCard.CardHolder.NoPoasDescription"
                />
              </AlertContent>
            </Alert>
          )}

          <ValidationResult
            show
            validations={validations}
          />
        </SubSectionContent>
      </SubSection>
    );
  }
}

Poa.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  powerOfAttorneys: PropTypes.array,
  validations: PropTypes.object,
  value: PropTypes.number
};

Poa.defaultProps = {
  id: 'poa',
  onChange: noop,
  powerOfAttorneys: [],
  validations: {},
  value: undefined
};

export default Poa;
