import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';
import toIntIfSet from '../../../../../../../lib/Utils/toIntIfSet';

const RelationshipToMainCardHolder = React.memo(({
  cardHolder,
  codes,
  language
}) => {
  const relationshipToMainCardHolder = get(cardHolder, 'clientInformation.relationshipToMainCardHolder');
  const code = get(codes, 'groups.relationPart', []).find((code) => toIntIfSet(get(code, 'number')) === relationshipToMainCardHolder);

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.RelationshipToMainCardHolder" />}>
      {get(code, `text.${language}`)}
    </Row>
  );
});

RelationshipToMainCardHolder.propTypes = {
  cardHolder: PropTypes.object,
  codes: PropTypes.object,
  language: PropTypes.string
};

RelationshipToMainCardHolder.defaultProps = {
  cardHolder: {},
  codes: { groups: {} },
  language: 'de'
};

export default RelationshipToMainCardHolder;
