import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, uniq } from 'lodash';
import { utils as codeUtils } from '@evoja-web/entity-code';

import Row from './Row';

const CategoryVariety = React.memo(({
  cards,
  codes,
  language
}) => {
  const variety = cards.map((card) => {
    const code = codeUtils.getCodeByNumber({ codes, group: 'cardType', number: get(card, 'cardVariety') });

    return get(code, `text.${language}`);
  });

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.CardVariety" />}>
      {uniq(variety).join(' & ')}
    </Row>
  );
});

CategoryVariety.propTypes = {
  cards: PropTypes.array,
  codes: PropTypes.object,
  language: PropTypes.string
};

CategoryVariety.defaultProps = {
  cards: [],
  codes: { groups: '' },
  language: 'de'
};

export default CategoryVariety;
