import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Toggle } from '@evoja-web/react-form';
import { noop } from 'lodash';

import './Toggle.css';

class MonthlyExpenseToggle extends React.Component {
  constructor(props) {
    super(props);

    this.onAdd = this.onAdd.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  /**
   * Handle click to add icon
   *
   * @param {Array} args Args
   *
   * @return void
   */
  onAdd(...args) {
    const {
      defaults,
      onAdd
    } = this.props;

    onAdd(defaults, ...args);
  }

  /**
   * Default behaviour if no onChange callback was passed (add an item)
   *
   * @param  {Event} ev   Event from @evoja-web/react-form/Toggle
   * @param  {Array} rest Rest args
   *
   * @return void
   */
  onToggle(key, value, ev, ...rest) {
    const {
      addOnChecked,
      defaults,
      onAdd,
      onReset,
      onToggle,
      resetOnUnchecked,
      type
    } = this.props;

    if (addOnChecked && value) {
      onAdd(defaults, ev, ...rest);
    }

    if (resetOnUnchecked && !value) {
      onReset(type);
    }

    onToggle(type, ev);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      addable,
      checked,
      children,
      type
    } = this.props;

    return (
      <Row className="setadvisor-limit-calculation--monthly-expense--header">
        <Col
          className="setadvisor-limit-calculation--monthly-expense--header--toggle"
          lg={1}
          md={1}
        >
          <Toggle
            id={type}
            value={checked}
            onChange={this.onToggle}
          />

        </Col>

        <Col
          className="setadvisor-limit-calculation--monthly-expense--header--label"
          lg={10}
          md={10}
        >
          {children}
        </Col>

        <Col
          className="setadvisor-limit-calculation--monthly-expense--header--add"
          lg={1}
          md={1}
        >
          {addable && (
            <span
              className="mdi mdi-plus-circle-outline pointer"
              onClick={this.onAdd}
            />
          )}
        </Col>
      </Row>
    );
  }
}

MonthlyExpenseToggle.propTypes = {
  addable: PropTypes.bool,
  addOnChecked: PropTypes.bool,
  checked: PropTypes.bool,
  children: PropTypes.node,
  defaults: PropTypes.object,
  onAdd: PropTypes.func,
  onReset: PropTypes.func,
  onToggle: PropTypes.func,
  resetOnUnchecked: PropTypes.bool,
  type: PropTypes.string.isRequired
};

MonthlyExpenseToggle.defaultProps = {
  addable: true,
  addOnChecked: false,
  checked: false,
  children: [],
  defaults: {},
  onAdd: noop,
  onReset: undefined,
  onToggle: noop,
  resetOnUnchecked: false
};

export default MonthlyExpenseToggle;
