import { DefaultAction } from '@evoja-web/redaction';

export const notificationBadgeCountAction = DefaultAction('map/notificationCenter', 'NOTIFICATION_BADGE_COUNT');

export const INCREASE_NOTIFICATION_BADGE_COUNT = 'redaction/map/notificationCenter/INCREASE_NOTIFICATION_BADGE_COUNT';
export const DECREASE_NOTIFICATION_BADGE_COUNT = 'redaction/map/notificationCenter/DECREASE_NOTIFICATION_BADGE_COUNT';
export const SET_NOTIFICATION_BADGE_COUNT = 'redaction/map/notificationCenter/SET_NOTIFICATION_BADGE_COUNT';

function increaseNotificationBadgeCount(props = {}) {
  return { type: INCREASE_NOTIFICATION_BADGE_COUNT, payload: props };
}

function decreaseNotificationBadgeCount(props = {}) {
  return { type: DECREASE_NOTIFICATION_BADGE_COUNT, payload: props };
}
function setNotificationBadgeCount(props = {}) {
  return { type: SET_NOTIFICATION_BADGE_COUNT, payload: props };
}

notificationBadgeCountAction.addFunction('decreaseNotificationBadgeCount', decreaseNotificationBadgeCount);
notificationBadgeCountAction.addFunction('increaseNotificationBadgeCount', increaseNotificationBadgeCount);
notificationBadgeCountAction.addFunction('setNotificationBadgeCount', setNotificationBadgeCount);

notificationBadgeCountAction.addConstant('SET_NOTIFICATION_BADGE_COUNT', SET_NOTIFICATION_BADGE_COUNT);
notificationBadgeCountAction.addConstant('DECREASE_NOTIFICATION_BADGE_COUNT', DECREASE_NOTIFICATION_BADGE_COUNT);
notificationBadgeCountAction.addConstant('INCREASE_NOTIFICATION_BADGE_COUNT', INCREASE_NOTIFICATION_BADGE_COUNT);

export default notificationBadgeCountAction.get();
