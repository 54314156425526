import { get, isArray } from 'lodash';

function undefinedIfEmpty(obj, path) {
  const value = get(obj, path);

  return isArray(value)
    ? get(value, 'length', 0) === 0
      ? undefined
      : value
    : value;
}

export default function FinancialSituation({ form }) {
  return get(form, 'data.active', false)
    ? {
      assets: undefinedIfEmpty(form, 'data.assets'),
      monthlyIncomes: undefinedIfEmpty(form, 'data.monthlyIncomes'),
      monthlyExpenses: undefinedIfEmpty(form, 'data.monthlyExpenses')
    }
    : undefined;
}
