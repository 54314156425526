import { call, put } from 'redux-saga/effects';
import { ChunkLoader, handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get } from 'lodash';

import contractDispatchRulesAction from '../../actions/DispatchRule/ContractDispatchRules';
import { toIntIfSet } from '../../../../lib/Utils';

const {
  CONTRACT_DISPATCH_RULES_PENDING,
  CONTRACT_DISPATCH_RULES_FULFILLED,
  CONTRACT_DISPATCH_RULES_REJECTED
} = contractDispatchRulesAction;

export default function* getContractDispatchRules(request) {
  const { dataKey } = request;

  yield put({ type: CONTRACT_DISPATCH_RULES_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('id', dataKey, { type: 'string' })
      .select(['assignedAccounts', 'customer', 'electronicDocuments', 'id', 'number'])
      .limit(1)
      .getQueryString();

    const url = `/entity/contract/${query}`;
    const data = yield call(handleNextHeaders, url);
    const contract = get(data, 0);

    // Load accounts in chunks as the list may be pretty big which will lead to an url that exceeds the limit
    const cl = ChunkLoader()
      .setServiceUrl('/account/account/')
      .setIdentifiers(get(contract, 'assignedAccounts', []), { type: 'string' });

    const accounts = yield call(cl.execute);

    const payload = {
      clientDesignation: get(contract, 'customer.designation'),
      contractId: get(contract, 'number'),
      dispatchRules: accounts.map((account) => ({
        accountHolderClientKey: toIntIfSet(account.customer.id),
        accountKey: toIntIfSet(account.id),
        accountTypeText: get(account, 'accountType.text'),
        clientDesignation: get(account, 'customer.designation'),
        eDocument: get(contract, 'electronicDocuments', []).includes(get(account, 'id')),
        editAccountNumber: get(account, 'numberFormatted')
      }))
    };

    yield put({ type: CONTRACT_DISPATCH_RULES_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONTRACT_DISPATCH_RULES_REJECTED, dataKey, error });

    return error;
  }
}
