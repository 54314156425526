import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import existingContractAction from '../../actions/BaseContract/ExistingContract';

const {
  EXISTING_CONTRACT_PENDING,
  EXISTING_CONTRACT_FULFILLED,
  EXISTING_CONTRACT_REJECTED
} = existingContractAction;

export default function* getExistingContract(request) {
  yield put({ type: EXISTING_CONTRACT_PENDING });

  try {
    const {
      customerId,
      tenant = 6300
    } = request;

    const url = `/sif/finnova/contractadministration/api/v5/banks/${tenant}/contracts?clientKey=${customerId}&contractType=100&contractStatus=90`;
    const { data } = yield call(axios, {
      url,
      method: 'get'
    });

    const payload = get(data, 0);

    yield put({ type: EXISTING_CONTRACT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: EXISTING_CONTRACT_REJECTED, error });

    return error;
  }
}
