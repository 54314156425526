import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Row from './Row';

const Promotion = React.memo(({ promotion }) => {
  const message = promotion
    ? 'General.Yes'
    : 'General.No';

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.Promotion" />}>
      <FormattedMessage id={message} />
    </Row>
  );
});

Promotion.propTypes = {
  promotion: PropTypes.bool
};

Promotion.defaultProps = {
  promotion: false
};

export default Promotion;
