import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import { Section, SectionTitle, SectionContent } from '../../../../General/components/Section/index';
import ContractRules from './ContractRules';

class DispatchRuleForm extends React.Component {
  constructor(props) {
    super(props);

    this.onContractChange = this.onContractChange.bind(this);
  }

  onContractChange(index, contract) {
    const {
      contracts,
      id,
      onChange
    } = this.props;

    const updated = update(contracts, {
      $splice: [[index, 1, contract]]
    });

    onChange(id, updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      contracts,
      disabled,
      language,
      session,
      showCommentInput,
      validations
    } = this.props;

    const children = contracts
      .map((contract, index) => {
        return (
          <ContractRules
            key={get(contract, 'contractId')}
            contract={contract}
            disabled={disabled}
            language={language}
            onChange={(...args) => this.onContractChange(index, ...args)}
            session={session}
            showCommentInput={showCommentInput}
            validations={get(validations, `contracts.${index}`)}
          />
        );
      });

    return (
      <div className="setadvisor-form-contract-dispatch-rules pb-5">
        <Section>
          <SectionTitle>
            <FormattedMessage id="Freemium.Form.OptionPaper.EDocumentTitle" />
          </SectionTitle>

          <SectionContent>
            <Alert type="warning" style={{ marginBottom: 20 }}>
              <AlertContent>
                <FormattedMessage id="Freemium.Form.EDocuments.AccountIndependent.Hint" />
                <br />
                <br />
                <FormattedMessage id="Freemium.Form.OptionPaper.Disclaimer" />

              </AlertContent>
            </Alert>

            {children}
          </SectionContent>
        </Section>
      </div>
    );
  }
}

DispatchRuleForm.propTypes = {
  contracts: PropTypes.array,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  onChange: PropTypes.func,
  session: PropTypes.object.isRequired,
  showCommentInput: PropTypes.bool,
  validations: PropTypes.object
};

DispatchRuleForm.defaultProps = {
  contracts: [],
  disabled: false,
  language: 'de',
  onChange: noop,
  showCommentInput: true,
  validations: {}
};

export default DispatchRuleForm;
