import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

function SuccessMessage({ redirectTo, countdownTime, title }) {
  const [countdown, setCountdown] = useState(countdownTime);
  useEffect(() => {
    // If countdown reaches 0, call the redirectToHome function
    if (countdown === 0) {
      redirectTo();
      return;
    }

    const timerId = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);

    // Cleanup the timer
    return () => clearTimeout(timerId);
  }, [redirectTo, countdown]);

  return (
    <div className="loading-modal-success-content">
      <h5>{title}</h5>
      <p>
        <FormattedMessage id="Workguide.LoadingModal.Countdown" values={{ countdown }} />
      </p>

      <Button onClick={redirectTo} outline color="primary">
        <FormattedMessage id="Workguide.LoadingModal.Redirect" />
      </Button>
    </div>
  );
}

SuccessMessage.propTypes = {
  countdownTime: PropTypes.number,
  redirectTo: PropTypes.func,
  title: PropTypes.node
};

SuccessMessage.defaultProps = {
  countdownTime: 10,
  redirectTo: noop,
  title: ''
};
export default SuccessMessage;
