import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';
import AccountOptionLabel from '../../Common/Account/AccountOptionLabel';

const ConnectionAccount = React.memo(({
  accounts,
  codes,
  id,
  language,
  onChange,
  validations,
  value
}) => {
  const options = accounts.map((account) => ({
    account,
    value: get(account, 'accountKey'),
    label: <AccountOptionLabel account={account} codes={codes} language={language} />
  }));

  const onSelectionChange = useCallback((key, value, option) => {
    onChange(key, get(option, 'account'));
  }, [onChange]);

  return (
    <Section className="setadvisor-form-credit-card-general--connection-account">
      <SectionTitle>
        <FormattedMessage id="Freemium.Form.CreditCard.ConnectionAccount.Title" />
      </SectionTitle>

      <SectionContent>
        <RadioButtonGroup
          id={id}
          value={value}
          onChange={onSelectionChange}
          options={options}
        />

        <ValidationResult
          show
          validations={validations}
        />
      </SectionContent>
    </Section>
  );
});

ConnectionAccount.propTypes = {
  accounts: PropTypes.array,
  codes: PropTypes.object,
  id: PropTypes.string,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.number
};

ConnectionAccount.defaultProps = {
  accounts: [],
  codes: { groups: {} },
  id: 'connectionAccount',
  language: 'de',
  onChange: noop,
  validations: {},
  value: undefined
};

export default ConnectionAccount;
