import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withAcl } from '@evoja-web/client-acl';

import './Canceled.css';
import NoCustomerChange from '../../NoCustomerChange';
import Summary from './Summary';
import Comments from '../../../../components/Form/Common/Comments/Comments';
import setAdvisorActions from '../../../../actions/Actions';
import { withWorkguideInstanceData } from '../../../../../Workguide/hocs';

class SetAdvisorWorguideCardLimitCanceled extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: []
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Submit the workguide
   *
   * @return void
   */
  onSubmit() {
    const { comments } = this.state;
    const {
      acl,
      process,
      setAdvisorActions,
      task,
      toggleRedirectModal
    } = this.props;

    setAdvisorActions.creditCardCanceledRequest({
      comments: [...get(process, 'comments', []), ...comments],
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
      taskId: get(task, 'id')
    });

    toggleRedirectModal();
  }

  /**
   * Handle comments change
   *
   * @param  {String} id       Form element id
   * @param  {Array}  comments Array of comments
   *
   * @return {[type]}          [description]
   */
  onCommentsChange(id, comments) {
    this.setState({ comments });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      onFormCancel,
      session,
      workguide
    } = this.props;

    return (
      <div className="setadvisor-workguide-card-limit-canceled">
        <NoCustomerChange workguideId={get(workguide, 'id')}>
          <Row className="setadvisor-workguide-card-limit-canceled--title">
            <Col lg={12} md={12}>
              <FormattedMessage
                id="Freemium.Workguide.CardLimitCanceled.Title"
                tagName="h5"
              />
            </Col>
          </Row>

          <Row className="setadvisor-workguide-card-limit-canceled--description mb-5">
            <Col lg={12} md={12}>
              <FormattedMessage
                id="Freemium.Workguide.CardLimitCanceled.DescriptionLineOne"
                tagName="p"
              />

              <FormattedMessage
                id="Freemium.Workguide.CardLimitCanceled.DescriptionLineTwo"
                tagName="p"
              />

              <FormattedMessage
                id="Freemium.Workguide.CardLimitCanceled.DescriptionLineThree"
                tagName="p"
              />
            </Col>
          </Row>

          <Summary />

          <Comments
            onChange={this.onCommentsChange}
            session={session}
          />

          <Row style={{ paddingTop: '4rem' }}>
            <Col lg={12} md={12} className="d-flex justify-content-end">
              <Button
                color="primary"
                outline
                onClick={onFormCancel}
              >
                <FormattedMessage id="Activity.Form.Cancel" />
              </Button>

              <span style={{ paddingRight: '1rem' }} />

              <Button
                color="primary"
                onClick={this.onSubmit}
              >
                <FormattedMessage id="Activity.Form.Submit" />
              </Button>
            </Col>
          </Row>
        </NoCustomerChange>
      </div>
    );
  }
}

SetAdvisorWorguideCardLimitCanceled.propTypes = {
  acl: PropTypes.object.isRequired,
  onFormCancel: PropTypes.func,
  process: PropTypes.object,
  session: PropTypes.object.isRequired,
  setAdvisorActions: PropTypes.object.isRequired,
  task: PropTypes.object,
  toggleRedirectModal: PropTypes.func,
  workguide: PropTypes.object
};

SetAdvisorWorguideCardLimitCanceled.defaultProps = {
  onFormCancel: noop,
  process: {},
  task: {},
  toggleRedirectModal: noop,
  workguide: undefined
};

function mapStateToProps(state, ownProps) {
  return {
    process: get(ownProps, 'bpf.process'),
    session: state.login.session,
    task: get(ownProps, 'bpf.task')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAdvisorActions: bindActionCreators(setAdvisorActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    withAcl()(SetAdvisorWorguideCardLimitCanceled)
  )
);
