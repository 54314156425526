function extractMentionDataByType(htmlString, mentionType) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Select only <span> elements with the specified data-mention-type
  const mentions = doc.querySelectorAll(`span[data-type="mention"][data-mention-type="${mentionType}"]`);

  return Array.from(mentions).map((span) => {
    const { dataset } = span;
    const result = { text: span.textContent };

    // Copy all data attributes
    for (const key in dataset) {
      result[key] = dataset[key];
    }

    return result;
  });
}

export default extractMentionDataByType;
