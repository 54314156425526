import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTarzan } from '@evoja-web/tarzan';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { Col, FormGroup, Label, Row, } from 'reactstrap';
import { Toggle } from '@evoja-web/react-form';
import { withAcl } from '@evoja-web/client-acl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConsultantSelect } from '../../General';

import notificationActions from '../actions/Actions';
import './NotificationSettings.css';

class NotificationSettings extends PureComponent {
  static defaultProps = {
    currentSubscription: [],
  };

  constructor(props) {
    super(props);

    this.onConsultantSelect = this.onConsultantSelect.bind(this);
    this.toggleSubscription = this.toggleSubscription.bind(this);
    this.deleteSubscription = this.deleteSubscription.bind(this);
  }

  onConsultantSelect(id, value) {
    const { currentSubscription, notificationActions } = this.props;

    if (!value) return;

    const existingSubscription = currentSubscription.find((sub) => sub.id === value.id);

    const updatedSubscriptions = existingSubscription
      ? currentSubscription.map((sub) => (sub.id === value.id ? { ...sub, active: true } : sub))
      : [
        ...currentSubscription,
        {
          id: value.id,
          label: get(value, 'shortDesignation'),
          active: true,
        }
      ];

    notificationActions.notificationCenterSubscriptionSaveRequest({
      topics: updatedSubscriptions
    });
  }

  toggleSubscription(subscriptionId) {
    const { currentSubscription, notificationActions } = this.props;

    const updatedSubscriptions = currentSubscription.map((sub) => (sub.id === subscriptionId ? { ...sub, active: !sub.active } : sub));

    notificationActions.notificationCenterSubscriptionSaveRequest({
      topics: updatedSubscriptions
    });
  }

  deleteSubscription(subscriptionId) {
    const { currentSubscription, notificationActions } = this.props;

    const updatedSubscriptions = currentSubscription.filter(
      (sub) => sub.id !== subscriptionId
    );

    notificationActions.notificationCenterSubscriptionSaveRequest({
      topics: updatedSubscriptions
    });
  }

  renderSubscriptionItem(subscription) {
    const { session, acl } = this.props;

    return (
      <Row
        key={subscription.id}
        className="subscription-item align-items-center justify-content-between p-2"
      >

        <Col md={9} className="d-flex align-items-center flex-grow-1">
          <div className="status-indicator mr-3">
            <i
              className={`mdi mdi-${subscription.active ? 'bell-outline' : 'bell-off-outline'} ${subscription.active ? 'text-success' : 'text-muted'}`}
              style={{ paddingRight: 10, fontSize: '1.2em' }}
            />
          </div>
          <div className="subscription-label font-weight-medium">
            {subscription.label || 'Unknown Consultant'}
          </div>
          {acl.isAllowed('NotificationCenter.Settings.ShowTopic') && (
          <div className="subscription-id text-muted small ml-3">
            TOPIC:
            {' '}
            {subscription.id}
          </div>
          )}
        </Col>

        <Col md={2}>
          <Toggle
            className="mx-3"
            value={subscription.active}
            onChange={() => this.toggleSubscription(subscription.id)}
          />
        </Col>
        <Col md={1}>
          {/* Only allow deleting of topics which are not my own */}
          {subscription.id !== get(session, 'id') && (
          <button
            type="button"
            aria-label="delete"
            className="btn text-danger p-0"
            onClick={(e) => {
              e.stopPropagation();
              this.deleteSubscription(subscription.id);
            }}
          >
            <i className="mdi mdi-delete-outline" style={{ fontSize: '1.2em' }} />
          </button>
          )}
        </Col>
      </Row>
    );
  }

  render() {
    const { consultants, currentSubscription, intl } = this.props;
    const activeSubscriptions = currentSubscription.filter((sub) => sub.active);
    const inactiveSubscriptions = currentSubscription.filter((sub) => !sub.active);

    return (
      <div className="notification-settings-container p-4">
        <div className="settings-header mb-4">
          <h2 className="h5 mb-2">
            <FormattedMessage id="NotificationCenter.Settings.Title" />
          </h2>
          <p className="text-muted small">
            <FormattedMessage id="NotificationCenter.Settings.Description" />
          </p>
        </div>

        <div className="add-subscription-section mb-4">
          <FormGroup className="mb-3">
            <Label for="consultantSelect" className="small font-weight-bold">
              <FormattedMessage id="NotificationCenter.Settings.AddTopic" />
            </Label>
            <ConsultantSelect
              consultants={consultants}
              id="consultantSelect"
              name="consultantSelect"
              multi={false}
              placeholder={intl.formatMessage({ id: 'NotificationCenter.Settings.AddTopic' })}
              onChange={this.onConsultantSelect}
              className="compact-select"
            />
          </FormGroup>
        </div>

        <div className="subscription-list">
          <div className="list-section mb-3">
            <div className="list-header small font-weight-bold text-uppercase mb-2">
              <FormattedMessage id="NotificationCenter.Settings.Active" />
              {' '}
              (
              {activeSubscriptions.length}
              )
            </div>
            {activeSubscriptions.map((sub) => this.renderSubscriptionItem(sub))}
            {activeSubscriptions.length === 0 && (
              <div className="empty-state py-2 small text-muted">
                <FormattedMessage id="NotificationCenter.Settings.NoActive" />
              </div>
            )}
          </div>

          <div className="list-section">
            <div className="list-header small font-weight-bold text-uppercase mb-2">
              <FormattedMessage id="NotificationCenter.Settings.Inactive" />
              {' '}
              (
              {inactiveSubscriptions.length}
              )
            </div>
            {inactiveSubscriptions.map((sub) => this.renderSubscriptionItem(sub))}
            {inactiveSubscriptions.length === 0 && (
              <div className="empty-state py-2 small text-muted">
                <FormattedMessage id="NotificationCenter.Settings.NoInactive" />

              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  notificationActions: PropTypes.object.isRequired,
  currentSubscription: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    })
  ),
  consultants: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
  acl: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  consultants: state.consultantSearch.allConsultants,
  session: state.login.session,
});

function mapDispatchToProps(dispatch) {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(
    withTarzan()(
      withAcl()(NotificationSettings)
    )
  )
);
