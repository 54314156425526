import classnames from 'classnames';
import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, head, isArray, isEmpty, isEqual, noop, size, uniqBy } from 'lodash';
import { withTarzan } from '@evoja-web/tarzan';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './NotificationOverview.scss';
import NotificationList from '../components/NotificationList';
import { notificationTabs } from '../lib/notificationMapping';
import notificationActions from '../actions/Actions';
import NotificationSettings from '../components/NotificationSettings';
import { withNavIndicator } from '../../CustomerDetail/hooks/useNavNotifications';
import withBrowserNotification from '../hooks/withBrowserNotification';
import extractMentionDataByType from '../lib/extractDataAttributes';

/**
 * Opens a customer page in a new tab if the message contains a messenger mention
 * with required data attributes (customerid, id, contract).
 */
function onMessageClick(messageContent) {
  const dataAttributes = extractMentionDataByType(messageContent, 'messenger');
  const { customerid, id, contract } = head(dataAttributes);
  if (customerid && id && contract) {
    const win = window.open(`/customer/${customerid}?isMessengerOpen=true&taskId=${id}&contract=${contract}`, '_blank');
    win.focus();
  }
}

/**
 * Notification Center Component
 *
 * A tabbed interface for viewing and managing notifications. Supports real-time updates via Tarzan
 * and integrates with Redux for state management.
 *
 * @param {Object} notificationActions - Redux actions for managing notifications.
 * @param {Function} tarzanUnsubscribe - Function to unsubscribe from Tarzan real-time updates.
 * @param {Function} tarzanSubscribe - Function to subscribe to Tarzan real-time updates.
 * @param {Object} notifications - Object containing the list of notifications.
 * @param {Object} session - Object containing user session data.
 * @param {Object} notificationCenterSubscription - Object containing subscription data for notifications.
 */
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1'
    };
    this.subscription = undefined;

    this.onMarkNotificationAsRead = this.onMarkNotificationAsRead.bind(this);
    this.onTopicChange = this.onTopicChange.bind(this);
  }

  async componentDidMount() {
    const { notificationActions, session } = this.props;
    const initialTopics = [{ id: get(session, 'id'), label: get(session, 'shortDesignation'), active: true }];
    notificationActions.notificationCenterSubscriptionInitRequest({ initialTopics });
  }

  componentDidUpdate(prevProps) {
    const { notificationCenterSubscription } = this.props;
    // Check if the subscription state has changed, and if so, create a new subscription and fetch existing notifications.
    if (get(notificationCenterSubscription, 'initialized')
     && !isEqual(get(notificationCenterSubscription, 'data'), get(prevProps, 'notificationCenterSubscription.data'))) {
      // Make sure only active subscriptions are used.
      const topicIds = get(notificationCenterSubscription, 'data', []).filter((t) => t.active).map((t) => t.id);
      this.onTopicChange(topicIds);
    }
  }

  async onTopicChange(topics) {
    const {
      notificationActions, tarzanSubscribe, tarzanUnsubscribe, addNavIndicator
    } = this.props;
    if (isEmpty(topics)) {
      // reset notification form
      notificationActions.notificationReset();
      // unsubscribe from old connections
      return tarzanUnsubscribe(this.subscription);
    }

    // fetch notification by topic
    notificationActions.notificationRequest({ topics });
    // subscribe to new messages
    this.subscription = await tarzanSubscribe({
      topic: topics,
      types: [],
      callback: ({ message }) => {
        const newMessage = get(message, 'data.data', {});
        if (!isEmpty(newMessage)) {
          notificationActions.increaseNotificationBadgeCount();
          notificationActions.addNewNotification({ notification: newMessage });
          addNavIndicator('notification-center');
        }
      }
    });
    return this.subscription;
  }

  onMarkNotificationAsRead(messageIds) {
    const { notificationActions } = this.props;
    if (!isEmpty(messageIds)) {
      const ids = isArray(messageIds) ? messageIds : [messageIds];
      notificationActions.markNotificationAsReadRequest({ messageIds: ids });
      notificationActions.decreaseNotificationBadgeCount({ count: size(ids) });
    }
  }

  render() {
    const { activeTab } = this.state;
    const { notifications, notificationCenterSubscription, session } = this.props;

    const uniqueNotificaitons = uniqBy([
      ...get(notifications, 'data', []),
    ], '_id');

    return (
      <div className="notification-center-tab-container">

        <Nav tabs className="notification-center-tab">

          <NavItem className="notification-center-tab-item">
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => this.setState({ activeTab: '1' })}
            >
              <div className="notification-center-tab-item-underline">
                <FormattedMessage id="NotificationCenter.Tabs.All" />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="notification-center-tab-item">
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => this.setState({ activeTab: '2' })}
            >
              <div className="notification-center-tab-item-underline">
                <i className="mdi mdi-format-list-checks" style={{ marginRight: 5 }} />

                <FormattedMessage id="NotificationCenter.Tabs.Activity" />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="notification-center-tab-item">
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => this.setState({ activeTab: '3' })}
            >
              <div className="notification-center-tab-item-underline">
                <i className="mdi mdi-bullhorn-variant-outline" style={{ marginRight: 5 }} />
                <FormattedMessage id="NotificationCenter.Tabs.Leads" />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="notification-center-tab-item">
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => this.setState({ activeTab: '4' })}
            >
              <div className="notification-center-tab-item-underline">
                <i className="mdi mdi-chat-outline" style={{ marginRight: 5 }} />
                <FormattedMessage id="NotificationCenter.Tabs.Messenger" />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="notification-center-tab-item">
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => this.setState({ activeTab: '5' })}
            >
              <div className="notification-center-tab-item-underline">
                <i className="mdi mdi-card-account-mail-outline" style={{ marginRight: 5 }} />
                <FormattedMessage id="NotificationCenter.Tabs.Note" />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="notification-center-tab-item">
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => this.setState({ activeTab: '6' })}
            >
              <div className="notification-center-tab-item-underline">
                <i className="mdi mdi-cog-outline" style={{ marginRight: 5 }} />
                <FormattedMessage id="NotificationCenter.Tabs.Settings" />
              </div>
            </NavLink>
          </NavItem>

        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {activeTab === '1' && (
            <NotificationList
              messages={uniqueNotificaitons}
              type={notificationTabs.ALL}
              onMarkNotificationAsRead={this.onMarkNotificationAsRead}
              currentSubscription={get(notificationCenterSubscription, 'data', [])}
              session={session}
            />
            )}
          </TabPane>
          <TabPane tabId="2">
            {activeTab === '2' && (
            <NotificationList
              messages={uniqueNotificaitons}
              type={notificationTabs.ACTIVITY}
              onMarkNotificationAsRead={this.onMarkNotificationAsRead}
              currentSubscription={get(notificationCenterSubscription, 'data', [])}
              session={session}
            />
            )}
          </TabPane>
          <TabPane tabId="3">
            {activeTab === '3' && (
            <NotificationList
              messages={uniqueNotificaitons}
              type={notificationTabs.LEAD}
              onMarkNotificationAsRead={this.onMarkNotificationAsRead}
              currentSubscription={get(notificationCenterSubscription, 'data', [])}
              session={session}
            />
            )}
          </TabPane>
          <TabPane tabId="4">
            {activeTab === '4' && (
            <NotificationList
              messages={uniqueNotificaitons}
              type={notificationTabs.MESSENGER}
              onMarkNotificationAsRead={this.onMarkNotificationAsRead}
              currentSubscription={get(notificationCenterSubscription, 'data', [])}
              session={session}
            />
            )}
          </TabPane>
          <TabPane tabId="5">
            {activeTab === '5' && (
            <NotificationList
              messages={uniqueNotificaitons}
              type={notificationTabs.NOTE}
              onMarkNotificationAsRead={this.onMarkNotificationAsRead}
              currentSubscription={get(notificationCenterSubscription, 'data', [])}
              session={session}
            />
            )}
          </TabPane>
          <TabPane tabId="6">
            <NotificationSettings
              currentSubscription={get(notificationCenterSubscription, 'data', [])}
              onTopicChange={this.onTopicChange}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

Notification.propTypes = {
  notificationActions: PropTypes.object.isRequired,
  tarzanUnsubscribe: PropTypes.func.isRequired,
  tarzanSubscribe: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  notificationCenterSubscription: PropTypes.object.isRequired,
  addNavIndicator: PropTypes.func
};

Notification.defaultProps = {
  addNavIndicator: noop
};

function mapStateToProps(state) {
  return {
    language: state.login.language,
    session: state.login.session,
    notifications: state.notificationCenter.notifications,
    notificationCenterSubscription: state.notificationCenter.notificationCenterSubscription
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTarzan()(
    withNavIndicator(
      withBrowserNotification({
        relevantNotifications: [
          (state, props) => ({
            topic: get(props, 'session.id'),
            types: [{ type: 'messenger' }],
            onClick: onMessageClick
          })
        ]
      })(Notification)
    )
  )
);
