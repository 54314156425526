import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { has, get } from 'lodash';
import { withCodeGroups } from '@evoja-web/entity-code';
import { CustomerAware } from '@evoja-web/customer-workguide-search';

import './General.css';
import BeneficialOwner from '../../../components/Form/CreditCard/General/BeneficialOwner';
import CardHolderAdditional from '../../../components/Form/CreditCard/General/CardHolderAdditional';
import CardHolderMain from '../../../components/Form/CreditCard/General/CardHolderMain';
import CardVariety from '../../../components/Form/CreditCard/General/CardVariety';
import ConnectionAccount from '../../../components/Form/CreditCard/General/ConnectionAccount';
import CurrentCards from '../../../components/Form/CreditCard/Common/CurrentCards';
import DebitAccount from '../../../components/Form/CreditCard/General/DebitAccount';
import PaymentType from '../../../components/Form/CreditCard/General/PaymentType';
import freemiumActions from '../../../actions/Actions';
import { getValidator } from '../../../../../globals';
import { withWorkguideInstanceData } from '../../../../Workguide/hocs';

import {
  isAdditionalCardCategorySelected,
  isMainAndAdditionalCardCategorySelected,
  isMainCardCategorySelected,
  isComboCardCategorySelected
} from '../../../lib/CreditCard/index';

const conditions = {
  currentCards: ({ props }) => get(props, 'showCurrentCards', false),
  debitAccount: ({ props }) => get(props, 'showDebitAccountSelection', true),
  cardVariety: () => true,
  connectionAccount: ({ form }) => !get(form, 'data.orderInCctTool', false) && !isAdditionalCardCategorySelected(get(form, 'data.cards', [])),
  cardHolderMain: ({ form }) => {
    const cards = get(form, 'data.cards', []);

    return (
      isMainCardCategorySelected(cards)
      || isMainAndAdditionalCardCategorySelected(cards)
      || isComboCardCategorySelected(cards)
    );
  },
  cardHolderAdditional: ({ form }) => {
    const cards = get(form, 'data.cards', []);

    return (
      isAdditionalCardCategorySelected(cards)
      || isMainAndAdditionalCardCategorySelected(cards)
    );
  },
  beneficialOwner: ({ form }) => !get(form, 'data.orderInCctTool', false),
  paymentType: ({ form }) => !get(form, 'data.orderInCctTool', false)
};

/**
 * Check if the component with the given id should be rendered
 *
 * @param  {String} id         ID
 * @param  {Object} [props={}] Props passed to func
 *
 * @return {Boolean}
 */
function shouldRenderComponent(id, props = {}) {
  return get(conditions, id, () => true)(props);
}

class CreditCardOrderGeneral extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onCustomerChange = this.onCustomerChange.bind(this);
  }

  componentWillUnmount() {
    const { freemiumActions } = this.props;

    freemiumActions.creditCardOrderFormReset();
    freemiumActions.creditCardOrderReset();
  }

  /**
   * Handle customer change.
   * Call init saga which will reset form data and load necessary data
   *
   * @param  {Object} customer  Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const { freemiumActions, additionalConnectionAccountTypes } = this.props;
    freemiumActions.creditCardOrderFormInitRequest({ customer, additionalConnectionAccountTypes });
  }

  /**
   * Handle form value change and revalidate data
   *
   * @param  {String} key   Form element id
   * @param  {Mixed}  value Form value
   * @param  {Array}  rest  Rest args
   *
   * @return void
   */
  onValueChange(key, value, ...rest) {
    const { freemiumActions } = this.props;

    freemiumActions.creditCardOrderFormSetValue(key, value, ...rest);
  }

  /**
   * Validate current form data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const {
      form,
      freemiumActions,
      showDebitAccountSelection
    } = this.props;

    const cards = get(form, 'data.cards');

    const hasMainCard = isMainCardCategorySelected(cards) || isMainAndAdditionalCardCategorySelected(cards) || isComboCardCategorySelected(cards);
    const hasAdditionalCard = isAdditionalCardCategorySelected(cards) || isMainAndAdditionalCardCategorySelected(cards);
    const orderInCctTool = get(form, 'data.orderInCctTool', false);

    const definition = {
      validations: {
        mainCardHolderProfileCompletion: {
          type: 'boolean',
          validations: {
            isTrue: []
          }
        },
        additionalCardHolderProfileCompletion: {
          type: 'boolean',
          validations: {
            isTrue: []
          }
        },
        debitAccount: {
          type: 'object',
          required: showDebitAccountSelection
        },
        cardVariety: {
          type: (isComboCardCategorySelected(cards) || orderInCctTool) ? 'string' : 'number',
          required: !orderInCctTool
        },
        connectionAccount: {
          type: 'object',
          required: !orderInCctTool && !isAdditionalCardCategorySelected(cards)
        },
        poaMain: {
          type: 'object',
          required: hasMainCard
        },
        additionalHolder: {
          type: 'object',
          required: hasAdditionalCard,
          validations: {
            poaAdditional: {
              type: 'object',
              required: true
            },
            otherCardHolder: {
              type: 'object',
              required: ({ parent }) => get(parent, 'poaAdditional.poaKey') === 'otherCardHolder'
            },
          }
        },
        addressTypeMain: {
          type: 'string',
          required: hasMainCard
        },
        addressTypeAdditional: {
          type: 'string',
          required: hasAdditionalCard
        },
        residencePermit: {
          type: 'object',
          required: hasMainCard && has(form, 'data.poaMain.nationality') && get(form, 'data.poaMain.nationality') !== 'CH'
        },
        residencePermitFile: {
          type: 'string',
          required: hasMainCard && has(form, 'data.poaMain.nationality') && get(form, 'data.poaMain.nationality') !== 'CH'
        },
        relationshipToMainCardHolder: {
          type: 'object',
          required: hasAdditionalCard
        },
        beneficialOwner: {
          type: 'string',
          required: !orderInCctTool
        },
        paymentType: {
          type: 'number',
          required: !orderInCctTool
        },
        cards: {
          type: 'array',
          required: !get(form, 'data.orderInCctTool', false),
          validations: {
            embossedLineOne: {
              type: 'string',
              required: true,
              validations: {
                maxStringLength: [24],
                isValidCreditCardCustomerName: []
              }
            }
          }
        }
      }
    };

    const validator = getValidator();
    const result = validator.validate(definition, get(form, 'data', {}));

    freemiumActions.formValidationsFulfilled({ dataKey: 'creditCardGeneral', payload: result });

    return result;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      alwaysEnableMainCard,
      alwaysEnableMainAndAdditionalCard,
      alwaysEnableDcCard,
      bpfAccounts,
      bpfCards,
      bpfPoas,
      codes,
      form,
      language,
      requesting,
      showCctToolOption,
      bpfCustomers
    } = this.props;

    const validations = this.validate();

    const debitAccountTypes = get(bpfAccounts, 'data.resultVariables.updatedAccounts', []).filter((q) => [90, 110, 140].includes(q.accountType));

    return (
      <div className="setadvisor-form-credit-card-general">
        <CustomerAware
          onChange={this.onCustomerChange}
          onInit={this.onCustomerChange}
          showLoadIndicator
          requesting={requesting}
        >
          {shouldRenderComponent('currentCards', { form, props: this.props }) && (
            <CurrentCards cards={get(bpfCards, 'data.resultVariables.productCards', [])} />
          )}

          {shouldRenderComponent('debitAccount', { form, props: this.props }) && (
            <DebitAccount
              accounts={debitAccountTypes}
              codes={codes}
              id="debitAccount"
              language={language}
              onChange={this.onValueChange}
              validations={get(validations, 'debitAccount')}
              value={get(form, 'data.debitAccount.accountKey')}
            />
          )}

          {shouldRenderComponent('cardVariety', { form }) && (
            <CardVariety
              alwaysEnableMainCard={alwaysEnableMainCard}
              alwaysEnableMainAndAdditionalCard={alwaysEnableMainAndAdditionalCard}
              alwaysEnableDcCard={alwaysEnableDcCard}
              codes={codes}
              currentCards={get(bpfCards, 'data.resultVariables.productCards', [])}
              form={form}
              language={language}
              onChange={this.onValueChange}
              showCctToolOption={showCctToolOption}
              validations={get(validations, 'cardVariety')}
            />
          )}

          {shouldRenderComponent('connectionAccount', { form }) && (
            <ConnectionAccount
              accounts={get(bpfAccounts, 'data.resultVariables.accounts')}
              codes={codes}
              id="connectionAccount"
              language={language}
              onChange={this.onValueChange}
              validations={get(validations, 'connectionAccount')}
              value={get(form, 'data.connectionAccount.accountKey')}
            />
          )}

          {shouldRenderComponent('cardHolderMain', { form }) && (
            <CardHolderMain
              codes={codes}
              form={form}
              language={language}
              onChange={this.onValueChange}
              powerOfAttorneys={get(bpfPoas, 'data.resultVariables.givenPoas', [])}
              validations={validations}
            />
          )}

          {shouldRenderComponent('cardHolderAdditional', { form }) && (
            <CardHolderAdditional
              codes={codes}
              form={form}
              language={language}
              onChange={this.onValueChange}
              powerOfAttorneys={get(bpfPoas, 'data.resultVariables.givenPoas', [])}
              validations={validations}
              bpfCustomers={bpfCustomers}
            />
          )}

          {shouldRenderComponent('beneficialOwner', { form }) && (
            <BeneficialOwner
              id="beneficialOwner"
              onChange={this.onValueChange}
              validations={get(validations, 'beneficialOwner')}
              value={get(form, 'data.beneficialOwner')}
            />
          )}

          {shouldRenderComponent('paymentType', { form }) && (
            <PaymentType
              id="paymentType"
              onChange={this.onValueChange}
              validations={get(validations, 'paymentType')}
              value={get(form, 'data.paymentType')}
            />
          )}
        </CustomerAware>
      </div>
    );
  }
}

CreditCardOrderGeneral.propTypes = {
  alwaysEnableMainCard: PropTypes.bool,
  alwaysEnableMainAndAdditionalCard: PropTypes.bool,
  alwaysEnableDcCard: PropTypes.bool,
  bpfAccounts: PropTypes.object,
  bpfCards: PropTypes.object,
  bpfPoas: PropTypes.object,
  codes: PropTypes.object,
  form: PropTypes.object,
  language: PropTypes.string,
  requesting: PropTypes.bool,
  freemiumActions: PropTypes.object.isRequired,
  showCctToolOption: PropTypes.bool,
  // Used by shouldRenderComponent
  // eslint-disable-next-line
  showCurrentCards: PropTypes.bool,
  showDebitAccountSelection: PropTypes.bool,
  additionalConnectionAccountTypes: PropTypes.array,
  bpfCustomers: PropTypes.object
};

CreditCardOrderGeneral.defaultProps = {
  alwaysEnableMainCard: false,
  alwaysEnableMainAndAdditionalCard: false,
  alwaysEnableDcCard: false,
  bpfAccounts: {},
  bpfCards: {},
  bpfPoas: {},
  codes: { groups: {} },
  form: {},
  language: 'de',
  requesting: false,
  showCctToolOption: true,
  showCurrentCards: false,
  showDebitAccountSelection: true,
  additionalConnectionAccountTypes: [],
  bpfCustomers: {}
};

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;

  const customerId = get(customer, 'id');
  const bpfAccounts = get(state, `freemium.customerAccounts.${customerId}`);
  const bpfCards = get(state, `freemium.customerCards.${customerId}`);
  const bpfPoas = get(state, `freemium.customerPoas.${customerId}`);
  const bpfCustomers = get(state, 'bpfCms.customer');
  const form = get(state, 'freemium.creditCardOrderForm');

  return {
    bpfAccounts,
    bpfCards,
    bpfPoas,
    bpfCustomers,
    form,
    language: state.login.language,
    requesting: get(form, 'initializing', false)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeGroups({ groups: ['accountType', 'addressType', 'cardCategory', 'cardType', 'relationPart', 'residencePermit'] })(
    connect(mapStateToProps, mapDispatchToProps)(CreditCardOrderGeneral)
  )
);
