import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';

import poasAction from '../../actions/Customer/Poas';
import toIntIfSet from '../../../../lib/Utils/toIntIfSet';

const {
  CUSTOMER_POAS_PENDING,
  CUSTOMER_POAS_FULFILLED,
  CUSTOMER_POAS_REJECTED
} = poasAction;

export default function* getPoas(request) {
  const { dataKey } = request;

  yield put({ type: CUSTOMER_POAS_PENDING, dataKey });

  try {
    const {
      doFilterDetails = true,
      getDetailsForReceivedPoas = true,
      loadAccounts = false,
      tenant = 6300
    } = request;
    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-product-loadPoas/tenant-id/${tenant}/execute`;
    const data = {
      variables: {
        clientKey: toIntIfSet(dataKey),
        doFilterDetails,
        getDetailsForReceivedPoas,
        loadAccounts
      },
      businessKey: 'Product LoadPoas',
      resultVariableNames: [
        'givenPoas',
        'receivedPoas',
        'endStatus',
        'allPoas'
      ],
      waitTimeoutInMs: 120000
    };

    const { data: result } = yield call(axios, {
      url,
      method: 'post',
      data
    });

    const payload = removeUnwantedValues(result, [null]);

    yield put({ type: CUSTOMER_POAS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: CUSTOMER_POAS_REJECTED, dataKey, error });

    return error;
  }
}
