import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './MultipleSessionsModal.css';
import MultipleSessionsWarning from '../../components/UserInfo/MultipleSessionsWarning';
import userActions from '../../actions/Actions';

class MultipleSessionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.onConfirm = this.onConfirm.bind(this);
  }

  /**
   * Confirm and close the modal
   *
   * @return  void
   */
  onConfirm() {
    const { userActions } = this.props;

    userActions.multipleSessionsModalReset();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { modal } = this.props;

    const {
      activeTokenCount,
      isOpen,
      username
    } = get(modal, 'data', {});

    return (
      <Modal
        {...get(modal, 'data.props', {})}
        backdrop="static"
        // backdropClassName="workguide-modal-backdrop"
        modalClassName="user--multiple-sessions-modal"
        isOpen={isOpen}
        size="xl"
        unmountOnClose
      >
        <ModalBody>
          <MultipleSessionsWarning
            activeTokenCount={activeTokenCount}
            username={username}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={this.onConfirm}
          >
            <FormattedMessage id="User.UserInfo.MultipleActiveSessions.Modal.Confirm" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

MultipleSessionsModal.propTypes = {
  modal: PropTypes.object,
  userActions: PropTypes.object.isRequired
};

MultipleSessionsModal.defaultProps = {
  modal: {}
};

function mapStateToProps(state) {
  return {
    modal: state.user.multipleSessionsModal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleSessionsModal);
