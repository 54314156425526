import { chain, get, isEqual, differenceBy } from 'lodash';

/**
 * Get the difference between the given contracts and the updated contracts
 *
 * @param   {Array}  contracts  Current contracts
 * @param   {Array}  updated    Updated contracts
 *
 * @return  {Array} difference Update contracts / fields
 */
export default function getContractDispatchRuleChanges({ contracts = [], updated = [] }) {
  return updated
    .map((contract) => {
      const current = contracts.find((c) => get(c, 'contractId') === get(contract, 'contractId'));

      return { current, contract };
    })
    .filter(({ current, contract }) => !isEqual(current, contract))
    .map(({ current, contract }) => {
      const dispatchRules = chain(contract)
        .get('dispatchRules', [])
        .filter((rule) => {
          const currentRule = get(current, 'dispatchRules', []).find((cr) => cr.accountKey === rule.accountKey);

          return rule.eDocument !== currentRule.eDocument;
        })
        .value();

      return {
        ...contract,
        dispatchRules
      };
    });
}
