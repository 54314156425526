import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAcl } from '@evoja-web/client-acl';
import { withCodeGroups } from '@evoja-web/entity-code';
import moment from 'moment';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import bpfOnboardingActions from '../../actions/Actions';
import { Checkbox, CustomerDetail } from '../../../General/index';
import ValidateOnboardingForm from '../../components/Workguide/Common/ValidateOnboardingForm';
import { withWorkguideInstanceData } from '../../../Workguide/index';

class WorkguideOnboardingClassification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: []
    };

    this.onFormValueChange = this.onFormValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Handle value change
   *
   * @param   {String}  key    Form element id
   * @param   {Mixed}   value  Value
   *
   * @return  void
   */
  onFormValueChange(key, value) {
    this.setState({ [key]: value });
  }

  /**
   * Submit the form
   *
   * @param   {Boolean}  accept  Accept or not
   *
   * @return  void
   */
  onSubmit(accept = false) {
    const { comments } = this.state;
    const {
      acl,
      bpfOnboardingActions,
      bpfTask,
      redirect
    } = this.props;

    bpfOnboardingActions.completeTaskRequest({
      dataKey: get(bpfTask, 'data.task.id'),
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
      variables: {
        onboardingAccepted: accept,
        comments
      }
    });

    redirect();
  }

  /**
   * Render content if onboardingStatus is "yellow"
   *
   * @return {ReactElement} markup
   */
  renderOnboardingStatusYellow() {
    const {
      bpfTask,
      customer,
      environment,
      language,
      session
    } = this.props;

    return (
      <div>
        <Alert type="warning">
          <AlertContent>
            <FormattedMessage id="Onboarding.Classification.Yellow.Warning" />
          </AlertContent>
        </Alert>

        <Row>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="Onboarding.Process.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <p>
          <FormattedMessage id="Onboarding.Process.Descritption" />
        </p>

        <ValidateOnboardingForm
          bpfTask={bpfTask}
          customer={customer}
          language={language}
          onChange={this.onFormValueChange}
          session={session}
          showCustomerInfo={false}
        />
      </div>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      bpfTask,
      codes,
      language,
      redirect,
      customer
    } = this.props;
    const documentTyp = get(codes, 'groups.documentIdType', []).find(
      (c) => Number(c.number) === get(bpfTask, 'data.process.clientIdentification.documentTypeCode', 0)
    );
    const birthDate = moment(get(bpfTask, 'data.process.customerInformation.personalInformation.dateOfBirth')).format('DD.MM.YYYY');

    function getCountryText(country) {
      const code = get(codes, 'groups.country', []).find((c) => get(c, `textShort.${language}`) === country);

      return get(code, `text.${language}`, '-');
    }

    return (
      <div>
        {(
          get(bpfTask, 'data.process.onboardingStatus') === 'yellow'
          || get(bpfTask, 'data.process.clientIdentification.identificationStatus')
        ) && this.renderOnboardingStatusYellow()}

        <Row>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="Onboarding.Classification.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <p>
          <FormattedMessage id="Onboarding.Classification.Descritption" />
        </p>

        <Checkbox
          id="AiaFatca"
          label={<FormattedMessage id="Onboarding.Process.AiaFatca" />}
          value={get(bpfTask, 'data.process.aia', false) || get(bpfTask, 'data.process.fatca', false)}
          disable
        />

        <Row style={{ paddingTop: '25px' }}>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="Onboarding.CustomerInfo.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <CustomerDetail id="Onboarding.CustomerInfo.PartnerNr" value={customer.customerNumberAlternate} />

        <CustomerDetail
          id="Onboarding.CustomerInfo.FirstLastName"
          value={`${get(bpfTask, 'data.process.customerInformation.personalInformation.firstName', '-')} ${get(
            bpfTask,
            'data.process.customerInformation.personalInformation.lastName',
            '-'
          )}`}
        />

        <CustomerDetail id="Onboarding.CustomerInfo.DateOfBirth" value={birthDate} />

        <CustomerDetail
          id="Onboarding.CustomerInfo.Address"
          value={`${get(bpfTask, 'data.process.customerInformation.addressInformation.domicileAddress.street', '-')} ${get(
            bpfTask,
            'data.process.customerInformation.addressInformation.domicileAddress.houseNumber',
            ''
          )}, ${get(bpfTask, 'data.process.customerInformation.addressInformation.domicileAddress.postcode', '-')} ${get(
            bpfTask,
            'data.process.customerInformation.addressInformation.domicileAddress.place',
            '-'
          )}`}
        />

        <CustomerDetail
          id="Onboarding.CustomerInfo.Country"
          value={getCountryText(get(bpfTask, 'data.process.customerInformation.addressInformation.domicileAddress.country'))}
        />

        <CustomerDetail
          id="Onboarding.CustomerInfo.Nationality"
          value={getCountryText(get(bpfTask, 'data.process.customerInformation.personalInformation.nationality'))}
        />

        <Row style={{ paddingTop: '10px', paddingBottom: '25px' }}>
          <Col sm={4}>
            <p>
              <FormattedMessage id="Onboarding.CustomerInfo.Identification.Title" />
            </p>
          </Col>
          <Col sm={8}>
            <CustomerDetail id="Onboarding.CustomerInfo.Identification.DocumentTyp" value={get(documentTyp, `text.${language}`)} />

            <CustomerDetail
              id="Onboarding.CustomerInfo.Identification.CountryOfIssue"
              value={getCountryText(get(bpfTask, 'data.process.clientIdentification.countryKey'))}
            />
          </Col>
        </Row>

        <Alert type="warning" style={{ width: '100%' }}>
          <AlertContent>
            <FormattedMessage id="Onboarding.Warning.Activity" />
          </AlertContent>
        </Alert>

        <Row>
          <Col lg="auto" md="auto" sm="auto">
            <Button
              color="primary"
              disabled={get(bpfTask, 'data.erroneous', false)}
              onClick={() => this.onSubmit(true)}
            >
              <FormattedMessage id="Activity.Form.Submit" />
            </Button>

            {(
              get(bpfTask, 'data.process.onboardingStatus') === 'yellow'
              || get(bpfTask, 'data.process.clientIdentification.identificationStatus')
            ) && (
              <>
                <span style={{ paddingRight: '20px' }} />

                <Button
                  color="primary"
                  disabled={get(bpfTask, 'data.erroneous', false)}
                  onClick={() => this.onSubmit(false)}
                  outline
                >
                  <FormattedMessage id="Activity.Form.Reject" />
                </Button>
              </>
            )}

            <span style={{ paddingRight: '20px' }} />

            <Button color="primary" onClick={redirect} outline>
              <FormattedMessage id="General.Button.Cancel" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

WorkguideOnboardingClassification.propTypes = {
  acl: PropTypes.object.isRequired,
  bpfOnboardingActions: PropTypes.object.isRequired,
  bpfTask: PropTypes.object.isRequired,
  codes: PropTypes.object,
  customer: PropTypes.object,
  environment: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  redirect: PropTypes.func,
  session: PropTypes.object.isRequired
};

WorkguideOnboardingClassification.defaultProps = {
  codes: { groups: {} },
  customer: {},
  redirect: noop
};

function isRequesting(state, ownProps) {
  return (
    get(state, 'codes.requesting', false)
    || get(ownProps, 'codes.requesting', false)
  );
}

function mapStateToProps(state) {
  return {
    environment: state.environment,
    language: state.login.language,
    requesting: isRequesting(state),
    workbenchData: state.workbench.data,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfOnboardingActions: bindActionCreators(bpfOnboardingActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    withCodeGroups({ groups: ['documentIdType', 'customerCheckType', 'country'] })(
      withAcl()(
        injectIntl(WorkguideOnboardingClassification)
      )
    )
  )
);
