import { chain, has, get, isNil } from 'lodash';

import Proto from './LeadBase';
import getDateValues from '../../Utils/getDateValues';
import getExpireDateByDueDate from '../../Utils/getExpireDateByDueDate';
import LeadFormConfigCreate from '../Config/Create';
import LeadFormConfigFactoryCustomerTaskType from '../Config/CustomerTaskTypeFactory';
import { LEAD_FORM_CONTEXT_CREATE } from '../../../constants';
import getDueDateByExpireDate from '../../Utils/getDueDateByExpireDate';

/**
 * Lead form create handler factory
 *
 * @param {Object} acl               Acl instance for permision checks
 * @param {Object} customer          Customer the lead belongs to
 * @param {String} language          User language
 * @param {Object} leadActions       Lead actions (bound)
 * @param {Object} leadQualification Lead qualification from /work/leadqualification
 * @param {Object} mapper            Code mapper instance
 *
 * @return  {Object} proto LeadFormBase instance
 */
export default function LeadFormCreateHandler({
  acl,
  customer,
  language,
  leadActions,
  leadQualification,
  mapper
}) {
  const proto = Proto({
    acl,
    customer,
    language,
    leadActions,
    leadQualification,
    mapper
  });

  // Add the create config to chain
  proto.getConfigChain().push({ id: 'create', config: LeadFormConfigCreate() });

  /* eslint-disable no-use-before-define */
  const handlers = {
    orderOrigin: onOrderOriginChange,
    customerTaskType: onCustomerTaskTypeChange,
    dueDate: onDueDateChange,
    expireDate: onExpireDateChange
  };
  /* eslint-enable no-use-before-define */

  /**
   * Init the lead form with the given data
   *
   * @param   {Object}  data  Initial data to set
   *
   * @return  void
   */
  function init({ data = {} } = {}) {
    leadActions.leadFormInitRequest({ customerId: get(customer, 'id'), data });
  }

  /**
   * Handle form value change.
   * Check if there is a specific handler for the given form element.
   * If not call parent onValueChange as there may be a handler for this value.
   *
   * @param   {String}  key    Form element id
   * @param   {Mixed}   value  Form value
   * @param   {Object}  data  Current form data
   * @param   {Object}  props  Additional props
   *
   * @return  void
   */
  function onValueChange(key, value, data, props = {}) {
    if (!has(handlers, key)) {
      return proto.onValueChange(key, value, data, props);
    }

    return get(handlers, key)(key, value, data, props);
  }

  /**
   * Handle orderOrigin change
   *
   * @param   {String}  key    Form element id
   * @param   {Object}  code   Selected code (group: orderOrigin)
   * @param   {Object}  data  Current form data
   * @param   {Object}  props  Additional props
   *
   * @return  void
   */
  function onOrderOriginChange(key, code, data, props = {}) {
    const { codes } = props;

    const {
      dueDate,
      expireDate,
      minDueDate,
      minExpireDate
    } = getDateValues({
      mapper,
      orderOrigin: code
    });

    // Set min dates
    proto.setMinDueDate(minDueDate);
    proto.setMinExpireDate(minExpireDate);

    const defaultStatus = get(codes, 'groups.customerTaskStatus', []).find((c) => c.id === 'customerTaskStatus-56');

    // Update values via proto as there may be a registered handler that has to do some additional stuff
    proto.onValueChange(key, code, data, props);
    // Reset customerTaskType
    proto.onValueChange('customerTaskType', undefined, data, props);
    // Reset status to default status
    proto.onValueChange('customerTaskStatus', defaultStatus, data, props);
    // Update dueDate and expireDate based on calculates values
    proto.onValueChange('dueDate', dueDate, data, props);
    proto.onValueChange('expireDate', expireDate, data, props);
  }

  /**
   * Handle customerTaskType change.
   * https://issue.swisscom.ch/browse/MAP-7393
   *
   * @param   {String}  key                Form element id
   * @param   {Object}  customerTaskType   Selected code (group: customerTaskType)
   * @param   {Object}  data               Current form data
   * @param   {Object}  props              Additional properties
   *
   * @return  void
   */
  function onCustomerTaskTypeChange(key, customerTaskType, data, props) {
    // Update config change with special config for the given customerTaskType
    const formConfig = LeadFormConfigFactoryCustomerTaskType({ customerTaskType, scope: 'create' });
    proto.getConfigChain().replace({ id: 'customerTaskType', config: formConfig, pushIfNil: true });

    const {
      dueDate,
      expireDate,
      minDueDate,
      minExpireDate
    } = getDateValues({
      customerTaskType,
      mapper,
      orderOrigin: get(data, 'orderOrigin')
    });

    proto.setMinDueDate(minDueDate);
    proto.setMinExpireDate(minExpireDate);

    const leadinstruction = isNil(customerTaskType)
      ? undefined
      : chain(leadQualification)
        .get('data', [])
        .find((c) => c.id === customerTaskType.id)
        .get(`leadinstruction.${language}`)
        .value();

    proto.onValueChange(key, customerTaskType, data, props);
    proto.onValueChange('leadinstruction', leadinstruction, data, props);
    proto.onValueChange('dueDate', dueDate, data, props);
    proto.onValueChange('expireDate', expireDate, data, props);
  }

  /**
   * Handle due date.
   * Calculate expire date depending on the order origin
   *
   * @param   {String}  key    Form element id
   * @param   {String}  value  Due date
   * @param   {Object}  data  Current form data
   * @param   {Object}  props  Additional properties
   *
   * @return  void
   */
  function onDueDateChange(key, value, data, props) {
    const expireDate = getExpireDateByDueDate({
      dueDate: value,
      expireDate: get(data, 'expireDate'),
      mapper,
      orderOrigin: get(data, 'orderOrigin')
    });

    proto.onValueChange(key, value, data, props);
    proto.onValueChange('expireDate', expireDate, data, props);
  }

  /**
   * Handle expire date
   * Calculate due date depending on the order origin
   *
   * @param   {String}  key    Form element id
   * @param   {String}  value  Due date
   * @param   {Object}  data  Current form data
   * @param   {Object}  props  Additional properties
   *
   * @return  void
   */
  function onExpireDateChange(key, value, data, props) {
    const dueDate = getDueDateByExpireDate({
      dueDate: get(data, 'dueDate'),
      expireDate: value,
      mapper,
      orderOrigin: get(data, 'orderOrigin')
    });

    proto.onValueChange(key, value, data, props);
    proto.onValueChange('dueDate', dueDate, data, props);
  }

  return Object.freeze({
    ...proto,
    context: LEAD_FORM_CONTEXT_CREATE,
    init,
    onValueChange
  });
}
