import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isUndefined, noop, isEqual, some } from 'lodash';
import memoizeOne from 'memoize-one';

import './ProfileCompletion.css';
import ProfileCompletionNotFulfilled from '../../components/Workguide/ProfileCompletionNotFulfilled';
import { actions as bpfCmsActions, ProfileCompletionWrapper } from '../../../BpfCms/index';
import freemiumActions from '../../actions/Actions';
import isProfileCompletionFulfilled from '../../../BpfCms/lib/ProfileCompletion/Common/isProfileCompletionFulfilled';

const getMemoizedChecks = memoizeOne((checks) => checks, isEqual);

class SetAdvisorProfileCompletion extends React.Component {
  constructor(props) {
    super(props);
    const {
      customerId, bpfCustomer, checks, onChange, id
    } = props;
    // ugly temp fix
    const value = !isUndefined(bpfCustomer) && isProfileCompletionFulfilled(get(bpfCustomer, 'data', {}), checks);
    onChange(id, value);

    this.state = {
      value
    };
    if (!isUndefined(customerId)) this.onCustomerChange(customerId);

    this.onCustomerChange = this.onCustomerChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      bpfCustomer,
      checks,
      customerId,
      id,
      onChange
    } = this.props;
    const { customerId: prevCustomerId } = prevProps;
    const { value: stateValue } = this.state;
    if (customerId !== prevCustomerId) {
      this.onCustomerChange(customerId);
    }

    const value = !isUndefined(bpfCustomer) && isProfileCompletionFulfilled(get(bpfCustomer, 'data', {}), checks);
    if (some(this.props, (prop, key) => {
      const pp = get(prevProps, key);
      return prop !== pp;
    }) || stateValue !== value) {
      this.setState({ value });
      onChange(id, value);
    }
  }

  componentWillUnmount() {
    const { id, onChange } = this.props;

    onChange(id);
  }

  /**
   * Load necessary data from bpf / graviton
   *
   * @param  {String} customerId  Customer id
   *
   * @return void
   */
  onCustomerChange(customerId) {
    const {
      bpfCmsActions,
      bpfCustomer,
      gravitonCustomer,
      freemiumActions
    } = this.props;

    if (isUndefined(customerId)) return;

    if (isUndefined(bpfCustomer)) {
      bpfCmsActions.customerRequest({ dataKey: customerId });
    }

    if (isUndefined(gravitonCustomer)) {
      freemiumActions.gravitonCustomerRequest({ dataKey: customerId });
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      bpfCustomer,
      checks,
      gravitonCustomer
    } = this.props;

    const notFulfilled = (
      !isUndefined(bpfCustomer)
      && !get(bpfCustomer, 'requesting', false)
      && !isProfileCompletionFulfilled(get(bpfCustomer, 'data', {}), checks)
    );

    return (
      <div className="setadvisor-profile-completion">
        {notFulfilled && (
          <ProfileCompletionNotFulfilled />
        )}

        <ProfileCompletionWrapper
          bpfCustomerData={bpfCustomer}
          customer={gravitonCustomer}
          disabled={false}
          checks={checks}
        />
      </div>
    );
  }
}

SetAdvisorProfileCompletion.propTypes = {
  bpfCmsActions: PropTypes.object.isRequired,
  bpfCustomer: PropTypes.object,
  checks: PropTypes.array,
  customerId: PropTypes.string,
  gravitonCustomer: PropTypes.object,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  freemiumActions: PropTypes.object.isRequired
};

SetAdvisorProfileCompletion.defaultProps = {
  bpfCustomer: undefined,
  checks: undefined,
  customerId: undefined,
  gravitonCustomer: undefined,
  onChange: noop
};

function mapStateToProps(state, ownProps) {
  const { customerId, checks } = ownProps;
  const bpfCustomer = get(state, `bpfCms.customer.${customerId}`);
  const gravitonCustomer = get(state, `freemium.gravitonCustomers.${customerId}`);
  const checkss = getMemoizedChecks(checks);
  return {
    bpfCustomer,
    // Get a memoized version of checks array to prevent rerendering loops
    // https://issue.swisscom.ch/browse/MAP-7768
    checks: checkss,
    gravitonCustomer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch),
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetAdvisorProfileCompletion);
