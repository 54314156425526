import axios from 'axios';
import { get, groupBy } from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import getCustomerCards from '../../../Card/sagas/getCustomerCards';
import { checkMobilePaymentType, productIconMapping } from '../../lib/mobilePaymentTypes';
import cardProducts from '../../actions/Products/cardProducts';

const {
  CARD_PRODUCTS_PENDING,
  CARD_PRODUCTS_FULFILLED,
  CARD_PRODUCTS_REJECTED,

} = cardProducts;
class CardProductFactory {
  constructor(paymentCode) {
    this.paymentCode = paymentCode;
  }

  createMobilePayment(card, cardDetails) {
    const mobilePayments = get(cardDetails, 'mobilePayments', []);
    return get(mobilePayments, 'token_wallets', []).map((mobilePayment) => {
      let mpName = mobilePayment.name.includes('Google Pay') ? 'Google Pay' : mobilePayment.name;
      mpName = mpName.includes('Apple Pay') ? 'Apple Pay' : mpName;

      return {
        type: mpName,
        id: `${mpName.replace(/ /g, '')}_${card.customer.id}`,
        customerDesignation: card.customer.shortDesignationAbbreviation,
        customerId: card.customer.id,
        icon: productIconMapping[mpName],
        sector: this.paymentCode
      };
    }).filter((mp) => checkMobilePaymentType(mp.type));
  }

  createVisecaOneApp(card) {
    return {
      type: 'VisecaOne App',
      id: `VisecaOneApp_${card.customer.id}`,
      customerDesignation: card.customer.shortDesignationAbbreviation,
      customerId: card.customer.id,
      icon: productIconMapping['VisecaOne App'],
      sector: this.paymentCode
    };
  }

  createCardProduct(card) {
    return {
      type: card.cardType.text,
      id: card.id,
      customerDesignation: card.customer.shortDesignationAbbreviation,
      customerId: card.customer.id,
      icon: productIconMapping[get(card, 'cardType.text.de').includes('visa') ? 'visa' : 'mastercard'],
      sector: this.paymentCode
    };
  }
}

export default function* getCardProducts(request) {
  const { dataKey } = request;
  yield put({ type: CARD_PRODUCTS_PENDING, dataKey });
  try {
    const { data: paymentCode } = yield call(axios.get, '/entity/code/sector-10');
    const cards = yield call(getCustomerCards, { dataKey, loadDetails: true });
    const cardsDetails = yield select((state) => get(state, 'viseca.details'));

    const factory = new CardProductFactory(paymentCode);

    const cardMobilePaymentData = cards.flatMap((card) => factory.createMobilePayment(card, get(cardsDetails, `${card.id}.data`)));

    const visecaOneAppData = cards
      .filter((card) => card.state.id === 'cardStatus-6' && card.useVisecaOne)
      .map((card) => factory.createVisecaOneApp(card));

    const cardProducts = cards
      .filter((card) => card.state.id === 'cardStatus-6')
      .map((card) => factory.createCardProduct(card));

    const groupedProducts = groupBy([
      ...cardMobilePaymentData,
      ...visecaOneAppData,
      ...cardProducts
    ], 'sector.id');

    yield put({ type: CARD_PRODUCTS_FULFILLED, dataKey, payload: groupedProducts });
    return groupedProducts;
  } catch (error) {
    yield put({ type: CARD_PRODUCTS_REJECTED, dataKey });
    return error;
  }
}
