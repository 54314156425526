import { DefaultAction } from '@evoja-web/redaction';

export const ADD_NEW_NOTIFICATION = 'redaction/map/notificationCenter/ADD_NEW_NOTIFICATION';
export const notificationAction = DefaultAction('map/notificationCenter', 'NOTIFICATION');

function addNewNotification(props = {}) {
  return { type: ADD_NEW_NOTIFICATION, payload: props };
}
notificationAction.addFunction('addNewNotification', addNewNotification);

notificationAction.addConstant('ADD_NEW_NOTIFICATION', ADD_NEW_NOTIFICATION);

export default notificationAction.get();
