import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isEmpty } from 'lodash';
import { CheckboxGroup, ValidationResult } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import AccountOptionLabel from '../Common/Account/AccountOptionLabel';

const Accounts = React.memo(({
  accounts,
  disabled,
  id,
  language,
  onChange,
  value,
  validations,
  showValidations,
  ignoreValidations
}) => {
  const options = accounts.map((account) => ({
    account,
    value: get(account, 'accountKey'),
    label: <AccountOptionLabel account={account} language={language} />
  }));

  const selected = value.map((a) => get(a, 'accountKey'));

  return (
    <div className="setadvisor-workguide-ebanking-contract-order--accounts">
      {isEmpty(accounts) && (
        <Alert type="warning">
          <AlertContent>
            <FormattedMessage id="Freemium.Workguide.EBankingContract.NoAccounts" />
          </AlertContent>
        </Alert>
      )}
      <CheckboxGroup
        id={id}
        disabled={disabled}
        multi
        onChange={onChange}
        options={options}
        returnValue="account"
        value={selected}
      />
      <ValidationResult
        show={showValidations}
        validations={validations}
        ignore={ignoreValidations}
      />
    </div>
  );
});

Accounts.propTypes = {
  accounts: PropTypes.array,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  onChange: PropTypes.func,
  ignoreValidations: PropTypes.array,
  showValidations: PropTypes.bool,
  validations: PropTypes.object,
  value: PropTypes.array
};

Accounts.defaultProps = {
  accounts: [],
  disabled: false,
  language: 'de',
  onChange: noop,
  ignoreValidations: ['type'],
  showValidations: false,
  validations: {},
  value: []
};

export default Accounts;
