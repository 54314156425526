import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import actions from '../../actions/Actions';
import modalActions from '../../../Modal/actions/Actions';

import {
  toIntIfSet, toStringIfSet,
} from '../../../../lib/Utils/index';

const {
  OPTION_PAPER_FORM_SAVE_PENDING,
  OPTION_PAPER_FORM_SAVE_FULFILLED,
  OPTION_PAPER_FORM_SAVE_REJECTED,
} = actions;

const { LOADING_MODAL_REQUEST, LOADING_MODAL_FULFILLED, LOADING_MODAL_REJECTED } = modalActions;

export default function* saveOptionPaperForm(request) {
  yield put({ type: OPTION_PAPER_FORM_SAVE_PENDING });
  yield put({ type: LOADING_MODAL_REQUEST, dataKey: 'workguideAsync' });

  try {
    const {
      customerId,
      tenant = 6300,
      payload,
      consultantId
    } = request;

    const data = {
      tenantId: tenant,
      resultEnabled: true,
      businessKey: toStringIfSet(customerId),
      variablesInResultEnabled: true,
      variables: {
        clientKey: toIntIfSet(customerId),
        responsibleUser: toIntIfSet(consultantId),
        processCallOrigin: 'MAP',
        ...payload
      }
    };

    const response = yield call(axios, {
      url: `/sif/bpf/public/api/v1/process-definition/key/valiant-product-modulePaper/tenant-id/${tenant}/start`,
      method: 'post',
      data
    });

    const result = get(response, 'data', {});

    yield put({ type: OPTION_PAPER_FORM_SAVE_FULFILLED, payload: result });
    yield put({ type: LOADING_MODAL_FULFILLED, dataKey: 'workguideAsync' });

    return payload;
  } catch (error) {
    yield put({ type: OPTION_PAPER_FORM_SAVE_REJECTED, error });
    yield put({ type: LOADING_MODAL_REJECTED, dataKey: 'workguideAsync', error });

    return error;
  }
}
