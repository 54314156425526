import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import cl from 'classnames';

import './Coverage.css';

const LimitDeterminationCoverage = React.memo(({
  calculation,
  className,
  desiredGlobalLimit
}) => {
  const calculatedGlobalLimit = get(calculation, 'calculatedGlobalLimit', 0);
  const value = calculatedGlobalLimit > 0
    ? (100 / get(calculation, 'calculatedGlobalLimit', 0)) * desiredGlobalLimit
    : 0;

  const classNames = cl(className, 'setadvisor-form-limit-determintation--coverage');

  const sliderClassName = cl({
    'setadvisor-form-limit-determintation--slider': true,
    'setadvisor-form-limit-determintation--slider--warning': value > 100
  });

  const max = value > 100
    ? value
    : 100;

  return (
    <Row className={classNames}>
      <Col
        className="setadvisor-form-limit-determintation--row--label"
        lg={4}
        sm={4}
      >
        <FormattedMessage id="Freemium.Form.CreditCard.Coverage" />
      </Col>

      <Col
        className="setadvisor-form-limit-determintation--row--value"
        lg={6}
        sm={6}
      >
        <Slider
          className={sliderClassName}
          min={0}
          max={max}
          defaultValue={80}
          value={value}
        />
      </Col>

      <Col lg={2} md={2}>
        <span>
          {Math.round(value)}
        </span>

        <span>
          %
        </span>
      </Col>
    </Row>
  );
});

LimitDeterminationCoverage.propTypes = {
  calculation: PropTypes.object,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  desiredGlobalLimit: PropTypes.number
};

LimitDeterminationCoverage.defaultProps = {
  calculation: {},
  className: undefined,
  desiredGlobalLimit: 0
};

export default LimitDeterminationCoverage;
