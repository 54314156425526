import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

import './MonthlyExpenses.css';
import Residential from './MonthlyExpenses/Residential';
import Leasing from './MonthlyExpenses/Leasing';
import ConsumerLoan from './MonthlyExpenses/ConsumerLoan';
import Alimony from './MonthlyExpenses/Alimony';
import calculateItem from '../../../../lib/CreditCard/calculateItem';

class SetAdvisorFormLimitCalculationMonthlyExpense extends React.Component {
  constructor(props) {
    super(props);

    this.onAdd = this.onAdd.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  /**
   * Add a new item to the array
   *
   * @param  {Object} [entry={}] Entry to add
   * @param  {Array}  rest       Rest props
   *
   * @return void
   */
  onAdd(entry, ...rest) {
    const {
      id,
      onChange,
      value
    } = this.props;

    const updated = update(value, {
      $push: [entry]
    });

    onChange(id, updated, ...rest);
  }

  /**
   * Remove all items of the given type from value
   *
   * @param {String} type Expense type
   * @param {Array}  rest Rest args
    *
   * @return void
   */
  onReset(type, ...rest) {
    const {
      id,
      onChange,
      value
    } = this.props;

    const updated = value.filter((item) => get(item, 'expenseType') !== type);

    onChange(id, updated, ...rest);
  }

  /**
   * Add id to the onChange callback
   *
   * @param  {Array} updated Updated values
   * @param  {Array} rest    Rest props
   *
   * @return void
   */
  onChange(type, items, ...rest) {
    const {
      id,
      onChange,
      value
    } = this.props;

    const updated = value
      .filter((item) => get(item, 'expenseType') !== type)
      .concat(items)
      .map((item) => calculateItem(item));

    onChange(id, updated, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { value } = this.props;

    return (
      <div className="setadvisor-form-limit-calculation--monthly-expense">
        <div className="setadvisor-form-limit-calculation--monthly-expense--title">
          <FormattedMessage id="Freemium.Form.CreditCard.MonthlyExpense.Title" />
        </div>

        <div className="setadvisor-form-limit-calculation--monthly-expense--children">
          <Residential
            onAdd={this.onAdd}
            onChange={this.onChange}
            onReset={this.onReset}
            data={value.filter((item) => ['residentialCosts', 'mortgage'].includes(get(item, 'expenseType')))}
          />

          <Leasing
            onAdd={this.onAdd}
            onChange={this.onChange}
            onReset={this.onReset}
            data={value.filter((item) => ['leasing'].includes(get(item, 'expenseType')))}
          />

          <ConsumerLoan
            onAdd={this.onAdd}
            onChange={this.onChange}
            onReset={this.onReset}
            data={value.filter((item) => ['consumerLoan'].includes(get(item, 'expenseType')))}
          />

          <Alimony
            onAdd={this.onAdd}
            onChange={this.onChange}
            onReset={this.onReset}
            data={value.filter((item) => ['alimony'].includes(get(item, 'expenseType')))}
          />
        </div>
      </div>
    );
  }
}

SetAdvisorFormLimitCalculationMonthlyExpense.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array
};

SetAdvisorFormLimitCalculationMonthlyExpense.defaultProps = {
  id: 'monthlyExpenses',
  onChange: noop,
  value: []
};

export default SetAdvisorFormLimitCalculationMonthlyExpense;
