import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';
import { get, isNil } from 'lodash';

import cctToolAction from '../../actions/CreditCard/CctTool';
import uploadFiles from './uploadFiles';

const {
  CREDIT_CARD_CCT_TOOL_PENDING,
  CREDIT_CARD_CCT_TOOL_FULFILLED,
  CREDIT_CARD_CCT_TOOL_REJECTED
} = cctToolAction;

export default function* cctTool(request) {
  yield put({ type: CREDIT_CARD_CCT_TOOL_PENDING });

  try {
    const {
      creditcardOrdered = false,
      comments = [],
      file,
      skipUserCheck = false,
      taskId
    } = request;

    let fileLink;
    if (!isNil(file)) {
      if (isNil(file.file)) {
        // We want to log the files array to be able to debug this one...
        // eslint-disable-next-line no-console
        console.error('Files:', file);
        throw new Error('No file to upload given!');
      }

      yield call(uploadFiles, [file]);
      fileLink = `/file/${get(file, 'id')}`;
    }

    const data = {
      variables: {
        creditcardOrdered,
        comments,
        fileLink
      }
    };

    const payload = yield call(axios, {
      url: `/sif/bpf/public/api/v1/task/${taskId}/complete?skipUserCheck=${skipUserCheck}`,
      method: 'post',
      data: removeUnwantedValues(data, [undefined, null])
    });

    yield put({ type: CREDIT_CARD_CCT_TOOL_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CREDIT_CARD_CCT_TOOL_REJECTED, error });

    return error;
  }
}
