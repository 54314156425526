import { get, head } from 'lodash';

/**
 * Check if the main card category was selected based on cards in the array
 *
 * @param  {Array}   [cards=[]] Array of cards
 *
 * @return {Boolean}
 */
export default function isMainCardCategorySelected(cards = []) {
  return (
    get(cards, 'length', 0) === 1
    && get(head(cards), 'categoryType') === 'HK'
  );
}
