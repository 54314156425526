import { call, put } from 'redux-saga/effects';

import axios from 'axios';
import { has } from 'lodash';
import orderCheckAction from '../../actions/EBankingContract/OrderCheck';

const {
  ORDER_CHECK_PENDING,
  ORDER_CHECK_FULFILLED,
  ORDER_CHECK_REJECTED
} = orderCheckAction;

export default function* orderCheck({ customerId }) {
  yield put({ type: ORDER_CHECK_PENDING });

  try {
    const url = `/sif/bpf/camunda/v7/process-instance/count?processDefinitionKey=valiant-product-orderEBankingContract&businessKey=${customerId}`;
    let { data } = yield call(axios, url);
    // Make sure count is set on the data object
    data = has(data, 'count') ? data : { count: 0 };
    yield put({ type: ORDER_CHECK_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: ORDER_CHECK_REJECTED, error });

    return error;
  }
}
