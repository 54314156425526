import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, isNil } from 'lodash';

import PayloadFactory from './Payload/Order/Factory';
import orderAction from '../../actions/Form/CreditCardOrder';
import modalActions from '../../../Modal/actions/Actions';
import toStringIfSet from '../../../../lib/Utils/toStringIfSet';
import uploadFiles from './uploadFiles';

const {
  CREDIT_CARD_ORDER_FORM_SAVE_PENDING,
  CREDIT_CARD_ORDER_FORM_SAVE_FULFILLED,
  CREDIT_CARD_ORDER_FORM_SAVE_REJECTED
} = orderAction;

const { LOADING_MODAL_REQUEST, LOADING_MODAL_FULFILLED, LOADING_MODAL_REJECTED } = modalActions;

export default function* order(request) {
  yield put({ type: CREDIT_CARD_ORDER_FORM_SAVE_PENDING });
  yield put({ type: LOADING_MODAL_REQUEST, dataKey: 'workguideAsync' });
  try {
    const {
      customer,
      files,
      orderWithoutModule = false,
      tenant = 6300
    } = request;
    const variables = PayloadFactory(request);

    yield call(uploadFiles, files);

    const url = orderWithoutModule
      ? `/sif/bpf/public/api/v1/process-definition/key/valiant-product-orderCreditcard/tenant-id/${tenant}/start`
      : `/sif/bpf/public/api/v1/process-definition/key/valiant-product-moduleCreditcard/tenant-id/${tenant}/start`;

    const payload = yield call(axios, {
      url,
      method: 'post',
      data: {
        variables,
        businessKey: toStringIfSet(get(customer, 'id')),
        resultVariableNames: [
          'callingProcessKeyDE',
          'callingProcessKeyFR',
          'orderCreditcardProcessInstanceId',
          'endStatus'
        ],
        waitTimeoutInMs: 20000
      }
    });

    yield put({ type: CREDIT_CARD_ORDER_FORM_SAVE_FULFILLED, payload });
    yield put({ type: LOADING_MODAL_FULFILLED, dataKey: 'workguideAsync' });

    return payload;
  } catch (error) {
    yield put({ type: CREDIT_CARD_ORDER_FORM_SAVE_REJECTED, error });
    yield put({ type: LOADING_MODAL_REJECTED, dataKey: 'workguideAsync', error });

    return error;
  }
}
