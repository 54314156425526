import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import { SubSection, SubSectionContent } from '../../../../../General/components/SubSection';
import { CustomerSearch } from '../../../../../General';

const OtherCardHolder = React.memo(({
  intl, onOtherCardHolderChange, onPoaAdditionalChange, value, powerOfAttorneys, validations
}) => {
  /**
   * Handle onChange of the poa radio group.
   * Update selected value and set the card holder name
   *
   * @param  {String} key     Form element id
   * @param  {Number} value   Selected option value
   * @param  {Object} option  Selected option
   *
   * @return void
   */
  const onRadioChange = useCallback((key, _, option) => {
    onPoaAdditionalChange(key, get(option, 'poa'));
  }, [onPoaAdditionalChange]);

  const options = powerOfAttorneys.map((poa) => ({
    value: get(poa, 'poaKey'),
    label: get(poa, 'clientDesignationLong'),
    poa
  }));
  options.push({
    label: intl.formatMessage({ id: 'Freemium.Form.CreditCard.CardHolder.OtherHolderLabel' }),
    value: 'otherCardHolder',
    poa: { poaKey: 'otherCardHolder' }
  });

  return (
    <div className="other-card-holder-container">
      <SubSection>
        <SubSectionContent>
          <RadioButtonGroup
            id="poaAdditional"
            options={options}
            onChange={onRadioChange}
            validations={get(validations, 'poaAdditional')}
            value={get(value, 'poaAdditional.poaKey')}
          />

          {get(powerOfAttorneys, 'length', 0) === 0 && (
            <Alert type="error">
              <AlertTitle>
                <FormattedMessage
                  id="Freemium.Form.CreditCard.CardHolder.NoPoasTitle"
                />
              </AlertTitle>
              <AlertContent>
                <FormattedMessage
                  id="Freemium.Form.CreditCard.CardHolder.NoPoasDescription"
                />
              </AlertContent>
            </Alert>
          )}

        </SubSectionContent>
        <div style={{ paddingTop: 10 }}>
          {get(value, 'poaAdditional.poaKey') === 'otherCardHolder'
            && (
              <CustomerSearch
                id="otherCardHolder"
                intl={intl}
                value={get(value, 'otherCardHolder')}
                validations={get(validations, 'otherCardHolder')}
                onChange={onOtherCardHolderChange}
              />

            )}
          <ValidationResult
            show
            validations={validations}
          />
        </div>
      </SubSection>

    </div>
  );
});

OtherCardHolder.propTypes = {
  powerOfAttorneys: PropTypes.array,
  validations: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onOtherCardHolderChange: PropTypes.func.isRequired,
  onPoaAdditionalChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

OtherCardHolder.defaultProps = {
  powerOfAttorneys: [],
  validations: {},
  value: {}
};

export default injectIntl(OtherCardHolder);
