import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { get } from 'lodash';

const AccountOptionLabel = React.memo(({ account, language }) => {
  return (
    <Row className="setadvisor-form-common--account-option-label">
      <Col
        lg={4}
        md={4}
      >
        {get(account, `accountTypeText.${language}`)}
      </Col>

      <Col
        lg={3}
        md={3}
      >
        {get(account, 'editAccountNumber', '')}
      </Col>

      <Col
        className="setadvisor-form-common--account-option-label--customer"
        lg={5}
        md={5}
      >
        {get(account, 'clientDesignation', '')}
      </Col>
    </Row>
  );
});

AccountOptionLabel.propTypes = {
  account: PropTypes.object,
  language: PropTypes.string
};

AccountOptionLabel.defaultProps = {
  account: {},
  language: 'de'
};

export default AccountOptionLabel;
