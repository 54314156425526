import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';
import { get, noop } from 'lodash';

import AddressOptionLabel from '../Common/Address/AddressOptionLabel';

const ShippingAddress = React.memo(({
  form,
  language,
  onChange,
  validations
}) => {
  const options = get(form, 'data.poa.addresses', [])
    .map((address) => ({
      value: get(address, 'addressType.number'),
      label: <AddressOptionLabel address={address} language={language} />,
      address
    }));

  return (
    <div className="setadvisor-workguide-ebanking-contract-order--shipping-address">
      <RadioButtonGroup
        id="shippingAddress"
        onChange={onChange}
        options={options}
        returnValue="address"
        value={get(form, 'data.shippingAddress.addressType.number')}
      />

      <ValidationResult
        show
        validations={get(validations, 'shippingAddress')}
      />
    </div>
  );
});

ShippingAddress.propTypes = {
  form: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object
};

ShippingAddress.defaultProps = {
  form: {},
  language: 'de',
  onChange: noop,
  validations: {}
};

export default ShippingAddress;
