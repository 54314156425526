import { createInstance } from '@evoja-web/indexdb-common';
import { call } from 'redux-saga/effects';

export default function* initIndexDb() {
  try {
    const v1 = {
      consultant: 'id',
      environment: 'id',
      holiday: 'id',
      modified: 'id',
      productGroup: 'id',
      workguide: 'id'
    };

    const v2 = {
      compactable: 'id',
      uiNote: 'id',
      quickFilter: 'dataKey',
    };

    const v3 = {
      visecaTransaction: '++id, dataKey, from, to'
    };

    const v4 = {
      notiicationSubscriptions: 'id'
    };

    // Create the db instance and add stores for current versions
    const db = createInstance({ id: 'map' });
    db.version(1).stores(v1);
    db.version(2).stores(v2);
    db.version(3).stores(v3);
    db.version(4).stores(v4);

    // Open the db
    yield call(db.open);

    return db;
  } catch (error) {
    console.error('Unable to bootstrap index db "map"!');
    console.error(error);

    return false;
  }
}
