import { Reducers } from '@evoja-web/redaction';

import { reducer as cancleLilaSetProcessReducer } from './Customer/CancleLilaSetProcess';
import { reducer as checkOrderReducer } from './EBankingContract/OrderCheck';
import { reducer as connectAccountsToContractReducer } from './Form/ConnectAccountsToContract';
import { reducer as contractDispatchRulesReducer } from './DispatchRule/ContractDispatchRules';
import { reducer as contractDispatchRulesFormReducer } from './DispatchRule/ContractDispatchRulesForm';
import { reducer as contractDispatchRulesPostProcessingFormReducer } from './DispatchRule/ContractDispatchRulesPostProcessingForm';
import { reducer as creditCardApprovalReducer } from './CreditCard/Approval';
import { reducer as creditCardCanceledReducer } from './CreditCard/Canceled';
import { reducer as creditCardCctToolReducer } from './CreditCard/CctTool';
import { reducer as creditCardKubeEditReducer } from './CreditCard/KubeEdit';
import { reducer as creditCardOrderFormReducer } from './Form/CreditCardOrder';
import { reducer as creditCardOrderReducer } from './CreditCard/Order';
import { reducer as customerAccountsReducer } from './Customer/Accounts';
import { reducer as customerCardsReducer } from './Customer/Cards';
import { reducer as customerPoasReducer } from './Customer/Poas';
import { reducer as customerSavingsReducer } from './Customer/Savings';
import { reducer as customerSetReducer } from './Customer/Set';
import { reducer as dissolveLilaSetFormReducer } from './Form/DissolveLilaSetForm';
import { reducer as eBankingKubeEditFormReducer } from './EBankingContract/KubeEditForm';
import { reducer as eBankingOrderFormReducer } from './EBankingContract/OrderForm';
import { reducer as eBankingOrderViaMobileAppFormReducer } from './EBankingContract/DirectActivation/OrderViaMobileApp';
import { reducer as eBankingReactivateFormReducer } from './EBankingContract/DirectActivation/ReactivateForm';
import { reducer as eContractReducer } from './EBankingContract/eContract';
import { reducer as formValidationsReducer } from './Form/Validations';
import { reducer as gravitonCustomerReducer } from './Customer/Graviton';
import { reducer as hasCustomerAccountExpensesReducer } from './Customer/HasCustomerAccountExpenses';
import { reducer as isEligibleForReducedPriceReducer } from './Customer/IsEligibleForReducedPrice';
import { reducer as openAccountFormReducer } from './Form/OpenAccount';
import { reducer as productGroupsReducer } from './Customer/ProductGroups';
import { reducer as productbundleBundleReducer } from './Customer/ProductbundleBundle';
import { reducer as accountProductGroupsReducer } from './Customer/AccountProductGroups';
import { reducer as contractProductGroupsReducer } from './Customer/ContractProductGroups';

import { reducer as loadProductReducer } from './loadLilaSet';
import { reducer as gravitonProductsReducer } from './Products/gravitonProducts';
import { reducer as lilaSetProductsReducer } from './Products/lilaSetProducts';
import { reducer as cardProductReducer } from './Products/cardProducts';
import { reducer as optionComfortFormReducer } from './Form/OptionComfort';
import { reducer as openLilaSetFormReducer } from './Form/OpenNewLilaSet';
import { reducer as openOptionPaperFormReducer } from './Form/OptionPaperForm';
import { reducer as customerProductUsageReducer } from './Customer/ProductUsage';

export const reducers = Reducers([
  cancleLilaSetProcessReducer,
  cancleLilaSetProcessReducer,
  checkOrderReducer,
  connectAccountsToContractReducer,
  contractDispatchRulesReducer,
  contractDispatchRulesFormReducer,
  contractDispatchRulesPostProcessingFormReducer,
  creditCardApprovalReducer,
  creditCardCanceledReducer,
  creditCardCctToolReducer,
  creditCardKubeEditReducer,
  creditCardOrderFormReducer,
  creditCardOrderReducer,
  customerAccountsReducer,
  customerCardsReducer,
  customerPoasReducer,
  customerSavingsReducer,
  customerSetReducer,
  dissolveLilaSetFormReducer,
  eBankingKubeEditFormReducer,
  eBankingOrderFormReducer,
  eBankingOrderViaMobileAppFormReducer,
  eBankingReactivateFormReducer,
  eContractReducer,
  formValidationsReducer,
  gravitonCustomerReducer,
  hasCustomerAccountExpensesReducer,
  isEligibleForReducedPriceReducer,
  openAccountFormReducer,
  productGroupsReducer,
  accountProductGroupsReducer,
  contractProductGroupsReducer,
  productbundleBundleReducer,
  loadProductReducer,
  gravitonProductsReducer,
  lilaSetProductsReducer,
  cardProductReducer,
  optionComfortFormReducer,
  openLilaSetFormReducer,
  openOptionPaperFormReducer,
  customerProductUsageReducer
]);

export default reducers.get();
