import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { get } from 'lodash';
import { connect } from 'react-redux';
import cl from 'classnames';
import { HtmlPreview } from '../../../General';
import './NotificationChild.scss';
import RelativeTimeDisplay from './RelativeTime';

const NotificationChild = memo(({
  notification, contentKey, language, onMarkAsRead, consultants, session
}) => {
  const handleMark = useCallback((e) => {
    e.stopPropagation();
    if (!get(notification, 'read')) {
      onMarkAsRead(get(notification, '_id'));
    }
  }, [notification, onMarkAsRead]);

  const getUsername = useCallback((topic) => {
    const consultant = consultants.find((c) => c.id === topic);
    return get(consultant, 'employeeId', get(consultant, 'shortDesignation'));
  }, [consultants]);

  const canMarkAsRead = get(session, 'id') === get(notification, 'topic');

  const isRead = notification.read;

  return (
    <Row
      className={cl('notification-child g-2 mt-2', {
        'notification-child--read': isRead,
        'notification-child--unread': !isRead
      })}
    >
      <Col xs={11} onClick={handleMark}>
        <Row>
          <Col>
            <p className="notification-child__content">
              <HtmlPreview>
                {notification[contentKey]}
              </HtmlPreview>
            </p>
            <div className="notification-child__meta">
              <span className="notification-child__meta-username">
                {getUsername(get(notification, 'topic'))}
              </span>
              <span className="notification-child__meta-separator">|</span>
              <span>

                <RelativeTimeDisplay timestamp={notification.timeIndex} />
              </span>
            </div>
          </Col>
        </Row>
      </Col>
      {!isRead && canMarkAsRead && (
        <Col xs={1} className="d-flex align-items-center justify-content-end">
          <button
            onClick={handleMark}
            type="button"
            className="notification-child__mark-button"
            aria-label="Mark as read"
            title="Mark as read"
          >
            <i className="mdi mdi-check notification-child__mark-button-icon" />
          </button>
        </Col>
      )}
    </Row>
  );
});

NotificationChild.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    contentDe: PropTypes.string,
    contentFr: PropTypes.string,
    timeIndex: PropTypes.number.isRequired,
    read: PropTypes.bool
  }).isRequired,
  language: PropTypes.oneOf(['fr', 'de']),
  onMarkAsRead: PropTypes.func.isRequired,
  contentKey: PropTypes.string,
  consultants: PropTypes.array,
  session: PropTypes.object.isRequired
};

NotificationChild.defaultProps = {
  language: 'de',
  contentKey: 'contentDe',
  consultants: []
};

function mapStateToProps(state) {
  return {
    consultants: state.consultantSearch.activeConsultants,
    session: state.login.session,
  };
}

export default connect(mapStateToProps)(NotificationChild);
