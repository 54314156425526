export const CONST_MOBILE_PAYMENTS = {
  ApplePay: 'Apple Pay',
  SamsungPay: 'Samsung Pay',
  FitbitPay: 'Fitbit Pay',
  GarminPay: 'Garmin Pay',
  'SwatchPAY!': 'SwatchPAY!',
  GooglePay: 'Google Pay',
  PayPal: 'PayPal',
  ClickToPay: 'ClickToPay'
};

export const productIconMapping = {
  [CONST_MOBILE_PAYMENTS.GooglePay]: '/images/products/googlePay.svg',
  [CONST_MOBILE_PAYMENTS.ApplePay]: '/images/products/applePay.svg',
  [CONST_MOBILE_PAYMENTS.PayPal]: '/images/products/paypal.svg',
  [CONST_MOBILE_PAYMENTS.SamsungPay]: '/images/products/samsungPay.svg',
  [CONST_MOBILE_PAYMENTS.ClickToPay]: '/images/products/clickToPay.svg',
  'VisecaOne App': '/images/products/visecaOne.svg',
  'Mobile Banking': '/images/products/mobileBanking.svg',
  twint: '/images/products/twint.svg',
  eBill: '/images/products/eBill.svg',
  visa: '/images/products/visa.svg',
  mastercard: '/images/products/mastercard.svg',
  eBanking: '/images/products/eBanking.svg',
  lilaSet: '/images/products/lilaSet.png'
};

export const checkMobilePaymentType = (mpName) => {
  return Object.values(CONST_MOBILE_PAYMENTS).includes(mpName);
};
