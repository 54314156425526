import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, has, isUndefined, noop } from 'lodash';
import update from 'immutability-helper';
import { removeUnwantedProperties } from '@evoja-web/redux-saga-utils';

import ProfileCompletion from '../../../../containers/Workguide/ProfileCompletion';
import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';

import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../General/components/SubSection/index';
import AddressType from './AddressType';
import EmbossedLine from './EmbossedLine';
import RelationshipToMainCardHolder from './RelationshipToMainCardHolder';
import { normalizeEmbossedLine } from '../../../../lib/CreditCard';
import OtherCardHolder from './OtherCardHolder';

class CardHolderAdditional extends React.PureComponent {
  constructor(props) {
    super(props);

    this.updateCards = this.updateCards.bind(this);
    this.onOtherCardHolderChange = this.onOtherCardHolderChange.bind(this);
    this.onPoaAdditionalChange = this.onPoaAdditionalChange.bind(this);
  }

  /**
   * update otherCardHolder prop
   */
  onOtherCardHolderChange(key, value) {
    const { onChange, form } = this.props;
    const current = get(form, 'data.additionalHolder', {});
    onChange('additionalHolder', update(current, {
      otherCardHolder: { $set: value }
    }));
    const clientDesignationNormalized = has(value, 'lastName')
      ? normalizeEmbossedLine(`${get(value, 'firstName')} ${get(value, 'lastName')}`) : '';
    this.updateCards(value, clientDesignationNormalized);
  }

  /**
   * update otherCardHolder prop
   */
  onPoaAdditionalChange(key, value) {
    const { onChange, form } = this.props;
    const current = get(form, 'data.additionalHolder', {});
    onChange('additionalHolder', update(current, {
      poaAdditional: { $set: value },
      otherCardHolder: { $set: undefined }
    }));
    // dont update cards when otherCardHolder radio button is selected
    if (get(value, 'poaKey') === 'otherCardHolder') return;
    const clientDesignationNormalized = normalizeEmbossedLine(get(value, 'clientDesignation'));
    this.updateCards(value, clientDesignationNormalized);
  }

  /**
   * update card with the client designation from additionalHolder data
   * @param {*} value
   * @param {*} clientDesignationNormalized
   */
  updateCards(value, clientDesignationNormalized) {
    const { onChange, form } = this.props;

    const cards = get(form, 'data.cards', []);
    const index = cards.findIndex((card) => get(card, 'categoryType') === 'ZK');
    const cardsUpdated = update(cards, {
      [index]: { embossedLineOne: { $set: clientDesignationNormalized } }
    });

    // Change Cards
    onChange('cards', cardsUpdated);

    /**
     * This check is necessary because the profile completion component
     * is only shown when the poaType is 102, but any other poaType is also valid.
     */
    if (get(value, 'poaType') !== 102) {
      onChange('additionalCardHolderProfileCompletion', true);
    }
  }

  render() {
    const {
      codes,
      form,
      language,
      onChange,
      powerOfAttorneys,
      validations,
      bpfCustomers
    } = this.props;

    const cards = get(form, 'data.cards', []);
    const index = cards.findIndex((card) => get(card, 'categoryType') === 'ZK');
    const profileCompletionCustomerId = get(form, 'data.additionalHolder.poaAdditional.clientKey');
    const otherCardHolder = get(form, 'data.additionalHolder.otherCardHolder');
    const otherCardHolderAddresse = !isUndefined(otherCardHolder)
      ? get(bpfCustomers, `${get(otherCardHolder, 'id')}.data.clientAddress`)
      : undefined;
    const powerOfAttorneysAdditionalCard = powerOfAttorneys.filter((poa) => ['HOLDER', 'AUTHORISED_PERSON'].includes(get(poa, 'legitimation')));
    return (
      <Section className="setadvisor-form-credit-card-general--card-holder-additional">
        <SectionTitle>
          <FormattedMessage id="Freemium.Form.CreditCard.CardHolderAdditional.Title" />
        </SectionTitle>

        <SectionContent>
          <OtherCardHolder
            id="additionalHolder"
            powerOfAttorneys={powerOfAttorneysAdditionalCard}
            value={get(form, 'data.additionalHolder')}
            onPoaAdditionalChange={this.onPoaAdditionalChange}
            onOtherCardHolderChange={this.onOtherCardHolderChange}
            validations={get(validations, 'additionalHolder')}
          />

          {get(form, 'data.additionalHolder.poaAdditional.poaType') === 102 && (
            <SubSection>
              <SubSectionTitle>
                <FormattedMessage id="Freemium.Form.CreditCard.CardHolder.ProfileCompletion" />
              </SubSectionTitle>

              <SubSectionContent>
                <ProfileCompletion
                  checks={['identificationDocument']}
                  customerId={profileCompletionCustomerId}
                  id="additionalCardHolderProfileCompletion"
                  onChange={onChange}
                />
              </SubSectionContent>
            </SubSection>
          )}
          {!isUndefined(get(form, 'data.additionalHolder.otherCardHolder')) && (
            <SubSection>
              <SubSectionTitle>
                <FormattedMessage id="Freemium.Form.CreditCard.CardHolder.ProfileCompletion" />
              </SubSectionTitle>

              <SubSectionContent>
                <ProfileCompletion
                  checks={['identificationDocument']}
                  customerId={get(form, 'data.additionalHolder.otherCardHolder.id')}
                  id="additionalCardHolderProfileCompletion"
                  onChange={onChange}
                />
              </SubSectionContent>
            </SubSection>
          )}

          <RelationshipToMainCardHolder
            codes={codes}
            id="relationshipToMainCardHolder"
            language={language}
            onChange={onChange}
            validations={get(validations, 'relationshipToMainCardHolder')}
            value={get(form, 'data.relationshipToMainCardHolder')}
          />

          <EmbossedLine
            cards={cards}
            categoryType="ZK"
            id="embossedLineOne"
            onChange={onChange}
            validations={get(validations, `cards.${index}.embossedLineOne`)}
          />

          <AddressType
            addresses={removeUnwantedProperties(
              [
                ...get(form, 'data.additionalHolder.poaAdditional.addresses', []),
                otherCardHolderAddresse],
              [undefined]
            )}
            codes={codes}
            id="addressTypeAdditional"
            language={language}
            onChange={onChange}
            validations={get(validations, 'addressTypeAdditional')}
            value={get(form, 'data.addressTypeAdditional')}
          />
        </SectionContent>
      </Section>
    );
  }
}

CardHolderAdditional.propTypes = {
  codes: PropTypes.object,
  form: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  powerOfAttorneys: PropTypes.array,
  validations: PropTypes.object,
  bpfCustomers: PropTypes.object
};

CardHolderAdditional.defaultProps = {
  codes: { groups: {} },
  form: {},
  language: 'de',
  onChange: noop,
  powerOfAttorneys: [],
  bpfCustomers: {},
  validations: {}
};

export default CardHolderAdditional;
