import React from 'react';
import PropTypes from 'prop-types';
import { HtmlPreview } from '../../packages/General';

const Textblock = React.memo(({ children }) => {
  return (
    <HtmlPreview>
      {children}
    </HtmlPreview>
  );
});

Textblock.propTypes = {
  children: PropTypes.node
};

Textblock.defaultProps = {
  children: []
};

export default Textblock;
