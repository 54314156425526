import { call, put } from 'redux-saga/effects';

import accountsAction from '../../actions/Customer/AccountProductGroups';
import getProductGroups from './getProductGroups';

const {
  ACCOUNT_PRODUCT_GROUPS_PENDING,
  ACCOUNT_PRODUCT_GROUPS_FULFILLED,
  ACCOUNT_PRODUCT_GROUPS_REJECTED
} = accountsAction;

export default function* getAccountProductGroups(request) {
  const { dataKey } = request;

  yield put({ type: ACCOUNT_PRODUCT_GROUPS_PENDING, dataKey });

  try {
    const { tenant = 6300 } = request;

    const payload = yield call(getProductGroups, {
      customerId: dataKey,
      tenant,
      productNumbers: [90, 110, 2000, 2011, 2010, 2021, 2031],
      productType: 'Account'
    });

    yield put({ type: ACCOUNT_PRODUCT_GROUPS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: ACCOUNT_PRODUCT_GROUPS_REJECTED, dataKey, error });

    return error;
  }
}
