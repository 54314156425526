import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader
} from 'reactstrap';
import cl from 'classnames';
import { get, noop } from 'lodash';

import './Header.css';
import { withAcl } from '@evoja-web/client-acl';
import { FormattedMessage } from 'react-intl';
import Logo from '../../../components/Layout/Header/Logo';
import Links from '../../../components/Layout/Header/Links';
import Powersearch from './Powersearch/Powersearch';
import UserProfile from './UserProfile';
import ToastHandler from '../../../../../components/General/ToastHandler';
import Notification from '../../../../NotificationCenter/containers/NotificationOverview';
import { withNavIndicator } from '../../../../CustomerDetail/hooks/useNavNotifications';

function openNewTab() {
  window.open(window.location.href, '_blank');
}
class LayoutHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isSearchFocused: false,
      notificationCenterOpen: false,
    };

    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.onSearchFocus = this.onSearchFocus.bind(this);
    this.onSearchBlur = this.onSearchBlur.bind(this);
    this.toggleNotificationCenter = this.toggleNotificationCenter.bind(this);
  }

  onSearchFocus() {
    this.setState({ isSearchFocused: true });
  }

  onSearchBlur() {
    this.setState({ isSearchFocused: false });
  }

  toggleNavigation() {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  toggleNotificationCenter() {
    const { notificationCenterOpen } = this.state;

    const { removeNavIndicator } = this.props;

    this.setState({ notificationCenterOpen: !notificationCenterOpen });
    // stop pusling badge
    if (!notificationCenterOpen) {
      removeNavIndicator('notification-center');
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { isOpen, isSearchFocused, notificationCenterOpen } = this.state;
    const {
      toast,
      language,
      notificationBadgeCount,
      acl,
      activeNavIndicators
    } = this.props;

    return (
      <div className="layout-header">
        <Navbar
          className="layout-header-navbar"
          fixed="top"
          expand="md"
        >
          <Logo />

          <NavbarToggler onClick={this.toggleNavigation} />

          <Collapse isOpen={isOpen} navbar>
            <Nav
              className={cl({
                'layout-header-navbar--nav': true,
                'layout-header-navbar--nav--hidden': isSearchFocused,
                'layout-header-navbar--nav--visible': !isSearchFocused
              })}
              navbar
              tag="div"
            >
              <Links />
            </Nav>

            <Nav
              className="ms-auto layout-header-navbar--nav"
              navbar
              tag="div"
            >
              <NavItem
                className="layout-header-navbar--nav--powersearch"
                tag="div"
              >
                <Powersearch
                  onFocus={this.onSearchFocus}
                  onBlur={this.onSearchBlur}
                />
              </NavItem>

              <NavItem
                className="layout-header-navbar--nav--open-new-tab"
                onClick={openNewTab}
                tag="div"
              >
                <span className="mdi mdi-plus open-new-tab-icon" />
              </NavItem>
              {acl.isAllowed('NotificationCenter.ShowNotificationCenter') && (
              <>
                <NavItem className="layout-header-navbar--nav--notification-center" onClick={this.toggleNotificationCenter}>
                  <div className="notification-icon">
                    <i className="mdi mdi-bell-outline" style={{ fontSize: 22 }} />
                    {notificationBadgeCount > 0 && (
                    <div className={`notification-badge ${notificationBadgeCount >= 100 ? 'notification-badge-small-text' : ''}`}>
                      {activeNavIndicators.includes('notification-center') && (
                      <div className="sploosh-container">
                        <div className="sploosh-ring" />
                        <div className="sploosh-ring sploosh-ring-delay" />
                      </div>
                      )}
                      <span className="badge-text">{notificationBadgeCount}</span>
                    </div>
                    )}
                  </div>
                </NavItem>
                {/* Ensure the backdrop is hidden when .nc-hide is active.
                This is necessary because the Offcanvas component remains mounted at all times.
                Instead of unmounting, we only hide it to prevent unnecessary re-renders
                when opening and closing the notification center. */
                }
                <Offcanvas
                  isOpen
                  toggle={this.toggleNotificationCenter}
                  direction="end"
                  className={`notification-center-container ${notificationCenterOpen ? 'nc-show' : 'nc-hide'}`}
                >
                  <OffcanvasHeader toggle={this.toggleNotificationCenter}>
                    <FormattedMessage id="NotificationCenter.Header.Title" />
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <Notification requesting={false} />
                  </OffcanvasBody>
                </Offcanvas>
              </>
              )}

              <NavItem
                className="layout-header-navbar--nav--user-profile"
                tag="div"
              >
                <UserProfile language={language} />
              </NavItem>

              {/* <NavItem
                className="layout-header-navbar--nav--news"
                tag="div"
              >
                <News />
              </NavItem> */}
            </Nav>
          </Collapse>
        </Navbar>

        <ToastHandler data={toast} />
      </div>
    );
  }
}

LayoutHeader.propTypes = {
  language: PropTypes.string,
  toast: PropTypes.object,
  notificationBadgeCount: PropTypes.number,
  acl: PropTypes.object.isRequired,
  activeNavIndicators: PropTypes.array,
  removeNavIndicator: PropTypes.func
};

LayoutHeader.defaultProps = {
  language: 'de',
  toast: {},
  notificationBadgeCount: 0,
  activeNavIndicators: [],
  removeNavIndicator: noop
};

function mapStateToProps(state) {
  return {
    toast: state.toast,
    textblock: get(state, 'textblock.textblock.technicalSupport'),
    language: state.login.language,
    notificationBadgeCount: state.notificationCenter.badgeCount.count
  };
}

export default connect(mapStateToProps)(
  withAcl()(withNavIndicator(LayoutHeader))
);
