import { FormReducer } from '@evoja-web/redaction';
import { get, isUndefined } from 'lodash';
import update from 'immutability-helper';

import action, { creditCardOrderAction } from '../../actions/Form/CreditCardOrder';
import calculateLimit from '../../lib/CreditCard/calculateLimit';
import calculateItem from '../../lib/CreditCard/calculateItem';

const {
  CREDIT_CARD_ORDER_FORM_INIT_FULFILLED,
  CREDIT_CARD_ORDER_FORM_SET_VALUE
} = action;

function recalculateLimit(state) {
  const data = get(state, 'creditCardOrderForm.data', {});
  const taxes = get(data, 'monthlyExpenses', []).find((item) => get(item, 'expenseType') === 'taxes');
  let calculated = calculateLimit(data);

  // Recalculate taxes as totalMonthlyIncome may have changed
  let monthlyExpenses = get(data, 'monthlyExpenses', []);
  if (!isUndefined(taxes)) {
    const index = get(data, 'monthlyExpenses', []).findIndex((item) => get(item, 'expenseType') === 'taxes');

    monthlyExpenses = update(get(data, 'monthlyExpenses', []), {
      $splice: [[index, 1, calculateItem({ ...taxes, amount: get(calculated, 'totalMonthlyIncomes', 0) })]]
    });

    // Recalculate with new monthly expenses
    calculated = calculateLimit({
      ...data,
      monthlyExpenses
    });
  }

  return update(state, {
    creditCardOrderForm: {
      data: {
        monthlyExpenses: { $set: monthlyExpenses }
      }
    },
    creditCardLimitCalculation: { $set: calculated }
  });
}

export const reducer = FormReducer('creditCardOrderForm', creditCardOrderAction);
reducer.after(CREDIT_CARD_ORDER_FORM_INIT_FULFILLED, recalculateLimit);
reducer.after(CREDIT_CARD_ORDER_FORM_SET_VALUE, recalculateLimit);

export default reducer.get();
