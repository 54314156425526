import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { get, noop } from 'lodash';

import './ListItem.css';
import { NumberField } from '../../../../../../General/index';
import { getValidator } from '../../../../../../../globals';

class AmountListItem extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  /**
   * Handle value change
   *
   * @param  {String} key   Form element key (id)
   * @param  {Mixed}  value Updated value
   * @param  {Array}  rest  Rest props
   *
   * @return void
   */
  onValueChange(key, value, ...rest) {
    const {
      index,
      onChange
    } = this.props;

    onChange(index, key, value, ...rest);
  }

  /**
   * Remove this item
   *
   * @param  {Array}  args  Rest props
   *
   * @return void
   */
  onRemove(...args) {
    const {
      index,
      onRemove
    } = this.props;

    onRemove(index, ...args);
  }

  /**
   * Validate current data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const { data } = this.props;

    const validator = getValidator();
    const definition = {
      validations: {
        amount: {
          type: 'number',
          required: true,
          validations: {
            isGe: [0],
            isInt: []
          }
        }
      }
    };

    return validator.validate(definition, data);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      data,
      label,
      removeable
    } = this.props;

    const validations = this.validate();

    return (
      <Row className="setadvisor-limit-calculation--amount-list--item">
        <Col
          className="setadvisor-limit-calculation--amount-list--item--label"
          lg={4}
          md={4}
        >
          {label}
        </Col>

        <Col
          className="setadvisor-limit-calculation--amount-list--item--input"
          lg={7}
          md={7}
        >
          <NumberField
            id="amount"
            onChange={this.onValueChange}
            showValidations
            validations={get(validations, 'amount')}
            value={get(data, 'amount')}
          />
        </Col>

        {removeable && (
          <Col
            className="setadvisor-limit-calculation--amount-list--item--delete"
            lg={1}
            md={1}
          >
            <span
              className="mdi mdi-minus-circle-outline pointer"
              onClick={this.onRemove}
            />
          </Col>
        )}
      </Row>
    );
  }
}

AmountListItem.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  removeable: PropTypes.bool
};

AmountListItem.defaultProps = {
  data: {},
  label: '',
  onChange: noop,
  onRemove: noop,
  removeable: false
};

export default AmountListItem;
