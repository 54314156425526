import { get } from 'lodash';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';

import AdditionalCardHolder from './AdditionalCardHolder';
import toIntIfSet from '../../../../../../lib/Utils/toIntIfSet';

export default function OrderAdditionalCardPayload({
  form,
  session
}) {
  return removeUnwantedValues({
    responsibleUser: toIntIfSet(get(session, 'id')),
    processCallOrigin: 'MAP',
    creditCardAccount: {
      accountKey: get(form, 'data.connectionAccount.accountKey'),
      clientKey: get(form, 'data.connectionAccount.clientKey')
    },
    beneficialOwner: get(form, 'data.beneficialOwner'),
    debitAccountForFee: get(form, 'data.debitAccount.accountKey'),
    paymentType: get(form, 'data.paymentType'),
    additionalCardHolder: AdditionalCardHolder({ form })
  }, [undefined]);
}
