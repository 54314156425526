import React from 'react';
import PropTypes from 'prop-types';

import CreditCardOrder from './Order';

const CreditCardOrderWithoutModule = React.memo((props = {}) => {
  return (
    <CreditCardOrder
      {...props}
      titleId="Freemium.Workguide.CreditCard.TitleWithoutModule"
      alwaysEnableMainCard
      alwaysEnableMainAndAdditionalCard
      alwaysEnableDcCard
      orderWithoutModule
      showCctToolOption={false}
      showCurrentCards
      showDebitAccountSelection={false}
      additionalConnectionAccountTypes={[141, 1]}
    />
  );
});

CreditCardOrderWithoutModule.propTypes = {

};

CreditCardOrderWithoutModule.defaultProps = {

};

export default CreditCardOrderWithoutModule;
