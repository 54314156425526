import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';
import CustomerDocuments from '../../../../EDossier/containers/CustomerDocuments';

const Documents = React.memo(({ customers }) => {
  const children = get(customers, 'data', []).map((customer) => {
    return (
      <CustomerDocuments
        customerNumber={get(customer, 'customerNumber')}
      />
    );
  });

  return (
    <div>
      <Label>
        <strong>
          <FormattedMessage id="SecuritiesAccount.Unlock.Form.Documents" />
        </strong>
      </Label>

      {children}
    </div>
  );
});

Documents.propTypes = {
  customers: PropTypes.object
};

Documents.defaultProps = {
  customers: {}
};

export default Documents;
