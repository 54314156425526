import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from '@evoja-web/react-form';
import { get, noop } from 'lodash';
import { Row, Col } from 'reactstrap';
import cl from 'classnames';
import update from 'immutability-helper';

import './IndividualCardLimit.css';
import { NumberField } from '../../../../../General/index';

class IndividualCardLimit extends React.Component {
  constructor(props) {
    super(props);

    this.onActiveChange = this.onActiveChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle active change
   *
   * @param  {Array} args Args from click event
   *
   * @return void
   */
  onActiveChange(key, value, ...rest) {
    const {
      active,
      id,
      onChange
    } = this.props;

    onChange(`${id}Active`, value, ...rest);

    // If current active (switches to incactive), unset cardLimit on cards
    if (active) {
      this.unsetLimitOnCards();
    }
  }

  /**
   * Handle input change
   *
   * @param  {String} key   Form element id
   * @param  {Number} value Input value
   *
   * @return {[type]}       [description]
   */
  onChange(key, value) {
    const {
      cards,
      categoryType,
      onChange
    } = this.props;

    const updated = cards.map((card) => {
      return get(card, 'categoryType') === categoryType
        ? update(card, {
          cardLimit: { $set: value }
        })
        : card;
    });

    onChange('cards', updated);
  }

  /**
   * Unset limit on cards with the given category type and call onChange
   *
   * @return void
   */
  unsetLimitOnCards() {
    const {
      cards,
      categoryType,
      onChange
    } = this.props;

    const updated = cards.map((card) => {
      return get(card, 'categoryType') === categoryType
        ? update(card, {
          $unset: ['cardLimit']
        })
        : card;
    });

    onChange('cards', updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      active,
      cards,
      categoryType,
      className,
      id,
      label,
      validations
    } = this.props;

    const classNames = cl(className, 'setadvisor-form-credit-card--individual-card-limit');
    const index = cards.findIndex((card) => get(card, 'categoryType') === categoryType);

    return (
      <div className={classNames}>
        <Row>
          <Col
            className="setadvisor-form-credit-card--individual-card-limit--toggle"
            lg={12}
            md={12}
          >
            <Toggle
              onChange={this.onActiveChange}
              value={active}
            >
              {label}
            </Toggle>

          </Col>
        </Row>

        {active && (
          <Row style={{ paddingTop: '1rem' }}>
            <Col lg={12} md={12}>
              <NumberField
                disabled={!active}
                id={id}
                onChange={this.onChange}
                showValidations
                validations={get(validations, `cards.${index}.cardLimit`)}
                value={get(cards, `${index}.cardLimit`)}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

IndividualCardLimit.propTypes = {
  active: PropTypes.bool,
  cards: PropTypes.array,
  categoryType: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  onChange: PropTypes.func,
  validations: PropTypes.object
};

IndividualCardLimit.defaultProps = {
  active: false,
  cards: [],
  className: undefined,
  label: '',
  onChange: noop,
  validations: {}
};

export default IndividualCardLimit;
