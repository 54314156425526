import { call, put, select, all } from 'redux-saga/effects';
import { get, isUndefined } from 'lodash';

import action from '../../actions/Form/CreditCardOrder';
import getAccounts from '../Customer/getAccounts';
import getCards from '../Customer/getCards';
import getPoas from '../Customer/getPoas';
import getPartners from '../../../BpfCms/sagas/Customer/getPartners';
import isDoublePartner from '../../../../lib/Customer/Utils/isDoublePartner';
import toIntIfSet from '../../../../lib/Utils/toIntIfSet';

const {
  CREDIT_CARD_ORDER_FORM_INIT_PENDING,
  CREDIT_CARD_ORDER_FORM_INIT_FULFILLED,
  CREDIT_CARD_ORDER_FORM_INIT_REJECTED,
  CREDIT_CARD_ORDER_FORM_RESET
} = action;

function Data({ customer, partners = [] }) {
  const fixedCostsMultiplier = isDoublePartner(customer) ? 2 : 1;

  const monthlyIncomes = isDoublePartner(customer)
    ? partners.map((partner) => ({
      clientKey: toIntIfSet(get(partner, 'id')),
      incomeType: 'employmentGross',
      amount: undefined,
      proofOfIncome: undefined,
      fileLink: undefined,
      file: undefined
    }))
    : [{
      clientKey: toIntIfSet(get(customer, 'id')),
      incomeType: 'employmentGross',
      amount: undefined,
      proofOfIncome: undefined,
      fileLink: undefined,
      file: undefined
    }];

  const monthlyExpenses = [{
    expenseType: 'residentialCosts',
    amount: undefined,
    multiplier: 1,
    costs: undefined
  }, {
    expenseType: 'fixedCosts',
    amount: 500,
    multiplier: fixedCostsMultiplier,
    costs: fixedCostsMultiplier * 500
  }, {
    expenseType: 'taxes',
    amount: 0,
    multiplier: 0.15,
    costs: 0
  }];

  return {
    monthlyIncomes,
    monthlyExpenses,
    paymentType: 30,
    addressTypeMain: '11',
    addressTypeAdditional: '11'
  };
}

export default function* creditCardOrderFormInit(request) {
  const { data, additionalConnectionAccountTypes = [] } = request;

  try {
    const { customer } = request;

    // Customer is required. Do nothing if it is undefined
    if (isUndefined(customer)) return undefined;

    // Reset form and set init to pending
    yield put({ type: CREDIT_CARD_ORDER_FORM_RESET });
    yield put({ type: CREDIT_CARD_ORDER_FORM_INIT_PENDING });

    const customerId = get(customer, 'id');

    // Load necessary data.
    // Always do this (even if it is not necessary) as we don't know if data changed somewhere else.
    // https://issue.swisscom.ch/browse/MAP-6874
    yield all([
      call(getAccounts, { dataKey: customerId, accountTypes: [90, 110, 140, ...additionalConnectionAccountTypes] }),
      call(getCards, { dataKey: customerId, cardVarieties: [517, 506, 401, 404] }),
      call(getPoas, { dataKey: customerId })
    ]);

    // This is a long running call and partners should not change that often.
    // Lets keep this for now (try to get it from state and only load them if necessary).
    // If this leads to problems, always load them...
    let partners = yield select((state) => get(state, `bpfCms.partners.${customerId}.data`));
    if (isDoublePartner(customer) && (!get(partners, 'requesting', false) && !get(partners, 'fulfilled', false))) {
      partners = yield call(getPartners, { dataKey: customerId });
    }

    const payload = {
      ...data,
      ...Data({ customer, partners })
    };

    yield put({ type: CREDIT_CARD_ORDER_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CREDIT_CARD_ORDER_FORM_INIT_REJECTED, error });

    return error;
  }
}
