import { call, put } from 'redux-saga/effects';

import { isArray, isUndefined, uniqBy } from 'lodash';
import { getInstance as getDbInstance } from '@evoja-web/indexdb-common';

import notificationAction from '../actions/NotificationCenterSubscription';

const {
  NOTIFICATION_CENTER_SUBSCRIPTION_SAVE_PENDING,
  NOTIFICATION_CENTER_SUBSCRIPTION_SAVE_FULFILLED,
  NOTIFICATION_CENTER_SUBSCRIPTION_SAVE_REJECTED,
  NOTIFICATION_CENTER_SUBSCRIPTION_INIT_FULFILLED
} = notificationAction;

export default function* notificationCenterSubscriptionInitRequest(request) {
  const { topics } = request;
  yield put({ type: NOTIFICATION_CENTER_SUBSCRIPTION_SAVE_PENDING });

  try {
    if (!isArray(topics) || isUndefined(topics)) {
      throw new Error('The provided topics are not a array or empty');
    }

    const updatedSubscriptions = uniqBy(topics, 'id');

    const db = getDbInstance({ id: 'map' });
    yield call(db.bulkUpdate, { table: 'notiicationSubscriptions', data: updatedSubscriptions, clear: true });

    yield put({ type: NOTIFICATION_CENTER_SUBSCRIPTION_SAVE_FULFILLED, payload: updatedSubscriptions });
    yield put({ type: NOTIFICATION_CENTER_SUBSCRIPTION_INIT_FULFILLED, payload: updatedSubscriptions });
    return updatedSubscriptions;
  } catch (error) {
    yield put({ type: NOTIFICATION_CENTER_SUBSCRIPTION_SAVE_REJECTED, error });

    return error;
  }
}
