import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isUndefined, findKey } from 'lodash';

import Row from './Row';
import {
  isMainCardCategorySelected,
  isMainAndAdditionalCardCategorySelected,
  isAdditionalCardCategorySelected,
  isComboCardCategorySelected
} from '../../../../../lib/CreditCard/index';

const CategoryType = React.memo(({ cards }) => {
  const messages = {
    'Freemium.Form.CreditCard.CardCategory.Main': isMainCardCategorySelected(cards),
    'Freemium.Form.CreditCard.CardCategory.MainAndAdditional': isMainAndAdditionalCardCategorySelected(cards),
    'Freemium.Form.CreditCard.CardCategory.Additional': isAdditionalCardCategorySelected(cards),
    'Freemium.Form.CreditCard.CardCategory.Combo': isComboCardCategorySelected(cards)
  };

  const message = findKey(messages, (value) => value === true);
  const id = !isUndefined(message)
    ? message
    : 'Freemium.Form.Summary.Card.CategoryTypeUndefined';

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.CategoryType" />}>
      <FormattedMessage id={id} />
    </Row>
  );
});

CategoryType.propTypes = {
  cards: PropTypes.array
};

CategoryType.defaultProps = {
  cards: []
};

export default CategoryType;
