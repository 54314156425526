import React from 'react';
import { connect } from 'react-redux';

import hoistNonReactStatic from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { get, isEqual, isFunction } from 'lodash';
import convertHtmlToPlainTextSync from '../../General/components/HtmlPreview/lib/convertHtml';

/**
 * Get the display name for the given component
 *
 * @param  {Object} WrappedComponent Wrapped component
 *
 * @return {String} name Display name
 */
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withBrowserNotification({ relevantNotifications = [] } = {}) {
  return function withBrowserNotificationInner(WrappedComponent) {
    class BrowserNotification extends React.Component {
      constructor(props) {
        super(props);
        this.subscriptions = [];
      }

      async componentDidMount() {
        await this.subscribeAll();
      }

      async componentDidUpdate(prevProps) {
        const { relevantNotifications } = this.props;
        if (!isEqual(relevantNotifications, prevProps.relevantNotifications)) {
          await this.unsubscribeAll();
          await this.subscribeAll();
        }
      }

      componentWillUnmount() {
        this.unsubscribeAll();
      }

      sendBrowserNotification(message, config) {
        const { language } = this.props;
        const notificationMessage = get(message, 'data.data');
        const contentKey = `content${language.charAt(0).toUpperCase()}${language.slice(1)}`;

        const body = convertHtmlToPlainTextSync(get(notificationMessage, contentKey));
        if (
          typeof Notification !== 'undefined'
            && Notification.permission === 'granted'
        ) {
          const notification = new Notification('Mitarbeiterarbeitsplatz (MAP)', {
            body,
            icon: '/favicon.ico',
          });
          notification.addEventListener('click', () => config.onClick(get(notificationMessage, contentKey)));
        }
      }

      async subscribeAll() {
        const { tarzanSubscribe, relevantNotifications } = this.props;
        if (
          typeof Notification !== 'undefined'
          && Notification.permission === 'default'
        ) {
          await Notification.requestPermission();
        }

        const subscriptions = await Promise.all(
          relevantNotifications.map((config) => tarzanSubscribe({
            topic: config.topic,
            types: config.types,
            callback: ({ message }) => this.sendBrowserNotification(message, config),
            ignoreTabVisibility: true
          }))
        );

        this.subscriptions = subscriptions;
      }

      unsubscribeAll() {
        const { tarzanUnsubscribe } = this.props;
        this.subscriptions.forEach((sub) => tarzanUnsubscribe(sub));

        this.subscriptions = [];
      }

      render() {
        const {
          forwardedRef, relevantNotifications, language, ...rest
        } = this.props;

        return (
          <WrappedComponent
            ref={forwardedRef}
            {...rest}
            language={language}
            relevantNotifications={relevantNotifications}
          />
        );
      }
    }

    hoistNonReactStatic(BrowserNotification, WrappedComponent);

    BrowserNotification.displayName = `BrowserNotification(${getDisplayName(WrappedComponent)})`;

    BrowserNotification.propTypes = {
      forwardedRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(React.Component) }),
      ]),
      tarzanSubscribe: PropTypes.func.isRequired,
      tarzanUnsubscribe: PropTypes.func.isRequired,
      relevantNotifications: PropTypes.array.isRequired,
      language: PropTypes.string.isRequired
    };

    BrowserNotification.defaultProps = {
      forwardedRef: undefined
    };

    const mapStateToProps = (state, ownProps) => {
      const resolved = relevantNotifications
        .map((configOrFn) => {
          const config = isFunction(configOrFn) ? configOrFn(state, ownProps) : configOrFn;
          return config;
        })
        .filter(Boolean);

      return {
        relevantNotifications: resolved,
        language: state.login.language
      };
    };

    return connect(mapStateToProps)(BrowserNotification);
  };
}

export default withBrowserNotification;
