import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';

class EbankingContractPoa extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      form,
      onChange,
      poas,
      validations
    } = this.props;

    const selected = get(form, 'data.poa');

    const options = get(poas, 'data.resultVariables.givenPoas', []).filter((poa) => get(poa, 'poaType') === 6026)
      .filter((poa) => get(poa, 'signingAuthority') === 'INDIVIDUALLY')
      .map((poa) => ({
        value: get(poa, 'poaKey'),
        label: get(poa, 'clientDesignationLong'),
        poa
      }));

    return (
      <div className="setadvisor-workguide-ebanking-contract-order--poa">
        <RadioButtonGroup
          id="poa"
          onChange={onChange}
          options={options}
          returnValue="poa"
          value={get(selected, 'poaKey')}
        />

        <div className="pt-4" />

        <ValidationResult
          show
          validations={get(validations, 'poa')}
        />
      </div>
    );
  }
}

EbankingContractPoa.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  poas: PropTypes.object,
  validations: PropTypes.object
};

EbankingContractPoa.defaultProps = {
  form: {},
  onChange: noop,
  poas: {},
  validations: {}
};

export default EbankingContractPoa;
