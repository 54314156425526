import { compact, get, toNumber, isNil, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import { DateField, Select } from '../../../../General/index';
import { actions as bpfCmsActions } from '../../../../BpfCms/index';
import freemiumActions from '../../../actions/Actions';
import { getValidator } from '../../../../../globals';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../Workguide/hocs';

const validationDefintion = {
  validations: {
    cancellationDate: {
      type: 'string',
      required: true,
      validations: {
        minDate: [moment().startOf('day')],
        isValidDate: []
      }
    },
    contractType: {
      type: 'number',
      required: true
    }
  }
};

class DissolveLilaSet extends React.Component {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getProductNameByContractType = this.getProductNameByContractType.bind(this);
    this.getActiveModulesAndSetOptions = this.getActiveModulesAndSetOptions.bind(this);
  }

  componentDidMount() {
    this.onInit();
  }

  componentWillUnmount() {
    const { freemiumActions } = this.props;

    freemiumActions.dissolveLilaSetFormReset();
  }

  onInit() {
    const { freemiumActions, workguideModal } = this.props;
    const contractType = get(workguideModal, 'contractType');

    const data = {
      cancellationDate: moment().format(),
      contractType: !isUndefined(contractType) ? toNumber(contractType) : undefined,
    };

    freemiumActions.dissolveLilaSetFormInitFulfilled({ payload: data });
  }

  onSubmit() {
    const {
      freemiumActions, customer, toggleRedirectModal, consultantId, form
    } = this.props;

    const cancellationDate = get(form, 'data.cancellationDate');

    const contractType = get(form, 'data.contractType');

    freemiumActions.dissolveLilaSetFormSaveRequest({ customerId: get(customer, 'id'), consultantId, cancellationDate, contractType });
    toggleRedirectModal();
  }

  /**
   * Handle customer change
   *
   * @param  {Object} customer Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const { freemiumActions } = this.props;
    const customerId = get(customer, 'id');

    freemiumActions.loadLilaSetRequest({ dataKey: customerId });
  }

  getProductNameByContractType(contractType) {
    const { lilaSet } = this.props;

    const isSet = get(lilaSet, 'loadLilaSetModules.contractType', null) === toNumber(contractType);

    if (isSet) {
      return {
        de: get(lilaSet, 'loadLilaSetModules.productNameDe', null),
        fr: get(lilaSet, 'loadLilaSetModules.productNameFr', null)
      };
    }

    const module = get(lilaSet, 'modules', []).find((module) => module.contractType === toNumber(contractType));

    return {
      de: get(module, 'moduleNameDe', null),
      fr: get(module, 'moduleNameFr', null)
    };
  }

  getActiveModulesAndSetOptions() {
    const { lilaSet, language } = this.props;

    const activeModules = get(lilaSet, 'modules', []).filter((m) => m.active);
    const activeSet = get(lilaSet, 'set.active', false);
    const options = [activeSet ? get(lilaSet, 'set') : undefined, ...activeModules].filter(Boolean);
    return options.map((o) => {
      return {
        value: get(o, 'contractType'),
        label: get(o, `contractTypeText.${language}`)
      };
    });
  }

  /**
   * Validate current form data
   *
   * @return  {Object} validations Validation result
   */
  validate() {
    const { form } = this.props;

    const validator = getValidator();

    return validator.validate(validationDefintion, get(form, 'data', {}));
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      fulfilled,
      errors,
      onFormCancel,
      form,
      freemiumActions,
      language,
      lilaSet
    } = this.props;

    const validations = this.validate();
    const activeModules = get(lilaSet, 'modules', []).some((m) => m.active);
    const activeSet = get(lilaSet, 'set.active', false);

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        showLoadIndicator
        requesting={!fulfilled && get(errors, 'length', 0) === 0}
      >
        {activeModules && (
        <Alert type="info" className="my-3">
          <AlertContent>
            <FormattedMessage id="Freemium.LilaSet.NotDeletable" />
          </AlertContent>
        </Alert>
        )}

        {(activeModules || activeSet) ? (
          <>
            <FormGroup>
              <Label>
                <FormattedMessage id="Freemium.Form.DissolveLilaSet.SelectContractType" />
              </Label>

              <Select
                id="contractType"
                value={get(form, 'data.contractType')}
                options={this.getActiveModulesAndSetOptions()}
                onChange={freemiumActions.dissolveLilaSetFormSetValue}
                isOptionDisabled={(option) => option.value === 6440 && get(lilaSet, 'modules', []).some((m) => m.active)}
                showValidations
                validations={validations.contractType}
              />
            </FormGroup>

            <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="Freemium.Form.DissolveLilaSet.CancellationDateSelectText" />
                </Label>
                <DateField
                  id="cancellationDate"
                  minDate={moment().startOf('day')}
                  onChange={freemiumActions.dissolveLilaSetFormSetValue}
                  onInputChange={freemiumActions.dissolveLilaSetFormSetValue}
                  showValidations
                  validations={get(validations, 'cancellationDate')}
                  value={get(form, 'data.cancellationDate')}
                  language={language}
                />
              </FormGroup>
            </Row>

            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <Button
                color="primary"
                onClick={this.onSubmit}
                disabled={!isEmpty(validations)}
              >
                <FormattedMessage id="Workguide.Form.Button.Execute" />

              </Button>

              <span style={{ paddingRight: '20px' }} />

              <Button color="primary" outline onClick={onFormCancel}>
                <FormattedMessage id="Workguide.Form.Button.Cancel" />
              </Button>
            </div>
          </>
        ) : (
          <Alert type="error">
            <AlertContent>
              <FormattedMessage id="Freemium.Form.DissolveLilaSet.ExistingModules" />
            </AlertContent>
          </Alert>
        )}

      </WorkguideCustomerAware>
    );
  }
}

DissolveLilaSet.propTypes = {
  freemiumActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  customer: PropTypes.object,
  form: PropTypes.object,
  errors: PropTypes.array,
  fulfilled: PropTypes.bool,
  toggleRedirectModal: PropTypes.func.isRequired,
  consultantId: PropTypes.string.isRequired,
  onFormCancel: PropTypes.func.isRequired,
  lilaSet: PropTypes.object,
  workguideModal: PropTypes.object
};

DissolveLilaSet.defaultProps = {
  customer: undefined,
  form: {},
  language: 'de',
  errors: [],
  fulfilled: false,
  lilaSet: {},
  workguideModal: {}
};

function isFulfilled(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return get(state, `freemium.lilaSet.${customerId}.fulfilled`, false);
}

function isRequesting(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return get(state, `freemium.lilaSet.${customerId}.requesting`, false);
}

function collectErrors(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return compact([get(state, `freemium.lilaSet.${customerId}.error`)]);
}

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;
  const customerId = get(customer, 'id');

  return {
    customer,
    errors: collectErrors(state, ownProps),
    language: state.login.language,
    form: state.freemium.dissolveLilaSetForm,
    requesting: isRequesting(state, ownProps),
    fulfilled: isFulfilled(state, ownProps),
    consultantId: get(state, 'login.session.id'),
    lilaSet: get(state, `freemium.lilaSet.${customerId}.data`, {}),
    workguideModal: get(state, 'workguide.workguideModal.data')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch),
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(DissolveLilaSet)
);
