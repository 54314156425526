import update from 'immutability-helper';
import { compact, every, get, has, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { withCodeGroups } from '@evoja-web/entity-code';
import { Alert, AlertContent, StepContainer, Step, StepButtons } from '@evoja-web/react-layout-components';
import { createIsRequesting } from '@evoja-web/redaction';
import moment from 'moment';

import { actions as bpfCmsActions, Formalities } from '../../../../BpfCms/index';
import freemiumActions from '../../../actions/Actions';
import { Section, SectionContent, SectionTitle } from '../../../../General/components/Section';
import CreateNewAccount from '../../../components/Form/NewLilaSet/CreateNewAccount';
import OrderDebitMasterCard from '../../../components/Form/NewLilaSet/DebitMasterCard/OrderDebitMasterCard';
import EBankingContractForm from '../../../components/Form/NewLilaSet/EBankingContractForm';
import ExistingAccounts from '../../../components/Form/NewLilaSet/ExistingAccounts';
import ExtraProducts from '../../../components/Form/NewLilaSet/ExtraProduct';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../Workguide/hocs';
import { toIntIfSet } from '../../../../../lib/Utils';
import { getValidator } from '../../../../../globals';
import { isAiaFatcaDeclarationFulfilledOrRunning,
  isBasicContractFulfilledOrRunning,
  isCustomerDataFulfilled,
  isIdentificationDocumentFulfilledOrRunning } from '../../../../BpfCms/lib/ProfileCompletion/Common';
import { isDoublePartner } from '../../../../../lib/Customer/Utils';
import ckeckValidations from '../../../../General/lib/Utils/checkValidations';
import './NewLilaSet.css';

const STEP_ACCOUNT = 'account';
const STEP_DEBIT_CARD = 'debitCard';
const STEP_EBANKING_CONTRACT = 'eBankingContract';
const STEP_EXTRA_PRODUCTS = 'extraProducts';
class OpenLilaSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: STEP_ACCOUNT
    };

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validate = this.validate.bind(this);
    this.onLoadBpfCustomerData = this.onLoadBpfCustomerData.bind(this);
    this.onExtraProductChange = this.onExtraProductChange.bind(this);
    this.onActiveStepChange = this.onActiveStepChange.bind(this);
  }

  componentWillUnmount() {
    const {
      customer,
      freemiumActions
    } = this.props;

    const customerId = get(customer, 'id');

    freemiumActions.accountProductGroupsReset({ dataKey: customerId });
    freemiumActions.contractProductGroupsReset({ dataKey: customerId });
    freemiumActions.customerAccountsReset({ dataKey: customerId });
    freemiumActions.customerCardsReset({ dataKey: customerId, cardVarieties: [554, 910] });
    freemiumActions.customerPoasReset({ dataKey: customerId });
  }

  /**
   * Handle customer change
   *
   * @param  {Object} customer Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const { freemiumActions, form } = this.props;
    const customerId = get(customer, 'id');
    const accounts = get(form, 'data.accounts', []);
    if (accounts.length === 0) {
      this.onInit();
    }
    freemiumActions.customerAccountsRequest({ dataKey: customerId, accountTypes: [90, 110, 2000, 2010, 2011, 2021] });

    // Initial load
    freemiumActions.accountProductGroupsRequest({ dataKey: customerId });
    freemiumActions.contractProductGroupsRequest({ dataKey: customerId });
    freemiumActions.customerCardsRequest({ dataKey: customerId, cardVarieties: [554, 910] });
    freemiumActions.customerPoasRequest({ dataKey: customerId });
    freemiumActions.orderCheckRequest({ customerId });
  }

  /**
   * Load bpf cms data for the given customerId
   *
   * @param  {String} customerId Customer id (clientKey)
   *
   * @return void
   */
  onLoadBpfCustomerData(customerId) {
    const {
      bpfCmsActions,
      freemiumActions
    } = this.props;

    bpfCmsActions.customerRequest({ dataKey: customerId });
    freemiumActions.gravitonCustomerRequest({ dataKey: customerId });
  }

  /**
 * update current activeStep
 * @param {*} activeStep current set
 */
  onActiveStepChange(activeStep) {
    this.setState({ activeStep });
  }

  /**
   *  Create empty object in form
   */
  onInit() {
    const { freemiumActions } = this.props;
    const data = {
      showDebitCards: false,
      newAccounts: [],
      debitCards: [],
      newContract: [{}],
      poaAdditional: [{}],
      eDocument: true,
      selectedEBankingContractOption: undefined // 'contract-none'
    };
    freemiumActions.openLilaSetInitFulfilled({ payload: data });
  }

  onExtraProductChange(index, account) {
    const { freemiumActions, form } = this.props;
    const accounts = get(form, 'data.additionalProducts', []);
    const updated = update(accounts, {
      [index]: { $set: account }
    });
    freemiumActions.openLilaSetSetValue('additionalProducts', updated);
  }

  submit() {
    const {
      freemiumActions, form, customer, toggleRedirectModal, consultantId
    } = this.props;

    const clientKey = toIntIfSet(get(customer, 'id'));
    const debitCards = get(form, 'data.debitCards', []);
    const dto = {
      clientKey,
      eBanking: {
        existingContracts: get(form, 'data.existingContracts', [])
          .map((contract) => ({ contractKey: contract.contractKey, clientKey: contract.clientAssignedKey })),
      },
      newDebitMastercards: debitCards.map((card) => {
        const debitCard = {
          clientKeyCardholder: get(card, 'cardHolder.clientKey'),
          accountKey: get(card, 'cardAccount'),
          dailyLimit: toIntIfSet(get(card, 'dailyLimit'), 3000),
          monthlyLimit: toIntIfSet(get(card, 'monthlyLimit'), 5000),
          multiAccountsAssign: get(card, 'multiAccountsAssign', false),
          shippingToBank: get(card, 'deliveryToOffice', false),
          embossedLineOne: get(card, 'embossedLineOne', undefined),
          embossedLineTwo: get(card, 'embossedLineTwo', undefined),
        };
        if (!get(card, 'deliveryToOffice', false)) {
          debitCard.shippingToClient = {
            addressType: toIntIfSet(get(card, 'customerAddress.addressType.number')),
            addresseeKey: get(card, 'cardHolder.clientKey')
          };
        }
        if (get(card, 'cardAccount') === 'new') delete debitCard.accountKey;
        return debitCard;
      }),
      accounts: {
        newAccounts: [...get(form, 'data.newAccounts', []).map((account, index) => {
          return {
            accountType: get(account, 'accountType'),
            debitAccountForFee: get(form, 'data.chargingAccount', []).filter((el) => el.accountKey === 'newAccount').length > 0 && index === 0,
            accountCurrency: 'CHF',
            ...(get(account, 'hasRubric', false) && {
              ...(get(account, 'manuelRubric', false)
                ? {
                  accountCategory: get(account, 'customRubric'),
                }
                : {
                  accountCategoryId: toIntIfSet(get(account, 'accountCategory.number'))
                }),
            }),
          };
        }), ...get(form, 'data.additionalProducts', []).map((account) => {
          return {
            accountType: get(account, 'accountType'),
            debitAccountForFee: false,
            accountCurrency: 'CHF',
            ...(get(account, 'hasRubric', false) && {
              ...(get(account, 'manuelRubric', false)
                ? {
                  accountCategory: get(account, 'customRubric'),
                }
                : {
                  accountCategoryId: toIntIfSet(get(account, 'accountCategory.number'))
                }),
            }),
          };
        })],

      }
    };

    const relevanteChargingAccounts = get(form, 'data.chargingAccount', []).filter((account) => account.accountKey !== 'newAccount');
    if (get(relevanteChargingAccounts, 'length', 0) > 0) {
      dto.accounts.existingAccounts = get(form, 'data.chargingAccount', []);
    }

    if (get(form, 'data.selectedEBankingContractOption') === 'contract-new') {
      dto.eBanking.newContracts = get(form, 'data.newContract', []).map((contract) => {
        const address = get(contract, 'eBankingCustomerAddress', {});
        return {
          clientKey: get(contract, 'selectedContractPartner.clientKey'),
          edocuments: get(contract, 'eDocument', false),
          addressingRules: [
            {
              addressType: toIntIfSet(get(address, 'addressType.number')),
              addresseeKey: get(contract, 'selectedContractPartner.clientKey'),
            }
          ]
        };
      });
    }

    freemiumActions.openLilaSetSaveRequest({ payload: dto, customerId: clientKey, consultantId });
  }

  /**
  * Validate current form data
  *
  * @return {Object} result Validation result
  */
  validate() {
    const { form, accounts, hasExistingOrder } = this.props;

    const data = get(form, 'data', {});
    const validator = getValidator();
    const selectedEBankingContractOption = get(data, 'selectedEBankingContractOption');
    const definition = {
      validations: {
        newAccounts: {
          type: 'array',
          required: () => accounts.length === 0,
          validations: {
            accountType: {
              type: 'number',
              required: true,
              validations: {},
            },
            accountCategory: {
              type: 'object',
              required: ({ parent }) => {
                return get(parent, 'hasRubric', false) && !get(parent, 'manuelRubric', false);
              },
              validations: {},
            },
            customRubric: {
              type: 'string',
              required: ({ parent }) => get(parent, 'manuelRubric', false),
              validations: {},
            }
          }
        },
        chargingAccount: {
          type: 'array',
          required: true
        },
        debitCards: {
          type: 'array',
          required: ({ parent }) => get(parent, 'showDebitCards', false),
          validations: {
            deliveryToOffice: {
              type: 'boolean',
              required: true,
              validations: {}
            },
            profileCompletionDebitcard: {
              type: 'boolean',
              validations: { isTrue: [] }
            },
            cardAccount: {
              type: 'alphanumeric',
              required: true,
              validations: {}
            },
            cardHolder: {
              type: 'object',
              required: true,
              validations: {}
            },
            dailyLimit: {
              type: 'number',
              required: false,
              validations: {
                isGe: [0]
              }
            },
            monthlyLimit: {
              type: 'number',
              required: false,
              validations: {
                isGe: [0]
              }
            },
            customerAddress: {
              type: 'object',
              required: ({ parent }) => !get(parent, 'deliveryToOffice', false),
              validations: {}
            },
            embossedLineOne: {
              type: 'string',
              required: true,
              validations: {
                isLength: [{ max: 30 }]
              },
            },
            embossedLineTwo: {
              type: 'string',
              required: ({ parent }) => {
                const cardHolderClientKey = get(parent, 'cardHolder.clientKey');
                const accountHolderClientKey = get(
                  accounts.find((account) => toIntIfSet(account.accountKey) === get(parent, 'cardAccount')),
                  'clientKey'
                );
                return cardHolderClientKey !== accountHolderClientKey;
              },
              validations: {
                isLength: [{ max: 30 }]
              }
            }
          }
        },
        selectedEBankingContractOption: {
          type: 'string',
          required: true,
        },
        newContract: {
          type: 'array',
          required: () => {
            return selectedEBankingContractOption === 'contract-new' && !hasExistingOrder;
          },
          validations: {
            selectedContractPartner: {
              type: 'object',
              required: () => {
                return selectedEBankingContractOption === 'contract-new' && !hasExistingOrder;
              },
              validations: {}
            },
            eBankingCustomerAddress: {
              type: 'object',
              required: () => selectedEBankingContractOption === 'contract-new' && !hasExistingOrder,
              validations: {}
            }
          }
        },
        existingContracts: {
          type: 'array',
          required: ({ parent }) => get(parent, 'selectedEBankingContractOption', '') === 'contract-existing'
        },
        additionalProducts: {
          type: 'array',
          required: ({ parent }) => get(parent, 'showProducts', false),
          validations: {
            accountType: {
              type: 'number',
              required: true,
              validations: {}
            },
            accountCategory: {
              type: 'object',
              required: ({ parent }) => {
                return get(parent, 'hasRubric', false) && !get(parent, 'manuelRubric', false);
              },
              validations: {}
            },
            customRubric: {
              type: 'string',
              required: ({ parent }) => get(parent, 'manuelRubric', false),
              validations: {}
            }
          }
        },
      }
    };

    return validator.validate(definition, data);
  }

  /**
  * Render method
  *
  * @return {ReactElement} markup
  */
  render() {
    const {
      fulfilled,
      errors,
      accounts,
      freemiumActions,
      language,
      form,
      customer,
      codes,
      accountProductGroups,
      contractProductGroups,
      bpfCustomers,
      gravitonCustomers,
      bpfPoas,
      bpfCards,
      eBankingContracts,
      onFormCancel,
      lilaSet,
      hasExistingOrder,
      requesting,
      isProfileCompleted
    } = this.props;

    const { activeStep } = this.state;
    const validations = this.validate();

    const customerAge = has(customer, 'birthDate')
      ? moment().diff(moment(get(customer, 'birthDate')), 'years')
      : undefined;

    const powerOfAttorneysDebitCard = get(bpfPoas, 'data.resultVariables.givenPoas', [])
      .filter((poa) => get(poa, 'signingAuthority') === 'INDIVIDUALLY' && ['HOLDER', 'AUTHORISED_PERSON']
        .includes(get(poa, 'legitimation')));

    const isExternalCustomer = ['90', '91', '92', '93'].includes(get(customer, 'segment.number'));
    const noCodedEbankingContract = get(contractProductGroups, 'length', 0) > 0;

    if (!isProfileCompleted) {
      return (
        <Formalities
          customer={customer}
          title={<FormattedMessage id="Freemium.Form.NewLilaSet.IncompleteFormalities" />}
        />
      );
    }

    if ((isUndefined(customerAge) || customerAge < 12)) {
      return (
        <Alert type="warning">
          <AlertContent>
            <p>
              <FormattedMessage id="Freemium.Form.NewLilaSet.UnderAge" />
            </p>
            <Button color="primary" outline onClick={onFormCancel}>
              <FormattedMessage id="Workguide.Form.Button.Cancel" />
            </Button>
          </AlertContent>
        </Alert>
      );
    }

    if (get(lilaSet, 'set.active', false) || get(lilaSet, 'set.isRunning', false)) {
      return (
        <Alert type="warning">
          <AlertContent>
            <p>
              <FormattedMessage id="Freemium.Form.NewLilaSet.LilaSetExistings" />
            </p>
            <Button color="primary" outline onClick={onFormCancel}>
              <FormattedMessage id="Workguide.Form.Button.Cancel" />
            </Button>
          </AlertContent>
        </Alert>
      );
    }

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        showLoadIndicator
        requesting={(!fulfilled && get(errors, 'length', 0) === 0) || requesting}
      >

        {isExternalCustomer && (
          <Alert type="error">
            <AlertContent>
              <FormattedMessage id="Freemium.Form.NewLilaSet.IsExternalCustomer" />
            </AlertContent>
          </Alert>
        )}

        {noCodedEbankingContract && (
          <Alert type="error">
            <AlertContent>
              <FormattedMessage id="Freemium.Form.NewLilaSet.NoCodedEbankingContract" />
            </AlertContent>
          </Alert>
        )}

        {!isExternalCustomer && !noCodedEbankingContract && (
          <div className="open-lila-set--container">
            <StepContainer initialStep={STEP_ACCOUNT} onActiveStepChange={this.onActiveStepChange}>
              <Step
                id={STEP_ACCOUNT}
                title={(
                  <p>
                    <FormattedMessage id="Freemium.Form.NewLilaSet.PrivateAccount" />
                    {' / '}
                    <FormattedMessage id="Freemium.Form.NewLilaSet.DebitAccountFee" />
                  </p>
              )}
              >
                <Section>
                  <SectionTitle><FormattedMessage id="Freemium.Form.NewLilaSet.PrivateAccount" /></SectionTitle>
                  <Alert type="info">
                    <AlertContent>
                      <FormattedMessage id="Freemium.Form.NewLilaSet.PrivateAccountInfo" />
                    </AlertContent>
                  </Alert>
                  <SectionContent style={{ marginTop: 15 }}>

                    <ExistingAccounts
                      accounts={accounts.filter((account) => [90, 110].includes(account.accountType))}
                      language={language}
                      freemiumActions={freemiumActions}
                    />
                    <CreateNewAccount
                      accounts={accounts.filter((account) => [90, 110].includes(account.accountType))}
                      language={language}
                      form={form}
                      codes={codes}
                      productGroups={accountProductGroups.filter((product) => [90, 110].includes(product.productNumber))}
                      freemiumActions={freemiumActions}
                      validations={validations}
                    />

                  </SectionContent>
                </Section>
              </Step>

              <Step
                id={STEP_DEBIT_CARD}
                title={<FormattedMessage id="Freemium.Form.NewLilaSet.DebitCard" />}
                disabled={ckeckValidations(validations, ['newAccounts', 'chargingAccount'])}
              >
                <OrderDebitMasterCard
                  accounts={accounts}
                  language={language}
                  form={form}
                  codes={codes}
                  productGroups={accountProductGroups}
                  freemiumActions={freemiumActions}
                  customer={customer}
                  cmsCustomers={bpfCustomers}
                  gravitonCustomers={gravitonCustomers}
                  powerOfAttorneys={powerOfAttorneysDebitCard}
                  cards={get(bpfCards, 'data.resultVariables.productCards', [])}
                  onLoadBpfCustomerData={this.onLoadBpfCustomerData}
                  validations={validations}
                  lilaSet={lilaSet}
                />
              </Step>
              <Step
                id={STEP_EBANKING_CONTRACT}
                title={<FormattedMessage id="Freemium.Form.NewLilaSet.EBankingContract" />}
                disabled={ckeckValidations(validations, ['debitCards'])}
              >
                <EBankingContractForm
                  eBankingContracts={eBankingContracts}
                  freemiumActions={freemiumActions}
                  form={form}
                  cards={get(bpfCards, 'data.resultVariables.productCards', [])}
                  cmsCustomers={bpfCustomers}
                  powerOfAttorneys={get(bpfPoas, 'data.resultVariables.givenPoas', [])}
                  onLoadBpfCustomerData={this.onLoadBpfCustomerData}
                  gravitonCustomers={gravitonCustomers}
                  validations={validations}
                  customer={customer}
                  language={language}
                  hasExistingOrder={hasExistingOrder}
                />
              </Step>
              <Step
                id={STEP_EXTRA_PRODUCTS}
                title={<FormattedMessage id="Freemium.Form.NewLilaSet.ExtraProductTitle" />}
                disabled={ckeckValidations(validations, ['selectedEBankingContractOption'])}

              >
                <ExtraProducts
                  form={form}
                  codes={codes}
                  productGroups={accountProductGroups}
                  onExtraProductChange={this.onExtraProductChange}
                  freemiumActions={freemiumActions}
                  validations={validations}
                  language={language}
                />
              </Step>
              <StepButtons>
                <React.Fragment>
                  <Button color="primary" onClick={this.submit} disabled={!isEmpty(validations) || activeStep !== STEP_EXTRA_PRODUCTS}>
                    <FormattedMessage id="Workguide.Form.Button.Execute" />
                  </Button>
                  <span style={{ paddingRight: '20px' }} />
                  <Button color="primary" outline onClick={onFormCancel}>
                    <FormattedMessage id="Workguide.Form.Button.Cancel" />

                  </Button>
                </React.Fragment>

              </StepButtons>
            </StepContainer>
          </div>
        )}

      </WorkguideCustomerAware>
    );
  }
}

OpenLilaSet.propTypes = {
  freemiumActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  customer: PropTypes.object,
  form: PropTypes.object,
  errors: PropTypes.array,
  fulfilled: PropTypes.bool,
  accounts: PropTypes.array,
  accountProductGroups: PropTypes.array,
  contractProductGroups: PropTypes.array,
  codes: PropTypes.object,
  bpfCmsActions: PropTypes.object.isRequired,
  bpfCustomers: PropTypes.object,
  bpfPoas: PropTypes.object,
  gravitonCustomers: PropTypes.object,
  bpfCards: PropTypes.object,
  eBankingContracts: PropTypes.array,
  toggleRedirectModal: PropTypes.func.isRequired,
  consultantId: PropTypes.string.isRequired,
  onFormCancel: PropTypes.func.isRequired,
  lilaSet: PropTypes.object,
  hasExistingOrder: PropTypes.bool,
  requesting: PropTypes.bool,
  isProfileCompleted: PropTypes.bool
};

OpenLilaSet.defaultProps = {
  customer: undefined,
  form: {},
  language: 'de',
  errors: [],
  fulfilled: false,
  accounts: [],
  accountProductGroups: [],
  contractProductGroups: [],
  codes: {},
  bpfCustomers: {},
  bpfPoas: {},
  gravitonCustomers: {},
  bpfCards: {},
  eBankingContracts: [],
  lilaSet: {},
  hasExistingOrder: false,
  requesting: false,
  isProfileCompleted: false
};

function isFulfilled(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return get(state, `freemium.customerAccounts.${customerId}.fulfilled`, false);
}

function collectErrors(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return compact([get(state, `freemium.customerAccounts.${customerId}.error`)]);
}

const relevantPaths = [
  (state) => {
    const customer = get(state, 'powersearch.customer');
    const customerId = get(customer, 'id');

    return `freemium.lilaSet.${customerId}`;
  },
  (state) => {
    const customer = get(state, 'powersearch.customer');

    return isDoublePartner(customer)
      ? `bpfCms.partners.${get(customer, 'id')}`
      : undefined;
  },
  'freemium.customerAccounts',
  'bpfCms.customer'
];

const isRequesting = createIsRequesting({ path: relevantPaths });
/**
 * Check if formalities for the given customer (and its partners) are fulfilled
 *
 * @param  {Object} state Application state
 *
 * @return {Boolean}
 */
function formalitiesFulfilled(state) {
  const customer = get(state, 'powersearch.customer');
  const customerId = get(customer, 'id');

  const formalities = get(state, `bpfCms.customer.${customerId}.data`, {});
  const partnerFormalities = get(state, `bpfCms.partners.${customerId}.data`, [])
    .map((partner) => get(state, `bpfCms.customer.${get(partner, 'id')}.data`, {}));

  const isSeveralPrivateIndividuals = get(formalities, 'clientType') === 'severalPrivateIndividuals';

  if (isSeveralPrivateIndividuals) {
    // check main partner for basis contract and customer data
    const isDoublePartnerComplete = isBasicContractFulfilledOrRunning(formalities)
      && isCustomerDataFulfilled(formalities);
    // check other partners for aiaFatca, identification and customer data
    const areSinglePartnersComplete = every(partnerFormalities, (data) => {
      return (
        isAiaFatcaDeclarationFulfilledOrRunning(data)
        && isIdentificationDocumentFulfilledOrRunning(data)
        && isCustomerDataFulfilled(data)
      );
    });
    return isDoublePartnerComplete && areSinglePartnersComplete;
    // for privateIndividuals check aiaFatca, identification, basis contract and customer datata
  }

  return (
    isAiaFatcaDeclarationFulfilledOrRunning(formalities)
    && isIdentificationDocumentFulfilledOrRunning(formalities)
    && isBasicContractFulfilledOrRunning(formalities)
    && isCustomerDataFulfilled(formalities)
  );
}

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;
  const customerId = get(customer, 'id');
  const bpfPoas = get(state, `freemium.customerPoas.${customerId}`);
  const bpfCards = get(state, `freemium.customerCards.${customerId}`);

  return {
    accounts: get(state, `freemium.customerAccounts.${customerId}.data.resultVariables.updatedAccounts`),
    bpfCards,
    bpfCustomers: state.bpfCms.customer,
    bpfPoas,
    consultantId: get(state, 'login.session.id'),
    eBankingContracts: get(state, `freemium.lilaSet.${get(customer, 'id')}.data.other.ebankingContracts`),
    errors: collectErrors(state, ownProps),
    form: state.freemium.openNewLilaSet,
    fulfilled: isFulfilled(state, ownProps),
    gravitonCustomers: state.freemium.gravitonCustomers,
    hasExistingOrder: get(state, 'freemium.hasExistingOrder.data.count', 0) > 0,
    language: state.login.language,
    lilaSet: get(state, `freemium.lilaSet.${get(customer, 'id')}.data`, {}),
    accountProductGroups: get(state, `freemium.accountProductGroups.${get(customer, 'id')}.data`),
    contractProductGroups: get(state, `freemium.contractProductGroups.${get(customer, 'id')}.data`),
    requesting: isRequesting(state, ownProps),
    isProfileCompleted: formalitiesFulfilled(state) && !isRequesting(state, ownProps),

  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch),
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeGroups({ groups: ['accountRubric'] })(
    connect(mapStateToProps, mapDispatchToProps)(OpenLilaSet)
  )
);
