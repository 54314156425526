import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import cl from 'classnames';

import './Row.css';

const CardRow = React.memo(({
  children,
  className,
  label,
}) => {
  const classNames = cl('setadvisor-form-summary-cards--row', className);

  return (
    <Row className={classNames}>
      <Col
        className="setadvisor-form-summary-cards--row--label"
        lg={4}
        md={4}
      >
        {label}
      </Col>

      <Col
        className="setadvisor-form-summary-cards--row--value"
        lg={8}
        md={8}
      >
        {children}
      </Col>
    </Row>
  );
});

CardRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
};

CardRow.defaultProps = {
  children: [],
  className: undefined,
  label: ''
};

export default CardRow;
