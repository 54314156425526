import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withAcl } from '@evoja-web/client-acl';
import { actions as codeActions } from '@evoja-web/entity-code';

import './Approval.css';
import Summary from './Summary';
import NoCustomerChange from '../../NoCustomerChange';
import Comments from '../../../../components/Form/Common/Comments/Comments';
import { actions as bpfCmsActions } from '../../../../../BpfCms/index';
import setAdvisorActions from '../../../../actions/Actions';
import { withWorkguideInstanceData } from '../../../../../Workguide/hocs';

class SetAdvisorWorkguideCardLimitApproval extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: []
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCommentsChange = this.onCommentsChange.bind(this);
  }

  /**
   * Handle form submit
   *
   * @return void
   */
  onSubmit(approved = false) {
    const { comments } = this.state;
    const {
      acl,
      process,
      setAdvisorActions,
      task,
      toggleRedirectModal
    } = this.props;

    setAdvisorActions.creditCardApprovalRequest({
      approved,
      comments: [...get(process, 'comments', []), ...comments],
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
      taskId: get(task, 'id')
    });

    toggleRedirectModal();
  }

  /**
   * Handle comments change
   *
   * @param  {String} id      Form element id
   * @param  {Array} comments Array of comments
   *
   * @return void
   */
  onCommentsChange(id, comments) {
    this.setState({ comments });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      onFormCancel,
      session,
      workguide
    } = this.props;

    return (
      <div className="setadvisor-workguide-card-limit-approval">
        <NoCustomerChange workguideId={get(workguide, 'id')}>
          <Row className="setadvisor-workguide-card-limit-approval--title">
            <Col lg={12} md={12}>
              <FormattedMessage
                id="Freemium.Workguide.CardLimitApproval.Title"
                tagName="h5"
              />
            </Col>
          </Row>

          <Row className="setadvisor-workguide-card-limit-approval--description mb-5">
            <Col lg={12} md={12}>
              <FormattedMessage id="Freemium.Workguide.CardLimitApproval.Description" />
            </Col>
          </Row>

          <Summary />

          <Comments
            onChange={this.onCommentsChange}
            session={session}
          />

          <Row style={{ paddingTop: '4rem' }}>
            <Col lg={12} md={12} className="d-flex justify-content-end">
              <Button
                color="primary"
                outline
                onClick={onFormCancel}
              >
                <FormattedMessage id="Activity.Form.Cancel" />
              </Button>

              <span style={{ paddingRight: '1rem' }} />

              <Button
                color="primary"
                onClick={(...args) => this.onSubmit(false, ...args)}
              >
                <FormattedMessage id="Freemium.Form.CreditCard.RejectLimit" />
              </Button>

              <span style={{ paddingRight: '1rem' }} />

              <Button
                color="primary"
                onClick={(...args) => this.onSubmit(true, ...args)}
              >
                <FormattedMessage id="Freemium.Form.CreditCard.ApproveLimit" />
              </Button>
            </Col>
          </Row>
        </NoCustomerChange>
      </div>
    );
  }
}

SetAdvisorWorkguideCardLimitApproval.propTypes = {
  acl: PropTypes.object.isRequired,
  onFormCancel: PropTypes.func,
  process: PropTypes.object.isRequired,
  setAdvisorActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func,
  workguide: PropTypes.object
};

SetAdvisorWorkguideCardLimitApproval.defaultProps = {
  onFormCancel: noop,
  toggleRedirectModal: noop,
  workguide: undefined
};

function mapStateToProps(state, ownProps) {
  return {
    process: get(ownProps, 'bpf.process'),
    session: state.login.session,
    task: get(ownProps, 'bpf.task'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch),
    codeActions: bindActionCreators(codeActions, dispatch),
    setAdvisorActions: bindActionCreators(setAdvisorActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    withAcl()(SetAdvisorWorkguideCardLimitApproval)
  )
);
