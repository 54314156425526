import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';

const FormOfAddess = React.memo(({
  cardHolder,
  formOfAddresses
}) => {
  const value = get(formOfAddresses, get(cardHolder, 'clientInformation.formOfAddress'), '');

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.FormOfAddess" />}>
      {value}
    </Row>
  );
});

FormOfAddess.propTypes = {
  cardHolder: PropTypes.object,
  formOfAddresses: PropTypes.object
};

FormOfAddess.defaultProps = {
  cardHolder: {},
  formOfAddresses: {}
};

export default FormOfAddess;
