import { get, isNil } from 'lodash';

/**
 * Get the global limit of the current main card (if there is one)
 *
 * @param  {Array}  cards          Array of cards from bpf
 * @param  {Number} defaultReturn  Default value to return if no value is set
 *
 * @return {Number} limit Global limit of main card
 */
export default function getMainCardGlobalLimit(cards, defaultReturn) {
  const currentCards = get(cards, 'data.resultVariables.productCards', []);
  const mainCard = currentCards.find((card) => get(card, 'basicData.categoryType') === 'HK');
  const cardLimitList = get(mainCard, 'cardLimitList', []);
  const mainCardGlobalLimit = isNil(cardLimitList)
    ? undefined
    : get(mainCard, 'cardLimitList', []).find((limit) => get(limit, 'limitType') === 1);

  return get(mainCardGlobalLimit, 'limitAmount', defaultReturn);
}
