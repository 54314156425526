import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import './Image.css';
import moment from 'moment';
import { get, isNil, noop, omit } from 'lodash';
import ProfileImage from './ProfileImage';

const UserProfileImage = React.memo((props) => {
  const {
    className,
    consultant,
    disabled,
    fileActions,
    profilePictures,
    onToggle,
    ...rest
  } = props;

  const classNames = cl({
    'layout-header-user-profile--image': true,
    'layout-header-user-profile--image--disabled': disabled
  }, className);

  return (
    <div
      {...omit(rest, 'userSwitch')}
      className={classNames}
      onClick={onToggle}
    >

      <div className="layout-header-user-profile--image--container">
        <ProfileImage consultant={consultant} profilePictures={profilePictures} fileActions={fileActions} withAlternativeUserImage />
      </div>
    </div>
  );
});

UserProfileImage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  consultant: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  profilePictures: PropTypes.object,
  fileActions: PropTypes.object.isRequired,
  onToggle: PropTypes.func
};

UserProfileImage.defaultProps = {
  className: undefined,
  disabled: false,
  profilePictures: {},
  onToggle: noop
};

export default UserProfileImage;
