import { get } from 'lodash';

import toIntIfSet from '../../../../lib/Utils/toIntIfSet';

export default function getCardTypeOptions({ codes, types = [], language }) {
  return types.map((type) => get(codes, 'groups.cardType', [])
    .find((code) => toIntIfSet(get(code, 'number')) === type))
    .map((code) => ({
      value: toIntIfSet(get(code, 'number')),
      label: get(code, `text.${language}`)
    }));
}
