import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StepContainer, Step, StepButtons, Alert, AlertContent } from '@evoja-web/react-layout-components';
import { get, isEmpty, noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { PdfPreview } from '@evoja-web/react-document-preview';

import spsActions from '../../actions/Actions';
import { getValidator } from '../../../../globals';
import { WorkguideCustomerAware } from '../../../Workguide';
import CreateCoverLetterForm, { getValidationDefinition } from '../../components/SendByPost/CreateCoverLetterForm';
import { Section, SectionContent, SectionTitle } from '../../../General/components/Section';
import { BeatLoader } from '../../../General';

const shouldDisable = (validations) => {
  return !isEmpty(validations);
};

const STEPS = {
  CREATE_COVER_LETTER: 'SendByPostCreateCoverLetter',
  PREVIEW_DOCUMENT: 'SendByPostPreviewDocument'
};

class CreateCoverLetter extends PureComponent {
  constructor(props) {
    super(props);

    this.initForm = this.initForm.bind(this);
    this.submit = this.submit.bind(this);
    this.generateDocument = this.generateDocument.bind(this);
    this.onActiveStepChange = this.onActiveStepChange.bind(this);
  }

  onActiveStepChange(activeStep) {
    const {
      spsActions, customer, data, session
    } = this.props;

    if (activeStep === STEPS.PREVIEW_DOCUMENT) {
      spsActions.createCoverLetterWorkguideFormSaveRequest({ customer, data, consultant: session });
    }
  }

  initForm() {
    const {
      spsActions,
      customer
    } = this.props;

    spsActions.createCoverLetterWorkguideFormInitRequest({
      customer
    });
  }

  /**
   * Validate the current data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const { data } = this.props;
    const validator = getValidator();

    const CreateCoverLetterForValidationDef = getValidationDefinition();

    const definition = {
      validations: {
        ...CreateCoverLetterForValidationDef,
      }
    };

    return validator.validate(definition, data);
  }

  generateDocument(validations) {
    const { spsActions, customer, data } = this.props;

    if (!shouldDisable(validations)) {
      spsActions.createCoverLetterWorkguideFormSaveRequest({ customer, data });
    }
  }

  submit(validations) {
    const {
      spsActions, customer, data, session, toggleRedirectModal
    } = this.props;

    if (!shouldDisable(validations)) {
      spsActions.saveArchiveCoverLetterWorkguideRequest({ customer, file: get(data, 'previewDocument'), consultant: session });
    }

    toggleRedirectModal();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customerAddresses, data, fulfilled, spsActions, language, onFormCancel, intl, fileGenerating, fileGenerated, fileError, consultants
    } = this.props;

    const validationResult = this.validate();

    return (
      <WorkguideCustomerAware
        onChange={this.initForm}
        onInit={this.initForm}
        requesting={!fulfilled}
        showLoadIndicator
      >
        <Section>
          <SectionContent>
            <StepContainer initialStep={STEPS.CREATE_COVER_LETTER} onActiveStepChange={this.onActiveStepChange}>
              <Step id={STEPS.CREATE_COVER_LETTER} title={<FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.CreateCoverLetter" />}>
                <>
                  <SectionTitle>
                    <FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.CreateCoverLetter" />
                  </SectionTitle>
                  <CreateCoverLetterForm
                    customerAddresses={customerAddresses}
                    consultants={consultants}
                    data={data}
                    fulfilled={fulfilled}
                    language={language}
                    showValidations
                    validations={validationResult}
                    onChange={spsActions.createCoverLetterWorkguideFormSetValue}
                    withoutReplyLetter
                  />
                </>
              </Step>

              <Step
                id={STEPS.PREVIEW_DOCUMENT}
                title={<FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.Preview" />}
                disabled={!isEmpty(validationResult)}
                unmountOnInactive
              >
                {
                  fileGenerating ? (
                    <Alert type="info">
                      <AlertContent>
                        <FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.Document.Generating" />
                        <BeatLoader />
                      </AlertContent>
                    </Alert>
                  ) : (
                    <PdfPreview
                      blob={get(data, 'previewDocument')}
                      errorDescription={intl.formatMessage({ id: 'Sps.CreateCoverLetterForm.Workguide.Document.Error.Description' })}
                      errorTitle={intl.formatMessage({ id: 'Sps.CreateCoverLetterForm.Workguide.Document.Error.Title' })}
                    />
                  )
                }
              </Step>

              <StepButtons>
                {/* Save generated Cover Letter Button */}
                <Button
                  color="primary"
                  disabled={!isEmpty(validationResult) || !isEmpty(get(fileError, 'error')) || !fileGenerated}
                  onClick={() => this.submit(validationResult)}
                >
                  <FormattedMessage id="Sps.CreateCoverLetterForm.Workguide.Steps.Create" />
                </Button>

                {/* Cancel Button */}
                <Button
                  color="primary"
                  outline
                  onClick={onFormCancel}
                >
                  <FormattedMessage id="Sps.CreateCoverLetterForm.Close" />
                </Button>
              </StepButtons>
            </StepContainer>
          </SectionContent>
        </Section>
      </WorkguideCustomerAware>
    );
  }
}

CreateCoverLetter.propTypes = {
  customer: PropTypes.object,
  customerAddresses: PropTypes.array,
  consultants: PropTypes.array,
  data: PropTypes.object,
  fileGenerated: PropTypes.bool,
  fileGenerating: PropTypes.bool,
  fileError: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  fulfilled: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string,
  onFormCancel: PropTypes.func,
  spsActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func
};

CreateCoverLetter.defaultProps = {
  customer: undefined,
  customerAddresses: [],
  consultants: [],
  data: {},
  fileGenerated: false,
  fileGenerating: true,
  fileError: false,
  language: 'de',
  fulfilled: false,
  toggleRedirectModal: noop,
  onFormCancel: noop
};

function isFulfilled(state) {
  return (
    get(state, 'address.customerAddresses.fulfilled', false)
    || get(state, 'address.createCoverLetterWorkguideForm.initialized', false)
  );
}

function mapStateToProps(state, ownProps) {
  const customer = get(ownProps, 'customer');

  return {
    customer,
    consultants: state.consultantSearch.allConsultants,
    data: get(state, 'sps.createCoverLetterWorkguideForm.data'),
    fileGenerating: get(state, 'sps.createCoverLetterWorkguideForm.saving'),
    fileGenerated: get(state, 'sps.createCoverLetterWorkguideForm.saved'),
    fileError: get(state, 'sps.createCoverLetterWorkguideForm.error'),
    fulfilled: isFulfilled(state),
    customerAddresses: get(state, 'address.customerAddresses.data'),
    language: state.login.language,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    spsActions: bindActionCreators(spsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateCoverLetter));
