import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './LimitDetermination.css';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';
import TableHeader from './LimitDetermination/TableHeader';
import IncomeAndAssets from './LimitDetermination/IncomeAndAssets';
import Expenses from './LimitDetermination/Expenses';
import Total from './LimitDetermination/Total';
import Limits from './LimitDetermination/Limits';
import Files from './LimitDetermination/Files';

const LimitCalculationSummaryLimitDetermination = React.memo(({
  cards,
  codes,
  customer,
  data,
  language,
  partners
}) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Title" />
      </SectionTitle>

      <SectionContent>
        <TableHeader />

        <IncomeAndAssets
          customer={customer}
          data={data}
          partners={partners}
        />

        <Expenses data={data} />

        <Total data={data} />

        <Limits
          cards={cards}
          data={data}
        />

        <Files
          codes={codes}
          data={data}
          language={language}
        />
      </SectionContent>
    </Section>
  );
});

LimitCalculationSummaryLimitDetermination.propTypes = {
  cards: PropTypes.array,
  codes: PropTypes.object,
  customer: PropTypes.object,
  data: PropTypes.object,
  language: PropTypes.string,
  partners: PropTypes.object
};

LimitCalculationSummaryLimitDetermination.defaultProps = {
  cards: [],
  codes: { groups: {} },
  customer: undefined,
  data: {},
  language: 'de',
  partners: {}
};

export default LimitCalculationSummaryLimitDetermination;
