import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isEmpty, flatten, compact, isNil } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { withCodeMapper } from '@evoja-web/entity-code';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import NoPoasError from '../../../../components/Workguide/NoPoasError';
import freemiumActions from '../../../../actions/Actions';
import { getValidator } from '../../../../../../globals';
import { isCompany, isDoublePartner } from '../../../../../../lib/Customer/Utils';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../../Workguide/hocs';
import EBankingContractForm, { AssignmentAccounts,
  getOldEbankingWorkguideLink,
  hasExistingEbankingContract } from '../../../../components/Form/EBankingContract/EBankingContractForm';

const validationDefinition = {
  validations: {
    poa: {
      type: 'object',
      required: true
    },
    selectedPoaProfileCompletion: {
      type: 'boolean',
      required: ({ parent }) => !isEmpty(get(parent, 'customerAccounts', [])),
      validations: {
        isTrue: []
      }
    },
    shippingAddress: {
      type: 'object',
      required: true
    },
    customerAccounts: {
      type: 'array',
      required: ({ parent }) => get(parent, 'poaAccounts.length', 0) === 0
    },
    poaAccounts: {
      type: 'array',
      required: ({ parent }) => get(parent, 'customerAccounts.length', 0) === 0
    }
  }
};

/**
 * Check if the selected customer has the necessary poas
 *
 * @param   {Object}  poas  Result from /valiant-product-loadPoas
 *
 * @return  {Boolean}
 */
function hasNecessaryPoas(givenPoas, poas) {
  const eBankingPoas = givenPoas
    .filter((poa) => get(poa, 'poaType') === 6026 && get(poa, 'signingAuthority') === 'INDIVIDUALLY');

  return get(poas, 'fulfilled', false) && get(eBankingPoas, 'length', 0) > 0;
}

class SetAdvisorWorkguideEbankingContractOrder extends React.Component {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Set defaults as soon everything is loaded
   *
   * @param   {Object}  prevProps Prev props
   *
   * @return  void
   */
  componentDidUpdate(prevProps) {
    const {
      poas,
      requesting,
      freemiumActions
    } = this.props;
    const { requesting: prevRequesting } = prevProps;

    const eBankingPoas = get(poas, 'data.resultVariables.givenPoas', [])
      .filter((poa) => get(poa, 'poaType') === 6026 && get(poa, 'signingAuthority') === 'INDIVIDUALLY');

    if (!requesting && prevRequesting) {
      freemiumActions.ebankingOrderFormSetValue('customerAccounts', AssignmentAccounts(get(eBankingPoas, 0)));
      freemiumActions.ebankingOrderFormSetValue('addFutureOpenedAccounts', true);
      freemiumActions.ebankingOrderFormSetValue('edocuments', true);
    }
  }

  /**
   * Reset form on unmount
   *
   * @return  void
   */
  componentWillUnmount() {
    const { freemiumActions } = this.props;

    freemiumActions.ebankingOrderFormReset();
  }

  /**
   * Handle customer change in workguide instance data
   *
   * @param   {Object}  customer  Selected customer
   *
   * @return  void
   */
  onCustomerChange(customer) {
    const { freemiumActions } = this.props;

    if (!this.isBusinessOrPartnerCustomer()) {
      const customerId = get(customer, 'id');
      freemiumActions.contractProductGroupsRequest({ dataKey: customerId });
      freemiumActions.customerPoasRequest({ dataKey: customerId, loadAccounts: true });
      freemiumActions.customerAccountsRequest({ dataKey: customerId });
      freemiumActions.loadLilaSetRequest({ dataKey: customerId });
      freemiumActions.orderCheckRequest({ customerId });
    }
  }

  /**
   * Submit the form and toggle the redirect modal
   *
   * @return  {[type]}  [return description]
   */
  onSubmit() {
    const {
      customer,
      form,
      session,
      freemiumActions,
      toggleRedirectModal
    } = this.props;
    const poaAccounts = flatten(get(form, 'data.poaAccounts', []));

    freemiumActions.ebankingOrderFormSaveRequest({
      customerId: get(customer, 'id'),
      data: { ...get(form, 'data', {}), poaAccounts },
      responsibleUser: get(session, 'id')
    });

    toggleRedirectModal();
  }

  /**
   * Validate current form data
   *
   * @return  {Object} validations Validation result
   */
  validate() {
    const { form } = this.props;

    const validator = getValidator();
    const poaAccounts = compact(flatten(get(form, 'data.poaAccounts')), isEmpty);
    // @Mänu maybe you can help me make this code cleaner reguarding the poaAccounts, valiadation does no work when poaAccounts
    // is a empty array
    const formData = { ...get(form, 'data', {}), poaAccounts: isEmpty(poaAccounts) ? undefined : poaAccounts };
    return validator.validate(validationDefinition, formData);
  }

  isBusinessOrPartnerCustomer() {
    const { customer } = this.props;
    return isDoublePartner(customer) || isCompany(customer);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customer,
      givenPoas,
      poas,
      requesting,
      hasExistingOrder,
      receivedPoas,
      language,
      form,
      lilaSet,
      onFormCancel,
      freemiumActions,
      contractProductGroups
    } = this.props;
    const hasExistingContract = hasExistingEbankingContract(get(form, 'data.poa'), lilaSet);
    const validations = this.validate();
    const isBusinessOrPartnerCustomer = this.isBusinessOrPartnerCustomer();
    const hasPoas = hasNecessaryPoas(givenPoas, poas);
    const noCodedEbankingContract = get(contractProductGroups, 'length', 0) > 0;

    return (
      <div className="setadvisor-workguide-ebanking-contract-order">

        <Alert type="info" className="my-3">
          <AlertContent>
            <p className="pb-4">
              <FormattedMessage
                id="Freemium.Workguide.EBankingContract.Hint"
                values={{
                  workguideLink: (chunks) => getOldEbankingWorkguideLink(chunks, customer)
                }}
              />
            </p>
          </AlertContent>
        </Alert>

        <WorkguideCustomerAware
          onChange={this.onCustomerChange}
          onInit={this.onCustomerChange}
          requesting={requesting}
          showLoadIndicator
        >
          {hasExistingOrder && (
          <Alert type="error">
            <AlertContent>
              <FormattedMessage id="Freemium.Workguide.EBankingContract.ExistingOrderWarning" />
            </AlertContent>
          </Alert>
          )}

          {isBusinessOrPartnerCustomer && (
            <Alert type="error">
              <AlertContent>
                <FormattedMessage id="Freemium.Workguide.EBankingContract.NoBusinessCustomer" />
              </AlertContent>
            </Alert>
          )}

          {noCodedEbankingContract && (
          <Alert type="error">
            <AlertContent>
              <FormattedMessage id="SetAdvisor.Workguide.EBankingContract.NoCodedEbankingContract" />
            </AlertContent>
          </Alert>
          )}

          {(!hasPoas && !isBusinessOrPartnerCustomer) && (
            <NoPoasError />
          )}

          {!isBusinessOrPartnerCustomer && hasPoas && !hasExistingOrder && !noCodedEbankingContract
          && (
          <EBankingContractForm
            form={form}
            language={language}
            receivedPoas={receivedPoas}
            customer={customer}
            poas={poas}
            validations={validations}
            onChange={freemiumActions.ebankingOrderFormSetValue}
            hasExistingContract={hasExistingContract}
            onFormCancel={onFormCancel}
            onSubmit={this.onSubmit}
          />
          )}
        </WorkguideCustomerAware>
      </div>
    );
  }
}

SetAdvisorWorkguideEbankingContractOrder.propTypes = {
  customer: PropTypes.object,
  form: PropTypes.object,
  language: PropTypes.string,
  lilaSet: PropTypes.object,
  onFormCancel: PropTypes.func,
  poas: PropTypes.object,
  requesting: PropTypes.bool,
  session: PropTypes.object.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func,
  hasExistingOrder: PropTypes.bool,
  receivedPoas: PropTypes.array,
  givenPoas: PropTypes.array,
  mapper: PropTypes.object.isRequired,
  contractProductGroups: PropTypes.array
};

SetAdvisorWorkguideEbankingContractOrder.defaultProps = {
  customer: undefined,
  form: {},
  language: 'de',
  lilaSet: {},
  onFormCancel: noop,
  poas: {},
  requesting: false,
  toggleRedirectModal: noop,
  hasExistingOrder: false,
  receivedPoas: [],
  givenPoas: [],
  contractProductGroups: [],
};

function isRequesting(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return (
    get(state, `freemium.customerAccounts.${customerId}.requesting`, false)
    || get(state, `freemium.contractProductGroups.${customerId}.requesting`, false)
    || get(state, `freemium.customerPoas.${customerId}.requesting`, false)
    || get(state, `freemium.lilaSet.${customerId}.requesting`, false)
  );
}

function removeMortgageAccountFromPoas(poas, mapper) {
  const mortgageTypes = mapper.get('setAdvisorAccountTypes', 'mortgage');
  return poas.map((poa) => {
    const filteredAccount = get(poa, 'assignmentAccounts', [])
      .filter((account) => !mortgageTypes.includes(`accountType-${get(account, 'accountType')}`));
    return { ...poa, assignmentAccounts: filteredAccount };
  });
}

function mapStateToProps(state, ownProps) {
  const { mapper } = ownProps;
  const customerId = get(ownProps, 'customer.id');
  const customerAccounts = get(state, `freemium.customerAccounts.${customerId}`);
  const poas = get(state, `freemium.customerPoas.${customerId}`);
  const lilaSet = get(state, `freemium.lilaSet.${customerId}`);
  return {
    customerAccounts,
    form: state.freemium.eBankingOrderForm,
    language: state.login.language,
    lilaSet,
    poas,
    givenPoas: removeMortgageAccountFromPoas(get(poas, 'data.resultVariables.givenPoas', []), mapper),
    receivedPoas: removeMortgageAccountFromPoas(get(poas, 'data.resultVariables.receivedPoas', []), mapper),
    session: state.login.session,
    requesting: isRequesting(state, ownProps),
    hasExistingOrder: get(state, 'freemium.hasExistingOrder.data.count', 0) > 0,
    contractProductGroups: get(state, `freemium.contractProductGroups.${customerId}.data`)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeMapper()(
    connect(mapStateToProps, mapDispatchToProps)(SetAdvisorWorkguideEbankingContractOrder)
  )
);
