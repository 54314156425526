import update from 'immutability-helper';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@evoja-web/react-form';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

import './DebitMasterCard.scss';
import { Section, SectionContent, SectionTitle } from '../../../../../General/components/Section';
import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../../General/components/SubSection';
import QuantityInput from '../../OpenAccount/QuantityInput';
import DebitMasterCardFrom from './DebitMasterCardForm';
import { RadioSelectWrapper } from '../../../../../General/index';

class OrderDebitMasterCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountQuantity: 1,
    };
    this.onDebitCardChange = this.onDebitCardChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  onQuantityChanged(val) {
    const { accountQuantity } = this.state;
    const { freemiumActions, form } = this.props;

    const debitCards = get(form, 'data.debitCards', []);
    const updated = accountQuantity > val
      ? update(debitCards, { $splice: [[val - 1, 1]] })
      : update(debitCards, { $push: [{ deliveryToOffice: false }] });

    freemiumActions.openLilaSetSetValue('debitCards', updated);
    this.setState({ accountQuantity: val });
  }

  onDebitCardChange(index, account) {
    const { freemiumActions, form } = this.props;
    const accounts = get(form, 'data.debitCards', []);
    const updated = update(accounts, {
      [index]: { $set: account },
    });
    freemiumActions.openLilaSetSetValue('debitCards', updated);
  }

  onToggle(key, value) {
    const { freemiumActions } = this.props;

    // Add first card if toggle switches to true. Else unset value in state
    const cards = value
      ? [{ deliveryToOffice: false }]
      : [];

    freemiumActions.openLilaSetSetValue('debitCards', cards);
    freemiumActions.openLilaSetSetValue('showDebitCards', value);
  }

  cardOptions() {
    const { lilaSet, language } = this.props;
    const moduleCards = get(lilaSet, 'modules', []).find((module) => module.creditCards);
    const cards = [...get(lilaSet, 'set.debitCards', []), ...get(moduleCards, 'creditCards', [])];

    return cards.map((card) => {
      return {
        label: (
          <Row style={{ width: '95%' }}>
            <Col md={3} lg={3}>{get(card, `cardVarietyText.${language}`)}</Col>
            <Col md={3} lg={3}>{card.displayCardNumber}</Col>
            <Col md={4} lg={4}>{card.embossedLineOne}</Col>
            <Col md={2} lg={2}>{moment(card.expiryDate).format('MM/YY')}</Col>
          </Row>
        ),
        value: card.id
      };
    });
  }

  render() {
    const {
      accounts,
      cards,
      cmsCustomers,
      customer,
      form,
      gravitonCustomers,
      language,
      onLoadBpfCustomerData,
      powerOfAttorneys,
      freemiumActions,
      validations
    } = this.props;
    return (
      <Section className="debit-master-card--container">
        <SectionTitle>
          <FormattedMessage id="Freemium.Form.NewLilaSet.DebitCard" />
        </SectionTitle>

        <SubSection>
          <RadioSelectWrapper
            onChange={noop}
            value={get(form, 'data.chargingAccount', []).find((el) => el.debitAccountForFee)?.accountKey}
            id="chargingAccount"
            disabled
            options={this.cardOptions()}
            showValidations
          />
        </SubSection>

        <SectionContent>
          <SubSection>
            <SubSectionContent>
              <Row>
                <Col md={1} lg={1}>
                  <Toggle
                    value={get(form, 'data.showDebitCards', false)}
                    onChange={this.onToggle}
                  />
                </Col>
                <Col>
                  <FormattedMessage id="Freemium.Form.NewLilaSet.OrderNewDebitCard" />
                </Col>
              </Row>
              {get(form, 'data.showDebitCards', false) && <QuantityInput min={0} max={2} onChange={(val) => this.onQuantityChanged(val)} />}
            </SubSectionContent>
          </SubSection>

          {get(form, 'data.showDebitCards', false)
            && get(form, 'data.debitCards', []).map((value, index) => {
              return (
                <DebitMasterCardFrom
                  // eslint-disable-next-line react/no-array-index-key
                  key={`debit-card-${index}`}
                  accounts={accounts}
                  onDebitCardChange={this.onDebitCardChange}
                  index={index}
                  form={form}
                  customers={[customer]}
                  customer={customer}
                  cmsCustomers={cmsCustomers}
                  gravitonCustomers={gravitonCustomers}
                  powerOfAttorneys={powerOfAttorneys}
                  onLoadBpfCustomerData={onLoadBpfCustomerData}
                  freemiumActions={freemiumActions}
                  cards={cards}
                  validations={validations}
                  language={language}
                />
              );
            })}
        </SectionContent>
      </Section>
    );
  }
}

OrderDebitMasterCard.propTypes = {
  accounts: PropTypes.array,
  cards: PropTypes.array,
  cmsCustomers: PropTypes.object,
  customer: PropTypes.object,
  form: PropTypes.object,
  gravitonCustomers: PropTypes.object,
  language: PropTypes.string,
  lilaSet: PropTypes.object,
  onLoadBpfCustomerData: PropTypes.func,
  powerOfAttorneys: PropTypes.array,
  freemiumActions: PropTypes.object.isRequired,
  validations: PropTypes.object
};

OrderDebitMasterCard.defaultProps = {
  accounts: [],
  cards: [],
  cmsCustomers: {},
  customer: {},
  form: {},
  gravitonCustomers: {},
  language: 'de',
  lilaSet: {},
  onLoadBpfCustomerData: noop,
  powerOfAttorneys: [],
  validations: {}
};

export default OrderDebitMasterCard;
