import { takeEvery, takeLatest } from 'redux-saga/effects';
import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import actions from '../actions/Actions';
import cancelLilaSetProcess from './Customer/cancelLilaSetProcess';
import creditCardApproval from './CreditCard/approval';
import creditCardCanceled from './CreditCard/canceled';
import creditCardCctTool from './CreditCard/cctTool';
import creditCardKubeEdit from './CreditCard/kubeEdit';
import ebankingOrderViaMobileFormSave from './EBankingContract/DirectActivation/OrderViaMobileAppSave';
import fetchCustomerSavings from './Customer/Savings';
import fetchEContract from './EBankingContract/fetchEContract';
import getContractDispatchRules from './DispatchRule/getContractDispatchRules';
import getCrmProductGroups from './Customer/getProductGroups';
import getCustomerAccounts from './Customer/getAccounts';
import getCustomerCreditCards from './Customer/getCards';
import getCustomerPoas from './Customer/getPoas';
import getGravitonCustomer from './Customer/getGravitonCustomer';
import hasCustomerAccountExpenses from './Customer/hasCustomerAccountExpenses';
import initContractDispatchRulesForm from './DispatchRule/initContractDispatchRulesForm';
import initCreditCardOrderForm from './Form/initCreditCardOrderForm';
import isEligibleForReducedPrice from './Customer/isEligibleForReducedPriceSaga';
import orderCheck from './EBankingContract/OrderCheck';
import orderCreditCard from './CreditCard/order';
import reactivateFormSave from './EBankingContract/DirectActivation/ReactivateFormSave';
import saveConnectAccountsToContract from './Form/saveConnectAccountsToContract';
import saveContractDispatchRulesForm from './DispatchRule/saveContractDispatchRulesForm';
import saveContractDispatchRulesPostProcessingForm from './DispatchRule/saveContractDispatchRulesPostProcessingForm';
import saveDissolveLilaSet from './Form/saveDissolveLilaSetForm';
import saveEBankingKubeEditForm from './EBankingContract/saveKubeEditForm';
import saveEBankingOrderForm from './EBankingContract/saveOrderForm';

import loadProduct from './loadLilaSet';
import gravitonProducts from './Products/gravitonProducts';
import lilaSetProducts from './Products/lilaSetProducts';
import cardProdcuts from './Products/cardProducts';
import openAccount from './Form/openAccount';
import openOptionComfort from './Form/saveOptionComfortForm';
import openNewLilaSet from './Form/openNewLilaSet';
import initOptionPaperForm from './Form/initOptionPaperForm';
import saveOptionPaperForm from './Form/saveOptionPaperForm';
import fetchProductUsage from './Customer/fetchProductUsage';
import getAccountProductGroups from './Customer/getAccountProductGroups';
import getContractProductGroups from './Customer/getContractProductGroups';

const {
  CANCEL_LILA_SET_PROCESS_REQUEST,
  CONNNECT_ACCOUNTS_TO_CONTRACT_SAVE_REQUEST,
  OPEN_ACCOUNT_FORMS_SAVE_REQUEST,
  CONTRACT_DISPATCH_RULES_REQUEST,
  CONTRACT_DISPATCH_RULES_FORM_INIT_REQUEST,
  CONTRACT_DISPATCH_RULES_FORM_SAVE_REQUEST,
  CONTRACT_DISPATCH_RULES_POST_PROCESSING_FORM_SAVE_REQUEST,
  CREDIT_CARD_APPROVAL_REQUEST,
  CREDIT_CARD_CANCELED_REQUEST,
  CREDIT_CARD_CCT_TOOL_REQUEST,
  CREDIT_CARD_KUBE_EDIT_REQUEST,
  CREDIT_CARD_ORDER_FORM_INIT_REQUEST,
  CREDIT_CARD_ORDER_FORM_SAVE_REQUEST,
  CUSTOMER_ACCOUNTS_REQUEST,
  CUSTOMER_CARDS_REQUEST,
  CUSTOMER_POAS_REQUEST,
  DISSOLVE_LILA_SET_FORM_SAVE_REQUEST,
  EBANKING_KUBE_EDIT_FORM_SAVE_REQUEST,
  EBANKING_ORDER_FORM_SAVE_REQUEST,
  EBANKING_ORDER_VIA_MOBILE_FORM_SAVE_REQUEST,
  FETCH_ECONTRACT_REQUEST,
  PRODUCT_GROUPS_REQUEST,
  ACCOUNT_PRODUCT_GROUPS_REQUEST,
  CONTRACT_PRODUCT_GROUPS_REQUEST,
  GRAVITON_CUSTOMER_REQUEST,
  HAS_CUSTOMER_ACCOUNT_EXPENSES_REQUEST,
  IS_ELIGIBLE_FOR_REDUCED_PRICE_REQUEST,
  ORDER_CHECK_REQUEST,
  REACTIVATE_SAVE_REQUEST,
  SET_ADVISOR_SAVINGS_REQUEST,
  LOAD_LILA_SET_REQUEST,
  GRAVITON_PRODUCTS_REQUEST,
  LILA_SET_PRODUCTS_REQUEST,
  CARD_PRODUCTS_REQUEST,
  OPTION_COMFORT_FORM_SAVE_REQUEST,
  OPEN_LILA_SET_SAVE_REQUEST,
  OPTION_PAPER_FORM_INIT_REQUEST,
  OPTION_PAPER_FORM_SAVE_REQUEST,
  PRODUCT_USAGE_REQUEST
} = actions;

export default function* setAdvisorRootSaga() {
  yield takeEvery(CUSTOMER_ACCOUNTS_REQUEST, getCustomerAccounts);
  yield takeEvery(CUSTOMER_CARDS_REQUEST, getCustomerCreditCards);
  yield takeEvery(CUSTOMER_POAS_REQUEST, getCustomerPoas);
  yield takeEvery(GRAVITON_CUSTOMER_REQUEST, getGravitonCustomer);
  yield takeLatest(CANCEL_LILA_SET_PROCESS_REQUEST, cancelLilaSetProcess);
  yield takeLatest(CANCEL_LILA_SET_PROCESS_REQUEST, cancelLilaSetProcess);
  yield takeLatest(CONNNECT_ACCOUNTS_TO_CONTRACT_SAVE_REQUEST, saveConnectAccountsToContract);
  yield takeLatest(CONTRACT_DISPATCH_RULES_FORM_INIT_REQUEST, initContractDispatchRulesForm);
  yield takeLatest(CONTRACT_DISPATCH_RULES_FORM_SAVE_REQUEST, saveContractDispatchRulesForm);
  yield takeLatest(CONTRACT_DISPATCH_RULES_POST_PROCESSING_FORM_SAVE_REQUEST, saveContractDispatchRulesPostProcessingForm);
  yield takeLatest(CREDIT_CARD_APPROVAL_REQUEST, creditCardApproval);
  yield takeLatest(CREDIT_CARD_CANCELED_REQUEST, creditCardCanceled);
  yield takeLatest(CREDIT_CARD_CCT_TOOL_REQUEST, creditCardCctTool);
  yield takeLatest(CREDIT_CARD_KUBE_EDIT_REQUEST, creditCardKubeEdit);
  yield takeLatest(CREDIT_CARD_ORDER_FORM_INIT_REQUEST, initCreditCardOrderForm);
  yield takeLatest(CREDIT_CARD_ORDER_FORM_SAVE_REQUEST, orderCreditCard);
  yield takeLatest(DISSOLVE_LILA_SET_FORM_SAVE_REQUEST, saveDissolveLilaSet);
  yield takeLatest(EBANKING_KUBE_EDIT_FORM_SAVE_REQUEST, saveEBankingKubeEditForm);
  yield takeLatest(EBANKING_ORDER_FORM_SAVE_REQUEST, saveEBankingOrderForm);
  yield takeLatest(EBANKING_ORDER_VIA_MOBILE_FORM_SAVE_REQUEST, ebankingOrderViaMobileFormSave);
  yield takeLatest(FETCH_ECONTRACT_REQUEST, fetchEContract);
  yield takeLatest(PRODUCT_GROUPS_REQUEST, getCrmProductGroups);
  yield takeLatestByDataKey(ACCOUNT_PRODUCT_GROUPS_REQUEST, getAccountProductGroups);
  yield takeLatestByDataKey(CONTRACT_PRODUCT_GROUPS_REQUEST, getContractProductGroups);
  yield takeLatest(HAS_CUSTOMER_ACCOUNT_EXPENSES_REQUEST, hasCustomerAccountExpenses);
  yield takeLatest(IS_ELIGIBLE_FOR_REDUCED_PRICE_REQUEST, isEligibleForReducedPrice);
  yield takeLatest(ORDER_CHECK_REQUEST, orderCheck);
  yield takeLatest(REACTIVATE_SAVE_REQUEST, reactivateFormSave);
  yield takeLatest(SET_ADVISOR_SAVINGS_REQUEST, fetchCustomerSavings);
  yield takeLatestByDataKey(CONTRACT_DISPATCH_RULES_REQUEST, getContractDispatchRules);

  yield takeLatestByDataKey(LOAD_LILA_SET_REQUEST, loadProduct);
  yield takeLatestByDataKey(GRAVITON_PRODUCTS_REQUEST, gravitonProducts);
  yield takeLatestByDataKey(LILA_SET_PRODUCTS_REQUEST, lilaSetProducts);
  yield takeLatestByDataKey(CARD_PRODUCTS_REQUEST, cardProdcuts);
  yield takeLatest(OPEN_ACCOUNT_FORMS_SAVE_REQUEST, openAccount);
  yield takeLatest(OPTION_COMFORT_FORM_SAVE_REQUEST, openOptionComfort);
  yield takeLatest(OPEN_LILA_SET_SAVE_REQUEST, openNewLilaSet);
  yield takeLatest(OPTION_PAPER_FORM_INIT_REQUEST, initOptionPaperForm);
  yield takeLatest(OPTION_PAPER_FORM_SAVE_REQUEST, saveOptionPaperForm);
  yield takeLatestByDataKey(PRODUCT_USAGE_REQUEST, fetchProductUsage);
}
