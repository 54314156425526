import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';

const TimeContext = createContext(Date.now());

export function GlobalTimeProvider({ children, refreshCycle }) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, refreshCycle);

    return () => clearInterval(intervalId);
  }, [refreshCycle]);

  return (
    <TimeContext.Provider value={currentTime}>
      {children}
    </TimeContext.Provider>
  );
}

GlobalTimeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  refreshCycle: PropTypes.number
};

GlobalTimeProvider.defaultProps = {
  refreshCycle: 60000
};

export const useGlobalTime = () => useContext(TimeContext);
