import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isUndefined } from 'lodash';
import { CustomerAware } from '@evoja-web/customer-workguide-search';
import { withRouter } from 'react-router';

import NoCustomerChangeError from '../../components/Workguide/NoCustomerChangeError';

class SetAdvisorWorkguideNoCustomerChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerChanged: false,
      prevCustomer: undefined
    };

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onBackToPrevCustomer = this.onBackToPrevCustomer.bind(this);
  }

  /**
   * Handle customer change.
   * Set customerChanged to true / store prev customer
   * as customer change is not possible in this workguide.
   *
   * @param  {Object} customer     Selected customer
   * @param  {Object} prevCustomer Prev customer
   *
   * @return void
   */
  onCustomerChange(customer, prevCustomer) {
    if (!isUndefined(prevCustomer) && (get(customer, 'id') !== get(prevCustomer, 'id'))) {
      this.setState({ customerChanged: true, prevCustomer });
    }
  }

  /**
   * Switch back to the prev customer
   *
   * @return void
   */
  onBackToPrevCustomer() {
    const { prevCustomer } = this.state;
    const { history, workguideId } = this.props;

    this.setState({ customerChanged: false, prevCustomer: undefined });

    history.push(`/activity/${workguideId}/${get(prevCustomer, 'id')}`);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { customerChanged } = this.state;
    const {
      children,
      onCustomerInit
    } = this.props;

    if (customerChanged) {
      return (
        <NoCustomerChangeError onBackToPrevCustomer={this.onBackToPrevCustomer} />
      );
    }

    return (
      <CustomerAware
        onChange={this.onCustomerChange}
        onInit={onCustomerInit}
      >
        {children}
      </CustomerAware>
    );
  }
}

SetAdvisorWorkguideNoCustomerChange.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object.isRequired,
  onCustomerInit: PropTypes.func,
  workguideId: PropTypes.string.isRequired
};

SetAdvisorWorkguideNoCustomerChange.defaultProps = {
  children: [],
  onCustomerInit: noop
};

export default withRouter(SetAdvisorWorkguideNoCustomerChange);
