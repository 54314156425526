import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import TableRow from './TableRow';
import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../../General/components/SubSection/index';

const LimitDeterminationSummaryExpenses = React.memo(({ data }) => {
  const children = get(data, 'monthlyExpenses', []).map((item, index) => (
    <TableRow
      // eslint-disable-next-line
      key={index}
      data={item}
      type="expense"
    />
  ));

  return (
    <SubSection>
      <SubSectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Expenses.Title" />
      </SubSectionTitle>

      <SubSectionContent>
        {children}
      </SubSectionContent>
    </SubSection>
  );
});

LimitDeterminationSummaryExpenses.propTypes = {
  data: PropTypes.object
};

LimitDeterminationSummaryExpenses.defaultProps = {
  data: {}
};

export default LimitDeterminationSummaryExpenses;
