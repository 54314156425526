import { call, put } from 'redux-saga/effects';

import multipleSessionsModalAction from '../actions/MultipleSessionsModal';
import getUserInfo from './getUserInfo';

const { MULTIPLE_SESSIONS_MODAL_FULFILLED } = multipleSessionsModalAction;

export default function* checkMultipleSessions() {
  try {
    const userInfo = yield call(getUserInfo);
    const { username, activeTokenCount } = userInfo;

    const payload = {
      activeTokenCount,
      username,
      isOpen: false
    };

    if (activeTokenCount > 1) {
      yield put({ type: MULTIPLE_SESSIONS_MODAL_FULFILLED, payload: { ...payload, isOpen: true } });
    }

    return payload;
  } catch (error) {
    return error;
  }
}
