import React, { useCallback, memo } from 'react';
import cl from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { HtmlPreview } from '../../../General';
import './NotificationHeader.scss';
import RelativeTimeDisplay from './RelativeTime';

const NotificationHeader = memo(({
  hasUnread,
  description,
  latestMessage,
  toggleExpand,
  handleMarkAllAsRead,
  sortedCount,
  expanded,
  consultants,
  session,
  onMarkAsRead
}) => {
  const getUsername = useCallback((topic) => {
    const consultant = consultants.find((c) => c.id === topic);
    return get(consultant, 'employeeId', get(consultant, 'shortDesignation'));
  }, [consultants]);

  const onItemClicked = useCallback((e) => {
    const id = get(latestMessage, 'id', get(latestMessage, '_id')); onMarkAsRead(id);
  }, [latestMessage, onMarkAsRead]);

  const handleMarkAllClick = (e) => {
    e.stopPropagation();
    handleMarkAllAsRead();
  };

  const canMarkAsRead = get(session, 'id') === get(latestMessage, 'topic');

  return (
    <Row
      className={cl('notification-header align-items-center g-2')}
      role="button"
      tabIndex={0}
    >
      <Col xs={11} onClick={onItemClicked}>
        <Row>
          <Col>
            <p className="notification-header__content mb-2">
              <HtmlPreview>
                {description}
              </HtmlPreview>
            </p>
            <div className="notification-header__meta">
              <span className="notification-header__meta-username">
                {getUsername(get(latestMessage, 'topic'))}
              </span>
              <span className="notification-header__meta-separator">|</span>
              <span>
                <RelativeTimeDisplay timestamp={latestMessage.timeIndex} />

              </span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={1}>
        <Row className="justify-content-end g-2">
          {hasUnread && canMarkAsRead && (
            <Col xs="auto">
              <button
                onClick={handleMarkAllClick}
                type="button"
                className="notification-header__action-button"
                aria-label="Mark all as read"
                title="Mark all as read"
              >
                <i className={cl('mdi notification-header__action-button-icon', {
                  'mdi-check-all': sortedCount > 1,
                  'mdi-check': sortedCount <= 1
                })}
                />
              </button>
            </Col>
          )}
          {sortedCount > 1 && (
            <Col xs="auto" onClick={toggleExpand}>
              <i
                className={cl('mdi notification-header__toggle-icon', {
                  'mdi-chevron-down': !expanded,
                  'mdi-chevron-up': expanded,
                  'notification-header__toggle-icon--expanded': expanded
                })}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
});

NotificationHeader.propTypes = {
  latestMessage: PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    contentDe: PropTypes.string,
    contentFr: PropTypes.string,
    timeIndex: PropTypes.number.isRequired,
    read: PropTypes.bool
  }).isRequired,
  description: PropTypes.string.isRequired,
  hasUnread: PropTypes.bool,
  toggleExpand: PropTypes.func.isRequired,
  handleMarkAllAsRead: PropTypes.func.isRequired,
  sortedCount: PropTypes.number,
  expanded: PropTypes.bool,
  consultants: PropTypes.array,
  session: PropTypes.object.isRequired
};

NotificationHeader.defaultProps = {
  hasUnread: false,
  sortedCount: 0,
  expanded: false,
  consultants: []
};

function mapStateToProps(state) {
  return {
    consultants: state.consultantSearch.activeConsultants,
    session: state.login.session
  };
}

export default connect(mapStateToProps)(NotificationHeader);
