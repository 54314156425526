import React from 'react';
import PropTypes from 'prop-types';
import { get, head, defaults, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Toggle from '../AmountList/Toggle';
import List from '../AmountList/List';

const defaultValues = {
  residentialCosts: {
    expenseType: 'residentialCosts',
    amount: undefined,
    multiplier: 1,
    costs: undefined
  },
  mortgage: {
    expenseType: 'mortgage',
    amount: undefined,
    multiplier: 0.003,
    costs: undefined
  }
};

class MonthlyExpenseResidential extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaults: get(defaultValues, 'residentialCosts')
    };

    this.onToggle = this.onToggle.bind(this);
  }

  /**
   * Handle toggle change.
   * Update current data with default values
   *
   * @param  {String} key  Form element key (id)
   * @param  {Event}  ev   Event
   * @param  {Array}  rest Rest props
   *
   * @return void
   */
  onToggle(key, ev, ...rest) {
    const {
      data,
      onChange
    } = this.props;

    const checked = get(ev, 'target.checked', false);
    const d = checked
      ? get(defaultValues, 'mortgage')
      : get(defaultValues, 'residentialCosts');

    const updated = data.map((item) => defaults({}, d, item));

    this.setState({ defaults: d });
    onChange(key, updated, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      data,
      onAdd,
      onChange
    } = this.props;

    const selectedType = get(head(data), 'expenseType');
    const itemLabel = selectedType === 'mortgage'
      ? <FormattedMessage id="Freemium.Form.CreditCard.MonthlyExpense.Amount.Mortgage" />
      : (
        <FormattedMessage
          id="Freemium.Form.CreditCard.MonthlyExpense.Amount.ResidentialCosts"
          values={{
            small: (chunks) => <small>{chunks}</small>
          }}
        />
      );

    return (
      <div className="setadvisor-limit-calculation--monthly-expense--residential">
        <Toggle
          checked={selectedType === 'mortgage'}
          defaults={get(this, 'state.defaults', {})}
          onAdd={onAdd}
          onToggle={this.onToggle}
          type={selectedType}
        >
          <FormattedMessage id="Freemium.Form.CreditCard.ExpenseType.Residential" />
        </Toggle>

        <List
          itemLabel={itemLabel}
          items={data}
          onChange={onChange}
          removeable={({ index }) => index > 0}
          type={selectedType}
        />
      </div>
    );
  }
}

MonthlyExpenseResidential.propTypes = {
  data: PropTypes.array,
  onAdd: PropTypes.func,
  onChange: PropTypes.func
};

MonthlyExpenseResidential.defaultProps = {
  data: [],
  onAdd: noop,
  onChange: noop
};

export default MonthlyExpenseResidential;
