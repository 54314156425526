import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

const FormSubSectionContent = React.memo(({
  children,
  className,
  style
}) => {
  const classNames = cl(className, 'form-common-sub-section--content');

  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
});

FormSubSectionContent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ])
};

FormSubSectionContent.defaultProps = {
  children: [],
  className: undefined,
  style: {}
};

export default FormSubSectionContent;
