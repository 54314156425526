import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import kubeEditAction from '../../actions/CreditCard/KubeEdit';

const {
  CREDIT_CARD_KUBE_EDIT_PENDING,
  CREDIT_CARD_KUBE_EDIT_FULFILLED,
  CREDIT_CARD_KUBE_EDIT_REJECTED
} = kubeEditAction;

export default function* kubeEdit(request) {
  yield put({ type: CREDIT_CARD_KUBE_EDIT_PENDING });

  try {
    const {
      acl,
      comments = [],
      skipUserCheck = false,
      taskId
    } = request;

    const data = {
      variables: {
        comments
      }
    };

    const payload = yield call(axios, {
      url: `/sif/bpf/public/api/v1/task/${taskId}/complete?skipUserCheck=${skipUserCheck}`,
      method: 'post',
      data
    });

    yield put({ type: CREDIT_CARD_KUBE_EDIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CREDIT_CARD_KUBE_EDIT_REJECTED, error });

    return error;
  }
}
