import { get } from 'lodash';

import AdditionalCard from './AdditionalCard';
import CctTool from './CctTool';
import MainAndAdditionalCard from './MainAndAdditonalCard';
import MainOrComboCard from './MainOrComboCard';
import {
  isAdditionalCardCategorySelected,
  isComboCardCategorySelected,
  isMainCardCategorySelected,
  isMainAndAdditionalCardCategorySelected
} from '../../../../lib/CreditCard/index';

const factories = [{
  cond: ({ form }) => isAdditionalCardCategorySelected(get(form, 'data.cards', [])),
  Factory: AdditionalCard
}, {
  cond: ({ form }) => get(form, 'data.orderInCctTool', false),
  Factory: CctTool
}, {
  cond: ({ form }) => isMainAndAdditionalCardCategorySelected(get(form, 'data.cards', [])),
  Factory: MainAndAdditionalCard
}, {
  cond: ({ form }) => isMainCardCategorySelected(get(form, 'data.cards', [])) || isComboCardCategorySelected(get(form, 'data.cards', [])),
  Factory: MainOrComboCard
}];

/**
 * Return the order payload based on current form data
 *
 * @param       {Object} calculation  Limit calculation (if active)
 * @param       {Object} customer     Selected customer
 * @param       {Object} form         Form object
 * @param       {Object} session      User session
 *
 * @return {Object} payload Request payload
 */
export default function OrderPayloadFactory({
  calculation,
  customer,
  form,
  session
}) {
  const factory = factories.find((f) => get(f, 'cond', () => false)({ form }));

  return get(factory, 'Factory', () => {})({
    calculation,
    customer,
    form,
    session
  });
}
