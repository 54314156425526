import { Actions } from '@evoja-web/redaction';

import { notificationAction } from './Notification';
import { notificationBadgeCountAction } from './BadgeCount';
import { markNotificationAsReadAction } from './MarkNotificationRead';
import { notificationCenterSubscriptionAction } from './NotificationCenterSubscription';

export const actions = Actions([
  notificationAction,
  notificationBadgeCountAction,
  markNotificationAsReadAction,
  notificationCenterSubscriptionAction
]);

export default actions.get();
