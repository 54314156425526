import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col } from 'reactstrap';
import { get, noop, isUndefined, isEmpty } from 'lodash';
import moment from 'moment';

import './Comment.css';
import { InputField } from '../../../../../General/index';
import Comment from './Comment';
import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../General/components/SubSection/index';

class CommentInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: get(props, 'comments', []),
      value: undefined
    };

    this.onAdd = this.onAdd.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  /**
   * Handle input change
   *
   * @param  {String} id    Form element id
   * @param  {String} value Input value
   *
   * @return void
   */
  onValueChange(id, value) {
    this.setState({ value });
  }

  /**
   * Add the current value to comments and call parent onChange
   *
   * @return {[type]} [description]
   */
  onAdd() {
    const { comments, value } = this.state;
    const { id, onChange, session } = this.props;

    const comment = {
      value,
      editor: get(session, 'username'),
      timestamp: moment().utc().format()
    };
    const updated = [...comments, comment];

    this.setState({ comments: updated, value: undefined });

    onChange(id, updated);
  }

  /**
   * Remove the given comment from comments
   *
   * @param  {Number} index  Index
   * @param  {Array}  rest   Rest args
   *
   * @return void
   */
  onRemove(index, ...rest) {
    const { comments } = this.state;
    const { id, onChange } = this.props;

    const updated = comments.filter((c, i) => i !== index);

    this.setState({ comments: updated });

    onChange(id, updated, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      comments,
      value
    } = this.state;
    const {
      disabled,
      id,
      title,
      timeFormat
    } = this.props;

    const children = comments.map((comment, index) => (
      <Comment
        // eslint-disable-next-line
        key={index}
        comment={comment}
        isRemovable={!disabled}
        onRemove={(...args) => this.onRemove(index, ...args)}
        timeFormat={timeFormat}
      />
    ));

    return (
      <SubSection>
        <SubSectionContent className="setadvisor-form-common-comments--content">
          {!isEmpty(children) && (
            <React.Fragment>
              <SubSectionTitle>
                <FormattedMessage id="Freemium.General.Comments.Comment" />
              </SubSectionTitle>

              {children}
            </React.Fragment>
          )}

          {!disabled && (
            <>
              <SubSectionTitle>
                {title}
              </SubSectionTitle>

              <Row>
                <Col lg={12} md={12}>
                  <InputField
                    id={id}
                    name={id}
                    onChange={this.onValueChange}
                    type="textarea"
                    value={value}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: '2rem' }}>
                <Col lg={12} md={12} className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    disabled={isUndefined(value)}
                    onClick={this.onAdd}
                  >
                    <FormattedMessage id="General.Button.Add" />
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </SubSectionContent>
      </SubSection>
    );
  }
}

CommentInput.propTypes = {
  // Used in constructor
  // eslint-disable-next-line
  comments: PropTypes.array,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  session: PropTypes.object.isRequired,
  title: PropTypes.node,
  timeFormat: PropTypes.string
};

CommentInput.defaultProps = {
  comments: [],
  disabled: false,
  id: 'comments',
  onChange: noop,
  title: <FormattedMessage id="Freemium.Form.CreditCard.AddComment" />,
  timeFormat: undefined
};

export default CommentInput;
