import { get, some } from 'lodash';

/**
 * Check if the main and additional card category was selected based on cards in the array
 *
 * @param  {Array}   [cards=[]] Array of cards
 *
 * @return {Boolean}
 */
export default function isMainAndAdditionalCardCategorySelected(cards = []) {
  return (
    get(cards, 'length', 0) > 1
    && some(cards, ['categoryType', 'HK'])
    && some(cards, ['categoryType', 'ZK'])
  );
}
