import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { upperFirst } from 'lodash';

import Row from './Row';

const BeneficialOwner = React.memo(({ beneficialOwner }) => {
  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.BeneficialOwner" />}>
      <FormattedMessage id={`Freemium.Form.CreditCard.BeneficialOwner.${upperFirst(beneficialOwner)}`} />
    </Row>
  );
});

BeneficialOwner.propTypes = {
  beneficialOwner: PropTypes.string
};

BeneficialOwner.defaultProps = {
  beneficialOwner: ''
};

export default BeneficialOwner;
