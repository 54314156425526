import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

const UserInfoMultipleActiveSessionWarning = React.memo(function UserInfoMultipleActiveSessionWarning({
  activeTokenCount,
  username
}) {
  return (
    <Alert color="warning">
      <AlertTitle>
        <FormattedMessage id="User.UserInfo.MultipleActiveSessions.Warning.Title" />
      </AlertTitle>

      <AlertContent>
        <p>
          <FormattedMessage
            id="User.UserInfo.MultipleActiveSessions.Warning.Description1"
            values={{ username }}
          />
        </p>

        <p>
          <FormattedMessage id="User.UserInfo.MultipleActiveSessions.Warning.Description2" />
        </p>

        <p>
          <FormattedMessage id="User.UserInfo.MultipleActiveSessions.Warning.Description3" />
        </p>

        <p>
          <FormattedMessage id="User.UserInfo.MultipleActiveSessions.Warning.Description4" />
        </p>

        <p>
          <FormattedMessage id="User.UserInfo.MultipleActiveSessions.Warning.Description5" />
        </p>

        <p className="pt-4">
          <FormattedMessage
            id="User.UserInfo.MultipleActiveSessions.Warning.ActiveTokenCount"
            values={{ activeTokenCount }}
          />
        </p>
      </AlertContent>
    </Alert>
  );
});

UserInfoMultipleActiveSessionWarning.propTypes = {
  activeTokenCount: PropTypes.number,
  username: PropTypes.object.isRequired
};

UserInfoMultipleActiveSessionWarning.defaultProps = {
  activeTokenCount: 0
};

export default UserInfoMultipleActiveSessionWarning;
