const notificationTabs = {
  ALL: 'all',
  LEAD: 'lead',
  ACTIVITY: 'activity',
  OTHERS: 'others',
  DOCUMENT: 'document',
  MESSENGER: 'messenger',
  NOTE: 'note'
};

const notificationTypeMapping = new Map([
  [notificationTabs.LEAD, ['lead', 'mention-lead']],
  [notificationTabs.ACTIVITY, ['activity', 'activity-changed', 'activity-updated']],
  [notificationTabs.DOCUMENT, ['document']],
  [notificationTabs.MESSENGER, ['messenger']],
  [notificationTabs.NOTE, ['note']]
]);

const groupingRules = {
  activity: {
    fields: ['typeId'],
  },
  document: {
    fields: ['typeId']
  },
  lead: {
    fields: ['typeId']
  },
  note: {
    fields: ['typeId']
  }
};

export { notificationTabs, notificationTypeMapping, groupingRules };
