import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Toggle from '../AmountList/Toggle';
import List from '../AmountList/List';

const defaults = {
  expenseType: 'leasing',
  multiplier: 1,
  costs: undefined,
  amount: undefined
};

const MonthlyExpenseLeasing = React.memo(({
  data,
  onAdd,
  onChange,
  onReset
}) => {
  return (
    <div className="setadvisor-limit-calculation--monthly-expense--leasing">
      <Toggle
        addOnChecked
        checked={get(data, 'length', 0) > 0}
        defaults={defaults}
        onAdd={onAdd}
        onReset={onReset}
        resetOnUnchecked
        type="leasing"
      >
        <FormattedMessage id="Freemium.Form.CreditCard.ExpenseType.Leasing" />
      </Toggle>

      <List
        itemLabel={<FormattedMessage id="Freemium.Form.CreditCard.MonthlyExpense.Amount.LeasingRate" />}
        items={data}
        onChange={onChange}
        removeable
        type="leasing"
      />
    </div>
  );
});

MonthlyExpenseLeasing.propTypes = {
  data: PropTypes.array,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onReset: PropTypes.func
};

MonthlyExpenseLeasing.defaultProps = {
  data: [],
  onAdd: noop,
  onChange: noop,
  onReset: noop
};

export default MonthlyExpenseLeasing;
