import { get } from 'lodash';

import toIntIfSet from '../../../../../../lib/Utils/toIntIfSet';

export default function AdditionalCardHolder({ form }) {
  const cards = get(form, 'data.cards', []);

  const additionalCard = cards.find((card) => get(card, 'categoryType') === 'ZK');

  const clientKey = get(form, 'data.additionalHolder.poaAdditional.poaKey') === 'otherCardHolder'
    ? get(form, 'data.additionalHolder.otherCardHolder.id')
    : get(form, 'data.additionalHolder.poaAdditional.clientKey');
  return {
    clientInformation: {
      // fallback otherCardHolder which stores the customer object
      clientKey: toIntIfSet(clientKey),
      addressType: toIntIfSet(get(form, 'data.addressTypeAdditional')),
      relationshipToMainCardHolder: toIntIfSet(get(form, 'data.relationshipToMainCardHolder.number'))
    },
    cards: [additionalCard]
  };
}
