import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';

const Email = React.memo(({ cardHolder }) => {
  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.Email" />}>
      {get(cardHolder, 'clientInformation.email', '')}
    </Row>
  );
});

Email.propTypes = {
  cardHolder: PropTypes.object
};

Email.defaultProps = {
  cardHolder: {}
};

export default Email;
