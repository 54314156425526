import { Actions } from '@evoja-web/redaction';

import { multipleSessionsModalAction } from './MultipleSessionsModal';
import { userInfoAction } from './UserInfo';

export const actions = Actions([
  multipleSessionsModalAction,
  userInfoAction
]);

export default actions.get();
