import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import './SubSectionTitle.css';

const FormSubSectionTitle = React.memo(({
  children,
  className
}) => {
  const classNames = cl(className, 'form-common-sub-section--title');

  return (
    <div className={classNames}>
      {children}
    </div>
  );
});

FormSubSectionTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ])
};

FormSubSectionTitle.defaultProps = {
  children: [],
  className: undefined
};

export default FormSubSectionTitle;
