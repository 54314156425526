import update from 'immutability-helper';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@evoja-web/react-form';
import { Col, Row } from 'reactstrap';

import { Alert, AlertContent, AlertTitle } from '@evoja-web/react-layout-components';
import { Section, SectionContent, SectionTitle } from '../../../../General/components/Section';
import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../General/components/SubSection';
import AccountCreator from '../OpenAccount/AccountCreator';
import QuantityInput from '../OpenAccount/QuantityInput';
import './ExtraProduct.scss';

class ExtraProducts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountQuantity: 0
    };

    this.onChange = this.onChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onQuantityChanged = this.onQuantityChanged.bind(this);
  }

  onQuantityChanged(val) {
    const { accountQuantity } = this.state;
    const { freemiumActions, form } = this.props;

    const additionalProducts = get(form, 'data.additionalProducts', []);
    const updated = accountQuantity > val
      ? update(additionalProducts, { $splice: [[val - 1, 1]] })
      : update(additionalProducts, { $push: [{}] });

    freemiumActions.openLilaSetSetValue('additionalProducts', updated);
    this.setState({ accountQuantity: val });
  }

  onChange(key, value, index) {
    const { form, onExtraProductChange } = this.props;
    const additionalProducts = get(form, `data.additionalProducts.${index}`, {});
    onExtraProductChange(
      index,
      update(additionalProducts, {
        [key]: { $set: value }
      })
    );
  }

  onToggle(key, value) {
    const { freemiumActions } = this.props;

    // Add first (empty) product if toggle switches to true. Else reset products
    const products = value
      ? [{}]
      : [];

    freemiumActions.openLilaSetSetValue('additionalProducts', products);
    freemiumActions.openLilaSetSetValue('showProducts', value);
  }

  render() {
    const {
      form, validations, language, productGroups, codes, onExtraProductChange
    } = this.props;

    return (
      <Section className="extra-prduct--container">
        <SectionTitle>
          <FormattedMessage id="Freemium.Form.NewLilaSet.ExtraProductTitle" />
        </SectionTitle>
        <SectionContent>
          <SubSection>
            <Row>
              <Col md={12} lg={12}>
                <Alert type="info">
                  <AlertContent>
                    <FormattedMessage id="Freemium.Form.NewLilaSet.NewProductsTechnicalDisclaimer" />
                  </AlertContent>
                </Alert>
              </Col>
            </Row>
            <SubSectionContent style={{ marginTop: 20 }}>
              <Row>
                <Col md={1} lg={1}>
                  <Toggle
                    value={get(form, 'data.showProducts', false)}
                    onChange={this.onToggle}
                  />
                </Col>
                <Col>
                  <FormattedMessage id="Freemium.Form.NewLilaSet.AllowExtraProduct" />
                </Col>
              </Row>
              {
                  get(form, 'data.showProducts', false) && <QuantityInput min={1} onChange={(val) => this.onQuantityChanged(val)} />
              }
            </SubSectionContent>
          </SubSection>

          {get(form, 'data.showProducts', false) && get(form, 'data.additionalProducts', []).map((value, index) => {
            return (
              <div className="extra-product--content">

                <AccountCreator
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  label={<FormattedMessage id="Freemium.Form.OpenAccount.CreateAccountLabel" values={{ value: index + 1 }} />}
                  onChange={onExtraProductChange}
                  productGroups={productGroups.filter((product) => [2000, 2010, 2011, 2021].includes(product.productNumber))}
                  account={value}
                  codes={codes}
                  validations={validations}
                  language={language}
                />
              </div>

            );
          })}

        </SectionContent>
      </Section>
    );
  }
}

ExtraProducts.propTypes = {
  form: PropTypes.object,
  onExtraProductChange: PropTypes.func.isRequired,
  validations: PropTypes.object.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  productGroups: PropTypes.object.isRequired,
  language: PropTypes.string,
  codes: PropTypes.object.isRequired
};

ExtraProducts.defaultProps = {
  form: {},
  language: 'de'
};

export default ExtraProducts;
