import cl from 'classnames';
import { isUndefined, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './QuantityInput.scss';

class QuantityInput extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.defaultValue };
    this.emitValue = this.emitValue.bind(this);
  }

  emitValue = (newValue) => {
    const { max, min, onChange } = this.props;

    if (!isUndefined(max) && newValue > max) return;
    if (!isUndefined(min) && newValue < min) return;

    this.setState({ value: newValue });
    onChange(newValue);
  };

  render() {
    const {
      emitValue,
      state: { value },
    } = this;
    const { maxWidth, showBorder } = this.props;
    return (
      <div
        className={cl({
          'quantity-input': true,
          'show-border': showBorder
        })}
        style={{ maxWidth }}
      >
        <button type="button" className="quantity-input--button" onClick={() => emitValue(value - 1)}>
          <span className="mdi mdi-minus" />
        </button>
        <span type="number" className="quantity-input--display">
          {value}
        </span>
        <button type="button" className="quantity-input--button" onClick={() => emitValue(value + 1)}>
          <span className="mdi mdi-plus" />
        </button>
      </div>
    );
  }
}

QuantityInput.propTypes = {
  max: PropTypes.number,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  onChange: PropTypes.func,
  showBorder: PropTypes.bool,
  defaultValue: PropTypes.number
};

QuantityInput.defaultProps = {
  max: undefined,
  maxWidth: 165,
  min: undefined,
  onChange: noop,
  showBorder: false,
  defaultValue: 1
};

export default QuantityInput;
