import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import canceledAction from '../../actions/CreditCard/Canceled';

const {
  CREDIT_CARD_CANCELED_PENDING,
  CREDIT_CARD_CANCELED_FULFILLED,
  CREDIT_CARD_CANCELED_REJECTED
} = canceledAction;

export default function* rejected(request) {
  yield put({ type: CREDIT_CARD_CANCELED_PENDING });

  try {
    const {
      comments = [],
      skipUserCheck = false,
      taskId
    } = request;

    const data = {
      variables: {
        comments
      }
    };

    const payload = yield call(axios, {
      url: `/sif/bpf/public/api/v1/task/${taskId}/complete?skipUserCheck=${skipUserCheck}`,
      method: 'post',
      data
    });

    yield put({ type: CREDIT_CARD_CANCELED_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CREDIT_CARD_CANCELED_REJECTED, error });

    return error;
  }
}
