import moment from 'moment';
import { get } from 'lodash';

/**
 * Get the expire date based on the given dueDate and orderOrigin
 * Return the current value if the given dueDate is not valid or the given orderOrigin is not a task or potential
 *
 * @return  {String} expireDate Expire date
 */
export default function getDueDateByExpireDate({
  dueDate,
  expireDate,
  mapper,
  orderOrigin
}) {
  // Return current value if dueDate is not a valid date
  if (!moment(expireDate, moment.ISO_8601, true).isValid()) {
    return dueDate;
  }

  return mapper.getCombined('leadCategory', ['codeMapping-task', 'codeMapping-potential']).includes(get(orderOrigin, 'id'))
    // Add 1 month to the dueDate if orderOrigin in a task or potential
    ? moment(expireDate).endOf('day').subtract(1, 'months').format()
    // expireDate must not before dueDate
    : moment(dueDate).isSameOrBefore(moment(expireDate))
      ? dueDate
      : expireDate;
}
