import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import './Active.css';

const SetAdvisorFormLimitCalculationActive = React.memo(({
  id,
  onChange,
  value
}) => {
  return (
    <div className="setadvisor-form-limit-calculation--active">
      <Toggle
        id="toggle-manuel-address"
        onChange={(key, value) => onChange(id, value)}
        value={value}
      >
        <span className="setadvisor-form-limit-calculation--active--title">
          <FormattedMessage
            id="Freemium.Form.CreditCard.LimitCalculationActive"
            values={{
              small: (chunks) => (<span className="setadvisor-form-limit-calculation--active--hint">{chunks}</span>)
            }}
          />
        </span>
      </Toggle>

    </div>
  );
});

SetAdvisorFormLimitCalculationActive.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool
};

SetAdvisorFormLimitCalculationActive.defaultProps = {
  id: 'active',
  onChange: noop,
  value: false
};

export default SetAdvisorFormLimitCalculationActive;
