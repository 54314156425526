import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';
import { noop } from 'lodash';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';

const BeneficialOwner = React.memo(({
  id,
  onChange,
  validations,
  value
}) => {
  const options = [{
    value: 'mainCardholder',
    label: <FormattedMessage id="Freemium.Form.CreditCard.BeneficialOwner.MainCardholder" />
  }, {
    value: 'mainAndSupplementaryCardholders',
    label: <FormattedMessage id="Freemium.Form.CreditCard.BeneficialOwner.MainAndSupplementaryCardholders" />
  }, {
    value: 'supplementaryCardholder',
    label: <FormattedMessage id="Freemium.Form.CreditCard.BeneficialOwner.SupplementaryCardholder" />
  }];

  return (
    <Section className="setadvisor-form-credit-card-general--beneficial-owner">
      <SectionTitle>
        <FormattedMessage id="Freemium.Form.CreditCard.BeneficialOwner.Title" />
      </SectionTitle>

      <SectionContent>
        <RadioButtonGroup
          id={id}
          onChange={onChange}
          options={options}
          value={value}
        />

        <ValidationResult
          show
          validations={validations}
        />
      </SectionContent>
    </Section>
  );
});

BeneficialOwner.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string
};

BeneficialOwner.defaultProps = {
  id: 'beneficialOwner',
  onChange: noop,
  validations: {},
  value: undefined
};

export default BeneficialOwner;
