import { call, put, select } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import { first, get, isEmpty, isNil } from 'lodash';
import productUsageActions from '../../actions/Customer/ProductUsage';

const {
  PRODUCT_USAGE_PENDING,
  PRODUCT_USAGE_FULFILLED,
  PRODUCT_USAGE_REJECTED
} = productUsageActions;

export default function* fetchProductUsage(request) {
  const { dataKey } = request;

  yield put({ type: PRODUCT_USAGE_PENDING });

  try {
    const existing = yield select((state) => get(state, `freemium.productUsage.${dataKey}.data`));
    if (!isNil(existing) && !isEmpty(existing)) {
      yield put({ type: PRODUCT_USAGE_FULFILLED, dataKey, payload: existing });
      return existing;
    }

    const url = `/basic/productbundle/usage/?eq(customer.ref.$id,string:${dataKey})&limit(1)`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: PRODUCT_USAGE_FULFILLED, dataKey, payload: first(payload) });
    return payload;
  } catch (error) {
    yield put({ type: PRODUCT_USAGE_REJECTED, dataKey, error });

    return error;
  }
}
