import { get, groupBy, isNil } from 'lodash';
import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { v4 } from 'uuid';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import { toIntIfSet } from '../../../../lib/Utils';
import getCustomerAccounts from '../../../Account/sagas/getCustomerAccounts';
import { getCodeMapper } from '../../../../globals';
import { PRODUCT_CARD_TYPE_ACCOUNT, PRODUCT_CARD_TYPE_EBANKING, PRODUCT_CARD_TYPE_TWINT_ACCOUNT } from '../../lib/ProductCardTypes';
import { productIconMapping } from '../../lib/mobilePaymentTypes';
import fetchMobileBanking from '../../../Contract/sagas/Customer/fetchMobileBanking';
import gravitonProductsActions from '../../actions/Products/gravitonProducts';
import { isCompany } from '../../../../lib/Customer/Utils';

const { GRAVITON_PRODUCTS_PENDING, GRAVITON_PRODUCTS_FULFILLED, GRAVITON_PRODUCTS_REJECTED } = gravitonProductsActions;
class ProductFactory {
  constructor(paymentCode) {
    this.paymentCode = paymentCode;
  }

  createMobileBankingProduct(contract) {
    const customer = get(contract, 'customer');
    return {
      type: get(contract, 'contractType.text'),
      id: toIntIfSet(get(contract, 'id')),
      customerDesignation: get(customer, 'shortDesignationAbbreviation', get(customer, 'shortDesignation')),
      customerId: get(customer, 'id'),
      accountNumber: get(contract, 'number'),
      icon: productIconMapping.eBanking,
      productType: PRODUCT_CARD_TYPE_EBANKING,
      sector: this.paymentCode
    };
  }

  createEBillingProduct(contract) {
    const customer = get(contract, 'customer');
    return {
      type: 'eBill',
      id: `EBilling_${get(customer, 'id')}_${get(contract, 'id')}`,
      customerDesignation: get(customer, 'shortDesignationAbbreviation', get(customer, 'shortDesignation')),
      customerId: get(customer, 'id'),
      accountNumber: get(contract, 'number'),
      icon: productIconMapping.eBill,
      productType: PRODUCT_CARD_TYPE_EBANKING,
      sector: this.paymentCode
    };
  }

  // eslint-disable-next-line class-methods-use-this
  createAccountProduct(account) {
    return {
      id: toIntIfSet(get(account, 'id')),
      sector: get(account, 'sector'),
      type: get(account, 'accountType.text'),
      customerDesignation: get(account, 'customer.shortDesignationAbbreviation'),
      customerId: get(account, 'customerId', get(account, 'customer.id')),
      accountNumber: get(account, 'numberFormatted'),
      isAccount: true,
      accountId: get(account, 'id'),
      productType: PRODUCT_CARD_TYPE_ACCOUNT,
      icon: undefined,
      prio: 1
    };
  }

  // eslint-disable-next-line class-methods-use-this
  createTwintProduct(account) {
    return {
      id: v4(),
      sector: get(account, 'sector'),
      type: { de: 'Twint', fr: 'Twint' },
      customerDesignation: get(account, 'customer.shortDesignationAbbreviation'),
      customerId: get(account, 'customerId', get(account, 'customer.id')),
      accountNumber: get(account, 'numberFormatted'),
      isAccount: true,
      accountId: get(account, 'id'),
      productType: PRODUCT_CARD_TYPE_TWINT_ACCOUNT,
      icon: productIconMapping.twint
    };
  }
}

/**
 * Get twint information for the given customer
 *
 * @param   {String}  customerId  Customer id
 *
 * @return  {Array} info Twint info
 */
function* getTwintInfo({ customerId }) {
  const query = QueryBuilder()
    .eq('customerId', customerId, { type: 'string' })
    .limit(100)
    .getQueryString();

  const url = `/account/account/twint/${query}`;
  const data = yield call(handleNextHeaders, url);

  return data;
}

/**
 * Filter GRV accounts based on whether they are closed or are of a mortgage type.
 * Twint accounts are also specifically handled.
 * @param {string} customerId - Customer ID.
 * @param {ProductFactory} factory - The factory instance for creating account products.
 * @returns {array} Formatted account products.
 */
function* getRelevantAccounts(customerId, factory) {
  const grvCustomerAccounts = yield call(getCustomerAccounts, { dataKey: customerId });
  const grvAccounts = grvCustomerAccounts.filter((acc) => isNil(get(acc, 'closedDate')));
  // Get mortgage types to exclude
  const mapper = getCodeMapper();
  const mortgageTypes = mapper.get('accountsMap', 'interestAccounts');

  // Filter accounts to exclude mortgages
  const relevantAccounts = grvAccounts.filter((grvAccount) => !mortgageTypes.includes(get(grvAccount, 'accountType.id')));
  // Use factory to create account products
  const accountProducts = relevantAccounts.map((acc) => factory.createAccountProduct(acc));
  // Handle Twint accounts separately
  const twintInfo = yield call(getTwintInfo, { customerId });
  const twintAccountIds = twintInfo
    .filter((a) => get(a, 'hasTwint', false))
    .map((a) => a.accountId);
  const twintAccounts = relevantAccounts.filter((a) => twintAccountIds.includes(a.id));

  twintAccounts.forEach((acc) => {
    accountProducts.push(factory.createTwintProduct(acc));
  });

  return [...accountProducts, ...twintAccounts];
}

export default function* gravitonProducts(request) {
  const { dataKey, customer } = request;
  yield put({ type: GRAVITON_PRODUCTS_PENDING, dataKey });

  try {
    const { data: paymentCode } = yield call(axios.get, '/entity/code/sector-10');
    const mobileBanking = yield call(fetchMobileBanking, { dataKey: toIntIfSet(dataKey), loadAssignedCustomers: isCompany(customer) });

    const factory = new ProductFactory(paymentCode);

    const accounts = yield call(getRelevantAccounts, dataKey, factory);

    const eBilling = mobileBanking.map((contract) => factory.createEBillingProduct(contract));
    const mobileBankingData = mobileBanking.map((contract) => factory.createMobileBankingProduct(contract));

    const groupedProducts = groupBy([
      ...eBilling,
      ...mobileBankingData,
      ...accounts
    ], 'sector.id');

    yield put({ type: GRAVITON_PRODUCTS_FULFILLED, dataKey, payload: groupedProducts });
  } catch (error) {
    yield put({ type: GRAVITON_PRODUCTS_REJECTED, dataKey });
  }
}
