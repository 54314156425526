import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { InputField } from '../../../../../General/index';
import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../General/components/SubSection/index';
import normalizeEmbossedLine from '../../../../lib/CreditCard/normalizeEmbossedLine';

class EmbossedLine extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  /**
   * Handle onChange of the embossed line input
   *
   * @param  {String} id     Form element id
   * @param  {String} value  Input value
   *
   * @return void
   */
  onChange(elementKey, value) {
    const {
      onChange,
      id
    } = this.props;

    onChange(id, value);
  }

  /**
   * Normalize the input value when user leaves the input field
   *
   * @param  {String} id    Form element id
   * @param  {String} value Input value
   *
   * @return void
   */
  onBlur(id, value) {
    const { customerLanguage } = this.props;

    const normalized = normalizeEmbossedLine(value, customerLanguage);

    this.onChange(id, normalized);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      value,
      id,
      validations,
      title
    } = this.props;

    return (
      <SubSection>
        <SubSectionTitle>
          {title}
        </SubSectionTitle>

        <SubSectionContent>
          <InputField
            id={id}
            onBlur={this.onBlur}
            onChange={this.onChange}
            showValidations
            validations={validations}
            value={value}
          />
        </SubSectionContent>
      </SubSection>
    );
  }
}

EmbossedLine.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  customerLanguage: PropTypes.string
};

EmbossedLine.defaultProps = {
  id: 'embossedLineOne',
  title: '',
  value: '',
  onChange: noop,
  validations: {},
  customerLanguage: 'de'
};

export default EmbossedLine;
