import React from 'react';
import PropTypes from 'prop-types';
import { noop, get } from 'lodash';
import { RadioButton, RadioButtonGroup } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

import {
  SubSection,
  SubSectionTitle,
  SubSectionContent
} from '../../../../../../General/components/SubSection/index';
import isComboCardCategorySelected from '../../../../../lib/CreditCard/isComboCardCategorySelected';

class CardVarietyComboCard extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle change of the main card radio group.
   * Update cards in form data with a single card of category HK
   *
   * @param  {String} key      Form element id
   * @param  {Array}  selected Selected values
   * @param  {Array}  rest     Rest args
    *
   * @return void
   */
  onChange(key, selected, ...rest) {
    const { onCardsChange, onChange } = this.props;

    const c = {
      '517-401': [
        { categoryType: 'DC', cardVariety: 517, cardCurrency: 'CHF' },
        { categoryType: 'DC', cardVariety: 401, cardCurrency: 'CHF' }
      ],
      '506-404': [
        { categoryType: 'DC', cardVariety: 506, cardCurrency: 'CHF' },
        { categoryType: 'DC', cardVariety: 404, cardCurrency: 'CHF' }
      ]
    };

    onCardsChange(get(c, selected), ...rest);
    onChange(key, selected, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      cards,
      disabled,
      id
    } = this.props;
    const value = `${get(cards, '[0].cardVariety')}-${get(cards, '[1].cardVariety')}`;
    const active = isComboCardCategorySelected(cards);

    const options = [
      { value: '517-401', label: <FormattedMessage id="Freemium.Form.CreditCard.CardType.SilverAndClassic" /> },
      { value: '506-404', label: <FormattedMessage id="Freemium.Form.CreditCard.CardType.GoldAndGold" /> }
    ];

    return (
      <SubSection>
        <SubSectionTitle>
          <RadioButton
            id={`${id}-active`}
            disabled={disabled}
            value={active}
            onChange={(...args) => this.onChange(id, '517-401', ...args)}
          >
            <FormattedMessage id="Freemium.Form.CreditCard.CardCategory.Combo" />
          </RadioButton>
        </SubSectionTitle>

        {active && (
          <SubSectionContent>
            <RadioButtonGroup
              className="setadvisor-form-credit-card-general--card-variety--type"
              disabled={disabled}
              id={id}
              value={isComboCardCategorySelected(cards) ? value : undefined}
              onChange={this.onChange}
              options={options}
            />
          </SubSectionContent>
        )}
      </SubSection>
    );
  }
}

CardVarietyComboCard.propTypes = {
  cards: PropTypes.array,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onCardsChange: PropTypes.func,
  onChange: PropTypes.func
};

CardVarietyComboCard.defaultProps = {
  cards: [],
  disabled: false,
  id: 'cardVariety',
  onCardsChange: noop,
  onChange: noop
};

export default CardVarietyComboCard;
