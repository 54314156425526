import PropTypes from 'prop-types';
import React from 'react';
import update from 'immutability-helper';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';
import { get, isNil, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { workguideMap } from '../../../../../staticMappings/workguides';
import { SubSection, SubSectionContent } from '../../../../General/components/SubSection';
import './EBankingContractForm.scss';
import EBankingCreateNewContract from './EbankingCreateNewContract';
import { CheckboxGroup } from '../../../../General';
import { SectionTitle } from '../../../../General/components/Section';

class EBankingContractForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onBaseContractCreate = this.onBaseContractCreate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onExistingContractChanged = this.onExistingContractChanged.bind(this);
  }

  onChange(index, contract) {
    const { freemiumActions, form } = this.props;
    const contracts = get(form, 'data.newContract', []);
    const updated = update(contracts, {
      [index]: { $set: contract }
    });
    freemiumActions.openLilaSetSetValue('newContract', updated);
  }

  /**
   * Handle onClick of base contract create button
   *
   * @param  {String} customerId Customer id
   *
   * @return void
   */
  onBaseContractCreate(customerId) {
    const { history } = this.props;
    const url = `/activity/${workguideMap.get('create-basic-contract')}/${customerId}`;
    history.push(url);
  }

  onExistingContractChanged(id, value, selected) {
    const { freemiumActions } = this.props;
    const contracts = selected.map((contract) => contract.contract);
    freemiumActions.openLilaSetSetValue(id, contracts);
  }

  render() {
    const {
      eBankingContracts,
      form,
      freemiumActions,
      cards,
      cmsCustomers,
      validations,
      gravitonCustomers,
      language,
      powerOfAttorneys,
      onLoadBpfCustomerData,
      hasExistingOrder
    } = this.props;
    const selectedEBankingContractOption = get(form, 'data.selectedEBankingContractOption');

    const formOptions = [
      {
        value: 'contract-none',
        label: <FormattedMessage id="Freemium.Form.NewLilaSet.CreateNoContract" />
      },
      {
        value: 'contract-new',
        label: <FormattedMessage id="Freemium.Form.NewLilaSet.CreateNewContract" />
      }
    ];

    // Only add formOption when contractIds exists
    if (eBankingContracts.filter((el) => !isNil(el.contractId)).length > 0) {
      formOptions.push({
        value: 'contract-existing',
        label: <FormattedMessage id="Freemium.Form.NewLilaSet.UseExistingContract" />
      });
    }

    return (
      <div className="eBanking-contract--container">
        <SectionTitle>
          <FormattedMessage id="Freemium.Form.NewLilaSet.EBankingContract" />
        </SectionTitle>
        <RadioButtonGroup
          disabled={false}
          id="selectedEBankingContractOption"
          value={selectedEBankingContractOption}
          onChange={freemiumActions.openLilaSetSetValue}
          options={formOptions}

        />
        <ValidationResult
          show
          validations={get(validations, 'selectedEBankingContractOption')}
        />

        <div className="eBanking-contract--wrapper">
          {selectedEBankingContractOption === 'contract-existing' && (
            <SubSection>
              <SubSectionContent>
                <RadioButtonGroup
                  disabled={false}
                  id="eBankingOption"
                  value={get(form, 'data.eBankingOption-1', false)}
                  onChange={freemiumActions.openLilaSetSetValue}
                >
                  <b><FormattedMessage id="Freemium.Form.NewLilaSet.ExistingEbankingContract" /></b>
                </RadioButtonGroup>
                <CheckboxGroup
                  className="bpf-cms-customer-form-several-private-individuals--customer-address--checkbox-group"
                  id="existingContracts"
                  name="existingContracts"
                  onChange={this.onExistingContractChanged}
                  options={eBankingContracts.map((c) => ({ label: `${c.clientDesignation} (${c.contractId})`, value: c.contractId, contract: c }))}
                  value={get(form, 'data.existingContracts', []).map((existingContract) => existingContract.contractId)}
                  showValidations
                  multi
                  validations={get(validations, 'existingContracts')}
                />
              </SubSectionContent>
            </SubSection>
          )}
          {selectedEBankingContractOption === 'contract-new' && (
            <SubSection>
              <SubSectionContent>
                <EBankingCreateNewContract
                  language={language}
                  form={form}
                  freemiumActions={freemiumActions}
                  validations={validations}
                  cards={cards}
                  cmsCustomers={cmsCustomers}
                  gravitonCustomers={gravitonCustomers}
                  powerOfAttorneys={powerOfAttorneys}
                  onLoadBpfCustomerData={onLoadBpfCustomerData}
                  onChange={this.onChange}
                  hasExistingOrder={hasExistingOrder}
                />
              </SubSectionContent>
            </SubSection>
          )}

        </div>

      </div>
    );
  }
}

EBankingContractForm.propTypes = {
  form: PropTypes.object.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  eBankingContracts: PropTypes.array,
  cards: PropTypes.array.isRequired,
  cmsCustomers: PropTypes.object.isRequired,
  powerOfAttorneys: PropTypes.array.isRequired,
  gravitonCustomers: PropTypes.object.isRequired,
  onLoadBpfCustomerData: PropTypes.func,
  hasExistingOrder: PropTypes.bool,
  history: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
  language: PropTypes.string
};

EBankingContractForm.defaultProps = {
  eBankingContracts: [],
  onLoadBpfCustomerData: noop,
  hasExistingOrder: false,
  language: 'de'
};

export default withRouter(EBankingContractForm);
