import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get, isUndefined } from 'lodash';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';

function getPhoneNumberFromContacts(contacts = []) {
  const contact = contacts.find((c) => get(c, 'protocol') === 'tel' && get(c, 'type') === 'business');

  return get(contact, 'value', '');
}

const ResponsibleUsers = React.memo(({
  responsibleApprover,
  responsibleUser
}) => {
  return (
    <Section className="setadvisor-form-summary--responsible-users">
      <SectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.ResponsibleUsers" />
      </SectionTitle>

      <SectionContent>
        {!isUndefined(responsibleUser) && (
          <Row style={{ paddingBottom: '1rem' }}>
            <Col lg={4} md={4}>
              <FormattedMessage id="Freemium.Form.Summary.ResponsibleUser" />
            </Col>

            <Col lg={4} md={4}>
              {get(responsibleUser, 'firstName')}
              {' '}
              {get(responsibleUser, 'lastName')}
              {' '}
              {`(${get(responsibleUser, 'username')})`}
            </Col>

            <Col lg={4} md={4}>
              {getPhoneNumberFromContacts(get(responsibleUser, 'contacts'))}
            </Col>
          </Row>
        )}

        {!isUndefined(responsibleApprover) && (
          <Row>
            <Col lg={4} md={4}>
              <FormattedMessage id="Freemium.Form.Summary.ResponsibleApprover" />
            </Col>

            <Col lg={4} md={4}>
              {get(responsibleApprover, 'firstName')}
              {' '}
              {get(responsibleApprover, 'lastName')}
              {' '}
              {`(${get(responsibleApprover, 'username')})`}
            </Col>

            <Col lg={4} md={4}>
              {getPhoneNumberFromContacts(get(responsibleApprover, 'contacts'))}
            </Col>
          </Row>
        )}
      </SectionContent>
    </Section>
  );
});

ResponsibleUsers.propTypes = {
  responsibleApprover: PropTypes.object,
  responsibleUser: PropTypes.object
};

ResponsibleUsers.defaultProps = {
  responsibleApprover: undefined,
  responsibleUser: undefined
};

export default ResponsibleUsers;
