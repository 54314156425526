import { all, call } from 'redux-saga/effects';
import axios from 'axios';
import { compact, isUndefined } from 'lodash';

export default function* uploadFiles(files) {
  const operations = files.map((item) => {
    const { id, file } = item;

    if (isUndefined(id) || isUndefined(file)) return undefined;

    const formData = new FormData();
    formData.append('upload', file);

    return call(axios, {
      url: `/file/${id}`,
      method: 'put',
      data: formData
    });
  });

  const result = yield all(compact(operations));

  return result;
}
