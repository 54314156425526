import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { has, get } from 'lodash';

import './Limits.css';
import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../../General/components/SubSection/index';

const LimitDeterminationLimits = React.memo(({ cards, data }) => {
  const main = cards.find((card) => ['HK', 'DC'].includes(get(card, 'categoryType')));
  const additional = cards.find((card) => ['ZK'].includes(get(card, 'categoryType')));

  return (
    <SubSection className="setadvisor-form-limit-determination-summary--limits">
      <SubSectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Limits.Title" />
      </SubSectionTitle>

      <SubSectionContent>
        <Row className="setadvisor-form-limit-determination-summary--limits--row">
          <Col
            className="fw-normal"
            lg={8}
            md={8}
          >
            <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Limits.Desired" />
          </Col>

          <Col
            className="text-end"
            lg={2}
            md={2}
          >
            <FormattedNumber
              value={get(data, 'desiredGlobalLimit', get(data, 'calculatedDesiredGlobalLimit', 0))}
              // eslint-disable-next-line
              style="currency"
              currency="CHF"
            />
          </Col>
        </Row>

        {has(main, 'cardLimit') && (
          <Row className="setadvisor-form-limit-determination-summary--limits--row">
            <Col
              className="fw-normal"
              lg={8}
              md={8}
            >
              <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Limits.MainCard" />
            </Col>

            <Col
              className="text-end"
              lg={2}
              md={2}
            >
              <FormattedNumber
                value={get(main, 'cardLimit', 0)}
                // eslint-disable-next-line
                style="currency"
                currency="CHF"
              />
            </Col>
          </Row>
        )}

        {has(additional, 'cardLimit') && (
          <Row className="setadvisor-form-limit-determination-summary--limits--row">
            <Col
              className="fw-normal"
              lg={8}
              md={8}
            >
              <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Limits.AdditionalCard" />
            </Col>

            <Col
              className="text-end"
              lg={2}
              md={2}
            >
              <FormattedNumber
                value={get(additional, 'cardLimit', 0)}
                // eslint-disable-next-line
                style="currency"
                currency="CHF"
              />
            </Col>
          </Row>
        )}
      </SubSectionContent>
    </SubSection>
  );
});

LimitDeterminationLimits.propTypes = {
  cards: PropTypes.array,
  data: PropTypes.object
};

LimitDeterminationLimits.defaultProps = {
  cards: [],
  data: {}
};

export default LimitDeterminationLimits;
