import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { noop } from 'lodash';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

const NoCustomerChange = React.memo(({ onBackToPrevCustomer }) => {
  return (
    <Alert type="error">
      <AlertTitle>
        <FormattedMessage
          id="Freemium.Workguide.NoCustomerChange.Title"
        />
      </AlertTitle>
      <AlertContent>
        <FormattedMessage
          id="Freemium.Workguide.NoCustomerChange.Description"
        />

        <Button
          color="primary"
          onClick={onBackToPrevCustomer}
        >
          <FormattedMessage id="Freemium.Workguide.NoCustomerChange.BackToPrevCustomer" />
        </Button>
      </AlertContent>
    </Alert>
  );
});

NoCustomerChange.propTypes = {
  onBackToPrevCustomer: PropTypes.func
};

NoCustomerChange.defaultProps = {
  onBackToPrevCustomer: noop
};

export default NoCustomerChange;
