import React, { useMemo, useCallback, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import { camelCase, get, isEmpty, noop, orderBy, upperFirst } from 'lodash';
import { FormattedMessage } from 'react-intl';

import './NotificationList.scss';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';
import classNames from 'classnames';
import NotificationCardItem from './NotificationCardItem';
import { groupingRules, notificationTabs, notificationTypeMapping } from '../lib/notificationMapping';
import { SortDropdown, SortDropdownItem } from '../../General';
import { GlobalTimeProvider } from '../hooks/GlobalTimeProvider';

function groupMessages(messages, sortInstructions) {
  const sortFields = Object.keys(sortInstructions).filter(
    (field) => sortInstructions[field] !== undefined
  );
  const sortOrders = sortFields.map((field) => sortInstructions[field]);

  const sortMessages = orderBy(messages, sortFields, sortOrders);
  return sortMessages.reduce((acc, message) => {
    const { type } = message;
    const rule = groupingRules[type] || { fields: ['typeId'] };

    // Generate the grouping key dynamically
    const groupingKey = rule.fields.length === 0
      ? 'Ungrouped'
      : `${rule.fields.map((field) => message[field] || '').join('-')}`;

    acc[groupingKey] = acc[groupingKey] || [];
    acc[groupingKey].push(message);

    return acc;
  }, {});
}

function renderItem(index, groupedMessages, language, onMarkNotificationAsRead, sort) {
  return (
    <Card className="notification-list-card">
      <NotificationCardItem
        key={index}
        notifications={groupedMessages}
        language={language}
        sortInstructions={sort}
        onMarkNotificationAsRead={onMarkNotificationAsRead}
      />
    </Card>
  );
}

function NotificationList({
  messages, language, type, onMarkNotificationAsRead, currentSubscription, session
}) {
  const [sort, setSort] = useState({ read: undefined, timeIndex: 'desc' });

  const filteredAndGroupedMessages = useMemo(() => {
    if (type === notificationTabs.ALL) {
      return groupMessages(messages, sort);
    }
    // Filter messages based on the determined types
    const filteredMessages = messages.filter((message) => {
      return notificationTypeMapping.get(type).includes(message.type);
    });

    return groupMessages(filteredMessages, sort);
  }, [messages, type, sort]);

  const onSortChange = useCallback((id, direction) => {
    setSort({ ...sort, [id]: direction });
  }, [sort]);

  const itemRenderer = useCallback(
    (index, groupedMessages) => renderItem(index, groupedMessages, language, onMarkNotificationAsRead, sort),
    [language, onMarkNotificationAsRead, sort]
  );

  const markAllAsReadByType = useCallback(() => {
    const ids = Object.values(filteredAndGroupedMessages)
      .flat()
      .filter((n) => n.topic === get(session, 'id'))
      .filter((n) => !n.read)
      .map((n) => get(n, '_id', get(n, 'id')))
      .filter(Boolean);
    onMarkNotificationAsRead(ids);
  }, [filteredAndGroupedMessages, onMarkNotificationAsRead, session]);

  if (isEmpty(filteredAndGroupedMessages)) {
    const activeSubscriptions = currentSubscription.filter((c) => c.active).map((c) => c.label).filter(Boolean).join(', ');
    return (
      <Alert type="warning">
        <AlertContent>
          <FormattedMessage id="NotificationCenter.Notification.NoData" values={{ activeSubscriptions }} />
        </AlertContent>
      </Alert>
    );
  }

  return (
    <>
      <div className="notification-list-header">
        <div className="read-all-button" onClick={markAllAsReadByType}>
          <span className={classNames('mdi mdi-check')} />
          <span className="ms-1">
            <FormattedMessage id={`NotificationCenter.Tabs.Mark${upperFirst(camelCase(type))}AsRead`} />
          </span>
        </div>

        <SortDropdown
          label={<FormattedMessage id="NotificationCenter.Filter.SortTitle" />}
          menuStyle={{ zIndex: 10000 }}
        >
          <SortDropdownItem
            id="read"
            key="read"
            direction={get(sort, 'read')}
            onChange={onSortChange}
          >
            <FormattedMessage id="NotificationCenter.Filter.Read" />
          </SortDropdownItem>
          <SortDropdownItem
            id="timeIndex"
            key="timeIndex"
            direction={get(sort, 'timeIndex')}
            onChange={onSortChange}
          >
            <FormattedMessage id="NotificationCenter.Filter.Newest" />
          </SortDropdownItem>
        </SortDropdown>

      </div>
      <GlobalTimeProvider>
        <Virtuoso
          data={Object.values(filteredAndGroupedMessages)}
          className="notification-list"
          style={{ height: '100%' }}
          itemContent={itemRenderer}
          overscan={200}
        />
      </GlobalTimeProvider>
    </>
  );
}

NotificationList.propTypes = {
  messages: PropTypes.array,
  currentSubscription: PropTypes.array,
  language: PropTypes.string,
  type: PropTypes.string,
  onMarkNotificationAsRead: PropTypes.func,
  session: PropTypes.object.isRequired
};

NotificationList.defaultProps = {
  messages: [],
  currentSubscription: [],
  language: 'de',
  type: notificationTabs.ALL,
  onMarkNotificationAsRead: noop,
};

export default NotificationList;
