import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { noop } from 'lodash';

import './Header.css';

const MonthlyIncomeHeader = React.memo(({
  onRemove,
  removeable
}) => {
  return (
    <Row className="setadvisor-form-limit-calculation--monthly-income-item--header">
      <Col
        className="setadvisor-form-limit-calculation--monthly-income-item--header--border"
        lg={11}
        md={11}
      />

      <Col
        className="setadvisor-form-limit-calculation--monthly-income-item--header--remove"
        lg={1}
        md={1}
      >
        {removeable && (
          <span
            className="mdi mdi-minus-circle-outline pointer"
            onClick={onRemove}
          />
        )}
      </Col>
    </Row>
  );
});

MonthlyIncomeHeader.propTypes = {
  onRemove: PropTypes.func,
  removeable: PropTypes.bool
};

MonthlyIncomeHeader.defaultProps = {
  onRemove: noop,
  removeable: false
};

export default MonthlyIncomeHeader;
