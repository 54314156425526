import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { v4 } from 'uuid';
import { get } from 'lodash';

// ToDo: Move functions like removeUnwantedProperties/removeUnwantedValues to own repo
import removeUnwantedProperties from '../../../../lib/Utils/removeUnwantedProperties';
import removeUnwantedValues from '../../../../lib/Utils/removeUnwantedValues';
import kubeEditFormAction from '../../actions/EBankingContract/KubeEditForm';

const {
  EBANKING_KUBE_EDIT_FORM_SAVE_PENDING,
  EBANKING_KUBE_EDIT_FORM_SAVE_FULFILLED,
  EBANKING_KUBE_EDIT_FORM_SAVE_REJECTED
} = kubeEditFormAction;

export default function* saveKubeEditForm(request) {
  yield put({ type: EBANKING_KUBE_EDIT_FORM_SAVE_PENDING });

  try {
    const {
      skipUserCheck,
      taskId
    } = request;

    const url = `/sif/bpf/public/api/v1/task/${taskId}/complete?skipUserCheck=${skipUserCheck}`;

    const payload = yield call(axios, {
      url,
      method: 'post',
      data: {}
    });

    yield put({ type: EBANKING_KUBE_EDIT_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: EBANKING_KUBE_EDIT_FORM_SAVE_REJECTED, error });

    return error;
  }
}
