import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, isArray, uniq } from 'lodash';

import actions from '../../actions/Actions';
import { isDoublePartner } from '../../../../lib/Customer/Utils';
import getPartners from '../../../BpfCms/sagas/Customer/getPartners';
import getByReceiver from '../../../PowerOfAttorney/sagas/getByReceiver';
import getCustomerAccounts from '../../../Account/sagas/getCustomerAccounts';

const { IS_ELIGIBLE_FOR_REDUCED_PRICE_PENDING, IS_ELIGIBLE_FOR_REDUCED_PRICE_FULFILLED, IS_ELIGIBLE_FOR_REDUCED_PRICE_REJECTED } = actions;

export default function* isEligibleForReducedPrice(request) {
  const { customer } = request;
  const customerId = get(customer, 'id');
  yield put({ type: IS_ELIGIBLE_FOR_REDUCED_PRICE_PENDING });

  let partners = [];
  if (isDoublePartner(customer)) {
    const data = yield call(getPartners, { dataKey: customerId });
    if (isArray(data)) {
      partners = data.map((partner) => partner.id);
    }
  }

  let powerOfAttorney = [];
  const receivers = yield call(getByReceiver, { dataKey: customerId });
  if (isArray(receivers)) {
    powerOfAttorney = receivers.map((receiver) => receiver.customerId);
  }

  const allRelevantCustomers = uniq([customerId, ...partners, ...powerOfAttorney]);
  try {
    if (allRelevantCustomers.length === 0) {
      throw new Error('No customers found');
    }
    const trancheResult = yield call(axios, {
      url: `/financing/tranche/?in(customerId,(${allRelevantCustomers.join(',')}))`,
      method: 'get'
    });

    const depotAccountTypes = [
      'accountType-5034',
      'accountType-5200',
      'accountType-5540',
      'accountType-5013',
      'accountType-5010',
      'accountType-5027'
    ];

    const accounts = yield call(getCustomerAccounts, { dataKey: customerId });
    const depotResult = accounts.filter((account) => (
      allRelevantCustomers.includes(account.customer.id)
      && depotAccountTypes.includes(account.accountType.id)
    ));

    const totalDepotAmount = depotResult.reduce((acc, curr) => {
      const newAcc = acc + get(curr, 'accountCurrency.balance', 0);
      return newAcc;
    }, 0);

    const payload = {
      isEligible: get(trancheResult, 'data.length', 0) > 0 || totalDepotAmount >= 10000
    };

    yield put({ type: IS_ELIGIBLE_FOR_REDUCED_PRICE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: IS_ELIGIBLE_FOR_REDUCED_PRICE_REJECTED, error });

    return error;
  }
}
