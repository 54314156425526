import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isUndefined } from 'lodash';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import './CardLimits.css';
import IndividualCardLimit from './IndividualCardLimit';
import {
  getMainCardGlobalLimit,
  isAdditionalCardCategorySelected,
  isComboCardCategorySelected,
  isMainAndAdditionalCardCategorySelected,
  isMainCardCategorySelected
} from '../../../../lib/CreditCard/index';
import { NumberField } from '../../../../../General';

class CardLimits extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onDesiredGlobalLimitChange = this.onDesiredGlobalLimitChange.bind(this);
  }

  componentDidMount() {
    const { form, onChange } = this.props;

    const cards = get(form, 'data.cards');
    const desiredGlobalLimit = isAdditionalCardCategorySelected(cards)
      ? undefined
      : get(form, 'data.desiredGlobalLimit', 1000);

    onChange('desiredGlobalLimit', desiredGlobalLimit);
  }

  /**
   * Handle onChange of desired global limit input.
   * Reset to calculated value if value is undefined
   *
   * @param  {String} key   Form element id
   * @param  {Number} value Input value
   * @param  {Array}  rest  Rest props
   *
   * @return void
   */
  onDesiredGlobalLimitChange(key, value, ...rest) {
    const {
      calculatedDesiredGlobalLimit,
      onChange
    } = this.props;

    onChange(key, value, ...rest);

    if (isUndefined(value)) {
      onChange(key, calculatedDesiredGlobalLimit, ...rest);
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      bpfCards,
      calculation,
      form,
      onChange,
      validations
    } = this.props;
    const cards = get(form, 'data.cards', []);
    const mainCard = cards.find((card) => ['HK', 'DC'].includes(get(card, 'categoryType')));

    return (
      <div className="setadvisor-form-credit-card--limits">
        {(isMainCardCategorySelected(cards) || isMainAndAdditionalCardCategorySelected(cards) || isComboCardCategorySelected(cards)) && (
          <React.Fragment>
            <Row className="setadvisor-form-credit-card--limits--row">
              <Col lg={4} md={4} className="setadvisor-form-credit-card--limits--label">
                <FormattedMessage id="Freemium.Form.CreditCard.DesiredGlobalLimit" />
              </Col>

              <Col lg={8} md={8}>
                <NumberField
                  id="desiredGlobalLimit"
                  onChange={this.onDesiredGlobalLimitChange}
                  value={get(form, 'data.desiredGlobalLimit')}
                  showValidations
                  validations={get(validations, 'desiredGlobalLimit')}
                />
              </Col>
            </Row>

            {get(calculation, 'actualCalculatedGlobalLimit', 0) < 0 && (
              <Alert type="warning">
                <AlertTitle>
                  <FormattedMessage
                    id="Freemium.Form.CreditCard.CalculatedLimitLt0.Title"
                  />
                </AlertTitle>
                <AlertContent>
                  <FormattedMessage
                    id="Freemium.Form.CreditCard.CalculatedLimitLt0.Description"
                  />
                </AlertContent>
              </Alert>
            )}

            <IndividualCardLimit
              active={get(form, 'data.individualCardLimitMainActive')}
              cards={cards}
              categoryType={get(mainCard, 'categoryType')}
              id="individualCardLimitMain"
              onChange={onChange}
              label={<FormattedMessage id="Freemium.Form.CreditCard.IndividualCardLimitMain" />}
              validations={validations}
            />
          </React.Fragment>
        )}

        {isAdditionalCardCategorySelected(cards) && (
          <Row className="setadvisor-form-credit-card--limits--row">
            <Col lg={4} md={4}>
              <FormattedMessage id="Freemium.Form.CreditCard.MainCardGlobalLimit" />
            </Col>

            <Col lg={4} md={4}>
              <FormattedNumber
                currency="CHF"
                // eslint-disable-next-line
                style="currency"
                value={getMainCardGlobalLimit(bpfCards, 0)}
              />
            </Col>
          </Row>
        )}

        {(isAdditionalCardCategorySelected(cards) || isMainAndAdditionalCardCategorySelected(cards)) && (
          <IndividualCardLimit
            active={get(form, 'data.individualCardLimitAdditionalActive')}
            cards={cards}
            categoryType="ZK"
            id="individualCardLimitAdditional"
            onChange={onChange}
            label={<FormattedMessage id="Freemium.Form.CreditCard.IndividualCardLimitAdditional" />}
            validations={validations}
          />
        )}
      </div>
    );
  }
}

CardLimits.propTypes = {
  bpfCards: PropTypes.object,
  calculatedDesiredGlobalLimit: PropTypes.number,
  calculation: PropTypes.object,
  form: PropTypes.object,
  onChange: PropTypes.func,
  validations: PropTypes.object
};

CardLimits.defaultProps = {
  bpfCards: {},
  calculatedDesiredGlobalLimit: undefined,
  calculation: {},
  form: {},
  onChange: noop,
  validations: {}
};

export default CardLimits;
