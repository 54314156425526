import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { get } from 'lodash';

import './AccountSelector.scss';
import { BeatLoader, RadioSelectWrapper } from '../../../../General';
import { toIntIfSet } from '../../../../../lib/Utils';

function SetAdvisorFormAccountSelector({
  accounts, language, onChange, id, value, validations, requesting
}) {
  return (
    <div>
      {requesting && <BeatLoader />}
      {
            accounts.length === 0
              ? (
                <p className="setadvisor-workguide-form-account-selector--accounts-error">
                  <FormattedMessage id="Freemium.Form.ModuleWorld.AccountsError" />
                </p>
              )
              : (
                <RadioSelectWrapper
                  onChange={onChange}
                  value={value}
                  id={id}
                  disabled={false}
                  options={
                    accounts.map((account) => ({
                      label: (
                        <Row style={{ width: '90%' }}>
                          <Col md={3} lg={3}>{get(account, `accountTypeText.${language}`)}</Col>
                          <Col md={3} lg={3}>{account.editAccountNumber}</Col>
                          <Col md={6} lg={6}>{account.clientDesignation}</Col>
                        </Row>
                      ),
                      value: toIntIfSet(account.accountKey)
                    }))
                  }
                  showValidations
                  validations={validations}
                />
              )
          }

    </div>
  );
}

SetAdvisorFormAccountSelector.propTypes = {
  accounts: PropTypes.array,
  id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validations: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  requesting: PropTypes.bool
};

SetAdvisorFormAccountSelector.defaultProps = {
  accounts: [],
  validations: {},
  value: undefined,
  requesting: false
};

export default injectIntl(SetAdvisorFormAccountSelector);
