import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { get, upperFirst } from 'lodash';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import './TableRow.css';
import isDoublePartner from '../../../../../../../lib/Customer/Utils/isDoublePartner';
import toIntIfSet from '../../../../../../../lib/Utils/toIntIfSet';

const LimitDeterminationSummaryTableRow = React.memo(({
  customer,
  data,
  partners,
  type
}) => {
  const partner = get(partners, 'data', []).find((p) => toIntIfSet(get(p, 'id')) === get(data, 'clientKey'));
  const designation = isDoublePartner(customer)
    ? get(partner, 'designation', '')
    : get(customer, 'designation', '');

  const valueProp = ['asset', 'income'].includes(type)
    ? 'value'
    : 'costs';

  return (
    <Row className="setadvisor-form-limit-determination-summary--table-row">
      <Col lg={4} md={4}>
        <FormattedMessage id={`Freemium.Form.CreditCard.${upperFirst(type)}Type.${upperFirst(get(data, `${type}Type`))}`} />
      </Col>

      <Col
        className="text-end"
        lg={2}
        md={2}
      >
        <FormattedNumber
          value={get(data, 'amount', 0)}
          // eslint-disable-next-line
          style="currency"
          currency="CHF"
        />
      </Col>

      <Col
        className="text-end"
        lg={2}
        md={2}
      >
        <FormattedNumber
          value={get(data, 'multiplier', 1)}
          // eslint-disable-next-line
          style="decimal"
        />
      </Col>

      <Col
        className="text-end"
        lg={2}
        md={2}
      >
        <FormattedNumber
          value={get(data, valueProp, get(data, 'amount', 0))}
          // eslint-disable-next-line
          style="currency"
          currency="CHF"
        />
      </Col>

      <Col lg={2} md={2}>
        {designation}
      </Col>
    </Row>
  );
});

LimitDeterminationSummaryTableRow.propTypes = {
  customer: PropTypes.object,
  data: PropTypes.object,
  partners: PropTypes.object,
  type: PropTypes.string.isRequired
};

LimitDeterminationSummaryTableRow.defaultProps = {
  customer: undefined,
  data: {},
  partners: {}
};

export default LimitDeterminationSummaryTableRow;
