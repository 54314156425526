import { compact, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { bindActionCreators } from 'redux';

import './OpenOptionComfort.css';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';
import { getValidator } from '../../../../../globals';
import { actions as bpfCmsActions } from '../../../../BpfCms/index';
import freemiumActions from '../../../actions/Actions';

import AccountSelector from '../../../components/Form/OpenOptionComfort/AccountSelector';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../Workguide/hocs';
import { SectionTitle } from '../../../../General/components/Section';

class OpenModuleWorld extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validate = this.validate.bind(this);
  }

  /**
   * Handle customer change
   *
   * @param  {Object} customer Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const { freemiumActions } = this.props;
    freemiumActions.customerAccountsRequest({ dataKey: get(customer, 'id'), accountTypes: [90, 110] });
  }

  /**
   * Validate current form data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const { form } = this.props;

    const data = get(form, 'data', {});
    const validator = getValidator();

    const definition = {
      validations: {
        debitAccountForFee: {
          type: 'number',
          required: true,
        },
      },
    };

    return validator.validate(definition, data);
  }

  submit() {
    const {
      freemiumActions, form, customer, consultantId
    } = this.props;
    freemiumActions.optionComfortFormSaveRequest({ consultantId, customerId: get(customer, 'id'), accountId: get(form, 'data.debitAccountForFee') });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      fulfilled, errors, accounts, freemiumActions, language, form, onFormCancel, requesting, lilaSet
    } = this.props;
    const validations = this.validate();

    if (get(lilaSet, 'data.modules', []).some((m) => m.contractType === 6441 && m.active)) {
      return (
        <Alert type="warning">
          <AlertContent>
            <p>
              <FormattedMessage id="Freemium.General.OptionExistings" />
            </p>
            <Button color="primary" outline onClick={onFormCancel}>
              <FormattedMessage id="Workguide.Form.Button.Cancel" />
            </Button>
          </AlertContent>
        </Alert>
      );
    }

    if (!get(lilaSet, 'data.set.active', false)) {
      return (
        <Alert type="warning">
          <AlertContent>
            <p>
              <FormattedMessage id="Freemium.General.NoActiveLilaSet" />
            </p>
            <Button
              color="primary"
              outline
              onClick={onFormCancel}
            >
              <FormattedMessage id="Workguide.Form.Button.Cancel" />
            </Button>
          </AlertContent>
        </Alert>
      );
    }

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        showLoadIndicator
        requesting={!fulfilled && get(errors, 'length', 0) === 0}
      >

        <SectionTitle>
          <FormattedMessage id="Freemium.ModuelComfort.AccountsTitle" />
        </SectionTitle>

        <AccountSelector
          accounts={accounts}
          onChange={freemiumActions.optionComfortFormSetValue}
          language={language}
          id="debitAccountForFee"
          value={get(form, 'data.debitAccountForFee')}
          validations={get(validations, 'accountId', {})}
          requesting={requesting}
        />

        <div className="open-option-comfort--action-buttons">
          <Button color="primary" onClick={this.submit} disabled={!isEmpty(validations) || get(form, 'submitted', false)}>
            <FormattedMessage id="Workguide.Form.Button.Execute" />
          </Button>
          <span style={{ paddingRight: '20px' }} />
          <Button color="primary" outline onClick={onFormCancel}>
            <FormattedMessage id="Workguide.Form.Button.Cancel" />
          </Button>
        </div>
      </WorkguideCustomerAware>
    );
  }
}

OpenModuleWorld.propTypes = {
  freemiumActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  customer: PropTypes.object,
  form: PropTypes.object,
  errors: PropTypes.array,
  fulfilled: PropTypes.bool,
  accounts: PropTypes.array,
  toggleRedirectModal: PropTypes.func.isRequired,
  consultantId: PropTypes.string.isRequired,
  onFormCancel: PropTypes.func.isRequired,
  requesting: PropTypes.func.isRequired,
  lilaSet: PropTypes.object
};

OpenModuleWorld.defaultProps = {
  customer: undefined,
  form: {},
  language: 'de',
  errors: [],
  fulfilled: false,
  accounts: [],
  lilaSet: {}
};

function isFulfilled(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return get(state, `freemium.customerAccounts.${customerId}.fulfilled`, false)
  && get(state, `freemium.lilaSet.${customerId}.fulfilled`, false);
}

function isRequesting(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return (
    get(state, `freemium.customerAccounts.${customerId}.requesting`, false)
    || get(state, `freemium.lilaSet.${customerId}.requesting`)
  );
}

function isSubmitting(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return get(state, `freemium.customerAccounts.${customerId}.saving`, false);
}

function collectErrors(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return compact([
    get(state, 'freemium.accounts.error'),
    get(state, `freemium.lilaSet.${customerId}.error`),
    get(state, 'freemium.optionComfortdForm.error'),
    get(state, `freemium.customerAccounts.${customerId}.error`)
  ]);
}

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;

  return {
    errors: collectErrors(state, ownProps),
    language: state.login.language,
    form: state.freemium.optionComfortForm,
    accounts: get(state, `freemium.customerAccounts.${get(customer, 'id')}.data.resultVariables.updatedAccounts`),
    requesting: isRequesting(state, ownProps),
    isSubmitting: isSubmitting(state, ownProps),
    fulfilled: isFulfilled(state, ownProps),
    consultantId: get(state, 'login.session.id'),
    lilaSet: get(state, `freemium.lilaSet.${get(customer, 'id')}`)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfCmsActions: bindActionCreators(bpfCmsActions, dispatch),
    freemiumActions: bindActionCreators(freemiumActions, dispatch),
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(OpenModuleWorld)
);
