import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { get, noop } from 'lodash';
import moment from 'moment';

import './Comment.css';
import { FormattedMessage } from 'react-intl';

const Comment = React.memo(({
  comment,
  isRemovable,
  onRemove,
  timeFormat
}) => {
  return (
    <div className="setadvisor-form-common-comments--comment">
      <Row className="setadvisor-form-common-comments--comment--meta">
        <Col lg={11} md={11}>
          {get(comment, 'editor')}
        </Col>

        <Col lg={1} md={1} className="d-flex pe-0">
          {isRemovable && (
            <div
              className="mdi mdi-close setadvisor-form-common-comments--comment--remove"
              onClick={onRemove}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} className="setadvisor-form-common-comments--comment--value">
          {get(comment, 'value')}
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} className="d-flex justify-content-end pe-0 pt-2" style={{ color: 'var(--neutral-dark-grey)' }}>
          <span style={{ paddingRight: 5 }}><FormattedMessage id="Freemium.General.Comments.CreatedAt" /></span>
          {moment(get(comment, 'timestamp')).format(timeFormat)}
        </Col>
      </Row>
    </div>
  );
});

Comment.propTypes = {
  comment: PropTypes.object,
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
  timeFormat: PropTypes.string,
};

Comment.defaultProps = {
  comment: {},
  isRemovable: false,
  onRemove: noop,
  timeFormat: 'L'
};

export default Comment;
