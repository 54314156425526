import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';

const ConnectionAccountHolder = React.memo(({ cardClientMaster }) => {
  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.ConnectionAccountHolder" />}>
      {`${get(cardClientMaster, 'clientDesignation', '')} (${get(cardClientMaster, 'editClientNumber', '')})`}
    </Row>
  );
});

ConnectionAccountHolder.propTypes = {
  cardClientMaster: PropTypes.object
};

ConnectionAccountHolder.defaultProps = {
  cardClientMaster: {}
};

export default ConnectionAccountHolder;
