import { takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import QueryBuilder from '@evoja-web/rql-query-builder';

import { head } from 'lodash';
import {
  DATA_LOAD_STATE_REQUEST,
  DATA_LOAD_STATE_PENDING,
  DATA_LOAD_STATE_FULFILLED,
  DATA_LOAD_STATE_REJECTED,
} from '../actions/DataLoadState';

function getByLoadConfigObject(id) {
  const query = QueryBuilder()
    .eq('component', 'targetloader')
    .eq('state', 'done')
    .eq('parameters.loadConfigObject', id)
    .select(['workHistory.done', 'parameters'])
    .sort('-workHistory.done')
    .limit(1)
    .getQueryString();

  const url = `/loader/action/${query}`;

  return axios({
    url,
    method: 'get',
    contentType: 'application/json',
  });
}

export function* fetchDataLoadState() {
  yield put({ type: DATA_LOAD_STATE_PENDING });

  try {
    const { data: baseData } = yield call(getByLoadConfigObject, 'FinancingCredit');
    const { data: leadManagement } = yield call(getByLoadConfigObject, 'CustomerTask');
    const { data: mailData } = yield call(getByLoadConfigObject, 'Workflow');

    const transactionData = baseData;

    const result = {
      baseData: head(baseData),
      transactionData: head(transactionData),
      leadManagement: head(leadManagement),
      mailData: head(mailData),
    };

    yield put({ type: DATA_LOAD_STATE_FULFILLED, payload: result });

    return result;
  } catch (error) {
    yield put({ type: DATA_LOAD_STATE_REJECTED, error });
    return error;
  }
}

export default function* root() {
  yield takeEvery(DATA_LOAD_STATE_REQUEST, fetchDataLoadState);
}
