import { get } from 'lodash';

export default function CalculatedGlobalLimit({
  calculation,
  form
}) {
  return get(form, 'data.active', false)
    ? get(calculation, 'calculatedGlobalLimit')
    : undefined;
}
