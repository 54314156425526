import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import productbundleBundleActions from '../../packages/Freemium/actions/Customer/ProductbundleBundle';

const {
  PRODUCTBUNDLE_BUNDLE_PENDING,
  PRODUCTBUNDLE_BUNDLE_FULFILLED,
  PRODUCTBUNDLE_BUNDLE_REJECTED
} = productbundleBundleActions;

export default function* getProductbundleBundle() {
  yield put({ type: PRODUCTBUNDLE_BUNDLE_PENDING });
  try {
    // https://issue.swisscom.ch/browse/MAP-2076
    // The family sets are currently not configured. Do not load them from backend and remove this code as soon
    // the family set is configured and ready to use.
    const familyBundles = ['trendSetFamily', 'trendSetFamilyPlus'];
    const neq = `&and(${familyBundles.map((b) => `ne(id,${b})`).join(',')})`;
    const payload = yield call(handleNextHeaders, `/basic/productbundle/bundle/?limit(9999)&sort(+order)${neq}`);

    yield put({ type: PRODUCTBUNDLE_BUNDLE_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: PRODUCTBUNDLE_BUNDLE_REJECTED, error });

    return error;
  }
}
