import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';

import './MonthlyIncomeItem.css';
import Header from './MonthlyIncome/Header';
import CustomerSelect from './MonthlyIncome/CustomerSelect';
import Amount from './MonthlyIncome/Amount';
import ProofOfIncome from './MonthlyIncome/ProofOfIncome';
import FileUpload from '../../Common/LocalFileUpload';

class SetAdvisorFormLimitCalculationMonthlyIncomeItem extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customer,
      data,
      onChange,
      onRemove,
      partners,
      removeable,
      validations
    } = this.props;

    return (
      <div className="setadvisor-form-limit-calculation--monthly-income-item">
        <Header
          onRemove={onRemove}
          removeable={removeable}
        />

        <CustomerSelect
          customer={customer}
          id="clientKey"
          onChange={onChange}
          partners={get(partners, 'data', [])}
          validations={get(validations, 'clientKey')}
          value={get(data, 'clientKey')}
        />

        <Amount
          id="amount"
          onChange={onChange}
          validations={get(validations, 'amount')}
          value={get(data, 'amount')}
        />

        <ProofOfIncome
          id="proofOfIncome"
          onChange={onChange}
          validations={get(validations, 'proofOfIncome')}
          value={get(data, 'proofOfIncome')}
        />

        {get(data, 'proofOfIncome') !== 'valiantAccount' && (
          <FileUpload
            id="fileLink"
            onChange={onChange}
            validations={get(validations, 'fileLink')}
            value={get(data, 'fileLink')}
          />
        )}
      </div>
    );
  }
}

SetAdvisorFormLimitCalculationMonthlyIncomeItem.propTypes = {
  customer: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  partners: PropTypes.object,
  removeable: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  validations: PropTypes.object
};

SetAdvisorFormLimitCalculationMonthlyIncomeItem.defaultProps = {
  customer: undefined,
  data: {},
  onChange: noop,
  onRemove: noop,
  partners: {},
  removeable: false,
  validations: {}
};

export default SetAdvisorFormLimitCalculationMonthlyIncomeItem;
