import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { has, get } from 'lodash';
import moment from 'moment';

import Row from './Row';

const PlaceOfOrigin = React.memo(({ cardHolder }) => {
  const birthDate = has(cardHolder, 'clientInformation.dateOfBirth')
    ? moment(get(cardHolder, 'clientInformation.dateOfBirth'), 'DD.MM.YYYYY').format('L')
    : '-';

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.DateOfBirth" />}>
      {birthDate}
    </Row>
  );
});

PlaceOfOrigin.propTypes = {
  cardHolder: PropTypes.object
};

PlaceOfOrigin.defaultProps = {
  cardHolder: {}
};

export default PlaceOfOrigin;
