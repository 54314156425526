import React from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './TableHeader.css';

const LimitDeterminationSummaryTableHeader = React.memo(() => {
  return (
    <Row className="setadvisor-form-limit-determination-summary--table-header">
      <Col lg={4} md={4} />

      <Col
        className="text-end"
        lg={2}
        md={2}
      >
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Header.Amount" />
      </Col>

      <Col
        className="text-end"
        lg={2}
        md={2}
      >
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Header.Multiplier" />
      </Col>

      <Col
        className="text-end"
        lg={2}
        md={2}
      >
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Header.Costs" />
      </Col>

      <Col lg={2} md={2}>
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Header.Customer" />
      </Col>
    </Row>
  );
});

export default LimitDeterminationSummaryTableHeader;
