import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';
import { Button } from 'reactstrap';

import loaderActions from '../../../actions/Actions';
import { toStringIfSet } from '../../../../../lib/Utils';
import {
  actions as workguideActions,
  withWorkguideInstanceData,
  withWorkguideFormState,
  withWorkguideModalToggle
} from '../../../../Workguide/index';
import LoadProgress from '../../Load/Progress';

class WorkguideComponentDiffLoad extends React.Component {
  constructor(props) {
    super(props);

    this.onTriggerDiffLoad = this.onTriggerDiffLoad.bind(this);
    this.onDiffLoadFulfilled = this.onDiffLoadFulfilled.bind(this);
  }

  /**
   * Trigger the diff load with the configured params
   *
   * @return  void
   */
  onTriggerDiffLoad() {
    const {
      customer,
      dataKey,
      diffLoadObjects,
      loaderActions
    } = this.props;

    const customerId = get(customer, 'id');
    const diffLoadParameter = [{
      key: 'kd_lnr',
      value: toStringIfSet(customerId)
    }];

    loaderActions.loadRequest({
      dataKey,
      objects: diffLoadObjects,
      diffLoadParameter
    });
  }

  /**
   * Reset and reopen the workguide modal after fulfilled to make sure all components data loads are triggered
   *
   * @return  void
   */
  onDiffLoadFulfilled() {
    const {
      activity,
      bpf,
      customer,
      dataKey,
      loaderActions,
      onToggleWorkguideModal,
      workguide,
      workguideActions,
      workguideForm,
    } = this.props;

    // All possible props for onToggleWorkguideModal
    const props = {
      activityId: get(activity, 'id'),
      customerId: get(customer, 'id'),
      initialFormData: get(workguideForm, 'data', {}),
      processInstanceId: get(bpf, 'process.processInstanceId'),
      taskId: get(bpf, 'task.id'),
      workguideId: get(workguide, 'id')
    };

    // Wait a second to make sure the use can read the success message
    setTimeout(() => {
      // Reset the modal
      workguideActions.workguideModalReset();
      // Re-init the modal with current data
      onToggleWorkguideModal(props);
      // Reset diff load state
      loaderActions.loadReset({ dataKey });
    }, 1500);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      dataKey,
      diffLoad
    } = this.props;

    const requesting = get(diffLoad, 'requesting', false);
    const fulfilled = get(diffLoad, 'fulfilled', false);
    const erroneous = get(diffLoad, 'error') instanceof Error;

    if (requesting || fulfilled || erroneous) {
      return (
        <LoadProgress
          dataKey={dataKey}
          onFulfilled={this.onDiffLoadFulfilled}
        >
          {fulfilled && (
            <div className="d-flex justify-content-center pt-4">
              <Button
                color="primary"
                onClick={this.onTriggerDiffLoad}
              >
                <FormattedMessage id="Loader.Workguide.Component.DiffLoad.ReloadWorkguide" />
              </Button>
            </div>
          )}
        </LoadProgress>
      );
    }

    return (
      <div className="loader-workguide-component--diff-load">
        <Alert>
          <AlertTitle>
            <FormattedMessage id="Loader.Workguide.Component.DiffLoad.Title" />
          </AlertTitle>

          <AlertContent>
            <div className="d-flex justify-content-center">
              <Button
                color="primary"
                onClick={this.onTriggerDiffLoad}
              >
                <FormattedMessage id="Loader.Workguide.Component.DiffLoad.TriggerDiffLoad" />
              </Button>
            </div>
          </AlertContent>
        </Alert>
      </div>
    );
  }
}

WorkguideComponentDiffLoad.propTypes = {
  activity: PropTypes.object,
  bpf: PropTypes.object,
  customer: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  diffLoad: PropTypes.object,
  diffLoadObjects: PropTypes.string,
  loaderActions: PropTypes.object.isRequired,
  onToggleWorkguideModal: PropTypes.func.isRequired,
  workguide: PropTypes.object.isRequired,
  workguideForm: PropTypes.object,
  workguideActions: PropTypes.object.isRequired
};

WorkguideComponentDiffLoad.defaultProps = {
  activity: undefined,
  bpf: undefined,
  diffLoad: {},
  diffLoadObjects: 'SingleCustomer',
  workguideForm: {}
};

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;

  const dataKey = `workguideDiffLoad-${id}`;

  return {
    dataKey,
    diffLoad: get(state, `loader.load.${dataKey}`)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    workguideActions: bindActionCreators(workguideActions, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withWorkguideModalToggle()(
    withWorkguideFormState()(
      connect(mapStateToProps, mapDispatchToProps)(
        WorkguideComponentDiffLoad
      )
    )
  )
);
