import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, head } from 'lodash';

import Row from './Row';

const PlaceOfOrigin = React.memo(({ cardHolder }) => {
  const card = head(get(cardHolder, 'cards', []));
  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.EmbossedLineOne" />}>
      {get(card, 'embossedLineOne', '')}
    </Row>
  );
});

PlaceOfOrigin.propTypes = {
  cardHolder: PropTypes.object
};

PlaceOfOrigin.defaultProps = {
  cardHolder: {}
};

export default PlaceOfOrigin;
