import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';
import Comment from '../../Common/Comments/Comment';

const Comments = React.memo(({ comments }) => {
  const children = comments.map((comment, index) => {
    return (
      <Comment
         // eslint-disable-next-line
        key={`comment-${index}`}
        comment={comment}
      />
    );
  });

  return (
    <Section className="setadvisor-form-summary-comments">
      <SectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.Comments" />
      </SectionTitle>

      <SectionContent>
        {children}
      </SectionContent>
    </Section>
  );
});

Comments.propTypes = {
  comments: PropTypes.array
};

Comments.defaultProps = {
  comments: []
};

export default Comments;
