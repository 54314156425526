import { Reducers } from '@evoja-web/redaction';

import { reducer as notificationReducer } from './Notification';
import { reducer as badgeCountReducer } from './BadgeCount';
import { reducer as markNotificationReadReducer } from './MarkNotificationRead';
import { reducer as notificationCenterSubscriptionReducer } from './NotificationCenterSubscription';

export const reducers = Reducers([
  notificationReducer,
  badgeCountReducer,
  markNotificationReadReducer,
  notificationCenterSubscriptionReducer
]);

export default reducers.get();
