import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';

const Nationality = React.memo(({
  cardHolder,
  countries
}) => {
  const value = get(countries, get(cardHolder, 'clientInformation.nationality'), '');

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.Nationality" />}>
      {value}
    </Row>
  );
});

Nationality.propTypes = {
  cardHolder: PropTypes.object,
  countries: PropTypes.object
};

Nationality.defaultProps = {
  cardHolder: {},
  countries: {}
};

export default Nationality;
