import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEmpty, noop, map } from 'lodash';
import { createIsRequesting } from '@evoja-web/redaction';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../../Workguide/index';
import freemiumActions from '../../../../actions/Actions';
import { isCompany } from '../../../../../../lib/Customer/Utils';
import Form from '../../../../components/Form/ContractDispatchRules/Form';
import { getValidator } from '../../../../../../globals';
import { toIntIfSet } from '../../../../../../lib/Utils';
import getChanges from '../../../../lib/ContractDispatchRule/getChanges';

const validationDefinition = {
  validations: {
    contracts: {
      type: 'array',
      required: true,
      validations: {
        eDocumentsAccountIndependent: {
          type: 'boolean',
          required: false,
          validations: {}
        },
        dispatchRules: {
          type: 'array',
          required: true,
          validations: {
            eDocument: {
              type: 'boolean',
              required: true,
              validations: {}
            },
            comment: {
              type: 'string',
              required: false,
              validations: {}
            }
          }
        }
      }
    }
  }
};

class ContractDispatchRules extends React.Component {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Handle customer change.
   * Init the form with custoemr data.
   *
   * @param   {Object}  customer  Selected customer (/person/customer)
   *
   * @return  void
   */
  onCustomerChange(customer) {
    const { freemiumActions } = this.props;

    freemiumActions.contractDispatchRulesFormInitRequest({ customerId: get(customer, 'id') });
  }

  /**
   * Submit the form and update changed contracts
   *
   * @return  void
   */
  onSubmit() {
    const {
      customer,
      session,
      freemiumActions,
      toggleRedirectModal
    } = this.props;

    const customerId = get(customer, 'id');
    const contracts = this.getChanges();

    freemiumActions.contractDispatchRulesFormSaveRequest({
      customerId,
      data: {
        clientKey: toIntIfSet(customerId),
        responsibleUser: toIntIfSet(get(session, 'id')),
        processCallOrigin: 'MAP',
        contracts
      }
    });

    toggleRedirectModal();
  }

  /**
   * Compare current with updated data and return changes
   *
   * @return  {Array} changes Changes in contract objects
   */
  getChanges() {
    const {
      contractDispatchRules,
      form
    } = this.props;

    const changes = getChanges({
      contracts: map(contractDispatchRules, (contract) => get(contract, 'data', {})),
      updated: get(form, 'data.contracts', [])
    });

    return changes;
  }

  /**
   * Valdiate current form data
   *
   * @return  {[type]}  [return description]
   */
  validate() {
    const { form } = this.props;

    const validator = getValidator();

    return validator.validate(validationDefinition, get(form, 'data', {}));
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customer,
      form,
      language,
      onFormCancel,
      requesting,
      session,
      freemiumActions
    } = this.props;

    const isCompanyCustomer = isCompany(customer);
    const validations = this.validate();

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        showLoadIndicator
        requesting={requesting}
      >
        <div className="setadvisor-workguide-contract-dispatch-rules">
          <h5>
            <FormattedMessage id="Freemium.Form.EDocuments.Title" />
          </h5>

          {isCompanyCustomer && (
            <Alert type="error">
              <AlertContent>
                <FormattedMessage id="Freemium.Form.EDocuments.CompnayNotAllowed" />
              </AlertContent>
            </Alert>
          )}

          {!isCompanyCustomer && (
            <div className="setadvisor-workguide-contract-dispatch-rules--content">
              <p className="open-module-world--disclaimer">
                <FormattedMessage id="Freemium.Form.EDocuments.Description" />
              </p>

              <Form
                contracts={get(form, 'data.contracts')}
                id="contracts"
                language={language}
                onChange={freemiumActions.contractDispatchRulesFormSetValue}
                session={session}
                showCommentInput={false}
                validations={validations}
              />
            </div>
          )}

          <div className="setadvisor-workguide-contract-dispatch-rules--buttons">
            <Button
              color="primary"
              onClick={this.onSubmit}
              disabled={!isEmpty(validations) || isEmpty(this.getChanges())}
            >
              <FormattedMessage id="Freemium.Form.EDocuments.Submit" />
            </Button>

            <span style={{ paddingRight: '20px' }} />

            <Button color="primary" outline onClick={onFormCancel}>
              <FormattedMessage id="Freemium.Form.General.Labels.Cancel" />
            </Button>
          </div>
        </div>
      </WorkguideCustomerAware>
    );
  }
}

ContractDispatchRules.propTypes = {
  contractDispatchRules: PropTypes.object,
  customer: PropTypes.object,
  form: PropTypes.object,
  language: PropTypes.string,
  onFormCancel: PropTypes.func,
  requesting: PropTypes.bool,
  session: PropTypes.object.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func
};

ContractDispatchRules.defaultProps = {
  contractDispatchRules: {},
  customer: undefined,
  form: {},
  language: 'de',
  onFormCancel: noop,
  requesting: false,
  toggleRedirectModal: noop
};

const isRequesting = createIsRequesting({ path: ['freemium.contractDispatchRulesForm'] });

function mapStateToProps(state, ownProps) {
  return {
    contractDispatchRules: state.freemium.contractDispatchRules,
    form: state.freemium.contractDispatchRulesForm,
    language: state.login.language,
    requesting: isRequesting(state, ownProps),
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(ContractDispatchRules)
);
