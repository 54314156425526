import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { utils as codeUtils } from '@evoja-web/entity-code';

import Row from './Row';
import mapping from '../../../../../../BpfCms/mappings/maritalStatusCode';

const MaritalStatus = React.memo(({
  cardHolder,
  codes,
  language
}) => {
  const code = codeUtils.getCodeById({
    codes,
    id: mapping.getKey(get(cardHolder, 'clientInformation.maritalStatus')),
    group: 'customerMaritalStatus'
  });

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.MaritalStatus" />}>
      {get(code, `text.${language}`)}
    </Row>
  );
});

MaritalStatus.propTypes = {
  cardHolder: PropTypes.object,
  codes: PropTypes.object,
  language: PropTypes.string
};

MaritalStatus.defaultProps = {
  cardHolder: {},
  codes: { groups: {} },
  language: 'de'
};

export default MaritalStatus;
