import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined, noop, get } from 'lodash';
import { RadioButton, RadioButtonGroup } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

import './MainAndAdditionalCard.css';
import {
  SubSection,
  SubSectionTitle,
  SubSectionContent
} from '../../../../../../General/components/SubSection/index';
import isMainAndAdditionalCardCategorySelected from '../../../../../lib/CreditCard/isMainAndAdditionalCardCategorySelected';
import getCardTypeOptions from '../../../../../lib/CreditCard/getCardTypeOptions';

const cardTypesGold = [404, 506];
const cardTypesSilver = [401, 517];

class CardVarietyMainAndAdditional extends React.Component {
  constructor(props) {
    super(props);

    this.onMainChange = this.onMainChange.bind(this);
    this.onAdditionalChange = this.onAdditionalChange.bind(this);
  }

  /**
   * Handle change of the main card.
   * Update or add the main card with the given card variety
   * and add an additional card wirh the same variety if there on no additional card in cards.
   *
   * @param  {String} key          Form element id
   * @param  {Array}  selected     Selected values
   * @param  {Array}  rest         Rest args
   *
   * @return void
   */
  onMainChange(key, selected, ...rest) {
    const {
      cards,
      onCardsChange,
      onChange
    } = this.props;

    const mainCard = cards.find((card) => get(card, 'categoryType') === 'HK');
    const additionalCard = cards.find((card) => get(card, 'categoryType') === 'ZK');

    const updatedMainCard = !isUndefined(mainCard)
      ? update(mainCard, { cardVariety: { $set: selected } })
      : { categoryType: 'HK', cardVariety: selected, cardCurrency: 'CHF' };

    const updatedAdditionalCard = !isUndefined(additionalCard)
      ? update(additionalCard, { cardVariety: { $set: selected } })
      : { categoryType: 'ZK', cardVariety: selected, cardCurrency: 'CHF' };

    onCardsChange([updatedMainCard, updatedAdditionalCard], ...rest);
    onChange(key, selected, ...rest);
  }

  /**
   * Handle onChange of the additional card.
   * Update variety on existing card or add a new one with categoryType "ZK" and the given variety
   *
   * @param  {String} key          Form element id
   * @param  {Array}  selected     Selected values
   * @param  {Array}  rest         Rest args
   *
   * @return void
   */
  onAdditionalChange(key, selected, ...rest) {
    const {
      cards,
      onCardsChange,
      onChange
    } = this.props;

    const index = cards.findIndex((card) => get(card, 'categoryType') === 'ZK');
    const updated = index > -1
      ? update(cards, { [index]: { cardVariety: { $set: selected } } })
      : update(cards, {
        $push: [{ categoryType: 'ZK', cardVariety: selected, cardCurrency: 'CHF' }]
      });

    onCardsChange(updated, ...rest);
    onChange(key, selected, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      cards,
      codes,
      disabled,
      id,
      language
    } = this.props;
    const mainCard = cards.find((card) => get(card, 'categoryType') === 'HK');
    const additionalCard = cards.find((card) => get(card, 'categoryType') === 'ZK');

    const mainCardTypes = [...cardTypesGold, ...cardTypesSilver];
    const additionalCardTypes = isUndefined(mainCard) || !isMainAndAdditionalCardCategorySelected(cards)
      ? mainCardTypes
      : cardTypesGold.includes(get(mainCard, 'cardVariety'))
        ? cardTypesGold
        : cardTypesSilver;

    const mainCardOptions = getCardTypeOptions({ codes, types: mainCardTypes, language });
    const additionalCardOptions = getCardTypeOptions({ codes, types: additionalCardTypes, language });
    const active = isMainAndAdditionalCardCategorySelected(cards);

    return (
      <SubSection>
        <SubSectionTitle>
          <RadioButton
            id={`${id}-active`}
            disabled={disabled}
            value={active}
            onChange={(...args) => this.onMainChange(id, 404, ...args)}
          >
            <FormattedMessage id="Freemium.Form.CreditCard.CardCategory.MainAndAdditional" />
          </RadioButton>
        </SubSectionTitle>

        {active && (
          <SubSectionContent className="setadvisor-form-credit-card-general--card-variety--content">
            <SubSection>
              <SubSectionTitle>
                <FormattedMessage id="Freemium.Form.CreditCard.CardCategory.Main" />
              </SubSectionTitle>

              <SubSectionContent>
                <RadioButtonGroup
                  className="setadvisor-form-credit-card-general--card-variety--type"
                  disabled={disabled}
                  id={id}
                  value={isMainAndAdditionalCardCategorySelected(cards) ? get(mainCard, 'cardVariety') : undefined}
                  onChange={this.onMainChange}
                  options={mainCardOptions}
                />
              </SubSectionContent>
            </SubSection>

            <SubSection>
              <SubSectionTitle>
                <FormattedMessage id="Freemium.Form.CreditCard.CardCategory.Additional" />
              </SubSectionTitle>

              <SubSectionContent>
                <RadioButtonGroup
                  className="setadvisor-form-credit-card-general--card-variety--type"
                  disabled={disabled || isUndefined(mainCard) || !isMainAndAdditionalCardCategorySelected(cards)}
                  id={id}
                  value={get(additionalCard, 'cardVariety')}
                  onChange={this.onAdditionalChange}
                  options={additionalCardOptions}
                />
              </SubSectionContent>
            </SubSection>
          </SubSectionContent>
        )}
      </SubSection>
    );
  }
}

CardVarietyMainAndAdditional.propTypes = {
  cards: PropTypes.array,
  codes: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  language: PropTypes.string,
  onCardsChange: PropTypes.func,
  onChange: PropTypes.func
};

CardVarietyMainAndAdditional.defaultProps = {
  cards: [],
  codes: { groups: {} },
  disabled: false,
  id: 'cardVariety',
  language: 'de',
  onCardsChange: noop,
  onChange: noop
};

export default CardVarietyMainAndAdditional;
