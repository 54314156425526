import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import gravitonAction from '../../actions/Customer/Graviton';

const {
  GRAVITON_CUSTOMER_PENDING,
  GRAVITON_CUSTOMER_FULFILLED,
  GRAVITON_CUSTOMER_REJECTED
} = gravitonAction;

export default function* getGravitonCustomer(request) {
  const { dataKey } = request;

  yield put({ type: GRAVITON_CUSTOMER_PENDING, dataKey });

  try {
    const url = `/person/customer/${dataKey}`;
    const payload = yield call(axios, {
      url,
      method: 'get'
    });

    yield put({ type: GRAVITON_CUSTOMER_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: GRAVITON_CUSTOMER_REJECTED, dataKey, error });

    return error;
  }
}
