import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { noop, upperFirst } from 'lodash';

import { Select } from '../../../../../../General/index';

const MonthlyIncomeProofOfIncome = React.memo(({
  id,
  intl,
  onChange,
  validations,
  value
}) => {
  const options = ['taxDeclaration', 'valiantAccount', 'wageOrPensionStatement', 'payroll'].map((value) => ({
    value,
    label: intl.formatMessage({ id: `Freemium.Form.CreditCard.MonthlyIncome.ProofOfIncome.${upperFirst(value)}` })
  }));

  return (
    <Row className="setadvisor-form-limit-calculation--monthly-income-item--row">
      <Col
        className="setadvisor-form-limit-calculation--monthly-income-item--row--label"
        lg={4}
        md={4}
      >
        <FormattedMessage id="Freemium.Form.CreditCard.MonthlyIncome.ProofOfIncome.Label" />
      </Col>

      <Col
        className="setadvisor-form-limit-calculation--monthly-income-item--row--input"
        lg={8}
        md={8}
      >
        <Select
          id={id}
          onChange={onChange}
          options={options}
          showValidations
          validations={validations}
          value={value}
        />
      </Col>
    </Row>
  );
});

MonthlyIncomeProofOfIncome.propTypes = {
  id: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string
};

MonthlyIncomeProofOfIncome.defaultProps = {
  id: 'proofOfIncome',
  onChange: noop,
  validations: {},
  value: undefined
};

export default injectIntl(MonthlyIncomeProofOfIncome);
