import { actions as loginActions } from '@evoja-web/react-login';
import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import getUserInfo from './getUserInfo';
import checkMultipleSessions from './checkMultipleSession';
import setLanguageAndLocale from './setLanguageAndLocale';

const { USER_INFO_REQUEST } = actions;
const { GATEWAY_LOGIN_FULFILLED, OIDC_LOGIN_FULFILLED } = loginActions;

export default function* userRootSaga() {
  // Check if there are multiple action sessions of this user
  // https://issue.swisscom.ch/browse/MAP-9589
  yield takeLatest(OIDC_LOGIN_FULFILLED, checkMultipleSessions);
  yield takeLatest(GATEWAY_LOGIN_FULFILLED, checkMultipleSessions);

  // Set user language and form locale
  // https://issue.swisscom.ch/browse/MAP-9729
  yield takeLatest(OIDC_LOGIN_FULFILLED, setLanguageAndLocale);
  yield takeLatest(GATEWAY_LOGIN_FULFILLED, setLanguageAndLocale);
  yield takeLatest(USER_INFO_REQUEST, getUserInfo);
}
