import moment from 'moment';
import PropTypes from 'prop-types';
import { useGlobalTime } from '../../hooks/GlobalTimeProvider';

function RelativeTimeDisplay({ timestamp }) {
  useGlobalTime(); // This will trigger re-render when the global time updates
  return moment(timestamp * 1000).fromNow();
}

RelativeTimeDisplay.propTypes = {
  timestamp: PropTypes.number.isRequired
};
export default RelativeTimeDisplay;
