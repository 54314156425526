import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined, noop, get } from 'lodash';
import { RadioButton, RadioButtonGroup } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

import {
  SubSection,
  SubSectionTitle,
  SubSectionContent
} from '../../../../../../General/components/SubSection/index';
import isMainCardCategorySelected from '../../../../../lib/CreditCard/isMainCardCategorySelected';
import getCardTypeOptions from '../../../../../lib/CreditCard/getCardTypeOptions';

const cardTypes = [404, 506, 401, 517];

class CardVarietyMainCard extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle change of the main card radio group.
   * Update cards in form data with a single card of category HK
   *
   * @param  {String} key      Form element id
   * @param  {Array}  selected Selected values
   *
   * @return void
   */
  onChange(key, selected, ...rest) {
    const { onCardsChange, onChange } = this.props;

    const cards = isUndefined(selected)
      ? undefined
      : [{ categoryType: 'HK', cardVariety: selected, cardCurrency: 'CHF' }];

    onCardsChange(cards, ...rest);
    onChange(key, selected, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      cards,
      codes,
      disabled,
      id,
      language
    } = this.props;
    const card = cards.find((card) => get(card, 'categoryType') === 'HK');
    const options = getCardTypeOptions({ codes, types: cardTypes, language });
    const active = isMainCardCategorySelected(cards);

    return (
      <SubSection>
        <SubSectionTitle>
          <RadioButton
            id={`${id}-active`}
            disabled={disabled}
            value={active}
            onChange={(...args) => this.onChange(id, 404, ...args)}
          >
            <FormattedMessage id="Freemium.Form.CreditCard.CardCategory.Main" />
          </RadioButton>
        </SubSectionTitle>

        {active && (
          <SubSectionContent>
            <RadioButtonGroup
              className="setadvisor-form-credit-card-general--card-variety--type"
              disabled={disabled}
              id={id}
              value={get(card, 'cardVariety')}
              onChange={this.onChange}
              options={options}
            />
          </SubSectionContent>
        )}
      </SubSection>
    );
  }
}

CardVarietyMainCard.propTypes = {
  cards: PropTypes.array,
  codes: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  language: PropTypes.string,
  onCardsChange: PropTypes.func,
  onChange: PropTypes.func
};

CardVarietyMainCard.defaultProps = {
  cards: [],
  codes: { groups: {} },
  disabled: false,
  id: 'cardVariety',
  language: 'de',
  onCardsChange: noop,
  onChange: noop
};

export default CardVarietyMainCard;
