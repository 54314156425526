import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';
import CategoryType from './Card/CategoryType';
import CardVariety from './Card/CardVariety';
import ConnectionAccount from './Card/ConnectionAccount';
import ConnectionAccountHolder from './Card/ConnectionAccountHolder';
import BeneficialOwner from './Card/BeneficialOwner';
import PaymentType from './Card/PaymentType';
import Promotion from './Card/Promotion';
import EmployeeConditions from './Card/EmployeeConditions';
import IndividualLimits from './Card/IndividualLimits';
import EmbossedLines from './Card/EmbossedLines';

const SummaryCards = React.memo(({
  codes,
  data,
  language,
  withEmbossedLines
}) => {
  const cards = [
    ...get(data, 'mainCardHolder.cards', []),
    ...get(data, 'additionalCardHolder.cards', [])
  ];

  return (
    <Section className="setadvisor-form-summary-cards">
      <SectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.Cards.Title" />
      </SectionTitle>

      <SectionContent>
        <CategoryType cards={cards} />

        <CardVariety
          cards={cards}
          codes={codes}
          language={language}
        />

        <ConnectionAccount account={get(data, 'account')} />

        <ConnectionAccountHolder cardClientMaster={get(data, 'cardClientMaster')} />

        <BeneficialOwner beneficialOwner={get(data, 'beneficialOwner')} />

        <PaymentType paymentType={get(data, 'paymentType')} />

        <Promotion promotion={get(data, 'promotion')} />

        <EmployeeConditions employeeConditions={get(data, 'employeeConditions')} />

        <IndividualLimits cards={cards} />

        {withEmbossedLines && (
          <EmbossedLines
            data={cards}
          />
        )}
      </SectionContent>
    </Section>
  );
});

SummaryCards.propTypes = {
  codes: PropTypes.object,
  data: PropTypes.object,
  language: PropTypes.string,
  withEmbossedLines: PropTypes.bool,
};

SummaryCards.defaultProps = {
  codes: { groups: {} },
  data: {},
  language: 'de',
  withEmbossedLines: false,
};

export default SummaryCards;
