import { flatMap, get, isEmpty, isNil, isUndefined, unionBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { withCodeMapper } from '@evoja-web/entity-code';
import { Button, Col, Label, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@evoja-web/react-form';
import moment from 'moment';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import { getValidator } from '../../../../../globals';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../Workguide/hocs';
import { Section, SectionContent, SectionTitle } from '../../../../General/components/Section';

import poaActions from '../../../actions/Customer/Poas';
import connectAccountsToContractActions from '../../../actions/Form/ConnectAccountsToContract';
import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../General/components/SubSection';

import freemiumActions from '../../../actions/Actions';
import { BeatLoader, CheckboxGroup } from '../../../../General';
import CollapsibleRow from '../../../../../components/General/CollapsibleRow';
import { toIntIfSet } from '../../../../../lib/Utils';
import './Form.css';

/**
   * make sure that accounts with the same clientAssignedKey are are
   * accessible in one object
   * @param {*} data poas
   * @returns mapped account to the associated clientAssignedKey
   */
function mergeAccountsByClientAssignedKey(data) {
  return data.reduce((mergedData, item) => {
    const { clientAssignedKey } = item;

    if (!mergedData[clientAssignedKey]) {
      mergedData[clientAssignedKey] = {
        ...item,
        assignmentAccounts: [...item.assignmentAccounts],
      };
    } else {
      mergedData[clientAssignedKey].assignmentAccounts = mergedData[clientAssignedKey].assignmentAccounts.concat(item.assignmentAccounts);
    }

    return mergedData;
  }, {});
}

/**
   *  check if givenAccounts and receivedPoas are < 18
   * @param {*} givenAccounts
   * @param {*} receivedPoas
   * @returns
   */
function checkPoasUnderEighTeen(givenAccounts, receivedPoas) {
  return [...givenAccounts, ...receivedPoas].filter((account) => {
    if (get(account, 'clientType') === 'companiesAndOther') return false;
    const dob = moment(account.dateOfBirth);
    const eighteenYearsAgo = moment().subtract(18, 'years');
    return !dob.isBefore(eighteenYearsAgo);
  }).map((poa) => poa.clientDesignation);
}

function checkIfPoaClientTypeCompany(receivedPoa, givenPoas) {
  return [...receivedPoa, ...givenPoas].some((poa) => get(poa, 'clientType') === 'companiesAndOther');
}

/**
   * filter givenPoas and combine all givenPoas into one array of accounts
   * @returns array of givenPoa accounts
   */
function getGivenPoasAccounts(validPoas) {
  return validPoas.reduce((prev, curr) => {
    // Include curr.clientAssignedKey in assignmentAccounts
    const updatedAssignmentAccounts = curr.assignmentAccounts.map((account) => {
      return {
        ...account,
        clientAssignedKey: curr.clientAssignedKey,
        activatedAccountEbanking: false,
        dateOfBirth: curr.dateOfBirth,
        clientDesignation: curr.clientDesignation
      };
    });

    return [...prev, ...updatedAssignmentAccounts];
  }, []);
}

class ConnectAccountsToContract extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validate = this.validate.bind(this);
    this.toggleAddFutureOpenedAccounts = this.toggleAddFutureOpenedAccounts.bind(this);
    this.onExistingContractChanged = this.onExistingContractChanged.bind(this);
    this.onGivenAccountChange = this.onGivenAccountChange.bind(this);
    this.getGivenPoas = this.getGivenPoas.bind(this);
    this.getReceivedPoasAccounts = this.getReceivedPoasAccounts.bind(this);
    this.getReceivedPoas = this.getReceivedPoas.bind(this);
    this.onInit = this.onInit.bind(this);
    this.onReceivedAccountChange = this.onReceivedAccountChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { eContract, connectAccountsToContractActions, form } = this.props;

    const { eContract: preveContract } = prevProps;
    const eContractData = get(eContract, 'data.resultVariables.eContract');

    // Set preselected values
    if (
      !isUndefined(eContractData)
      && get(eContractData, 'contractId') !== get(preveContract, 'data.resultVariables.eContract.contractId')
    ) {
      const givenPoas = this.getGivenPoas();
      const givenAccounts = getGivenPoasAccounts(givenPoas);
      const receivedAccounts = this.getReceivedPoasAccounts();
      const assignmentAccounts = get(eContractData, 'assignmentClients', []).reduce((prev, curr) => {
        const updatedAssignmentAccounts = curr.assignmentAccounts.map((account) => {
          return { ...account, clientAssignedKey: curr.clientAssignedKey, dateOfBirth: curr.dateOfBirth };
        });

        return [...prev, ...updatedAssignmentAccounts];
      }, []);
      const preSelectedGivenAccounts = assignmentAccounts.filter((item1) => {
        return givenAccounts.some((item2) => item1.accountKey === item2.accountKey && item1.activatedAccountEbanking);
      });
      // find matching accounts from eContract
      const preSelectedRecivedAccounts = assignmentAccounts.filter((item1) => {
        return receivedAccounts.some((item2) => item1.accountKey === item2.accountKey && item1.activatedAccountEbanking);
      });
      connectAccountsToContractActions.connnectAccountsToContractSetValue('givenAccounts', preSelectedGivenAccounts);

      // map pre select account to the associated clientAssignedKey
      let updatedValues = get(form, 'data.receivedPoas', {});
      preSelectedRecivedAccounts.forEach((account) => {
        updatedValues = update(updatedValues, {
          [account.clientAssignedKey]: isNil(updatedValues[account.clientAssignedKey]) ? { $set: [account] } : { $push: [account] }
        });
      });
      connectAccountsToContractActions.connnectAccountsToContractSetValue('receivedPoas', updatedValues);

      // default value for addFutureOpenedAccounts prop
      const assignmentClients = get(eContractData, 'assignmentClients', []);
      if (assignmentClients.every((ac) => isNil(ac.validUntil))) {
        connectAccountsToContractActions.connnectAccountsToContractSetValue('addFutureOpenedAccounts', true);
      }
      if (isEmpty(assignmentClients) || assignmentClients.some((ac) => !isNil(ac.validUntil))) {
        connectAccountsToContractActions.connnectAccountsToContractSetValue('addFutureOpenedAccounts', false);
      }
      // if no givenAccounts selected it should always be false
      if (isEmpty(get(form, 'data.givenAccounts'))) {
        connectAccountsToContractActions.connnectAccountsToContractSetValue('addFutureOpenedAccounts', false);
      }
    }
  }

  onInit() {
    const { connectAccountsToContractActions, customer } = this.props;
    const data = {
      existingContracts: undefined,
      givenPoas: undefined,
      receivedPoas: undefined,
      addFutureOpenedAccounts: get(customer, 'age', 0) > 18
    };
    connectAccountsToContractActions.connnectAccountsToContractInitFulfilled({ payload: data });
  }

  /**
   * Handle customer change
   *
   * @param  {Object} customer Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const { poaActions, freemiumActions } = this.props;
    const customerId = get(customer, 'id');
    this.onInit();
    poaActions.customerPoasRequest({ dataKey: customerId, loadAccounts: true });
    freemiumActions.loadLilaSetRequest({ dataKey: customerId });
  }

  /**
   * update contract
   * @param {*} id key
   * @param {*} value selected poaAccount
   * @param {*} selected array of selected poaAccounts
   */
  onExistingContractChanged(id, value, selected) {
    const { connectAccountsToContractActions, freemiumActions } = this.props;
    const contract = get(selected, '0.contract');
    // rest values
    connectAccountsToContractActions.connnectAccountsToContractSetValue('receivedPoas', undefined);
    connectAccountsToContractActions.connnectAccountsToContractSetValue('givenPoas', undefined);

    connectAccountsToContractActions.connnectAccountsToContractSetValue(id, contract);
    if (!isNil(contract)) {
      freemiumActions.fetchEcontractRequest({ dataKey: contract.contractId });
    }
  }

  /**
   * update the givenPoas
   * @param {*} id key
   * @param {*} value selected poaAccount
   * @param {*} selected array of selected poaAccounts
   */
  onGivenAccountChange(id, value, selected) {
    const { connectAccountsToContractActions } = this.props;
    const accounts = selected.map((account) => ({ ...account.account, activatedAccountEbanking: true }));
    // if no givenAccounts are selected it should always be false
    if (isEmpty(accounts)) {
      connectAccountsToContractActions.connnectAccountsToContractSetValue('addFutureOpenedAccounts', false);
    }
    connectAccountsToContractActions.connnectAccountsToContractSetValue(id, accounts);
  }

  /**
   * update receivedPoas
   * @param {*} id key
   * @param {*} value selected poaAccount
   * @param {*} selected array of selected poaAccounts
   * @param {*} index in this case index should be the clientAssignedKey
   */
  onReceivedAccountChange(id, value, selected, index) {
    const { connectAccountsToContractActions, form } = this.props;
    const accounts = selected.map((account) => ({ ...account.account, activatedAccountEbanking: true }));
    const currentReceivedPoas = get(form, 'data.receivedPoas', {});
    const payload = update(currentReceivedPoas, { [index]: { $set: accounts } });
    connectAccountsToContractActions.connnectAccountsToContractSetValue('receivedPoas', payload);
  }

  /**
   * get given poas
   * @returns valid given poas
   */
  getGivenPoas() {
    const { poas, form, mapper } = this.props;
    const mortgageTypes = mapper.get('setAdvisorAccountTypes', 'mortgage');
    const selectedAccount = get(form, 'data.existingContracts.clientAssignedKey');
    return get(poas, 'data.resultVariables.givenPoas', [])
      .filter((poa) => poa.clientKey === selectedAccount
        && poa.legitimation === 'HOLDER'
        && poa.signbaseStatus === 'ACTIVE'
        && ['INDIVIDUALLY'].includes(poa.signingAuthority))
      .map((poa) => {
        const filteredAccount = get(poa, 'assignmentAccounts', [])
          .filter((account) => !mortgageTypes.includes(`accountType-${get(account, 'accountType')}`));
        return { ...poa, assignmentAccounts: filteredAccount };
      });
  }

  /**
   * filter receivedPoas and make sure there are no duplicates with the same clientAssignedKey
   * @returns array of poas
   */
  getReceivedPoas() {
    const { poas, form, mapper } = this.props;
    const mortgageTypes = mapper.get('setAdvisorAccountTypes', 'mortgage');
    const selectedAccount = get(form, 'data.existingContracts.clientAssignedKey');
    const filteredPoas = get(poas, 'data.resultVariables.receivedPoas', [])
      .filter((poa) => poa.clientKey === selectedAccount
        && ['AUTHORISED_PERSON', 'HOLDER'].includes(poa.legitimation)
        && poa.signbaseStatus === 'ACTIVE'
        && ['INDIVIDUALLY'].includes(poa.signingAuthority))
      .map((poa) => {
        const filteredAccount = get(poa, 'assignmentAccounts', [])
          .filter((account) => !mortgageTypes.includes(`accountType-${get(account, 'accountType')}`));
        return { ...poa, assignmentAccounts: filteredAccount };
      });

    return Object.values(mergeAccountsByClientAssignedKey(filteredPoas));
  }

  /**
   * map everything into one array and enrich the account with clientAssignedKey
   * @returns array of receivedPoa accounts
   */
  getReceivedPoasAccounts() {
    const validPoas = this.getReceivedPoas();

    return validPoas.reduce((prev, curr) => {
      // Include curr.clientAssignedKey in assignmentAccounts
      const updatedAssignmentAccounts = curr.assignmentAccounts.map((account) => {
        return {
          ...account,
          clientAssignedKey: curr.clientAssignedKey,
          activatedAccountEbanking: false,
          dateOfBirth: curr.dateOfBirth,
          clientDesignation: curr.clientDesignation
        };
      });

      return [...prev, ...updatedAssignmentAccounts];
    }, []);
  }

  toggleAddFutureOpenedAccounts(key, value) {
    const { connectAccountsToContractActions, customer } = this.props;
    const isCustomerUnderAge = get(customer, 'age') < 18;
    // dont allow to change the toggle when customer is under age
    if (isCustomerUnderAge) return;
    connectAccountsToContractActions.connnectAccountsToContractSetValue('addFutureOpenedAccounts', value);
  }

  /**
   * select all receivedPoa by given clientAssignedKey
   * @param {*} receivedPoa
   * @param {*} clientAssignedKey
   */
  selectAllReceivedPoas(receivedPoa, clientAssignedKey) {
    const { connectAccountsToContractActions, form } = this.props;
    const accounts = get(receivedPoa, 'assignmentAccounts').map((account) => ({ ...account, clientAssignedKey, activatedAccountEbanking: true }));

    const currentReceivedPoas = get(form, 'data.receivedPoas', {});
    const payload = update(currentReceivedPoas, {
      [clientAssignedKey]: { $set: accounts }
    });
    connectAccountsToContractActions.connnectAccountsToContractSetValue('receivedPoas', payload);
  }

  /**
   * remove all receivedPoas by given clientAssignedKey
   * @param {*} receivedPoa
   * @param {*} clientAssignedKey
   */
  deselectReceivedPoas(clientAssignedKey) {
    const { connectAccountsToContractActions, form } = this.props;
    const currentReceivedPoas = get(form, 'data.receivedPoas', {});
    const payload = update(currentReceivedPoas, { $unset: [clientAssignedKey] });

    connectAccountsToContractActions.connnectAccountsToContractSetValue('receivedPoas', payload);
  }

  submit(hasCompnayPoas) {
    const {
      toggleRedirectModal, customer, form, consultant, connectAccountsToContractActions
    } = this.props;

    // combine all poas together
    // argh bpf now everything has to be sent
    const allReceivedAccounts = this.getReceivedPoasAccounts();
    const removedUnderagedReceivedAccounts = !hasCompnayPoas ? allReceivedAccounts.filter((account) => {
      const dob = moment(account.dateOfBirth);
      const eighteenYearsAgo = moment().subtract(18, 'years');
      return dob.isBefore(eighteenYearsAgo);
    }) : allReceivedAccounts;

    const newSelectedReceivedPoas = flatMap(get(form, 'data.receivedPoas', []));
    const mergedReveivedPoas = unionBy(newSelectedReceivedPoas, removedUnderagedReceivedAccounts, 'accountKey');
    const givenPoas = this.getGivenPoas();
    const allGivenAccounts = getGivenPoasAccounts(givenPoas);
    const newSelectedGivenPoas = get(form, 'data.givenAccounts', []);
    const mergedGivenPoas = unionBy(newSelectedGivenPoas, allGivenAccounts, 'accountKey');
    const payload = {
      clientKey: toIntIfSet(get(customer, 'id')),
      contractKey: toIntIfSet(get(form, 'data.existingContracts.contractKey')),
      responsibleUser: toIntIfSet(get(consultant, 'id')),
      addFutureOpenedAccounts: get(form, 'data.addFutureOpenedAccounts'),
      assignmentAccounts: [...mergedReveivedPoas, ...mergedGivenPoas].map((account) => ({
        accountKey: account.accountKey,
        accountHolderClientKey: account.clientAssignedKey,
        activatedAccountEbanking: account.activatedAccountEbanking
      }))
    };

    connectAccountsToContractActions.connnectAccountsToContractSaveRequest({ data: payload });
    toggleRedirectModal();
  }

  /**
   * Validate current form data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const { form } = this.props;

    const data = get(form, 'data', {});
    const validator = getValidator();

    const definition = {
      validations: {
        existingContracts: {
          type: 'object',
          required: true,
          validations: {}
        },
      },
    };

    return validator.validate(definition, data);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      language,
      form,
      eBankingContracts,
      workguide,
      eContract,
      isRequesting,
      redirect,
      customer
    } = this.props;
    const validations = this.validate();
    const givenPoas = this.getGivenPoas();
    const givenAccounts = getGivenPoasAccounts(givenPoas);
    const receivedPoas = this.getReceivedPoas();

    const selectedReceivedPoas = get(form, 'data.receivedPoas');
    const eContractRequesting = get(eContract, 'requesting') && !get(eContract, 'fulfilled', false);
    const hasCompnayPoas = checkIfPoaClientTypeCompany(receivedPoas, givenPoas);
    const hasPoasUnderEighTeen = checkPoasUnderEighTeen(givenAccounts, receivedPoas);
    const isDoublePartner = get(customer, 'mainType.id') === 'mainType-2';

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        showLoadIndicator
        requesting={isRequesting}
      >
        <div className="connect-ebanking-to-accounts-container" style={{ padding: 30 }}>
          <Section>
            <SectionTitle>{get(workguide, `title.${language}`)}</SectionTitle>

            {(get(eContract, 'error') instanceof Error) && (
              <Alert
                type="error"
              >
                <AlertTitle>
                  <FormattedMessage
                    id="Freemium.Form.ConnectAccountsToContract.EContractError.Title"
                  />
                </AlertTitle>
                <AlertContent>
                  <FormattedMessage
                    id="Freemium.Form.ConnectAccountsToContract.EContractError.Description"
                  />
                </AlertContent>
              </Alert>
            )}

            {isDoublePartner
              ? (
                <Alert type="error">
                  <AlertContent>
                    <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.DoublePartnerInfo" />
                  </AlertContent>
                </Alert>
              ) : (
                <>
                  <p><FormattedMessage id="Freemium.Form.ConnectAccountsToContract.WorkguideDescription" /></p>
                  <Alert type="warning">
                    <AlertContent>
                      <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.MultiselectionInfo" />
                    </AlertContent>
                  </Alert>
                  <SectionContent>
                    <SubSection>
                      <SubSectionTitle><FormattedMessage id="Freemium.Form.ConnectAccountsToContract.EBankingTitle" /></SubSectionTitle>
                      <SubSectionContent style={{ marginLeft: 18 }}>
                        <CheckboxGroup
                          id="existingContracts"
                          name="existingContracts"
                          onChange={this.onExistingContractChanged}
                          options={eBankingContracts.map((c) => {
                            return {
                              label: (
                                <Row xs={2}>
                                  <Col md={3} lg={4}>{c.contractId}</Col>
                                  <Col>{c.clientDesignation}</Col>
                                  <Col />
                                </Row>
                              ),
                              value: c.contractId,
                              contract: c
                            };
                          })}
                          validations={get(validations, 'existingContracts')}
                          showValidations
                          value={get(form, 'data.existingContracts.contractId')}
                        />
                      </SubSectionContent>
                    </SubSection>

                    <SubSection>
                      <SubSectionTitle>
                        <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.AccountAndDepots" />
                      </SubSectionTitle>
                      <SubSectionContent style={{ marginLeft: 18 }}>
                        {eContractRequesting ? <BeatLoader /> : (
                          <>
                            <Row style={{ marginTop: 20 }}>
                              <Col md={1} lg={1}>
                                <Toggle
                                  id="addFutureOpenedAccounts"
                                  value={get(form, 'data.addFutureOpenedAccounts', true)}
                                  onChange={this.toggleAddFutureOpenedAccounts}
                                  disabled={isEmpty(get(form, 'data.givenAccounts'))}
                                />
                              </Col>
                              <Col>
                                <Label>
                                  <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.AddFutureOpenedAccounts" />
                                </Label>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: 20 }}>

                              <CheckboxGroup
                                id="givenAccounts"
                                name="givenAccounts"
                                onChange={this.onGivenAccountChange}
                                options={givenAccounts.map((account) => ({
                                  label: (
                                    <Row xs={2}>
                                      <Col>{get(account, `accountTypeText.${language}`)}</Col>
                                      <Col>{get(account, `accountCategoryText.${language}`)}</Col>
                                      <Col>{account.editAccountNumber}</Col>
                                      <Col />
                                    </Row>
                                  ),
                                  id: account.accountKey,
                                  value: account.accountKey,
                                  account
                                }))}
                                multi
                                value={[...get(form, 'data.givenAccounts', []).map((account) => account.accountKey)]}
                              />

                            </Row>
                          </>
                        )}
                      </SubSectionContent>
                    </SubSection>

                    <SubSection>
                      <SubSectionTitle>
                        <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.PoaAccountsTitle" />
                      </SubSectionTitle>
                      <SubSectionContent>
                        {!eContractRequesting && (
                          <>
                            <Alert type="warning">
                              <AlertContent>
                                <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.InfoOne" />
                              </AlertContent>
                            </Alert>
                            <Alert type="warning">
                              <AlertContent>
                                <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.InfoTwo" />
                              </AlertContent>
                            </Alert>
                          </>
                        )}

                        {receivedPoas.map((receivedPoa, index) => {
                          const clientAssignedKey = get(receivedPoa, 'clientAssignedKey');
                          return (
                            <CollapsibleRow
                              title={<b>{get(receivedPoa, 'clientDesignationLong')}</b>}
                              collapsed={get(receivedPoa, 'assignmentAccounts.length' > 0)}
                              id={`collapsible-receivedPoas-${index}`}
                            >
                              <Col lg={12}>
                                {eContractRequesting ? <BeatLoader /> : (
                                  <>
                                    {get(selectedReceivedPoas, `${clientAssignedKey}.length`, 0) <= get(receivedPoa, 'length', 0)
                                      ? (
                                        <div className="select-all-button" onClick={() => this.selectAllReceivedPoas(receivedPoa, clientAssignedKey)}>
                                          <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.SelectAll" />
                                        </div>
                                      )
                                      : (
                                        <div className="select-all-button" onClick={() => this.deselectReceivedPoas(clientAssignedKey)}>
                                          <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.DeselectAll" />
                                        </div>
                                      )
                                    }
                                    <CheckboxGroup
                                      id={`receivedPoas-${index}`}
                                      name={`receivedPoas-${index}`}
                                      onChange={(id, value, selected) => this.onReceivedAccountChange(id, value, selected, clientAssignedKey)}
                                      options={get(receivedPoa, 'assignmentAccounts').map((account) => ({
                                        label: (
                                          <Row xs={2}>
                                            <Col>{get(account, `accountTypeText.${language}`)}</Col>
                                            <Col>{get(account, `accountCategoryText.${language}`)}</Col>
                                            <Col>{account.editAccountNumber}</Col>
                                            <Col />
                                          </Row>
                                        ),
                                        id: account.accountKey,
                                        value: account.accountKey,
                                        account: { ...account, clientAssignedKey },

                                      }))}
                                      multi
                                      value={get(form, `data.receivedPoas.${clientAssignedKey}`, []).map((account) => account.accountKey)}
                                    />
                                  </>
                                )}
                              </Col>
                            </CollapsibleRow>
                          );
                        })}

                        {isEmpty(receivedPoas) && (
                          <Alert type="warning">
                            <AlertContent>
                              <FormattedMessage id="Freemium.Form.ConnectAccountsToContract.NoPoaAccount" />
                            </AlertContent>
                          </Alert>
                        )}
                      </SubSectionContent>
                    </SubSection>
                    <SubSection>
                      {!isEmpty(hasPoasUnderEighTeen) && (
                        <div className="under-eighteen-content">
                          <Alert type="warning">
                            <AlertContent>
                              <FormattedMessage
                                id="Freemium.Form.ConnectAccountsToContract.UnderEighteenMessage"
                              />
                            </AlertContent>
                          </Alert>
                        </div>
                      )}
                    </SubSection>
                    <Row className="justify-content-start">
                      <Col lg="auto" md="auto" sm="auto">
                        <Button
                          color="primary"
                          onClick={() => this.submit(hasCompnayPoas)}
                          disabled={!isEmpty(validations) || (get(eContract, 'error') instanceof Error)}
                        >
                          <FormattedMessage id="Activity.Form.Submit" />
                        </Button>

                        <span style={{ paddingRight: '20px' }} />

                        <Button color="primary" outline onClick={redirect}>
                          <FormattedMessage id="General.Button.Cancel" />
                        </Button>

                      </Col>
                    </Row>
                  </SectionContent>
                </>
              )}
          </Section>
        </div>
      </WorkguideCustomerAware>
    );
  }
}

ConnectAccountsToContract.propTypes = {
  connectAccountsToContractActions: PropTypes.object.isRequired,
  consultant: PropTypes.object,
  customer: PropTypes.object,
  eBankingContracts: PropTypes.array,
  eContract: PropTypes.object,
  form: PropTypes.object,
  isRequesting: PropTypes.bool,
  language: PropTypes.string,
  poaActions: PropTypes.object.isRequired,
  poas: PropTypes.object,
  redirect: PropTypes.func.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func.isRequired,
  workguide: PropTypes.object.isRequired,
  mapper: PropTypes.object.isRequired,
};

ConnectAccountsToContract.defaultProps = {
  consultant: {},
  customer: undefined,
  eBankingContracts: [],
  eContract: {},
  form: {},
  isRequesting: false,
  language: 'de',
  poas: {}
};

function isRequesting(state, customerId) {
  return get(state, `freemium.customerPoas.${customerId}.requesting`, false)
    || get(state, `freemium.lilaSet.${customerId}.requesting`, false);
}

function mapStateToProps(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');
  const poas = get(state, `freemium.customerPoas.${customerId}`);
  const selectedAccount = get(state, 'freemium.connectAccountToContractForm.data.existingContracts.contractId');
  return {
    form: state.freemium.connectAccountToContractForm,
    poas,
    eBankingContracts: get(state, `freemium.lilaSet.${customerId}.data.other.ebankingContracts`),
    eContract: get(state, `freemium.eContract.${selectedAccount}`),
    consultant: state.login.session,
    isRequesting: isRequesting(state, customerId),
    language: state.login.language
  };
}

function mapDispatchToProps(dispatch) {
  return {
    poaActions: bindActionCreators(poaActions, dispatch),
    connectAccountsToContractActions: bindActionCreators(connectAccountsToContractActions, dispatch),
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeMapper()(
    connect(mapStateToProps, mapDispatchToProps)(ConnectAccountsToContract)
  )

);
