import { call, put } from 'redux-saga/effects';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';
import { get } from 'lodash';
import axios from 'axios';

import accountsAction from '../../actions/Customer/ProductGroups';
import toIntIfSet from '../../../../lib/Utils/toIntIfSet';

const {
  PRODUCT_GROUPS_PENDING,
  PRODUCT_GROUPS_FULFILLED,
  PRODUCT_GROUPS_REJECTED
} = accountsAction;

export default function* getCrmProductGroups(request) {
  yield put({ type: PRODUCT_GROUPS_PENDING });

  try {
    const {
      customerId,
      tenant = 6300,
      productNumbers = [],
      productType = 'Account',
      crmGroup = 'Could'
    } = request;

    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-product-getCrmProductGroups/tenant-id/${tenant}/execute`;
    const data = {
      variables: {
        clientKey: toIntIfSet(customerId),
        productNumbers,
        productType,
        crmGroup,
      },
      businessKey: 'Product GetCrmProductGroups',
      resultVariableNames: [
        'productGroups',
        'endStatus'
      ],
      waitTimeoutInMs: 10000
    };

    const result = yield call(axios, {
      url,
      method: 'post',
      data: removeUnwantedValues(data)
    });

    const payload = get(result, 'data.resultVariables.productGroups', []);

    yield put({ type: PRODUCT_GROUPS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: PRODUCT_GROUPS_REJECTED, error });

    return error;
  }
}
