import { call, put } from 'redux-saga/effects';

import axios from 'axios';
import { filter, isEmpty, isNil, size } from 'lodash';
import notificationAction from '../actions/Actions';

const {
  NOTIFICATION_PENDING,
  NOTIFICATION_FULFILLED,
  NOTIFICATION_REJECTED,
  SET_NOTIFICATION_BADGE_COUNT
} = notificationAction;

export default function* notification(request) {
  yield put({ type: NOTIFICATION_PENDING });
  const { topics } = request;

  try {
    if (isEmpty(topics) || isNil(topics)) {
      throw new Error("The 'topics' parameter is missing, undefined, or an empty array. Please provide valid topics.");
    }
    const url = `/rdi/notifications/${topics.join(',')}`;
    const { data } = yield call(axios.get, url);

    yield put({ type: NOTIFICATION_FULFILLED, payload: data });
    const unreadMessagesCount = size(filter(data, { read: false }));

    yield put({ type: SET_NOTIFICATION_BADGE_COUNT, payload: { count: unreadMessagesCount } });

    return data;
  } catch (error) {
    yield put({ type: NOTIFICATION_REJECTED, error });

    return error;
  }
}
