import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

const ProfileCompletionNotFulfilled = React.memo(() => {
  return (
    <Alert type="error">
      <AlertTitle>
        <FormattedMessage
          id="Freemium.Workguide.ProfileCompletionNotFulfilled.Title"
        />
      </AlertTitle>
      <AlertContent>
        <FormattedMessage
          id="Freemium.Workguide.ProfileCompletionNotFulfilled.Description"
        />
      </AlertContent>
    </Alert>
  );
});

export default ProfileCompletionNotFulfilled;
