import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';

import actions from '../../actions/Actions';
import getCustomerAccounts from '../../../Account/sagas/getCustomerAccounts';

const { HAS_CUSTOMER_ACCOUNT_EXPENSES_PENDING, HAS_CUSTOMER_ACCOUNT_EXPENSES_FULFILLED, HAS_CUSTOMER_ACCOUNT_EXPENSES_REJECTED } = actions;

export default function* hasCustomerAccountExpenses(request) {
  const { customer } = request;
  const customerId = get(customer, 'id');
  yield put({ type: HAS_CUSTOMER_ACCOUNT_EXPENSES_PENDING });

  try {
    const customerAccounts = yield call(getCustomerAccounts, { dataKey: customerId });
    const accounts = customerAccounts.filter((acc) => (
      get(acc, 'expenseCategory.id') === 'expenseCategory-99'
      && get(acc, 'accountType.id') === 'accountType-110'
    ));

    const payload = {
      hasExpenses: get(accounts, 'length', 0) > 0
    };

    yield put({ type: HAS_CUSTOMER_ACCOUNT_EXPENSES_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: HAS_CUSTOMER_ACCOUNT_EXPENSES_REJECTED, error });
    return error;
  }
}
