import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';

import notificationAction from '../actions/Actions';

const { MARK_NOTIFICATION_AS_READ_PENDING, MARK_NOTIFICATION_AS_READ_FULFILLED, MARK_NOTIFICATION_AS_READ_REJECTED } = notificationAction;

export default function* notification(request) {
  const { messageIds } = request;
  yield put({ type: MARK_NOTIFICATION_AS_READ_PENDING });
  try {
    if (isEmpty(messageIds) || isNil(messageIds)) {
      throw new Error("The 'messageIds' parameter is missing, undefined, or an empty array. Please provide valid messageIds.");
    }
    const url = '/rdi/notifications/markread/';
    yield call(axios, {
      method: 'post',
      url,
      data: messageIds
    });

    yield put({ type: MARK_NOTIFICATION_AS_READ_FULFILLED, payload: messageIds });

    return messageIds;
  } catch (error) {
    yield put({ type: MARK_NOTIFICATION_AS_READ_REJECTED, error });

    return error;
  }
}
