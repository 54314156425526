import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import './SubSection.css';

const FormSubsection = React.memo(({
  children,
  className
}) => {
  const classNames = cl(className, 'form-common-sub-section');

  return (
    <div className={classNames}>
      {children}
    </div>
  );
});

FormSubsection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ])
};

FormSubsection.defaultProps = {
  children: [],
  className: undefined
};

export default FormSubsection;
