import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';

import './MonthlyIncome.css';
import MonthlyIncomeItem from './MonthlyIncomeItem';
import toIntIfSet from '../../../../../../lib/Utils/toIntIfSet';
import isDoublePartner from '../../../../../../lib/Customer/Utils/isDoublePartner';

function Entry({ customer }) {
  return {
    clientKey: toIntIfSet(get(customer, 'id')),
    incomeType: 'employmentGross',
    amount: undefined,
    proofOfIncome: undefined,
    fileLink: undefined
  };
}

class SetAdvisorFormLimitCalculationMonthlyIncome extends React.Component {
  constructor(props) {
    super(props);

    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  /**
   * Add an income entry
   *
   * @param  {Array} args Args from click event
   *
   * @return void
   */
  onAdd(...args) {
    const {
      codes,
      customer,
      id,
      onChange,
      value
    } = this.props;

    const updated = update(value, {
      $push: [Entry({ codes, customer })]
    });

    onChange(id, updated, ...args);
  }

  /**
   * Change the given value for the given index / key combination
   *
   * @param  {Number} index Array index
   * @param  {String} k     Form element key
   * @param  {Mixed}  v     Updated value
   * @param  {Array}  rest  Rest props
   *
   * @return void
   */
  onValueChange(index, k, v, ...rest) {
    const {
      id,
      onChange,
      value
    } = this.props;

    const updated = update(value, {
      [index]: {
        [k]: { $set: v }
      }
    });

    onChange(id, updated, ...rest);
  }

  /**
   * Remove the given entry from list
   *
   * @param  {Number} index Item index
   * @param  {Array}  rest  Rest Props
   *
   * @return void
   */
  onRemove(index, ...rest) {
    const {
      id,
      onChange,
      value
    } = this.props;

    const updated = update(value, {
      $splice: [[index, 1]]
    });

    onChange(id, updated, ...rest);
  }

  /**
   * Render children
   *
   * @return {Array} children Array of children to render
   */
  renderChildren() {
    const {
      customer,
      partners,
      validations,
      value
    } = this.props;

    return value.map((item, index) => {
      const removeable = isDoublePartner(customer)
        ? index > 1
        : index > 0;

      return (
        <MonthlyIncomeItem
          // eslint-disable-next-line
          key={index}
          customer={customer}
          data={item}
          onChange={(...args) => this.onValueChange(index, ...args)}
          onRemove={this.onRemove}
          partners={partners}
          removeable={removeable}
          validations={get(validations, index)}
        />
      );
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    return (
      <div className="setadvisor-form-limit-calculation--monthly-income">
        <Row className="setadvisor-form-limit-calculation--monthly-income--title">
          <Col lg={11} md={11}>
            <FormattedMessage id="Freemium.Form.CreditCard.MonthlyIncome.Title" />
          </Col>

          <Col lg={1} md={1}>
            <span
              className="mdi mdi-plus-circle-outline float-end"
              onClick={this.onAdd}
            />
          </Col>
        </Row>

        <div className="setadvisor-form-limit-calculation--monthly-income--children">
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

SetAdvisorFormLimitCalculationMonthlyIncome.propTypes = {
  codes: PropTypes.object,
  customer: PropTypes.object,
  id: PropTypes.string,
  onChange: PropTypes.func,
  partners: PropTypes.object,
  validations: PropTypes.object,
  value: PropTypes.array
};

SetAdvisorFormLimitCalculationMonthlyIncome.defaultProps = {
  codes: { groups: {} },
  customer: undefined,
  id: 'monthlyIncomes',
  onChange: noop,
  partners: {},
  validations: {},
  value: []
};

export default SetAdvisorFormLimitCalculationMonthlyIncome;
