import { isArray, isObject } from 'lodash';

function ckeckValidations(validationResult = {}, checks = []) {
  if (!isObject(validationResult) || !isArray(checks)) {
    return new Error('Invalid input');
  }
  return Object.keys(validationResult).some((key) => checks.includes(key));
}

export default ckeckValidations;
