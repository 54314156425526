import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../General/components/Section/index';
import Coverage from '../../../components/Form/CreditCard/LimitDetermination/Coverage';
import ResponsibleApprover from '../../../components/Form/CreditCard/LimitDetermination/ResponsibleApprover';
import Limits from '../../../components/Form/CreditCard/Common/CardLimits';
import isApproverRequired from '../../../lib/CreditCard/isApproverRequired';
import { getValidator } from '../../../../../globals';
import setAdvisorActions from '../../../actions/Actions';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../Workguide/hocs';

class CreditCardOrderLimitDetermination extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onCustomerChange = this.onCustomerChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      calculation,
      form
    } = this.props;

    const calculatedDesiredGlobalLimit = get(calculation, 'calculatedDesiredGlobalLimit');
    const prevCalculatedDesiredGlobalLimit = get(prevProps, 'calculation.calculatedDesiredGlobalLimit');

    if (get(form, 'data.active') && (calculatedDesiredGlobalLimit !== prevCalculatedDesiredGlobalLimit)) {
      this.onValueChange('desiredGlobalLimit', calculatedDesiredGlobalLimit);
    }
  }

  componentWillUnmount() {
    const { setAdvisorActions } = this.props;

    setAdvisorActions.creditCardOrderFormReset();
    setAdvisorActions.creditCardOrderReset();
  }

  /**
   * Handle customer change.
   * Call init saga which will reset form data and load necessary data
   *
   * @param  {Object} customer  Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const {
      initFormOnCustomerChange,
      setAdvisorActions
    } = this.props;

    if (initFormOnCustomerChange) {
      setAdvisorActions.creditCardOrderFormInitRequest({ customer });
    }
  }

  /**
   * Handle form value change and revalidate data
   *
   * @param  {String} key   Form element id
   * @param  {Mixed}  value Form value
   * @param  {Array}  rest  Rest args
   *
   * @return void
   */
  onValueChange(key, value, ...rest) {
    const { setAdvisorActions } = this.props;

    setAdvisorActions.creditCardOrderFormSetValue(key, value, ...rest);
  }

  /**
   * Validate current form data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const {
      form,
      session,
      setAdvisorActions
    } = this.props;

    const definition = {
      validations: {
        desiredGlobalLimit: {
          type: 'number',
          required: true,
          validations: {
            isGt: [0],
            isInt: []
          }
        },
        cards: {
          type: 'array',
          required: true,
          validations: {
            cardLimit: {
              type: 'number',
              validations: {
                isGe: [0],
                isLe: [get(form, 'data.desiredGlobalLimit')]
              }
            }
          }
        },
        responsibleApprover: {
          type: 'object',
          required: () => get(form, 'data.active', false) && isApproverRequired({ data: get(form, 'data', {}), session }),
          validations: {}
        }
      }
    };

    const validator = getValidator();
    const result = validator.validate(definition, get(form, 'data', {}));
    setAdvisorActions.formValidationsFulfilled({ dataKey: 'creditCardLimitDetermination', payload: result });

    return result;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      calculation,
      consultants,
      form,
      requesting,
      session
    } = this.props;

    const validations = this.validate();

    const { data } = form;

    return (
      <div className="setadvisor-form-limit-determination">
        <WorkguideCustomerAware
          onChange={this.onCustomerChange}
          onInit={this.onCustomerChange}
          showLoadIndicator
          requesting={requesting}
        >
          <Section>
            <SectionTitle>
              <FormattedMessage id="Freemium.Form.CreditCard.LimitDetermination.Title" />
            </SectionTitle>

            <SectionContent>
              <Limits
                calculation={calculation}
                calculatedDesiredGlobalLimit={get(calculation, 'calculatedDesiredGlobalLimit')}
                form={form}
                onChange={this.onValueChange}
                validations={validations}
              />

              <div className="mb-4" />

              <Coverage
                calculation={calculation}
                desiredGlobalLimit={get(data, 'desiredGlobalLimit')}
              />

              {isApproverRequired({ data, session }) && (
                <ResponsibleApprover
                  consultants={consultants}
                  onChange={this.onValueChange}
                  validations={get(validations, 'responsibleApprover')}
                  value={get(data, 'responsibleApprover')}
                />
              )}
            </SectionContent>
          </Section>
        </WorkguideCustomerAware>
      </div>
    );
  }
}

CreditCardOrderLimitDetermination.propTypes = {
  calculation: PropTypes.object,
  consultants: PropTypes.array,
  form: PropTypes.object,
  initFormOnCustomerChange: PropTypes.bool,
  requesting: PropTypes.bool,
  session: PropTypes.object.isRequired,
  setAdvisorActions: PropTypes.object.isRequired
};

CreditCardOrderLimitDetermination.defaultProps = {
  calculation: {},
  consultants: [],
  form: {},
  initFormOnCustomerChange: false,
  requesting: false
};

function mapStateToProps(state) {
  const form = get(state, 'freemium.creditCardOrderForm');

  return {
    calculation: state.freemium.creditCardLimitCalculation,
    consultants: state.consultantSearch.activeConsultants,
    form,
    requesting: get(form, 'initializing', false),
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAdvisorActions: bindActionCreators(setAdvisorActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(CreditCardOrderLimitDetermination)
);
