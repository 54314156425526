import { get } from 'lodash';

import isComboCardCategorySelected from '../../../../lib/CreditCard/isComboCardCategorySelected';
import toIntIfSet from '../../../../../../lib/Utils/toIntIfSet';

export default function MainCardHolder({ form }) {
  const cards = get(form, 'data.cards', []);
  const isCombo = isComboCardCategorySelected(cards);

  const mainCard = cards.find((card) => get(card, 'categoryType') === 'HK');

  return {
    clientInformation: {
      clientKey: get(form, 'data.poaMain.clientKey'),
      addressType: toIntIfSet(get(form, 'data.addressTypeMain')),
      residencePermitCode: toIntIfSet(get(form, 'data.residencePermit.number')),
      residencePermitFileLink: get(form, 'data.residencePermitFile')
    },
    cards: isCombo ? cards : [mainCard]
  };
}
