import { call, put, select } from 'redux-saga/effects';
import { chain, get, groupBy, isEmpty } from 'lodash';
import axios from 'axios';
import QueryBuilder from '@evoja-web/rql-query-builder';

import { v4 } from 'uuid';
import { productIconMapping } from '../../lib/mobilePaymentTypes';
import {
  PRODUCT_CARD_TYPE_ACCOUNT,
  PRODUCT_CARD_TYPE_CARD,
  PRODUCT_CARD_TYPE_EBANKING,
  PRODUCT_FREEMIUM_COMFORT,
  PRODUCT_FREEMIUM_CREDIT_CARD,
  PRODUCT_FREEMIUM_PAPER,
  PRODUCT_FREEMIUM_LILA_SET
} from '../../lib/ProductCardTypes';
import loadSetProduct from '../loadLilaSet';
import lilaSetProductActions from '../../actions/Actions';
import { toIntIfSet, toStringIfSet } from '../../../../lib/Utils';
import { getCodeMapper } from '../../../../globals';
import { isCompany } from '../../../../lib/Customer/Utils';
import getContracts from '../../../Contract/sagas/Customer/getContracts';
import fetchProductUsage from '../Customer/fetchProductUsage';

const { LILA_SET_PRODUCTS_PENDING, LILA_SET_PRODUCTS_FULFILLED, LILA_SET_PRODUCTS_REJECTED } = lilaSetProductActions;

function removeWordsFromTextObject(wordsToRemove, inputTexts) {
  const regex = new RegExp(`\\b(${wordsToRemove.join('|')})\\b`, 'gi');

  const removeWordsFromString = (string) => string.replace(regex, '').replace(/\s{2,}/g, ' ').trim();

  const updatedTexts = {};
  Object.keys(inputTexts).forEach((key) => {
    updatedTexts[key] = {};
    Object.keys(inputTexts[key]).forEach((lang) => {
      updatedTexts[key][lang] = removeWordsFromString(inputTexts[key][lang]);
    });
  });

  return updatedTexts;
}

/**
 * Determines if a product is eligible for a recommendation.
 * */
function isEligibleForRecommendation(module, lilaSet, productUsage, clientKey) {
  if (get(module, 'contractType') !== 6441) return false;

  const hasMultiplePaymentAccount = chain(lilaSet)
    .get('productAccounts', [])
    .filter((a) => [90, 110].includes(a.accountType))
    .size()
    .thru((count) => count > 1)
    .value();

  const hasMultipleActiveDebitCard = chain(lilaSet)
    .get('other.debitCards', [])
    .filter((card) => get(card, 'isCardActive', false) && get(card, 'accountHolderClientKey', false) === toIntIfSet(clientKey))
    .size()
    .thru((count) => count > 1)
    .value();

  const foreignAtmWithdrawals = get(productUsage, 'withdrawalsForeignAtm', 0);
  const foreignCountryWithdrawals = get(productUsage, 'withdrawalsForeignCountry', 0);
  const hasExistingConfortModule = get(lilaSet, 'modules', []).some((m) => m.contractType === 6441 && m.active);

  const haseligibilityScore = (2 * foreignAtmWithdrawals + 5 * foreignCountryWithdrawals) >= 48;

  return (hasMultiplePaymentAccount || hasMultipleActiveDebitCard || haseligibilityScore) && !hasExistingConfortModule;
}

class ProductFactory {
  constructor(productGroups, sectors, language, intl) {
    this.sectors = sectors;
    this.productGroups = productGroups;
    this.language = language;
    this.paymentCode = sectors.find((s) => s.id === 'sector-10');
    this.intl = intl;
  }

  findSectorForProduct(productKey) {
    const group = get(this, 'productGroups.data', []).find((group) => group.products.some((product) => product.number === toStringIfSet(productKey)));

    return this.sectors.find((sector) => get(sector, 'number') === get(group, 'productGroup.addition'));
  }

  createCreditCard(card) {
    const iconKey = get(card, 'cardVarietyText.de', '').toLowerCase().includes('visa') ? 'visa' : 'mastercard';
    return {
      sector: this.paymentCode,
      type: {
        ...get(card, 'cardVarietyText'),
        text: removeWordsFromTextObject(['Valiant'], get(card, 'cardVarietyText'))
      },
      id: get(card, 'cardKey'),
      accountNumber: get(card, 'displayCardNumber'),
      customerDesignation: get(card, 'embossedLineOne'),
      customerId: get(card, 'accountHolderClientKey'),
      icon: productIconMapping[iconKey],
      isCard: true,
      productType: PRODUCT_CARD_TYPE_CARD
    };
  }

  createDebitCard(card) {
    return {
      sector: this.paymentCode,
      type: get(card, 'cardVarietyText'),
      id: get(card, 'cardKey'),
      accountNumber: get(card, 'displayCardNumber'),
      customerDesignation: get(card, 'embossedLineOne'),
      customerId: get(card, 'accountHolderClientKey'),
      icon: productIconMapping.mastercard,
      isCard: true,
      productType: PRODUCT_CARD_TYPE_CARD
    };
  }

  createEBankingContract(contract) {
    return {
      type: get(contract, 'contractTypeText'),
      id: get(contract, 'contractKey'),
      accountNumber: get(contract, 'contractId'),
      customerDesignation: get(contract, 'clientDesignation'),
      customerId: get(contract, 'clientAssignedKey'),
      icon: productIconMapping.eBanking,
      productType: PRODUCT_CARD_TYPE_EBANKING,
      sector: this.paymentCode
    };
  }

  createLilaSetAccount(account) {
    return {
      id: get(account, 'accountKey'),
      sector: this.findSectorForProduct(get(account, 'accountType')),
      type: get(account, 'accountTypeText'),
      customerDesignation: get(account, 'clientDesignation'),
      customerId: get(account, 'clientKey'),
      accountNumber: get(account, 'editAccountNumber'),
      isAccount: true,
      accountId: get(account, 'accountKey').toString(),
      productType: PRODUCT_CARD_TYPE_ACCOUNT
    };
  }

  createBusinessSet(dataKey, contracts = []) {
    return contracts.filter((c) => ['6406', '6407', '6408'].includes(c.contractType.number)).map((contract) => ({
      id: v4(),
      type: get(contract, `contractType.text.${this.language}`),
      sector: { id: 'business-set' },
      customerId: dataKey,
      icon: productIconMapping.lilaSet,
    }));
  }

  getSetModules(lilaSet, dataKey, productUsage) {
    const defaultSet = {
      contractTypeText: { de: 'Lila Set', fr: 'Lilas set' },
      active: false,
      type: 6440
    };

    // Initialize lilaSet.set with defaults or existing values
    const set = {
      ...defaultSet,
      ...lilaSet.set
    };
    const setProduct = {
      id: v4(),
      type: set.contractTypeText[this.language],
      sector: { id: 'lila-set' },
      icon: productIconMapping.lilaSet,
      active: set.active,
      isLilaSet: true,
      contractType: 6440,
      deletable: isEmpty(get(lilaSet, 'modules', []).filter((module) => module.active)),
      productType: PRODUCT_FREEMIUM_LILA_SET
    };

    const modules = get(lilaSet, 'modules', []);

    const requiredModules = [
      { contractType: 6441, productType: PRODUCT_FREEMIUM_COMFORT, title: this.intl.formatMessage({ id: 'Freemium.Products.OptionComfort' }) },
      { contractType: 6421, productType: PRODUCT_FREEMIUM_CREDIT_CARD, title: this.intl.formatMessage({ id: 'Freemium.Products.OptionCreditCard' }) },
      { contractType: 6423, productType: PRODUCT_FREEMIUM_PAPER, title: this.intl.formatMessage({ id: 'Freemium.Products.OptionPaper' }) },
    ];

    const setOptions = requiredModules.map((requiredModule) => {
      // Find if the module already exists
      const existingModule = modules.find(
        (module) => get(module, 'contractType') === requiredModule.contractType
      );

      // Return the module (either existing or with active set to false)
      return {
        id: v4(),
        type: get(
          existingModule || {},
          `contractTypeText.${this.language}`,
          requiredModule.title
        ),
        sector: { id: 'lila-set' },
        customerId: dataKey,
        icon: productIconMapping.lilaSet,
        active: get(existingModule, 'active', false),
        productType: requiredModule.productType,
        contractType: requiredModule.contractType,
        isLilaSetActive: get(set, 'active'),
        deletable: true,
        isSetOption: true,
        recommendProduct: isEligibleForRecommendation(requiredModule, lilaSet, productUsage, dataKey)
      };
    });

    return [setProduct, ...setOptions].filter(Boolean);
  }
}

/**
 * Fetch products saga.
 * @param {object} request - Request object.
 */
export default function* orderProducts(request) {
  const { dataKey, intl, customer } = request;
  yield put({ type: LILA_SET_PRODUCTS_PENDING, dataKey });

  try {
    const { data: sectors } = yield call(axios, '/entity/code/?eq(group,sector)');

    const language = yield select((state) => state.login.language) || 'de';
    const productGroup = yield select((state) => get(state, 'productGroup.groups', []));
    const factory = new ProductFactory(productGroup, sectors, language, intl);
    // if compnay customer skip
    if (isCompany(customer)) {
      const contracts = yield call(getContracts, { customerId: dataKey });
      const businessSets = factory.createBusinessSet(dataKey, contracts);
      const groupedProducts = groupBy([
        ...businessSets
      ], 'sector.id');

      yield put({ type: LILA_SET_PRODUCTS_FULFILLED, dataKey, payload: groupedProducts });
      return {};
    }
    const lilaSet = yield call(loadSetProduct, request);
    const productUsage = yield call(fetchProductUsage, { dataKey });

    const mapper = getCodeMapper();
    const mortgageTypes = mapper.get('accountsMap', 'interestAccounts');

    const relevantAccounts = get(lilaSet, 'productAccounts', []).filter((grvAccount) => {
      return !mortgageTypes.includes(`accountType-${get(grvAccount, 'accountType')}`);
    });

    const otherCreditCards = get(lilaSet, 'other.creditCards', []).filter((card) => card.isCardActive).map((card) => factory.createCreditCard(card));

    const creditCards = get(lilaSet, 'modules', []).reduce((acc, item) => {
      const cards = get(item, 'creditCards', []);
      return acc.concat(cards.map((card) => factory.createCreditCard(card)));
    }, []);

    const debitCards = get(lilaSet, 'debitCards', []).filter((card) => card.isCardActive).map((card) => factory.createDebitCard(card));
    const otherDebitCards = get(lilaSet, 'other.debitCards', []).filter((card) => card.isCardActive).map((card) => factory.createDebitCard(card));
    const ebankingContracts = get(lilaSet, 'other.ebankingContracts', []).filter((contract) => contract.contractStatus === 90).map((contract) => factory.createEBankingContract(contract));

    const lilaSetAccounts = relevantAccounts.map((account) => factory.createLilaSetAccount(account));
    const setProducts = factory.getSetModules(lilaSet, dataKey, productUsage);

    const groupedProducts = groupBy([
      ...creditCards,
      ...otherCreditCards,
      ...debitCards,
      ...otherDebitCards,
      ...ebankingContracts,
      ...lilaSetAccounts,
      ...setProducts
    ], 'sector.id');

    yield put({ type: LILA_SET_PRODUCTS_FULFILLED, dataKey, payload: groupedProducts });
    return groupedProducts;
  } catch (error) {
    yield put({ type: LILA_SET_PRODUCTS_REJECTED, dataKey, error });
    return error;
  }
}
