import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, noop, isUndefined } from 'lodash';
import { ValidationResult } from '@evoja-web/react-form';

import './CardVariety.css';
import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';

import MainCard from './CardVariety/MainCard';
import MainAndAdditionalCard from './CardVariety/MainAndAdditionalCard';
import ComboCard from './CardVariety/ComboCard';
import AdditionalCard from './CardVariety/AdditionalCard';
import CctTool from './CardVariety/CctTool';

class CardVariety extends React.Component {
  constructor(props) {
    super(props);

    this.onCardsChange = this.onCardsChange.bind(this);
    this.onAdditionalCardsChange = this.onAdditionalCardsChange.bind(this);
  }

  /**
   * Handle update of the cards array
   *
   * @param  {Array} cards Updated cards
   * @param  {Array} rest  Rest args
   *
   * @return void
   */
  onCardsChange(cards, ...rest) {
    const { onChange } = this.props;

    this.reset();
    onChange('cards', cards, ...rest);
  }

  /**
   * Handle Update for Additional Card, because it should not reset all Elements be for setting the new values.
   * @param {Array} cards Updated cards
   * @param {Array} rest Rest args
   */

  onAdditionalCardsChange(cards, ...rest) {
    const { onChange } = this.props;

    onChange('cardVariety');
    onChange('poaMain');
    onChange('poaAdditional');
    onChange('orderInCctTool');

    onChange('cards', cards, ...rest);
  }

  /**
     * Reset / unset necessary data
     *
     * @return void
     */
  reset() {
    const { onChange } = this.props;

    onChange('connectionAccount');
    onChange('cardVariety');
    onChange('poaMain');
    onChange('poaAdditional');
    onChange('orderInCctTool');
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      alwaysEnableMainCard,
      alwaysEnableMainAndAdditionalCard,
      alwaysEnableDcCard,
      codes,
      currentCards,
      language,
      form,
      onChange,
      showCctToolOption,
      validations
    } = this.props;

    const cards = get(form, 'data.cards', []);
    const currentMainOrDcCard = currentCards.find((card) => ['HK', 'DC'].includes(get(card, 'basicData.categoryType')));

    return (
      <Section className="freemium-form-credit-card-general--card-variety">
        <SectionTitle>
          <FormattedMessage id="Freemium.Form.CreditCard.CardType.Title" />
        </SectionTitle>

        <SectionContent>
          {showCctToolOption && (
            <CctTool
              onCardsChange={this.onCardsChange}
              onChange={onChange}
              value={get(form, 'data.orderInCctTool')}
            />
          )}

          <MainCard
            cards={cards}
            codes={codes}
            disabled={!isUndefined(currentMainOrDcCard) && !alwaysEnableMainCard}
            language={language}
            onCardsChange={this.onCardsChange}
            onChange={onChange}
          />

          <MainAndAdditionalCard
            cards={cards}
            codes={codes}
            disabled={!isUndefined(currentMainOrDcCard) && !alwaysEnableMainAndAdditionalCard}
            language={language}
            onCardsChange={this.onCardsChange}
            onChange={onChange}
          />

          <ComboCard
            cards={cards}
            disabled={!isUndefined(currentMainOrDcCard) && !alwaysEnableDcCard}
            onCardsChange={this.onCardsChange}
            onChange={onChange}
          />

          <AdditionalCard
            cards={cards}
            codes={codes}
            currentCards={currentCards}
            language={language}
            onCardsChange={this.onAdditionalCardsChange}
            onChange={onChange}
          />

          <ValidationResult
            show
            validations={validations}
          />
        </SectionContent>
      </Section>
    );
  }
}

CardVariety.propTypes = {
  alwaysEnableMainCard: PropTypes.bool,
  alwaysEnableMainAndAdditionalCard: PropTypes.bool,
  alwaysEnableDcCard: PropTypes.bool,
  codes: PropTypes.object,
  currentCards: PropTypes.array,
  language: PropTypes.string,
  onChange: PropTypes.func,
  form: PropTypes.object,
  showCctToolOption: PropTypes.bool,
  validations: PropTypes.object
};

CardVariety.defaultProps = {
  alwaysEnableMainCard: false,
  alwaysEnableMainAndAdditionalCard: false,
  alwaysEnableDcCard: false,
  codes: { groups: {} },
  currentCards: [],
  language: 'de',
  onChange: noop,
  form: {},
  showCctToolOption: true,
  validations: {}
};

export default CardVariety;
