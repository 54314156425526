import { call, put } from 'redux-saga/effects';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';
import axios from 'axios';

import accountsAction from '../../actions/Customer/Accounts';
import toIntIfSet from '../../../../lib/Utils/toIntIfSet';

const {
  CUSTOMER_ACCOUNTS_PENDING,
  CUSTOMER_ACCOUNTS_FULFILLED,
  CUSTOMER_ACCOUNTS_REJECTED
} = accountsAction;

export default function* getAccounts(request) {
  const { dataKey } = request;

  yield put({ type: CUSTOMER_ACCOUNTS_PENDING, dataKey });

  try {
    const {
      accountKey,
      accountTypes,
      tenant = 6300
    } = request;

    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-product-loadAccount/tenant-id/${tenant}/execute`;
    const data = {
      variables: {
        clientKey: toIntIfSet(dataKey),
        accountKey,
        accountTypes
      },
      businessKey: 'Product GetAccounts',
      resultVariableNames: [
        'updatedAccounts',
        'endStatus'
      ],
      waitTimeoutInMs: 40000
    };

    const { data: payload } = yield call(axios, {
      url,
      method: 'post',
      data: removeUnwantedValues(data)
    });

    yield put({ type: CUSTOMER_ACCOUNTS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: CUSTOMER_ACCOUNTS_REJECTED, dataKey, error });

    return error;
  }
}
