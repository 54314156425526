import { Reducers } from '@evoja-web/redaction';

import { reducer as multipleSessionsModalReducer } from './MultipleSessionsModal';
import { reducer as userInfoReducer } from './UserInfo';

export const reducers = Reducers([
  multipleSessionsModalReducer,
  userInfoReducer
]);

export default reducers.get();
