import { call, put } from 'redux-saga/effects';

import axios from 'axios';
import eContractAction from '../../actions/EBankingContract/eContract';

const {
  FETCH_ECONTRACT_PENDING,
  FETCH_ECONTRACT_FULFILLED,
  FETCH_ECONTRACT_REJECTED
} = eContractAction;

export default function* fetchEContract(request) {
  const { dataKey, tenant = 6300 } = request;

  yield put({ type: FETCH_ECONTRACT_PENDING, dataKey });

  try {
    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-vollmacht-ebanking/tenant-id/${tenant}/execute`;

    const { data } = yield call(axios, {
      method: 'post',
      url,
      timeout: 61000,
      data: {
        variables: {
          contractId: dataKey
        },
        resultVariableNames: [
          'eContract'
        ],
        waitTimeoutInMs: 60000
      }
    });

    yield put({ type: FETCH_ECONTRACT_FULFILLED, dataKey, payload: data });

    return data;
  } catch (error) {
    yield put({ type: FETCH_ECONTRACT_REJECTED, dataKey, error });

    return error;
  }
}
