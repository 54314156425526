import { call, put } from 'redux-saga/effects';
import { getInstance as getDbInstance } from '@evoja-web/indexdb-common';

import { uniqBy } from 'lodash';
import notificationAction from '../actions/NotificationCenterSubscription';

const {
  NOTIFICATION_CENTER_SUBSCRIPTION_INIT_PENDING,
  NOTIFICATION_CENTER_SUBSCRIPTION_INIT_FULFILLED,
  NOTIFICATION_CENTER_SUBSCRIPTION_INIT_REJECTED
} = notificationAction;

export default function* notificationCenterSubscriptionInitRequest(request) {
  const { initialTopics } = request;
  yield put({ type: NOTIFICATION_CENTER_SUBSCRIPTION_INIT_PENDING });

  try {
    const db = getDbInstance({ id: 'map' });

    const result = yield call(db.getAllRecords, { table: 'notiicationSubscriptions' });

    const updatedSubscriptions = uniqBy([...initialTopics, ...result], 'id');

    yield call(db.bulkUpdate, { table: 'notiicationSubscriptions', data: updatedSubscriptions, clear: true });
    yield put({ type: NOTIFICATION_CENTER_SUBSCRIPTION_INIT_FULFILLED, payload: updatedSubscriptions });

    return updatedSubscriptions;
  } catch (error) {
    yield put({ type: NOTIFICATION_CENTER_SUBSCRIPTION_INIT_REJECTED, error });

    return error;
  }
}
