import { call, put, all, delay } from 'redux-saga/effects';
import Axios from 'axios';
import { first, get, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import QueryBuilder from '@evoja-web/rql-query-builder';

import { removeUnwantedProperties } from '@evoja-web/redux-saga-utils';
import savingsAction from '../../actions/Customer/Savings';
import getCardUid from '../../../Card/sagas/getCardUid';
import getTransactionsSaga from '../../../Viseca/sagas/Transactions';
import fetchProductUsage from './fetchProductUsage';

const {
  SET_ADVISOR_SAVINGS_PENDING,
  SET_ADVISOR_SAVINGS_FULFILLED,
  SET_ADVISOR_SAVINGS_REJECTED
} = savingsAction;

function* getTransactions(creditCards, debitCards) {
  const processCard = function* (cardInfo, days) {
    const transactions = yield call(getTransactionsSaga, { dataKey: cardInfo.uid, cardType: cardInfo.cardType });

    // if there was error fetching transactions return the error
    if (transactions instanceof Error) {
      return transactions;
    }
    const to = moment().format('YYYY-MM-DD');
    const from = !isNil(days) ? moment().subtract(days, 'days').format('YYYY-MM-DD') : moment().startOf('year').format('YYYY-MM-DD');

    const filteredTransactions = transactions.filter((card) => {
      // only recognize booked transactions
      if (card.state !== 'booked') {
        return false;
      }
      // check if transaction is within given dates
      const timestamp = moment(get(card, 'transaction_timestamp')).format('YYYY-MM-DD');
      return moment(timestamp).isBetween(from, to, undefined, '[]');
    });
    return filteredTransactions;
  };

  const debitCardTasks = debitCards
    .map((cardInfo) => processCard(cardInfo, 365));

  const creditCardTransaction = yield all(creditCards.map((cardIndo) => processCard(cardIndo, undefined)));

  const debitCardsTransactions = yield all(debitCardTasks);

  return {
    transactionsDebitCard: debitCardsTransactions.flatMap((transactions) => (transactions instanceof Error ? [] : transactions)),
    transactionsDebitCardError: debitCardsTransactions.filter((transactions) => transactions instanceof Error),
    transactionsCreditCard: creditCardTransaction.flatMap((transactions) => (transactions instanceof Error ? [] : transactions)),
    transactionsCreditCardError: creditCardTransaction.filter((transactions) => transactions instanceof Error),
    amountOfDmcCards: debitCards.length + 1
  };
}

function* getCardUidById(request) {
  const { endpoint, dataKey, cardType } = request;

  try {
    const cardUid = yield call(getCardUid, { endpoint, dataKey, suppressErrorToast: true, cardType });
    if (cardUid instanceof Error) {
      throw cardUid;
    }
    return {
      cardType,
      uid: get(cardUid, 'card_uid')
    };
  } catch {
    console.error(`Unable to load Card Uid for card with pan: ${dataKey}`);
    return undefined;
  }
}

/**
 * Fetch assigned cards by customerId
 * @param {*} request
 * @returns array of cards
 */
function* fetchCardTransactions(request) {
  const { dataKey } = request;

  const entityContractsQuery = QueryBuilder()
    .and()
    .eq('customerId', dataKey, { type: 'string' })
    .or()
    .eq('contractType.id', 'contractType-6421')
    .eq('contractType.id', 'contractType-6420')
    .end()
    .eq('contractStatus.id', 'contractStatus-90')
    .end()
    .select(['assignedCards', 'contractType'])
    .getQueryString();

  const { data: entityContracts } = yield call(Axios.get, `/entity/contract/${entityContractsQuery}`);

  const creditCardModuleContract = entityContracts.find((card) => card.contractType.id === 'contractType-6421');
  const assignedCreditCard = first(get(creditCardModuleContract, 'assignedCards', []));
  const dmcCardSetContract = entityContracts.find((card) => card.contractType.id === 'contractType-6420');

  let assignedDmcCards = [];
  if (get(dmcCardSetContract, 'assignedCards.length', 0) > 0) {
    const { data: debitCards } = yield call(
      Axios.get,
      `/service/card/?in(id,(${get(dmcCardSetContract, 'assignedCards').join(',')}))&select(cardNumber,cardType)`
    );

    assignedDmcCards = debitCards.map((el) => ({ cardNumber: el.cardNumber, cardType: get(el, 'cardType'), id: get(el, 'id') }));
  } else {
    const { data: debitCards } = yield call(
      Axios.get,
      `/service/card/?eq(customer.id,string:${dataKey})&eq(cardType.id,string:cardType-910)&limit(3)&select(cardNumber,cardType)`
    );

    assignedDmcCards = debitCards.slice(0, 1).map((el) => ({ cardNumber: el.cardNumber, cardType: get(el, 'cardType'), id: get(el, 'id') }));
  }
  const debitCardsWithUid = assignedDmcCards.map((cardInfos) => {
    return call(getCardUidById, { endpoint: '/visecadmc', dataKey: get(cardInfos, 'id'), cardType: get(cardInfos, 'cardType') });
  });

  const debitCardsWithUidResults = yield all(debitCardsWithUid);

  let creditCardsWithUid = [];
  if (!isEmpty(assignedCreditCard)) {
    const { data } = yield call(Axios.get, `/service/card/${assignedCreditCard}?select(cardType,visecaAccountNumber)`);
    const { data: cardAccounts } = yield call(
      Axios.get,
      `/service/card/?eq(visecaAccountNumber,${get(data, 'visecaAccountNumber')})&select(id,cardType)`
    );

    creditCardsWithUid = yield all(cardAccounts.map((cardInfos) => call(getCardUidById, { endpoint: '/viseca',
      dataKey: get(cardInfos, 'id'),
      cardType: get(cardInfos, 'cardType') })));
  }

  return yield call(
    getTransactions,
    removeUnwantedProperties(creditCardsWithUid, [null, undefined]),
    removeUnwantedProperties(debitCardsWithUidResults, [null, undefined])
  );
}

export default function* fetchCustomerSavings(request) {
  const { dataKey } = request;

  yield put({ type: SET_ADVISOR_SAVINGS_PENDING, dataKey });

  try {
    const { data: sets } = yield call(Axios.get, '/basic/productbundle/bundle/?in(id,(lilaSetBasic,modulPapier,modulWelt,modulKreditkarte))');

    const { data: productUsage } = yield call(fetchProductUsage, { dataKey });

    const { data: products } = yield call(Axios.get, '/basic/productbundle/product/?limit(100)');

    // Map products to each set
    const setsWithProduct = sets.map((set) => {
      const matchingProducts = products.filter((product) => {
        return product.bundle.some((p) => p.$ref.includes(set.id));
      });
      set.product = matchingProducts;
      return set;
    });

    // inizialize befor the other stuff is loaded
    yield put({
      type: SET_ADVISOR_SAVINGS_FULFILLED,
      dataKey,
      payload: {
        sets: setsWithProduct,
        productUsage: first(productUsage),
        transactions: { debit: [], credit: [] },
        amountOfDmcCards: 0
      }
    });
    yield put({ type: SET_ADVISOR_SAVINGS_PENDING, dataKey });

    // timeout so make sure calls are less prioritize
    yield delay(500);
    const {
      transactionsDebitCard,
      transactionsDebitCardError,
      transactionsCreditCard,
      transactionsCreditCardError,
      amountOfDmcCards
    } = yield call(fetchCardTransactions, request);

    yield put({
      type: SET_ADVISOR_SAVINGS_FULFILLED,
      dataKey,
      payload: {
        sets: setsWithProduct,
        productUsage: first(productUsage),
        transactions: {
          debit: transactionsDebitCard,
          debitError: transactionsDebitCardError,
          credit: transactionsCreditCard,
          creditError: transactionsCreditCardError
        },
        amountOfDmcCards
      }
    });

    return setsWithProduct;
  } catch (error) {
    yield put({ type: SET_ADVISOR_SAVINGS_REJECTED, dataKey, error });

    return error;
  }
}
