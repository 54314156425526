import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Row, Col } from 'reactstrap';

import './CurrentCards.css';
import {
  Section,
  SectionTitle,
  SectionContent
} from '../../../../../General/components/Section/index';
import getCardNumberShort from '../../../../lib/CreditCard/getCardNumberShort';

/**
 * Filter cards by categoryType "HK"
 *
 * @param   {Array}  cards  Array of cards
 *
 * @return  {Array} filtered Filtered cards
 */
function getMainCards(cards = []) {
  return cards.filter((card) => get(card, 'basicData.categoryType') === 'HK');
}

/**
 * Filter cards by categoryType "ZK" and accountKey === accountKey of the given card
 *
 * @param   {Object} card   Main card
 * @param   {Array}  cards  Array of cards
 *
 * @return  {Array} filtered Filtered cards
 */
function getAdditionalCardsByMainCard(card, cards = []) {
  return cards.filter((c) => (
    get(c, 'basicData.categoryType') === 'ZK'
    && get(c, 'basicData.accountKey') === get(card, 'basicData.accountKey')
    && get(c, 'basicData.cardVariety') === get(card, 'basicData.cardVariety')
  ));
}

const CurrentCards = React.memo(({ cards, language }) => {
  const children = getMainCards(cards)
    .map((card) => {
      const additionalCards = getAdditionalCardsByMainCard(card, cards);

      return (
        <div className="setadvisor-form-credit-card-general--current-cards--card">
          <Row className="setadvisor-form-credit-card-general--current-cards--main-card">
            <Col lg={4} md={4}>
              {get(card, `cardVarietyText.${language}`)}
            </Col>

            <Col lg={2} md={2}>
              {getCardNumberShort(get(card, 'basicData.cardNumber', ''))}
            </Col>

            <Col lg={3} md={3}>
              {get(card, 'basicData.embossedLineOne')}
            </Col>

            <Col lg={3} md={3}>
              {get(card, 'editAccountNumber')}
            </Col>
          </Row>

          <div className="setadvisor-form-credit-card-general--current-cards--additional-cards">
            {additionalCards.map((card) => {
              return (
                <Row>
                  <Col lg={1} md={1} />

                  <Col lg={3} md={3}>
                    {get(card, `cardVarietyText.${language}`)}
                  </Col>

                  <Col lg={2} md={2}>
                    {getCardNumberShort(get(card, 'basicData.cardNumber', ''))}
                  </Col>

                  <Col lg={3} md={3}>
                    {get(card, 'basicData.embossedLineOne')}
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
      );
    });

  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="Freemium.Form.CreditCard.CurrentCards.Title" />
      </SectionTitle>

      <SectionContent>
        {
          get(cards, 'length', 0) <= 0 ? (
            <FormattedMessage id="Freemium.Form.CreditCard.CurrentCards.CustomerHasNoValiantCards" />
          ) : children
        }
      </SectionContent>
    </Section>
  );
});

CurrentCards.propTypes = {
  cards: PropTypes.array,
  language: PropTypes.string
};

CurrentCards.defaultProps = {
  cards: [],
  language: 'de'
};

export default CurrentCards;
