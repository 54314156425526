import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import { ConsultantSelect } from '../../../../../General/index';

class ResponsibleApprover extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      consultants,
      id,
      onChange,
      validations,
      value
    } = this.props;

    return (
      <Row className="setadvisor-form-limit-determintation--row">
        <Col
          className="setadvisor-form-limit-determintation--row--label"
          lg={4}
          sm={4}
        >
          <FormattedMessage id="Freemium.Form.CreditCard.ResponsibleApprover" />
        </Col>

        <Col
          className="setadvisor-form-limit-determintation--row--value"
          lg={6}
          sm={6}
        >
          <ConsultantSelect
            consultants={consultants}
            id={id}
            onChange={onChange}
            showValidations
            validations={validations}
            value={value}
          />
        </Col>
      </Row>
    );
  }
}

ResponsibleApprover.propTypes = {
  consultants: PropTypes.array,
  id: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.object
};

ResponsibleApprover.defaultProps = {
  consultants: [],
  id: 'responsibleApprover',
  onChange: noop,
  validations: {},
  value: undefined
};

export default ResponsibleApprover;
