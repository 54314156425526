import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, noop, isNil } from 'lodash';

import CardHolder from './CardHolder/CardHolder';
import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../../General/components/Section/index';

const SummaryCardHolders = React.memo(({
  codes,
  data,
  language,
  onChange,
  showProfileCompletionAdditional,
  showProfileCompletionMain
}) => {
  return (
    <Section className="setadvisor-form-summary-card-holders">
      <SectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.CardHolders.Title" />
      </SectionTitle>

      <SectionContent>
        {!isNil(get(data, 'mainCardHolder')) && (
          <CardHolder
            cardHolder={get(data, 'mainCardHolder')}
            codes={codes}
            countries={get(data, `countries.${language.toUpperCase()}`)}
            formOfAddresses={get(data, `formOfAddresses.${language.toUpperCase()}`)}
            id="mainCardHolder"
            language={language}
            label={<FormattedMessage id="Freemium.Form.Summary.CardHolders.MainCardHolder" />}
            onChange={onChange}
            poaStatus90={get(data, 'mainCardHolderPoaStatus90')}
            showProfileCompletion={showProfileCompletionMain}
          />
        )}

        {!isNil(get(data, 'additionalCardHolder')) && (
          <CardHolder
            cardHolder={get(data, 'additionalCardHolder')}
            codes={codes}
            countries={get(data, `countries.${language.toUpperCase()}`)}
            formOfAddresses={get(data, `formOfAddresses.${language.toUpperCase()}`)}
            id="additionalCardHolder"
            language={language}
            label={<FormattedMessage id="Freemium.Form.Summary.CardHolders.AdditionalCardHolder" />}
            onChange={onChange}
            poaStatus90={get(data, 'additionalCardHolderPoaStatus90')}
            showProfileCompletion={showProfileCompletionAdditional}
          />
        )}
      </SectionContent>
    </Section>
  );
});

SummaryCardHolders.propTypes = {
  codes: PropTypes.object,
  data: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  showProfileCompletionAdditional: PropTypes.bool,
  showProfileCompletionMain: PropTypes.bool
};

SummaryCardHolders.defaultProps = {
  codes: PropTypes.object,
  data: PropTypes.object,
  language: PropTypes.string,
  onChange: noop,
  showProfileCompletionAdditional: false,
  showProfileCompletionMain: false
};

export default SummaryCardHolders;
