import { get, every } from 'lodash';

/**
 * Check if the main and additional card category was selected based on cards in the array
 *
 * @param  {Array}   [cards=[]] Array of cards
 *
 * @return {Boolean}
 */
export default function isComboCardCategorySelected(cards = []) {
  return (
    get(cards, 'length', 0) > 0
    && every(cards, ['categoryType', 'DC'])
  );
}
