import { call, put } from 'redux-saga/effects';

import accountsAction from '../../actions/Customer/ContractProductGroups';
import getProductGroups from './getProductGroups';

const {
  CONTRACT_PRODUCT_GROUPS_PENDING,
  CONTRACT_PRODUCT_GROUPS_FULFILLED,
  CONTRACT_PRODUCT_GROUPS_REJECTED
} = accountsAction;

export default function* getContractProductGroups(request) {
  const { dataKey } = request;

  yield put({ type: CONTRACT_PRODUCT_GROUPS_PENDING, dataKey });

  try {
    const { tenant = 6300, crmGroup = 'Must not' } = request;

    const payload = yield call(getProductGroups, {
      customerId: dataKey,
      tenant,
      productNumbers: [2],
      productType: 'Contract',
      crmGroup
    });

    yield put({ type: CONTRACT_PRODUCT_GROUPS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONTRACT_PRODUCT_GROUPS_REJECTED, dataKey, error });

    return error;
  }
}
