import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import actions from '../../actions/Actions';
import toStringIfSet from '../../../../lib/Utils/toStringIfSet';
import bpfCmsActions from '../../../BpfCms/actions/Actions';
import { ADD_TOAST } from '../../../../actions/Toast';
import loadLilaSet from '../loadLilaSet';

const {
  CANCEL_LILA_SET_PROCESS_PENDING,
  CANCEL_LILA_SET_PROCESS_FULFILLED,
  CANCEL_LILA_SET_PROCESS_REJECTED,
} = actions;

const {
  CUSTOMER_REQUEST,
  CUSTOMER_RESET
} = bpfCmsActions;

export default function* cancelLilaSetProcess(request) {
  const { dataKey, withToasts = true } = request;

  yield put({ type: CANCEL_LILA_SET_PROCESS_PENDING, dataKey });

  try {
    const {
      reload = false,
      tenant = 6300
    } = request;

    const url = '/sif/bpf/public/api/v1/signal';
    const data = {
      name: `valiant-product-invalidate-${dataKey}`,
      tenant: toStringIfSet(tenant),
      variables: {}
    };

    const payload = yield call(axios, {
      url,
      method: 'post',
      data
    });

    yield put({ type: CANCEL_LILA_SET_PROCESS_FULFILLED, dataKey, payload });
    yield put({ type: CUSTOMER_RESET, dataKey });
    yield call(loadLilaSet, { dataKey });

    // Reload customer if necessary
    if (reload) {
      yield put({ type: CUSTOMER_REQUEST, dataKey });
    }
    if (withToasts) {
      yield put({
        type: ADD_TOAST,
        kind: 'success',
        title: 'Freemium.Form.General.Messages.RefreshLilaSetData',
        html: true
      });
    }
    return payload;
  } catch (error) {
    yield put({ type: CANCEL_LILA_SET_PROCESS_REJECTED, dataKey, error });
    if (withToasts) {
      yield put({
        type: ADD_TOAST,
        kind: 'error',
        title: 'Freemium.Form.General.Messages.RefreshLilaSetDataFailed',
        html: true
      });
    }

    return error;
  }
}
