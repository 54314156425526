import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { RadioButton } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

import {
  SubSection,
  SubSectionTitle
} from '../../../../../../General/components/SubSection/index';

class CardVarietyCctTool extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle click to the cct tool radio button.
   * Reset current cards
   *
   * @param  {String}  key   Form element id
   * @param  {Boolean} value Selected or not
   *
   * @return void
   */
  onChange(key, value, ...rest) {
    const { onCardsChange, onChange } = this.props;

    onCardsChange(undefined, ...rest);
    onChange(key, value);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      id,
      value
    } = this.props;

    return (
      <SubSection>
        <SubSectionTitle>
          <RadioButton
            id={id}
            value={value}
            onChange={this.onChange}
          >
            <FormattedMessage id="Freemium.Form.CreditCard.CardCategory.CctTool" />
          </RadioButton>
        </SubSectionTitle>
      </SubSection>
    );
  }
}

CardVarietyCctTool.propTypes = {
  id: PropTypes.string,
  onCardsChange: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.bool
};

CardVarietyCctTool.defaultProps = {
  id: 'orderInCctTool',
  onCardsChange: noop,
  onChange: noop,
  value: undefined
};

export default CardVarietyCctTool;
