import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';

const types = {
  30: 'Freemium.Form.CreditCard.PaymentType.PaymentSlip',
  undefined: 'Freemium.Form.CreditCard.PaymentType.Undefined'
};

const PaymentType = React.memo(({ paymentType }) => {
  const message = get(types, paymentType);

  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.PaymentType" />}>
      <FormattedMessage id={message} />
    </Row>
  );
});

PaymentType.propTypes = {
  paymentType: PropTypes.number
};

PaymentType.defaultProps = {
  paymentType: undefined
};

export default PaymentType;
