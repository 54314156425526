import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';

import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../General/components/SubSection/index';
import AddressOptionLabel from '../../Common/Address/AddressOptionLabel';

const AddressType = React.memo(({
  addresses,
  codes,
  id,
  language,
  onChange,
  validations,
  value
}) => {
  const options = addresses.map((address) => ({
    value: get(address, 'addressType.number', get(address, 'addressType', '').toString()),
    label: <AddressOptionLabel address={address} codes={codes} language={language} />
  }));

  return (
    <SubSection>
      <SubSectionTitle>
        <FormattedMessage id="Freemium.Form.CreditCard.AddressType.Title" />
      </SubSectionTitle>

      <SubSectionContent>
        <RadioButtonGroup
          id={id}
          options={options}
          onChange={onChange}
          value={value}
        />

        <ValidationResult
          show
          validations={validations}
        />
      </SubSectionContent>
    </SubSection>
  );
});

AddressType.propTypes = {
  addresses: PropTypes.array,
  codes: PropTypes.object,
  id: PropTypes.string,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string
};

AddressType.defaultProps = {
  addresses: [],
  codes: { grouops: {} },
  id: 'addressType',
  language: 'de',
  onChange: noop,
  validations: {},
  value: undefined
};

export default AddressType;
