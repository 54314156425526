import { call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import actions from '../../actions/Actions';
import modalActions from '../../../Modal/actions/Actions';
import { toIntIfSet } from '../../../../lib/Utils/index';

const { OPEN_ACCOUNT_FORMS_SAVE_PENDING, OPEN_ACCOUNT_FORMS_SAVE_FULFILLED, OPEN_ACCOUNT_FORMS_SAVE_REJECTED } = actions;
const { LOADING_MODAL_REQUEST, LOADING_MODAL_FULFILLED, LOADING_MODAL_REJECTED } = modalActions;

export default function* openAccount(request) {
  yield put({ type: OPEN_ACCOUNT_FORMS_SAVE_PENDING });
  yield put({ type: LOADING_MODAL_REQUEST, dataKey: 'workguideAsync' });
  try {
    const {
      customerId, tenant = 6300, accounts, consultantId
    } = request;

    const requests = accounts.map((account) => {
      return call(axios, {
        url: `/sif/bpf/public/api/v1/process-definition/key/valiant-product-openAccountV2/tenant-id/${tenant}/start`,
        method: 'post',
        data: {
          variables: {
            clientKey: toIntIfSet(customerId),
            ...account,
            responsibleUser: toIntIfSet(consultantId),
          },
          resultVariableNames: [
            'accountKey',
            'editAccountNumber',
            'accountNumber',
            'portfolioId',
            'contractKey',
            'assignmentKey',
            'endStatus',
          ],
          waitTimeoutInMs: 15000,
        },
      });
    });

    const result = yield all(requests);

    const payload = get(result, 'data', {});

    yield put({ type: OPEN_ACCOUNT_FORMS_SAVE_FULFILLED, payload });
    yield put({ type: LOADING_MODAL_FULFILLED, dataKey: 'workguideAsync' });
    return payload;
  } catch (error) {
    yield put({ type: OPEN_ACCOUNT_FORMS_SAVE_REJECTED, error });
    yield put({ type: LOADING_MODAL_REJECTED, dataKey: 'workguideAsync', error });
    return error;
  }
}
