import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isEmpty } from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getIdFromGravitonUrl } from '@evoja-web/react-document-preview';
import { withAcl } from '@evoja-web/client-acl';

import './CctTool.css';
import NoCustomerChange from '../../NoCustomerChange';
import Summary from './Summary';
import Comments from '../../../../components/Form/Common/Comments/Comments';
import FileUpload from '../../../../components/Form/Common/LocalFileUpload';
import setAdvisorActions from '../../../../actions/Actions';
import { getValidator } from '../../../../../../globals';
import { withWorkguideInstanceData } from '../../../../../Workguide/hocs';

class SetAdvisorWorkguideCreditCardCctTool extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: [],
      fileLink: undefined,
      cards: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  /**
   * Submit the workguide
   *
   * @param {Boolean} creditcardOrdered Accepted / rejected
   *
   * @return void
   */
  onSubmit(creditcardOrdered = false) {
    const { comments, fileLink } = this.state;
    const {
      acl,
      files,
      process,
      setAdvisorActions,
      task,
      toggleRedirectModal
    } = this.props;

    const fileId = getIdFromGravitonUrl(fileLink);
    const file = {
      id: fileId,
      file: get(files, `${fileId}.data.blob`)
    };

    setAdvisorActions.creditCardCctToolRequest({
      creditcardOrdered,
      comments: [...get(process, 'comments', []), ...comments],
      file,
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
      taskId: get(task, 'id'),
    });

    toggleRedirectModal();
  }

  /**
   * Handle value change (only profile completion atm)
   *
   * @param  {String} id    Form element id
   * @param  {Mixed}  value Value
   *
   * @return void
   */
  onValueChange(id, value) {
    this.setState({ [id]: value });
  }

  /**
   * Validate current form values
   *
   * @return {Object} result Validation result
   */
  validate() {
    const definition = {
      validations: {
        additionalCardHolderProfileCompletion: {
          type: 'boolean',
          required: true,
          validations: {
            isTrue: []
          }
        },
        mainCardHolderProfileCompletion: {
          type: 'boolean',
          required: true,
          validations: {
            isTrue: []
          }
        },
        fileLink: {
          type: 'string',
          required: true
        }
      }
    };

    const validator = getValidator();

    return validator.validate(definition, this.state);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { fileLink } = this.state;
    const {
      onFormCancel,
      session,
      workguide
    } = this.props;

    const validations = this.validate();

    return (
      <div className="setadvisor-workguide-credit-card-cct-tool">
        <NoCustomerChange workguideId={get(workguide, 'id')}>
          <Row className="setadvisor-workguide-credit-card-cct-tool--title">
            <Col lg={12} md={12}>
              <FormattedMessage
                id="Freemium.Workguide.CctTool.Title"
                tagName="h5"
              />
            </Col>
          </Row>

          <Row className="setadvisor-workguide-credit-card-cct-tool--description mb-5">
            <Col lg={12} md={12}>
              <FormattedMessage
                id="Freemium.Workguide.CctTool.Description"
                tagName="p"
              />
            </Col>
          </Row>

          <Summary
            onChange={this.onValueChange}
            showProfileCompletion
            withEmbossedLines
          />

          <Comments
            onChange={this.onValueChange}
            session={session}
          />

          <FileUpload
            id="fileLink"
            onChange={this.onValueChange}
            validations={get(validations, 'fileLink')}
            value={fileLink}
          />

          <Row style={{ paddingTop: '4rem' }}>
            <Col lg={12} md={12} className="d-flex justify-content-end">
              <Button
                color="primary"
                outline
                onClick={onFormCancel}
              >
                <FormattedMessage id="Activity.Form.Cancel" />
              </Button>

              <span style={{ paddingRight: '1rem' }} />

              <Button
                color="primary"
                onClick={(...args) => this.onSubmit(false, ...args)}
              >
                <FormattedMessage id="Activity.Form.Reject" />
              </Button>

              <span style={{ paddingRight: '1rem' }} />

              <Button
                color="primary"
                disabled={!isEmpty(get(validations, 'fileLink'))}
                onClick={(...args) => this.onSubmit(true, ...args)}
              >
                <FormattedMessage id="Activity.Form.Submit" />
              </Button>
            </Col>
          </Row>
        </NoCustomerChange>
      </div>
    );
  }
}

SetAdvisorWorkguideCreditCardCctTool.propTypes = {
  acl: PropTypes.object.isRequired,
  files: PropTypes.object,
  onFormCancel: PropTypes.func,
  process: PropTypes.object,
  session: PropTypes.object.isRequired,
  setAdvisorActions: PropTypes.object.isRequired,
  task: PropTypes.object,
  toggleRedirectModal: PropTypes.func,
  workguide: PropTypes.object
};

SetAdvisorWorkguideCreditCardCctTool.defaultProps = {
  files: {},
  onFormCancel: noop,
  process: {},
  task: {},
  toggleRedirectModal: noop,
  workguide: undefined
};

function mapStateToProps(state, ownProps) {
  return {
    files: get(state, 'documentPreview.file'),
    process: get(ownProps, 'bpf.process'),
    session: state.login.session,
    task: get(ownProps, 'bpf.task')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAdvisorActions: bindActionCreators(setAdvisorActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    withAcl()(SetAdvisorWorkguideCreditCardCctTool)
  )
);
