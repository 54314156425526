import update from 'immutability-helper';
import { chain, get, isUndefined, isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@evoja-web/react-form';
import { Col, Row } from 'reactstrap';

import './CreateNewAccount.css';
import { RadioSelectWrapper } from '../../../../General/index';
import AccountCreator from '../OpenAccount/AccountCreator';
import QuantityInput from '../OpenAccount/QuantityInput';
import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../General/components/SubSection';
import { SectionTitle } from '../../../../General/components/Section';

/**
 * Sadly the current implementation is a bit hackish...
 * The value in charging account has no reference to the newAccounts array.
 * This function checks if there is a account with accountKey === 'newAccount' in the charginAccounts array
 * https://issue.swisscom.ch/browse/MAP-8755
 *
 * @param   {Object}  form  Current form state
 *
 * @return  {Boolean}
 */
function isSelectedChargingAccount({ form }) {
  return !chain(form)
    .get('data.chargingAccount', [])
    .find((a) => a.accountKey === 'newAccount')
    .isNil()
    .value();
}

class CreateNewAccount extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountQuantity: 1
    };

    this.onChange = this.onChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onChangeCharingAccount = this.onChangeCharingAccount.bind(this);
  }

  componentDidMount() {
    const { freemiumActions, form, accounts } = this.props;
    if (accounts.length === 0) {
      const newAccounts = get(form, 'data.newAccounts', []);
      freemiumActions.openLilaSetSetValue('newAccounts', update(newAccounts, { $push: [{}] }));
    }
  }

  /**
   * Create initial empty account object or delete account object
   * @param val number quantity
   */
  onQuantityChanged(val) {
    const { accountQuantity } = this.state;
    const { form, freemiumActions } = this.props;

    const accounts = get(form, 'data.newAccounts', []);
    const updated = accountQuantity > val
      ? update(accounts, { $splice: [[val - 1, 1]] })
      : update(accounts, { $push: [{}] });

    freemiumActions.openLilaSetSetValue('newAccounts', updated);
    // Unset chargingAccount if all accounts get removed and one of the selected accounts is a new one (@see comment on isSelectedChargingAccount)
    if (val === 0 && isSelectedChargingAccount({ form })) {
      freemiumActions.openLilaSetSetValue('chargingAccount');
    }

    this.setState({ accountQuantity: val });
  }

  onChange(index, account) {
    const { freemiumActions, form } = this.props;
    const accounts = get(form, 'data.newAccounts', []);
    const updated = update(accounts, {
      [index]: { $set: account }
    });
    freemiumActions.openLilaSetSetValue('newAccounts', updated);
  }

  onToggle(key, value) {
    const {
      form,
      freemiumActions
    } = this.props;

    // Add first (empty) account if toggle switches to true. Else reset accounts
    const accounts = value
      ? [{}]
      : [];

    freemiumActions.openLilaSetSetValue('newAccounts', accounts);
    freemiumActions.openLilaSetSetValue('showNewAccount', value);
    // Unset the new account if toggle switches to false and "newAccount" is selected as charging account
    if (!value && isSelectedChargingAccount({ form })) {
      freemiumActions.openLilaSetSetValue('chargingAccount');
    }
  }

  onChangeCharingAccount(key, value) {
    const { freemiumActions, accounts } = this.props;
    if (isUndefined(value)) {
      freemiumActions.openLilaSetSetValue(key, value);
      return;
    }

    const updatedAccounts = value === 'newAccount'
      ? [{ debitAccountForFee: true, accountKey: value }]
      : accounts.map((account) => ({
        accountKey: account.accountKey,
        debitAccountForFee: value === account.accountKey
      }));
    freemiumActions.openLilaSetSetValue(key, updatedAccounts);
  }

  render() {
    const {
      accounts,
      language,
      form,
      codes,
      productGroups,
      validations
    } = this.props;

    const showAccountCreator = get(form, 'data.showNewAccount', get(accounts, 'length', 0) === 0);

    const chargingAccountOptions = accounts.map((account) => ({
      label: (
        <Row style={{ width: '90%' }}>
          <Col md={3} lg={3}>
            {get(account, `accountTypeText.${language}`)}
          </Col>
          <Col md={3} lg={3}>
            {account.editAccountNumber}
          </Col>
          <Col md={6} lg={6}>
            {account.clientDesignation}
          </Col>
        </Row>
      ),
      value: account.accountKey
    }));

    if (get(form, 'data.newAccounts.length', 0) > 0) {
      chargingAccountOptions.push({
        label: (
          <Row style={{ width: '90%' }}>
            <Col md={3} lg={3}><FormattedMessage id="Freemium.Form.NewLilaSet.NewlyOpenedAccount" /></Col>
            <Col md={9} lg={9} style={{ color: 'gray' }}>
              <i className="mdi mdi-information-outline" />
              <FormattedMessage id="Freemium.Form.NewLilaSet.NewlyOpenedAccountDisclaimer" />
            </Col>
          </Row>
        ),
        value: 'newAccount'
      });
    }

    return (
      <>
        <SubSection>
          <SubSectionContent>
            <div className="create-new-account--container">
              <Row>
                <Col md={1} lg={1}>
                  <Toggle
                    value={showAccountCreator}
                    onChange={this.onToggle}
                  />
                </Col>
                <Col>
                  <FormattedMessage id="Freemium.Form.NewLilaSet.CreateNewPrivateAccount" />
                </Col>
              </Row>
              {
               showAccountCreator && (
               <>
                 <QuantityInput min={0} max={5 - accounts.length} onChange={(val) => this.onQuantityChanged(val)} />
                 {get(form, 'data.newAccounts', []).map((account, index) => {
                   return (
                     <AccountCreator
                       // eslint-disable-next-line react/no-array-index-key
                       key={index}
                       index={index}
                       label={<FormattedMessage id="Freemium.Form.OpenAccount.CreateAccountLabel" values={{ value: index + 1 }} />}
                       onChange={this.onChange}
                       productGroups={productGroups}
                       account={account}
                       codes={codes}
                       validations={validations}
                       language={language}
                     />
                   );
                 })}
               </>
               )
              }

            </div>
          </SubSectionContent>
        </SubSection>
        <SubSection>
          <SectionTitle><FormattedMessage id="Freemium.Form.NewLilaSet.DebitAccountFee" /></SectionTitle>
          <SubSectionContent>
            <RadioSelectWrapper
              onChange={this.onChangeCharingAccount}
              value={get(form, 'data.chargingAccount', []).find((el) => el.debitAccountForFee)?.accountKey}
              id="chargingAccount"
              disabled={false}
              options={chargingAccountOptions}
              validations={get(validations, 'chargingAccount')}
              showValidations
            />
          </SubSectionContent>

        </SubSection>
      </>
    );
  }
}

CreateNewAccount.propTypes = {
  accounts: PropTypes.array.isRequired,
  freemiumActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  form: PropTypes.object,
  productGroups: PropTypes.array,
  codes: PropTypes.object,
  validations: PropTypes.object.isRequired
};

CreateNewAccount.defaultProps = {
  language: 'de',
  form: {},
  productGroups: [],
  codes: {}
};

export default CreateNewAccount;
