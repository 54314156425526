import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';

const ConnectionAccount = React.memo(({ account }) => {
  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.Card.ConnectionAccount" />}>
      {get(account, 'iban')}
    </Row>
  );
});

ConnectionAccount.propTypes = {
  account: PropTypes.object
};

ConnectionAccount.defaultProps = {
  account: {}
};

export default ConnectionAccount;
