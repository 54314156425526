import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, head, isNil } from 'lodash';
import { ValidationResult } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import update from 'immutability-helper';
import cl from 'classnames';
import moment from 'moment';

import './ContractRules.css';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';
import { SubSection, SubSectionTitle, SubSectionContent } from '../../../../General/components/SubSection/index';
import DispatchRule from './DispatchRule';
import { InputField } from '../../../../General';

class ContractRules extends React.Component {
  constructor(props) {
    super(props);

    this.onDispatchRuleChange = this.onDispatchRuleChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
  }

  /**
   * Handle dispatch r
   *
   * @param   {Number}  index  Rule index
   * @param   {Object}  key    Updated rule
   *
   * @return  void
   */
  onDispatchRuleChange(index, rule) {
    const {
      contract,
      onChange
    } = this.props;

    const updated = update(contract, {
      dispatchRules: {
        $splice: [[index, 1, rule]]
      }
    });

    onChange(updated);
  }

  /**
   * Handle onChange of the given key
   *
   * @param   {String}  key    Form element key / key in data
   * @param   {Mixed}  value  Value
   *
   * @return  void
   */
  onValueChange(key, value) {
    const {
      contract,
      onChange
    } = this.props;

    const updated = update(contract, {
      [key]: { $set: value }
    });

    onChange(updated);
  }

  /**
   * Handle on change of the comment field.
   * Update editor / timestamp as well
   *
   * @param   {String}  id     Form element id
   * @param   {String}  value  Value
   *
   * @return  void
   */
  onCommentChange(id, value) {
    const {
      contract,
      onChange,
      session
    } = this.props;

    const updated = isNil(value)
      ? update(contract, { $unset: ['comments'] })
      : update(contract, {
        comments: {
          $set: {
            type: 'Json',
            value: [{ editor: get(session, 'username'), timestamp: moment().utc().format(), value }]
          }
        }
      });

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      contract,
      disabled,
      language,
      showCommentInput,
      validations
    } = this.props;

    const accountIndependentLabel = <FormattedMessage id="Freemium.Form.OptionPaper.AccountDependencies" />;

    const children = get(contract, 'dispatchRules', [])
      .map((rule, index) => {
        return (
          <DispatchRule
            disabled={disabled}
            language={language}
            onChange={(...args) => this.onDispatchRuleChange(index, ...args)}
            rule={rule}
            validations={get(validations, `dispatchRules.${index}`)}
          />
        );
      });

    return (
      <div className="setadvisor-form-contract-dispatch-rules--contract-rules">
        <SubSection
          className={cl({
            'setadvisor-form-contract-dispatch-rules--subsection-no-content': get(contract, 'dispatchRules.length', 0) === 0
          })}
        >
          <SubSectionTitle className="setadvisor-form-contract-dispatch-rules--client-designation">
            {`${get(contract, 'clientDesignation', '')} (${get(contract, 'contractId')})`}
          </SubSectionTitle>

          <SubSectionContent>
            {children}
          </SubSectionContent>
        </SubSection>

        <SubSection>
          <SubSectionTitle>
            <FormattedMessage id="Freemium.Form.Common.Independent" />
          </SubSectionTitle>

          <SubSectionContent>
            <Alert type="warning">
              <AlertContent>
                <FormattedMessage id="Freemium.Form.Common.IndependentDataLoadInfo" />
              </AlertContent>
            </Alert>
            <DispatchRule
              disabled={disabled}
              id="eDocumentsAccountIndependent"
              language={language}
              onChange={(rule) => this.onValueChange('eDocumentsAccountIndependent', get(rule, 'eDocument', false))}
              rule={{
                accountTypeText: { de: accountIndependentLabel, fr: accountIndependentLabel, en: accountIndependentLabel },
                eDocument: get(contract, 'eDocumentsAccountIndependent')
              }}
            />

            <Row>
              <Col lg={12} md={12}>
                <ValidationResult
                  show
                  validations={get(validations, 'eDocumentsAccountIndependent')}
                />
              </Col>
            </Row>

            {/* https://issue.swisscom.ch/browse/MAP-8443 */}
            {showCommentInput && (
              <>
                <Row className="pt-2 pb-3">
                  <Col lg={12} md={12}>
                    <FormattedMessage id="Freemium.Form.Common.Comment" />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12}>
                    <InputField
                      disabled={disabled}
                      id="comments"
                      type="textarea"
                      value={get(head(get(contract, 'comments.value')), 'value')}
                      onChange={this.onCommentChange}
                      showValidations
                      validations={get(validations, 'comments.0')}
                    />
                  </Col>
                </Row>
              </>
            )}
          </SubSectionContent>
        </SubSection>
      </div>
    );
  }
}

ContractRules.propTypes = {
  contract: PropTypes.object,
  disabled: PropTypes.bool,
  language: PropTypes.string,
  onChange: PropTypes.func,
  session: PropTypes.object.isRequired,
  showCommentInput: PropTypes.bool,
  validations: {}
};

ContractRules.defaultProps = {
  contract: {},
  disabled: false,
  language: 'de',
  onChange: noop,
  showCommentInput: true,
  validations: {}
};

export default ContractRules;
