import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import approvalAction from '../../actions/CreditCard/Approval';

const {
  CREDIT_CARD_APPROVAL_PENDING,
  CREDIT_CARD_APPROVAL_FULFILLED,
  CREDIT_CARD_APPROVAL_REJECTED
} = approvalAction;

export default function* approval(request) {
  yield put({ type: CREDIT_CARD_APPROVAL_PENDING });

  try {
    const {
      approved = false,
      comments = [],
      skipUserCheck = false,
      taskId
    } = request;

    const data = {
      variables: {
        approved,
        comments
      }
    };

    const payload = yield call(axios, {
      url: `/sif/bpf/public/api/v1/task/${taskId}/complete?skipUserCheck=${skipUserCheck}`,
      method: 'post',
      data
    });

    yield put({ type: CREDIT_CARD_APPROVAL_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CREDIT_CARD_APPROVAL_REJECTED, error });

    return error;
  }
}
