import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { get, noop, isNil } from 'lodash';
import { RadioButtonGroup, ValidationResult } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

import './DispatchRule.css';

const options = [{
  value: 'post',
  label: <FormattedMessage id="Freemium.Form.EDocuments.OptionOne" />,
  returnValue: false
}, {
  value: 'eDocument',
  label: <FormattedMessage id="Freemium.Form.EDocuments.OptionTwo" />,
  returnValue: true
}];

class DispatchRule extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle selected change
   *
   * @param   {String}   key    Form element key
   * @param   {Boolean}  value  Selected value (true, false or undefined)
   *
   * @return  void
   */
  onChange(key, value) {
    const {
      onChange,
      rule
    } = this.props;

    const updated = update(rule, { eDocument: { $set: value } });

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
  */
  render() {
    const {
      disabled,
      id,
      language,
      rule,
      validations
    } = this.props;

    const value = isNil(get(rule, 'eDocument'))
      ? undefined
      : get(rule, 'eDocument', false)
        ? 'eDocument'
        : 'post';

    return (
      <div className="setadvisor-form-contract-dispatch-rules--dispatch-rule">
        <Row>
          <Col lg={3} md={3}>
            {get(rule, `accountTypeText.${language}`, '')}
          </Col>

          <Col lg={3} md={3}>
            {get(rule, 'editAccountNumber', '')}
          </Col>

          <Col lg={3} md={3}>
            {get(rule, 'clientDesignation', '')}
          </Col>

          <Col lg={3} md={3}>
            <RadioButtonGroup
              disabled={disabled}
              id={id}
              onChange={this.onChange}
              options={options}
              returnValue="returnValue"
              value={value}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <ValidationResult
              show
              validations={get(validations, 'eDocument')}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

DispatchRule.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  language: PropTypes.string,
  onChange: PropTypes.func,
  rule: PropTypes.object.isRequired,
  validations: PropTypes.object
};

DispatchRule.defaultProps = {
  disabled: false,
  id: 'dispatchRule',
  language: 'de',
  onChange: noop,
  validations: {}
};

export default DispatchRule;
