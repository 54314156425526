import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import moment from 'moment';
import { get, isNil, isUndefined } from 'lodash';

import { loadCache, saveCache } from '../../../localStorage';
import outlookCalendarAction from '../actions/OutlookCalendar';
import { MicrosoftGraphGeneralError } from '../../Error/index';

const {
  OUTLOOK_CALENDAR_PENDING,
  OUTLOOK_CALENDAR_FULFILLED,
  OUTLOOK_CALENDAR_REJECTED
} = outlookCalendarAction;

const CACHE_EXPIRED_SEC = 600;

/**
 * Load cached data
 *
 * @param  {String} userId User Id
 *
 * @return {Object} data Data from cache
 */
function getDataFromCache({
  userId,
  mail
}) {
  const cached = loadCache(`outlookCalendar-${userId}`);
  const lastRefresh = get(cached, 'lastRefresh');
  const items = get(cached, 'items', []);

  return {
    items,
    lastRefresh,
    userId,
    mail
  };
}

const onlyCategories = [
  'telefonischer Kundenkontakt',
  'physischer Kundenkontakt',
  'Contact clientèle physique',
  'Contact clientèle télephonique'
];

function hasCategory(item) {
  const categories = get(item, 'categories', []);
  const oc = onlyCategories.map((c = '') => c.toLowerCase());

  const found = categories
    .map((category = '') => category.toLowerCase())
    .find((category) => oc.includes(category));

  return !isUndefined(found);
}

/**
 * Load data from backend
 *
 * @param  {String} mail Mail address
 *
 * @return {Object} data Data from cache
 */
function* getDataFromService({ userId }) {
  const nowUtc = moment(new Date()).utc().format();
  const newString = `?$top=50&$filter=(categories/any(x:x eq 'physischer Kundenkontakt') or categories/any(x:x eq 'telefonischer Kundenkontakt')) and end/dateTime ge '${nowUtc}'&$select=categories,subject,body,start,end,location&$orderby=start/dateTime asc`;

  const { data } = yield call(axios, {
    url: (`/graph/v1.0/users/${userId}/calendar/events${newString}`),
    method: 'get'
  });

  // Headers Approach does not work... With the commented header above, we receive an CORS Error
  const items = get(data, 'value', [])
    .sort((a, b) => moment(a.start.dateTime) - moment(b.start.dateTime))
    .filter((item) => hasCategory(item))
    .map((el) => {
      const startDateStillUtc = moment.utc(el.start.dateTime).toDate();
      const endDateStillUtc = moment.utc(el.end.dateTime).toDate();

      return {
        ...el,
        start: { dateTime: startDateStillUtc, timeZone: 'CET' },
        end: { dateTime: endDateStillUtc, timeZone: 'CET' }
      };
    });

  return {
    items,
    lastRefresh: moment().format(),
    userId
  };
}

/**
 * Check if the cached values are expired
 *
 * @param  {Object} cached Data from cache
 *
 * @return {Boolean}
 */
function isCacheExpired(cached) {
  const { lastRefresh } = cached;

  const res = isUndefined(cached)
  || isUndefined(lastRefresh)
  || moment().diff(moment(lastRefresh), 'seconds') > CACHE_EXPIRED_SEC;

  return res;
}

/**
 * Fetch outlook calendar data
 *
 * @param  {mail} mail of consultant
 * @param  {clearCache} flag of clear cache
 *
 * @return {Generator}
 */
export default function* getOutlookCalendar(request) {
  yield put({ type: OUTLOOK_CALENDAR_PENDING });

  try {
    const {
      clearCache,
      userId,
    } = request;

    if (isNil(userId)) {
      const e = new MicrosoftGraphGeneralError('Cannot make Calendar Data Request, because User has no userId set');
      e.setTargetActive('sentry', false);

      throw e;
    }

    const cached = getDataFromCache(request);

    const payload = !clearCache
      ? isCacheExpired(cached)
        ? yield call(getDataFromService, request)
        : cached
      : yield call(getDataFromService, request);

    saveCache(`outlookCalendar-${userId}`, payload);

    yield put({ type: OUTLOOK_CALENDAR_FULFILLED, payload });

    return payload;
  } catch (error) {
    const cached = yield call(getDataFromCache, request);

    yield put({
      type: OUTLOOK_CALENDAR_REJECTED,
      suppressToast: true,
      error,
      payload: cached
    });

    return error;
  }
}
