import { call, put } from 'redux-saga/effects';
import axios from 'axios';
// ToDo: Move functions like removeUnwantedProperties/removeUnwantedValues to own repo
import removeUnwantedValues from '../../../../lib/Utils/removeUnwantedValues';
import contractDispatchRulesFormAction from '../../actions/DispatchRule/ContractDispatchRulesForm';
import { toStringIfSet } from '../../../../lib/Utils';

const {
  CONTRACT_DISPATCH_RULES_FORM_SAVE_PENDING,
  CONTRACT_DISPATCH_RULES_FORM_SAVE_FULFILLED,
  CONTRACT_DISPATCH_RULES_FORM_SAVE_REJECTED
} = contractDispatchRulesFormAction;

export default function* saveContractDispatchRulesForm(request) {
  const {
    customerId,
    data,
    tenant = 6300,
  } = request;

  yield put({ type: CONTRACT_DISPATCH_RULES_FORM_SAVE_PENDING });

  try {
    const payload = {
      businessKey: toStringIfSet(customerId),
      variables: removeUnwantedValues(data, [null, undefined])
    };

    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-product-changeEDocuments/tenant-id/${tenant}/start`;
    yield call(axios, {
      url,
      method: 'post',
      data: payload
    });

    yield put({ type: CONTRACT_DISPATCH_RULES_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONTRACT_DISPATCH_RULES_FORM_SAVE_REJECTED, error });

    return error;
  }
}
