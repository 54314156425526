import React from 'react';
import PropTypes from 'prop-types';
import { ValidationResult, CodeSelect } from '@evoja-web/react-form';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';

import './ResidencePermit.css';
import FileUpload from '../../Common/LocalFileUpload';
import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../General/components/SubSection/index';

class ResidencePermit extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      codes,
      form,
      language,
      onChange,
      validations,
      allowedResidencePermits
    } = this.props;

    const residencePermits = get(codes, 'groups.residencePermit', [])
      .filter((code) => get(allowedResidencePermits, 'length', 0) <= 0 || allowedResidencePermits.includes(get(code, 'id')));

    return (
      <SubSection>
        <SubSectionTitle>
          <FormattedMessage id="Freemium.Form.CreditCard.ResidencePermit.Title" />
        </SubSectionTitle>

        <SubSectionContent>
          <Row className="setadvisor-form-credit-card-general--residence-permit--row">
            <Col
              className="setadvisor-form-credit-card-general--residence-permit--row--label"
              lg={4}
              md={4}
            >
              <FormattedMessage id="Freemium.Form.CreditCard.ResidencePermit.Code" />
            </Col>

            <Col
              className="setadvisor-form-credit-card-general-residence-permit--row--select"
              lg={6}
              md={6}
            >
              <CodeSelect
                codes={residencePermits}
                id="residencePermit"
                language={language}
                onChange={onChange}
                value={get(form, 'data.residencePermit')}
              />

              <ValidationResult
                show
                validations={get(validations, 'residencePermit')}
              />
            </Col>
          </Row>
        </SubSectionContent>

        <FileUpload
          id="residencePermitFile"
          onChange={onChange}
          validations={get(validations, 'residencePermitFile')}
          value={get(form, 'data.residencePermitFile')}
        />
      </SubSection>
    );
  }
}

ResidencePermit.propTypes = {
  codes: PropTypes.object,
  form: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  allowedResidencePermits: PropTypes.array
};

ResidencePermit.defaultProps = {
  codes: { groups: {} },
  form: {},
  language: 'de',
  onChange: noop,
  validations: {},
  allowedResidencePermits: []
};

export default ResidencePermit;
