import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { get, isNil } from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import './ReactivateInformation.scss';
import { Section, SectionContent, SectionTitle } from '../../../../../General/components/Section';
import { SubSection, SubSectionContent, SubSectionTitle } from '../../../../../General/components/SubSection';

class ReactivateInformation extends PureComponent {
  render() {
    const { process, gravitonInformation } = this.props;
    return (
      <Section>
        <SectionTitle>
          <FormattedMessage id="Freemium.Workguide.Reactivate.Applicant" />
        </SectionTitle>
        <SectionContent>
          <SubSection>
            <SubSectionTitle>
              <FormattedMessage id="Freemium.Workguide.Reactivate.ApplicantText" />
            </SubSectionTitle>
            <SubSectionContent>
              <Container className="ebanking_order_via_mobileapp-information">
                <Row>
                  <Col xs={6} md={3} lg={2}>
                    <FormattedMessage id="Freemium.Workguide.Reactivate.IdentificationStatus" />
                  </Col>
                  <Col xs={6} md={3} lg={1}>
                    {get(process, 'identificationStatus') === 'yellow' ? (
                      <i className="mdi mdi-alert-circle-outline status-yellow" />
                    ) : <i className="mdi mdi-check-circle-outline status-green" />
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={3} lg={2}>
                    <FormattedMessage id="Freemium.Workguide.Reactivate.MachingStatus" />
                  </Col>
                  <Col xs={6} md={3} lg={1}>
                    {get(process, 'matchingQuality') === 'yellow' ? (

                      <i className="mdi mdi-alert-circle-outline status-yellow" />
                    ) : <i className="mdi mdi-check-circle-outline status-green" />
                  }
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} md={3} lg={2}>
                    <FormattedMessage id="Freemium.Workguide.Reactivate.CustomerNr" />
                  </Col>
                  <Col xs={6} md={3} lg={1}>
                    {get(gravitonInformation, 'customer.customerNumberAlternate')}
                  </Col>
                </Row>
                {!isNil(get(process, 'contractId')) && (
                <Row>
                  <Col xs={6} md={3} lg={2}>

                    <FormattedMessage id="Freemium.Workguide.Reactivate.EbankingContract" />
                  </Col>
                  <Col>
                    {`${get(gravitonInformation, 'contract.customer.shortDesignationAbbreviation')} (${get(gravitonInformation, 'contract.number')})`}
                  </Col>
                </Row>
                )}
              </Container>
            </SubSectionContent>
          </SubSection>
          <SubSection>
            <SubSectionTitle>
              <FormattedMessage id="Freemium.Workguide.Reactivate.Applicant" />
            </SubSectionTitle>
            <Container style={{ boxShadow: '1px 1px 15px 3px rgba(0,0,0,0.05)', padding: '2rem', width: '85%' }}>
              <Row style={{ color: 'gray' }}>
                <Col md={3}><FormattedMessage id="Freemium.Workguide.Reactivate.FirstName" /></Col>
                <Col><FormattedMessage id="Freemium.Workguide.Reactivate.LastName" /></Col>
                <Col><FormattedMessage id="Freemium.Workguide.Reactivate.BirthDate" /></Col>
                <Col><FormattedMessage id="Freemium.Workguide.Reactivate.Address" /></Col>
              </Row>
              <Row style={{ marginTop: 10, fontSize: 16, alignItems: 'center' }}>
                <Col md={3}>{get(process, 'personToActivate.firstName')}</Col>
                <Col>{get(process, 'personToActivate.lastName')}</Col>
                <Col>{moment(get(process, 'personToActivate.dateOfBirth')).format('l')}</Col>
                <Col className="d-flex flex-column">
                  <span>{`${get(process, 'clientAddress.street')} ${get(process, 'clientAddress.houseNumber')}`}</span>
                  <span>{`${get(process, 'clientAddress.postcode')} ${get(process, 'clientAddress.place')}`}</span>
                </Col>
              </Row>
              <Row>
                <div className="d-flex justify-content-center mt-3 mb-3">
                  <span className="ebanking_order_via_mobileapp-divider" />
                </div>
              </Row>
              <Row>
                <Col className="d-flex">
                  <span style={{ color: 'gray', paddingRight: 10 }}>IBAN:</span>
                  <p style={{ fontSize: 16 }}>
                    {get(process, 'personToActivate.iban')}
                  </p>
                </Col>
              </Row>
              <Row style={{ fontSize: 16, marginTop: 10 }}>
                <Col className="d-flex">
                  <span style={{ color: 'gray', paddingRight: 10 }}>Fidentity Link:</span>
                  <i
                    className="mdi mdi-link-variant pointer fs-20"
                    style={{ fontSize: 20 }}
                    onClick={() => window.open(get(process, 'fidentityLink'), '_blank')}
                  />
                </Col>

              </Row>
            </Container>
          </SubSection>
        </SectionContent>
      </Section>
    );
  }
}

ReactivateInformation.propTypes = {
  gravitonInformation: PropTypes.object,
  process: PropTypes.object
};

ReactivateInformation.defaultProps = {
  gravitonInformation: {},
  process: {}
};

export default ReactivateInformation;
