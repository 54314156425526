import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';

import {
  Section,
  SectionContent,
  SectionTitle
} from '../../../../General/components/Section/index';
import Active from '../../../components/Form/CreditCard/LimitCalculation/Active';
import MonthlyIncome from '../../../components/Form/CreditCard/LimitCalculation/MonthlyIncome';
import MonthlyExpenses from '../../../components/Form/CreditCard/LimitCalculation/MonthlyExpenses';
import Assets from '../../../components/Form/CreditCard/LimitCalculation/Assets';
import Limits from '../../../components/Form/CreditCard/Common/CardLimits';
import freemiumActions from '../../../actions/Actions';
import { getValidator } from '../../../../../globals';
import {
  isAdditionalCardCategorySelected,
  isMainCardCategorySelected,
  isMainAndAdditionalCardCategorySelected,
  isComboCardCategorySelected
} from '../../../lib/CreditCard/index';
import getMainCardGlobalLimit from '../../../lib/CreditCard/getMainCardGlobalLimit';
import { withWorkguideInstanceData, WorkguideCustomerAware } from '../../../../Workguide/hocs';

class CreditCardOrderLimitCalculation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentWillUnmount() {
    const { freemiumActions } = this.props;

    freemiumActions.creditCardOrderFormReset();
    freemiumActions.creditCardOrderReset();
  }

  /**
   * Handle customer change.
   * Call init saga which will reset form data and load necessary data
   *
   * @param  {Object} customer  Customer from workguide instance data
   *
   * @return void
   */
  onCustomerChange(customer) {
    const {
      initFormOnCustomerChange,
      freemiumActions
    } = this.props;

    if (initFormOnCustomerChange) {
      freemiumActions.creditCardOrderFormInitRequest({ customer });
    }
  }

  /**
   * Handle form value change and revalidate data
   *
   * @param  {String} key   Form element id
   * @param  {Mixed}  value Form value
   * @param  {Array}  rest  Rest args
   *
   * @return void
   */
  onValueChange(key, value, ...rest) {
    const { freemiumActions } = this.props;

    freemiumActions.creditCardOrderFormSetValue(key, value, ...rest);
  }

  /**
   * Validate current form data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const {
      bpfCards,
      form,
      freemiumActions
    } = this.props;
    const data = get(form, 'data', {});
    const cards = get(data, 'cards', []);
    const active = get(form, 'data.active', false);
    const isMain = isMainCardCategorySelected(cards);
    const isMainAndAdditional = isMainAndAdditionalCardCategorySelected(cards);
    const isAdditional = isAdditionalCardCategorySelected(cards);
    const isCombo = isComboCardCategorySelected(cards);

    const mainCardGlobalLimit = isAdditional
      ? getMainCardGlobalLimit(bpfCards, 0)
      : 1000;

    const limits = active
      ? {}
      : {
        desiredGlobalLimit: {
          type: 'number',
          required: !active && (isMain || isMainAndAdditional || isCombo),
          validations: {
            isGt: [0],
            isLe: [1000]
          }
        },
        cards: {
          type: 'array',
          required: true,
          validations: {
            cardLimit: {
              type: 'number',
              required: false,
              validations: {
                isGt: [0],
                isLe: [mainCardGlobalLimit]
              }
            }
          }
        }
      };

    const definition = {
      validations: {
        monthlyIncomes: {
          type: 'array',
          required: active,
          validations: {
            clientKey: {
              type: 'number',
              required: active,
              validations: {}
            },
            amount: {
              type: 'number',
              required: active,
              validations: {
                isGe: [0],
                isInt: []
              }
            },
            proofOfIncome: {
              type: 'string',
              required: active,
              validations: {}
            },
            fileLink: {
              type: 'string',
              required: ({ parent }) => active && ['taxDeclaration', 'wageOrPensionStatement', 'payroll'].includes(get(parent, 'proofOfIncome')),
              validations: {}
            }
          }
        },
        monthlyExpenses: {
          type: 'array',
          required: active,
          validations: {
            expenseType: {
              type: 'string',
              required: active,
              validations: {}
            },
            amount: {
              type: 'number',
              required: active,
              validations: {
                isGe: [0],
                isInt: []
              }
            },
          }
        },
        assets: {
          type: 'array',
          required: false,
          validations: {
            assetType: {
              type: 'string',
              required: active,
              validations: {}
            },
            amount: {
              type: 'number',
              required: active,
              validations: {
                isGe: [0],
                isInt: []
              }
            },
          }
        },
        ...limits
      }
    };

    const validator = getValidator();
    const result = validator.validate(definition, data);
    freemiumActions.formValidationsFulfilled({ dataKey: 'creditCardLimitCalculation', payload: result });

    return result;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      bpfCards,
      customer,
      form,
      partners,
      requesting
    } = this.props;

    const validations = this.validate();
    const cards = get(form, 'data.cards', []);

    return (
      <div className="setadvisor-form-limit-calculation">
        <WorkguideCustomerAware
          onChange={this.onCustomerChange}
          onInit={this.onCustomerChange}
          showLoadIndicator
          requesting={requesting}
        >
          <Section>
            <SectionTitle>
              <FormattedMessage id="Freemium.Form.CreditCard.LimitCalculation.Title" />
            </SectionTitle>

            <SectionContent>
              {!isAdditionalCardCategorySelected(cards) && (
                <Row>
                  <Col lg={12} md={12}>
                    <Active
                      id="active"
                      onChange={this.onValueChange}
                      value={get(form, 'data.active', false)}
                    />
                  </Col>
                </Row>
              )}

              {get(form, 'data.active', false) && (
                <React.Fragment>
                  <MonthlyIncome
                    customer={customer}
                    id="monthlyIncomes"
                    onChange={this.onValueChange}
                    partners={partners}
                    value={get(form, 'data.monthlyIncomes', [])}
                    validations={get(validations, 'monthlyIncomes', {})}
                  />

                  <MonthlyExpenses
                    id="monthlyExpenses"
                    onChange={this.onValueChange}
                    value={get(form, 'data.monthlyExpenses', [])}
                    validations={get(validations, 'monthlyExpenses', {})}
                  />

                  <Assets
                    id="assets"
                    onChange={this.onValueChange}
                    value={get(form, 'data.assets', [])}
                    validations={get(validations, 'assets', {})}
                  />
                </React.Fragment>
              )}

              {!get(form, 'data.active', false) && (
                <Limits
                  bpfCards={bpfCards}
                  form={form}
                  onChange={this.onValueChange}
                  validations={validations}
                />
              )}
            </SectionContent>
          </Section>
        </WorkguideCustomerAware>
      </div>
    );
  }
}

CreditCardOrderLimitCalculation.propTypes = {
  customer: PropTypes.object,
  bpfCards: PropTypes.object,
  form: PropTypes.object,
  initFormOnCustomerChange: PropTypes.bool,
  partners: PropTypes.object,
  requesting: PropTypes.bool,
  freemiumActions: PropTypes.object.isRequired
};

CreditCardOrderLimitCalculation.defaultProps = {
  customer: undefined,
  bpfCards: undefined,
  form: {},
  initFormOnCustomerChange: false,
  partners: {},
  requesting: false,
};

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;

  const bpfCards = get(state, `freemium.customerCards.${get(customer, 'id')}`);

  const form = get(state, 'freemium.creditCardOrderForm');

  return {
    bpfCards,
    form,
    language: state.login.language,
    partners: get(state, `bpfCms.partners.${get(customer, 'id')}`),
    requesting: get(form, 'initializing', false)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    freemiumActions: bindActionCreators(freemiumActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(CreditCardOrderLimitCalculation)
);
