import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { get } from 'lodash';

import './Total.css';
import {
  SubSection,
  SubSectionContent
} from '../../../../../../General/components/SubSection/index';

const LimitDeterminationSummaryTotal = React.memo(({ data }) => {
  return (
    <SubSection className="setadvisor-form-limit-determination-summary--total">
      <SubSectionContent>
        <Row className="setadvisor-form-limit-determination-summary--total--row">
          <Col
            lg={8}
            md={8}
          >
            <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Total.IncomeAndAssets" />
          </Col>

          <Col
            className="text-end"
            lg={2}
            md={2}
          >
            <FormattedNumber
              value={get(data, 'totalMonthlyIncomes', 0) + get(data, 'totalAssets', 0)}
              // eslint-disable-next-line
              style="currency"
              currency="CHF"
            />
          </Col>
        </Row>

        <Row className="setadvisor-form-limit-determination-summary--total--row">
          <Col
            lg={8}
            md={8}
          >
            <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Total.Expenses" />
          </Col>

          <Col
            className="text-end"
            lg={2}
            md={2}
          >
            <FormattedNumber
              value={get(data, 'totalMonthlyExpenses', 0)}
              // eslint-disable-next-line
              style="currency"
              currency="CHF"
            />
          </Col>
        </Row>

        <Row className="setadvisor-form-limit-determination-summary--total--row">
          <Col
            lg={8}
            md={8}
          >
            <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.Total.MaxGlobalLimit" />
          </Col>

          <Col
            className="text-end"
            lg={2}
            md={2}
          >
            <FormattedNumber
              value={get(data, 'calculatedGlobalLimit', 0)}
              // eslint-disable-next-line
              style="currency"
              currency="CHF"
            />
          </Col>
        </Row>
      </SubSectionContent>
    </SubSection>
  );
});

LimitDeterminationSummaryTotal.propTypes = {
  data: PropTypes.object
};

LimitDeterminationSummaryTotal.defaultProps = {
  data: {}
};

export default LimitDeterminationSummaryTotal;
