import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Row from './Row';

const ClientLanguage = React.memo(({ cardHolder }) => {
  return (
    <Row label={<FormattedMessage id="Freemium.Form.Summary.CardHolder.ClientLanguage" />}>
      {get(cardHolder, 'clientInformation.clientLanguage', '').toUpperCase()}
    </Row>
  );
});

ClientLanguage.propTypes = {
  cardHolder: PropTypes.object
};

ClientLanguage.defaultProps = {
  cardHolder: {}
};

export default ClientLanguage;
