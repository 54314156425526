import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { injectIntl } from 'react-intl';
import EmbossedLine from './EmbossedLine';

class EmbossedLineOne extends React.Component {
  constructor(props) {
    super(props);

    this.shouldRenderEmbossedLineTwo = this.shouldRenderEmbossedLineTwo.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(key, value) {
    const { onChange } = this.props;

    onChange(key, value);
  }

  shouldRenderEmbossedLineTwo() {
    const {
      accountHolderClientKey,
      cardHolderClientKey,
    } = this.props;

    return cardHolderClientKey !== accountHolderClientKey;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      embossedLineOne,
      embossedLineTwo,
      validations,
      intl,
      customerLanguage
    } = this.props;

    return (
      <>
        <EmbossedLine
          title={intl.formatMessage({ id: 'Freemium.Form.CreditCard.CardHolder.EmbossedLineCardHolder' })}
          id="embossedLineOne"
          value={embossedLineOne}
          onChange={this.onChange}
          validations={get(validations, 'embossedLineOne')}
          customerLanguage={customerLanguage}
        />
        {this.shouldRenderEmbossedLineTwo() && (
        <EmbossedLine
          title={intl.formatMessage({ id: 'Freemium.Form.CreditCard.CardHolder.EmbossedLineAccountHolder' })}
          id="embossedLineTwo"
          value={embossedLineTwo}
          onChange={this.onChange}
          validations={get(validations, 'embossedLineTwo')}
          customerLanguage={customerLanguage}
        />
        )}
      </>
    );
  }
}

EmbossedLineOne.propTypes = {
  embossedLineOne: PropTypes.string,
  embossedLineTwo: PropTypes.string,
  accountHolderClientKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  cardHolderClientKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  validations: PropTypes.object,
  intl: PropTypes.object.isRequired,
  customerLanguage: PropTypes.string
};

EmbossedLineOne.defaultProps = {
  embossedLineOne: '',
  embossedLineTwo: '',
  accountHolderClientKey: '',
  cardHolderClientKey: '',
  onChange: noop,
  validations: {},
  customerLanguage: 'de'
};

export default injectIntl(EmbossedLineOne);
