import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import TableRow from './TableRow';
import {
  SubSection,
  SubSectionContent,
  SubSectionTitle
} from '../../../../../../General/components/SubSection/index';

const LimitDeterminationSummaryIncomeAndAssets = React.memo(({
  customer,
  data,
  partners
}) => {
  const income = get(data, 'monthlyIncomes', []).map((item, index) => (
    <TableRow
      // eslint-disable-next-line
      key={`income-${index}`}
      customer={customer}
      data={item}
      partners={partners}
      type="income"
    />
  ));

  const assets = get(data, 'assets', []).map((item, index) => (
    <TableRow
      // eslint-disable-next-line
      key={`asset-${index}`}
      customer={customer}
      data={item}
      partners={partners}
      type="asset"
    />
  ));

  return (
    <SubSection>
      <SubSectionTitle>
        <FormattedMessage id="Freemium.Form.Summary.LimitDetermination.IncomeAndAssets.Title" />
      </SubSectionTitle>

      <SubSectionContent>
        {income}
        {assets}
      </SubSectionContent>
    </SubSection>
  );
});

LimitDeterminationSummaryIncomeAndAssets.propTypes = {
  customer: PropTypes.object,
  data: PropTypes.object,
  partners: PropTypes.object
};

LimitDeterminationSummaryIncomeAndAssets.defaultProps = {
  customer: undefined,
  data: {},
  partners: {}
};

export default LimitDeterminationSummaryIncomeAndAssets;
