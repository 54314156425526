import { all, call, put, select } from 'redux-saga/effects';
import { get } from 'lodash';

import action from '../../actions/Form/OptionPaperForm';
import getLilaSet from '../loadLilaSet';
import getContractDispatchRules from '../DispatchRule/getContractDispatchRules';
import { toIntIfSet } from '../../../../lib/Utils';

const {
  OPTION_PAPER_FORM_INIT_PENDING,
  OPTION_PAPER_FORM_INIT_FULFILLED,
  OPTION_PAPER_FORM_INIT_REJECTED
} = action;

export default function* initOptionPaperForm(request) {
  const { customerId } = request;

  yield put({ type: OPTION_PAPER_FORM_INIT_PENDING });

  try {
    const session = yield select((state) => get(state, 'login.session'));
    const lilaSet = yield call(getLilaSet, { dataKey: customerId });
    const contracts = yield all(
      get(lilaSet, 'other.ebankingContracts', []).map((c) => call(getContractDispatchRules, { dataKey: get(c, 'contractKey') }))
    );

    const payload = {
      clientKey: toIntIfSet(customerId),
      responsibleUser: toIntIfSet(get(session, 'id')),
      processCallOrigin: 'MAP',
      contracts
    };

    yield put({ type: OPTION_PAPER_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: OPTION_PAPER_FORM_INIT_REJECTED, error });

    return error;
  }
}
