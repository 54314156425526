import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import foreignAssetsAction from '../../actions/ForeignAsset/ForeignAssets';

const {
  FOREIGN_ASSETS_PENDING,
  FOREIGN_ASSETS_FULFILLED,
  FOREIGN_ASSETS_REJECTED
} = foreignAssetsAction;

export default function* getForeignAssets(request) {
  yield put({ type: FOREIGN_ASSETS_PENDING });

  try {
    const { customerId } = request;

    const query = QueryBuilder()
      .eq('customerId', customerId, { type: 'string' })
      .out('assetType.id', ['assetType-5999', 'assetType-6000'], { type: 'string' })
      .eq('isAtThirdParty', true)
      .limit(1000)
      .getQueryString();

    const url = `/person/customer-balance/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: FOREIGN_ASSETS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: FOREIGN_ASSETS_REJECTED, error });

    return error;
  }
}
