import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FileDropzone, ValidationResult } from '@evoja-web/react-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { noop, head, get, isEmpty } from 'lodash';
import { v4 } from 'uuid';
import { actions as documentPreviewActions } from '@evoja-web/react-document-preview';

import './LocalFileUpload.css';
import DocumentPreview from './DocumentPreview';

class LocalFileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.onFileChange = this.onFileChange.bind(this);
    this.onFileRemove = this.onFileRemove.bind(this);
  }

  onFileChange(key, accepted) {
    const {
      documentPreviewActions,
      id,
      onChange
    } = this.props;

    const fileId = v4();
    const payload = {
      id: fileId,
      blob: get(head(accepted), 'file')
    };

    documentPreviewActions.fileFulfilled({ dataKey: fileId, payload });

    onChange(id, `/file/${fileId}`);
  }

  onFileRemove() {
    const { id, onChange } = this.props;

    onChange(id);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      id,
      validations,
      value
    } = this.props;

    return (
      <Row className="setadvisor-form-common-file-upload">
        <Col
          className="setadvisor-form-common-file-upload--label"
          lg={4}
          md={4}
        >
          <FormattedMessage id="Freemium.Form.Common.FileUpload.Label" />
        </Col>

        <Col
          className="setadvisor-form-common-file-upload--input"
          lg={6}
          md={6}
        >
          <FileDropzone
            className={{ 'validatable-invalid': !isEmpty(get(validations, 'file')) }}
            id={id}
            onChange={this.onFileChange}
          />

          <ValidationResult
            show
            validations={validations}
          />
        </Col>

        <Col
          lg={2}
          md={2}
        >
          {value && (
            <DocumentPreview
              uri={value}
              onFileRemove={this.onFileRemove}
              removeable
            />
          )}
        </Col>
      </Row>
    );
  }
}

LocalFileUpload.propTypes = {
  documentPreviewActions: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string
};

LocalFileUpload.defaultProps = {
  onChange: noop,
  validations: {},
  value: undefined
};

function mapDispatchToProps(dispatch) {
  return {
    documentPreviewActions: bindActionCreators(documentPreviewActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(LocalFileUpload);
